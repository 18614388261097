import '../../../styles/scss/banner/banner.scss'
import { GEButton } from '../../../common/buttons'
import IconTelegram from '../../elements/svg_icons/IconTelegram'
import IconYoutube from '../../elements/svg_icons/IconYoutube'
import IconPen from '../../elements/svg_icons/IconPen'
import { useState } from 'react'
import ReviewModal from '../../modalwindows/ReviewModal'

const Banner = () => {

    const [showModal, setShowModal] = useState(false)

    const openLink = () => {
        window.open('https://t.me/geekenglishru', '_blank')
    }

    const openHelpLink = () => {
        window.open('https://www.youtube.com/watch?v=hQpfweaSPdc', '_blank')
    }

    const openReviewModal = () => {
        setShowModal(true)
    }

    return (
        <div id='banner'>


            <GEButton onClick={openReviewModal} className='banner__btn'
                      icon={<IconPen />}>Пожелания</GEButton>
            <GEButton onClick={openHelpLink} className='banner__btn'
                      icon={<IconYoutube />}>Как пользоваться</GEButton>
            <GEButton type='primary' onClick={openLink} className='banner__btn'
                      icon={<IconTelegram stroke='#ffffff' />}>Чат</GEButton>

            <ReviewModal isOpen={showModal} close={() => setShowModal(false)} />
        </div>
    )
}

export default Banner
