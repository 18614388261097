import { Tabs } from 'antd'
import Privileges from './tabs/Privileges'
import UserActivity from './tabs/UserActivity'
import { Helmet } from 'react-helmet'
import Summary from './tabs/Summary'
import Teachers from './tabs/Teachers'

const AdminPage = () => {
    const { TabPane } = Tabs

    return (
        <div id='admin-page'>
            <Helmet title='GE: Admin' />
            <Tabs defaultActiveKey='1'>
                <TabPane tab='Привилегии пользователей' key='1'>
                    <Privileges />
                </TabPane>
                <TabPane tab='Активность пользователей' key='2'>
                    <UserActivity />
                </TabPane>
                <TabPane tab='Сводка' key='3'>
                    <Summary />
                </TabPane>
                <TabPane tab='Переподаватели' key='4'>
                    <Teachers />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default AdminPage
