import '../../../../styles/scss/done-word-lists/done-word-lists-search.scss'
import { Flex, Input, Select } from 'antd'
import { useState } from 'react'
import { FC } from 'react'

interface DoneWordListsSearchComponentProps {
    fetchDoneWordList: (search: string | undefined, level: string | undefined, tag: string | undefined) => void
}

const DoneWordListsSearchComponent: FC<DoneWordListsSearchComponentProps> = ({ fetchDoneWordList }) => {

    const [level, setLevel] = useState<string | undefined>(undefined)
    const [tag, setTag] = useState<string | undefined>(undefined)
    const [search, setSearch] = useState<string | undefined>(undefined)

    const { Search } = Input

    const onChangeSelectLevel = (val: string): void => {
        setLevel(val)
        fetchDoneWordList(search, val, tag)
    }

    const onChangeSelectTags = (val: string): void => {
        setTag(val)
        fetchDoneWordList(search, level, val)
    }
    const onChangeSearch = (val: any): void => {
        setSearch(val.target.value)
    }

    const searchName = (): void => {
        fetchDoneWordList(search, level, tag)
    }


    return (
        <Flex gap='36px' className='done-word-search'>

            <Search placeholder='Поиск по названию' allowClear onChange={onChangeSearch} onSearch={searchName}
                    enterButton />

            <Flex gap='16px' className='done-word-search__select-area'>
                <Select
                    className='done-word-search__select'
                    placeholder='Поиск по уровню'
                    onChange={onChangeSelectLevel}
                    allowClear
                    options={[
                        { value: 'A1', label: 'А1 – начальный' },
                        { value: 'A2', label: 'А2 – ниже среднего' },
                        { value: 'B1', label: 'В1 – средний' },
                        { value: 'B2', label: 'В2 – выше среднего' },
                        { value: 'C1', label: 'C1 – продвинутый' },
                        { value: 'C2', label: 'C2 – профессиональный уровень владения' }
                    ]}
                />
                {/* <Select
                    className='done-word-search__select'
                    placeholder='Поиск по тегу'
                    onChange={onChangeSelectTags}
                    allowClear
                    options={[
                        { value: 0, label: 'Английский - русский' },
                        { value: 1, label: 'Слова из книги' },
                        { value: 2, label: 'Важные слова для начинающих' }
                    ]}
                />*/}
            </Flex>

        </Flex>
    )
}

export default DoneWordListsSearchComponent
