export const test = [
    {
        id: 1,
        title: 'You’ve been travelling all day. You ____ tired.',
        variants: [
            { id: 1, title: 'can’t be', isTrue: false },
            { id: 2, title: 'must be', isTrue: true },
            { id: 3, title: 'must', isTrue: false },
            { id: 4, title: 'can’t', isTrue: false }
        ]
    },
    {
        id: 2,
        title: 'You’ve just had lunch. You ____ hungry already.',
        variants: [
            { id: 1, title: 'must be', isTrue: false },
            { id: 2, title: 'must', isTrue: false },
            { id: 3, title: 'can’t', isTrue: false },
            { id: 4, title: 'can’t be', isTrue: true }
        ]
    },
    {
        id: 3,
        title: 'Sue hasn’t contacted me. She ____ got my message.',
        variants: [
            { id: 1, title: 'must have', isTrue: false },
            { id: 2, title: 'can’t have', isTrue: true },
            { id: 3, title: 'must be', isTrue: false },
            { id: 4, title: 'can’t be', isTrue: false }
        ]
    },
    {
        id: 4,
        title: 'I didn’t hear the phone. I ____ been asleep.',
        variants: [
            { id: 1, title: 'can’t have', isTrue: false },
            { id: 2, title: 'can’t be', isTrue: false },
            { id: 3, title: 'must have ', isTrue: true },
            { id: 4, title: 'must be', isTrue: false }
        ]
    },
    {
        id: 5,
        title: 'Sue ____ got my message.',
        variants: [
            { id: 1, title: 'couldn’t have', isTrue: true },
            { id: 2, title: 'can’t have', isTrue: true },
            { id: 3, title: 'must be', isTrue: false },
            { id: 4, title: 'must have', isTrue: false }
        ]
    },
    {
        id: 6,
        title: 'I’ve lost one of my gloves. I must ____ it somewhere.',
        variants: [
            { id: 1, title: 'are dropping', isTrue: false },
            { id: 2, title: 'could have', isTrue: false },
            { id: 3, title: 'have been dropped', isTrue: false },
            { id: 4, title: 'have dropped', isTrue: true }
        ]
    },
    {
        id: 7,
        title: 'Ted isn’t at work today. He must ____ ill.',
        variants: [
            { id: 1, title: 'be', isTrue: true },
            { id: 2, title: 'been', isTrue: false },
            { id: 3, title: 'has been', isTrue: false },
            { id: 4, title: 'has be', isTrue: false }
        ]
    }
]
