import WordUserStats from './WordUserStats'
import PropTypes from 'prop-types'
import { useAppSelector } from '../../../../../store/hooks'
import { userSelector } from '../../../../../store/global'

const PositionTopStats = (props) => {
    const { usersList, position } = props

    const user = useAppSelector(userSelector)

    const usersCount = usersList?.length
    const currentUser = user?.login
    const userMatch = usersList?.find((u) => u.login === user.login)

    return (
        <li className='top-stats__list-item'>
            <strong className={position < 4 ? 'top-stats__position position-top' : 'top-stats__position'}>
                {position}
            </strong>
            <div className='top-stats__users-wrapper'>
                {usersCount === 1 ? (
                    <WordUserStats
                        login={usersList[0].login}
                        premium={usersList[0].premium}
                        countWords={usersList[0].countUserWords}
                    />
                ) : userMatch ? (
                    <WordUserStats
                        login={currentUser}
                        premium={usersList[0].premium}
                        usersCount={usersCount - 1}
                        countWords={usersList[0].countUserWords}
                    />
                ) : (
                    <WordUserStats
                        login={usersList[0].login}
                        premium={usersList[0].premium}
                        usersCount={usersCount - 1}
                        countWords={usersList[0].countUserWords}
                    />
                )}
            </div>
        </li>
    )
}

PositionTopStats.propTypes = {
    position: PropTypes.number.isRequired, //Позиция
    usersList: PropTypes.array.isRequired //Пользователи
}

export default PositionTopStats
