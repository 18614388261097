/**
 * Дубль бэк GeekExceptionType
 */
export enum GeekExceptionType {
    GENERAL = 'GENERAL',
    FILL_WORD = 'FILL_WORD',
    MAX_NOT_PREMIUM_COMMUNITY_AMOUNT = 'MAX_NOT_PREMIUM_COMMUNITY_AMOUNT',
    SPECIFIC = 'SPECIFIC',
    VALID = 'VALID',
    CARD_LIMIT = 'CARD_LIMIT'
}