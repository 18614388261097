import '../../../styles/css/elements/svg_icons/icon-btn.css'

function IconBtnCancel(props) {
    return (
        <svg
            onClick={() => (props.onClick ? props.onClick() : false)}
            className={'icon-btn ' + props.class}
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 32 32'
        >
            <title>Отменить</title>
            <path d='M4.684 27.313c-6.247-6.247-6.247-16.38 0-22.625 6.25-6.25 16.382-6.25 22.632 0 6.247 6.246 6.247 16.378 0 22.625-6.25 6.25-16.382 6.25-22.632 0zM24.488 7.515c-4.691-4.688-12.289-4.688-16.972 0-4.688 4.683-4.688 12.28 0 16.968 4.683 4.688 12.28 4.688 16.972 0 4.684-4.687 4.684-12.285 0-16.968zM11.758 23.07L8.93 20.242 13.172 16 8.93 11.758l2.828-2.828L16 13.172l4.242-4.242 2.828 2.828L18.828 16l4.242 4.242-2.828 2.828L16 18.828zm0 0' />
        </svg>
    )
}

export default IconBtnCancel
