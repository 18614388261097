import { WordList } from '../../shared/types/api'
import { ID } from '../../shared/types/api'

export function setWlLoadedAfterUpdate(wlArr: Array<WordList>, data: WordList, id: number) {
    return wlArr.map((wl) => {
        if (wl.id === id) {
            return {
                ...wl,
                listName: data.listName,
                prevListName: data.listName,
                sideTwoLang: data.sideTwoLang,
                sideOneLang: data.sideOneLang,
                editMode: false,
                isLoading: false
            }
        }
        return wl
    })
}

export function setWordListLoading(wlArr: Array<WordList>, id: ID) {
    return wlArr.map((wl) => {
        if (wl.id === id) {
            return {
                ...wl,
                isLoading: true
            }
        }
        return wl
    })
}

export class setWlLoadedAfterCreate {
}