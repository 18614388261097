import { configureStore } from '@reduxjs/toolkit'
import globalReducer from './global'
import wordListsReducer from './wordLists'
import dialogsReducer from './dialogs'
import communitiesReducer from './communities'
import wordsReducer from './words'

export default configureStore({
    reducer: {
        global: globalReducer,
        wordLists: wordListsReducer,
        dialogs: dialogsReducer,
        communities: communitiesReducer,
        words: wordsReducer
    }
})
