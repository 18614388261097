import { FC } from 'react'
import { GEButton } from '../../../../../common/buttons'


interface CopyDoneCardListBtnProps {
    disabledButtons: boolean,
    deleteDoneWordList: () => void,
}

const DeleteDoneCardListBtn: FC<CopyDoneCardListBtnProps> = ({
                                                                 disabledButtons,
                                                                 deleteDoneWordList
                                                             }) => {
    return (
        <div
            className={
                disabledButtons ? 'not_allowed_cursor disabled_button' : 'pointer_cursor'
            }
            title={'Удалить список'}
            onClick={deleteDoneWordList}
        >
            <GEButton
                className='width170px'
                danger
                type='primary'
                disabled={disabledButtons}
            >
                Удалить список
            </GEButton>
        </div>
    )
}

export default DeleteDoneCardListBtn
