import '../../../../styles/scss/pages/premium/premium-new.scss'
import { Helmet } from 'react-helmet'
import { BuyingCard } from './blocks/BuyingCard'
import { VideoSection } from './blocks/VideoSection'
import IconLockPremium from '../../../elements/svg_icons/premium/IconLockPremium'
import IconCommunityPremium from '../../../elements/svg_icons/premium/IconCommunityPremium'
import IconTelegramPremium from '../../../elements/svg_icons/premium/IconTelegramPremium'
import IconCardsPremium from '../../../elements/svg_icons/premium/IconCardsPremium'
import { Link } from 'react-router-dom'
import { NavigationPages } from '../../../../common/navigation/navigation-pages'
import { useEffect } from 'react'
import * as API_TELEGRAM from '../../../../shared/http/telegram-notification'
import IconVolumeUp from '../../../elements/svg_icons/IconVolumeUp'
import { GELink } from '../../../../common/link/GELink'

const PremiumNew = ({ offers }) => {

    useEffect(() => {
        API_TELEGRAM.notifyTelegramBot('Перешел на новую страницу покупки')
    }, [])

    return (
        <div id='premium'>
            <Helmet title='Покупка премиум подписки' />
            <header className='premium__header'>
                <h1>Geekenglish Премиум</h1>
                <h2>Сделай обучение проще</h2>
                <h3>Ресурс предоставляет услуги с 2019 года</h3>
            </header>
            <main>
                <section className='premium__card-area'>
                    {offers.map((item) => (
                        <BuyingCard key={item.price}
                                    link={item.open}
                                    price={item.price}
                                    text={item.text}
                                    title={item.title}
                                    subTitle={item.subTitle}
                        />
                    ))}
                </section>
                <VideoSection title={'Добавляй слова с ИИ'}
                              gifLink={'../videos/card-ai-generation.mov'} />
                <section className='premium__icons-area'>
                    <div className='premium__icon-content'>
                        <IconLockPremium />
                        <span>Открой доступ к премиум карточкам </span>
                    </div>
                    <div className='premium__icon-content'>
                        <IconCommunityPremium />
                        <span>Создавай неограниченное число сообществ </span>
                    </div>
                </section>
                <VideoSection title={'Создавай списки карточек из текста в один клик'}
                              gifLink={'../videos/cardlist-generation.mov'} />
                <section className='premium__icons-area'>
                    <div className='premium__icon-content'>
                        <IconCardsPremium />
                        <span>Создавай неограниченное количество карточек  </span>
                    </div>
                    <div className='premium__icon-content'>
                        <IconTelegramPremium />
                        <span>Добавляй слова через телеграмм </span>
                    </div>
                </section>
                <section className='premium__card-area premium__card-area__1'>
                    {offers.map((item, i) => (
                        <BuyingCard key={item.price}
                                    link={item.open}
                                    price={item.price}
                                    text={item.text}
                                    title={item.title}
                                    subTitle={item.subTitle}
                        />
                    ))}
                </section>
            </main>
            <footer className='premium__footer'>
                <div>

                    <GELink className='premium__footer-aferta' to={NavigationPages.PREMIUM_HELP}> Возникла проблема с
                        активацией подписки?</GELink>


                    <GELink className='premium__footer-aferta'
                            to={NavigationPages.AFERTA}> Договор-оферты</GELink>

                </div>
            </footer>
        </div>
    )
}

export default PremiumNew