import { FC, useEffect, useState } from 'react'
import '../../../../styles/scss/communities/communities.scss'
import { CommunityItem } from './CommunityItem'
import Loading from '../../../elements/loading/Loading'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { communitiesDataSelector, setCommunitiesRoutine } from '../../../../store/communities'
import { fetchCommunities } from '../../../../store/communities/extraReducers'
import { CommunityDto } from '../../../../shared/types/api'
import CommunityUtils from '../../../utils/CommunityUtils'


interface CommunityTabProps {
    update: boolean
}

export const MyCommunities: FC<CommunityTabProps> = ({ update }) => {

    const [mainLoading, setMainLoading] = useState<boolean>(false)

    const dispatch = useAppDispatch()
    const communities = useAppSelector<Array<CommunityDto>>(communitiesDataSelector)

    useEffect(() => {
        setMainLoading(true)
        dispatch(fetchCommunities()).finally(() => {
            setMainLoading(false)
        })
    }, [])

    const updateCommunity = (c: CommunityDto) => {
        const newCommunities = communities.map((com) => {
            if (com.id === c.id) {
                return CommunityUtils.parseCommunity(c)
            }
            return com
        })
        dispatch(setCommunitiesRoutine(newCommunities))
    }


    return (
        <div className='communities__main'>
            {mainLoading && <Loading />}
            {!mainLoading && communities.length === 0 && <span>Похоже сообществ пока нет</span>}
            {!mainLoading && communities && communities.map((item) => (
                <CommunityItem key={item.id} userHas={true} item={item} i={item.id} canEdit={item.canEdit}
                               updateCommunity={updateCommunity} />
            ))}
        </div>
    )
}