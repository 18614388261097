import '../../styles/css/dictionary/modalwindow/modalw.css'
import { FC, useState } from 'react'
import { Button, Flex, Input, Modal } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import * as API_COMMUNITY from '../../shared/http/community'
import { CommunityDto } from '../../shared/types/api'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { communitiesDataSelector, setCommunitiesRoutine } from '../../store/communities'
import CreateTagElement from '../blocks/create-tag-element/CreateTagElement'


interface UpdateCommunityDialogProps {
    showWindow: boolean,
    cancelWindow: () => void,
    community: CommunityDto
}

const UpdateCommunityDialog: FC<UpdateCommunityDialogProps> = ({ showWindow, cancelWindow, community }) => {

    const [tags, setTags] = useState<Array<string>>(community.tags ? community.tags : [])
    const [name, setName] = useState<string>(community.name)
    const [description, setDescription] = useState<string>(community.description)
    const [loading, setLoading] = useState<boolean>(false)

    const dispatch = useAppDispatch()
    const communities = useAppSelector<Array<CommunityDto>>(communitiesDataSelector)

    const updateCommunity = () => {
        setLoading(true)
        API_COMMUNITY.updateCommunity(community.id, { name: name, description: description, tags: tags })
            .then(c => {
                const newCommunities = communities.map(com => {
                    if (com.id === c.id) {
                        return {
                            ...community,
                            tags: c.tags,
                            name: c.name,
                            description: c.description
                        }
                    }
                    return com
                })

                dispatch(setCommunitiesRoutine(newCommunities))
            })
            .finally(() => {
                cancelWindow()
                setLoading(false)
            })
    }

    const handleChangeName = (event: any) => {
        setName(event.target.value)
    }

    const handleChangeDescription = (event: any) => {
        setDescription(event.target.value)
    }

    return (
        <Modal destroyOnClose={true} title='Редактировать сообщество'
               onCancel={cancelWindow}
               footer={[
                   <Button key='submit' onClick={cancelWindow}>
                       Отмена
                   </Button>,
                   <Button
                       disabled={!name && name.length === 0}
                       onClick={updateCommunity}
                       loading={loading}
                       type='primary'
                   >
                       Обновить
                   </Button>
               ]}
               open={showWindow}>
            <Flex className='margin10top' gap={'20px'} vertical>
                <Input maxLength={42} placeholder='Название сообщества' value={name}
                       onChange={(e) => handleChangeName(e)} />
                <TextArea maxLength={600} rows={4} placeholder='Краткое описание...'
                          value={description}
                          onChange={(e) => handleChangeDescription(e)} />
                <CreateTagElement tags={tags} setTags={setTags} />
            </Flex>
        </Modal>
    )
}

export default UpdateCommunityDialog