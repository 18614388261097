import {Button, Modal, Table} from 'antd'
import {FC} from 'react'
import Privileges from '../../const/Privileges'
import '../../styles/css/dictionary/modalwindow/add_privilege_modal.css'
import '../../styles/css/dictionary/modalwindow/main_dialog.css'
import {GEButton} from "../../common/buttons";

interface AdminAddPrivilegeModalDialogProps {
    closeDialog: () => void,
    addPrivilege: (s: any) => void,
    open: boolean;

}

const AdminAddPrivilegeModalDialog: FC<AdminAddPrivilegeModalDialogProps> = ({closeDialog, addPrivilege, open}) => {

    const columns = [
        {
            title: 'Роль',
            dataIndex: 'name',
            key: 'id'
        },
        {
            title: 'Расшифровка',
            dataIndex: 'description',
            key: 'id'
        },
        {
            title: 'Action',
            dataIndex: 'description',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (_: any, privilege: any) => {
                return (
                    <GEButton onClick={() => addPrivilege(privilege)} style={{marginRight: 8}}>
                        Add
                    </GEButton>
                )
            }
        }
    ]
    const DEFAULT_CLOSE_BUTTON_TEXT = 'ЗАКРЫТЬ'


    return (
        <Modal onCancel={closeDialog}
               footer={[
                   <Button type='primary' onClick={closeDialog}>
                       {DEFAULT_CLOSE_BUTTON_TEXT}
                   </Button>,
               ]}
               open={open}>
            <div id='add-privilege-dialog_content'>
                <Table
                    // @ts-ignore
                    columns={columns}
                    dataSource={Object.values(Privileges)}
                    pagination={false}
                    locale={{emptyText: 'Не удалось загрузить привилегии'}}
                />
            </div>
        </Modal>
    )
}

export default AdminAddPrivilegeModalDialog
