import '../../../styles/css/loading/line_loading.css'

const LineLoading = () => {
    return (
        <div className='lds-ellipsis'>
            <div />
            <div />
            <div />
            <div />
        </div>
    )
}

export default LineLoading
