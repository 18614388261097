export const test = [
    {
        id: 1,
        title: 'We ______ the camp by 10 o’clock, I believe.',
        variants: [
            { id: 1, title: 'reached', isTrue: false },
            { id: 2, title: 'will have reached', isTrue: true },
            { id: 3, title: 'were reaching', isTrue: false }
        ]
    },
    {
        id: 2,
        title: 'I hope, we ______ half of our way by tomorrow.',
        variants: [
            { id: 1, title: 'are driving', isTrue: false },
            { id: 2, title: 'will have drived', isTrue: false },
            { id: 3, title: '’ll have driven', isTrue: true }
        ]
    },
    {
        id: 3,
        title: 'We’ll ______ decorating the room before you get back.',
        variants: [
            { id: 1, title: 'finish', isTrue: false },
            { id: 2, title: 'have finished', isTrue: true }
        ]
    },
    {
        id: 4,
        title: 'By the end of August we’ll ______ .',
        variants: [
            { id: 1, title: 'will move', isTrue: false },
            { id: 2, title: 'have moved', isTrue: true },
            { id: 3, title: 'would move', isTrue: false }
        ]
    },
    {
        id: 5,
        title: 'If we don’t hurry, the party will ______ before we get there.',
        variants: [
            { id: 1, title: 'finishes', isTrue: false },
            { id: 2, title: 'have finished', isTrue: true }
        ]
    },
    {
        id: 6,
        title: 'I hope they ______ this unpleasant episode by the time they meet again.',
        variants: [
            { id: 1, title: '‘ll be forgotten', isTrue: false },
            { id: 2, title: 'will have forgotten', isTrue: true },
            { id: 3, title: 'not book', isTrue: false }
        ]
    },
    {
        id: 7,
        title: 'I hope we will ______ the market research by January.',
        variants: [
            { id: 1, title: 'have done', isTrue: true },
            { id: 2, title: 'are doing', isTrue: false },
            { id: 3, title: 'be doing', isTrue: false },
            { id: 4, title: 'will do', isTrue: false }
        ]
    },
    {
        id: 8,
        title: 'By the time I come they ______ .',
        variants: [
            { id: 1, title: 'will be going', isTrue: false },
            { id: 2, title: 'will go', isTrue: false },
            { id: 3, title: 'will have gone', isTrue: true }
        ]
    },
    {
        id: 9,
        title: 'As you ______ , he has cut off his beard.',
        variants: [
            { id: 1, title: 'will notice', isTrue: false },
            { id: 2, title: 'will have noticed', isTrue: true },
            { id: 3, title: 'are noticing', isTrue: false }
        ]
    },
    {
        id: 10,
        title: 'Next Thursday, I ______ my present car for exactly 20 years.',
        variants: [
            { id: 1, title: 'will have owned', isTrue: true },
            { id: 2, title: '‘ll not do', isTrue: false },
            { id: 3, title: 'will be owning', isTrue: false }
        ]
    }
]
