import { Routes, Route, Link, useLocation } from 'react-router-dom'
import '../../../../styles/scss/rules/rulesCard.scss'
import { useEffect, useState } from 'react'
import * as API_RULES from '../../../../shared/http/rule'
import Constants from '../../../../const/Constants'
import { RulesMap } from './RulesMap'
import RuleCard from '../../../elements/cards/RuleCard'
import AdminRuleItemPanel from './AdminRuleItemPanel'
import PrivilegesUtil from '../../../utils/PrivilegesUtil'
import Privileges from '../../../../const/Privileges'
import { Switch as SwitchUI } from 'antd'
import { Helmet } from 'react-helmet'
import { useAppSelector } from '../../../../store/hooks'
import { userSelector } from '../../../../store/global'
import {GELink} from "../../../../common/link/GELink";

const Rules = (props) => {
    const user = useAppSelector(userSelector)

    const { pathname } = useLocation()

    const [rules, setRules] = useState([])
    const [showActiveRulesFlag, setShowActiveRulesFlag] = useState(false)

    useEffect(() => {
        const canEditPrivilege = PrivilegesUtil.hasPrivilege(props.user, Privileges.CHANGE_RULES)
        if (canEditPrivilege) {
            getAllRules()
        } else {
            getRules()
        }
    }, [])

    const getRules = () => {
        API_RULES.getRules().then((resp) => {
            resp.forEach((data) => {
                data.component = RulesMap[data.componentName]
            })
            setRules(resp)
        })
    }

    const getAllRules = () => {
        API_RULES.getAllRules().then((resp) => {
            resp.forEach((data) => {
                data.component = RulesMap[data.componentName]
            })
            setRules(resp)
        })
    }

    const showActiveRules = (checked) => {
        setShowActiveRulesFlag(checked)
    }

    const isRuleListPage = () => {
        return window.location.pathname.endsWith('/rules')
    }

    return (
        <div id='rules'>
            <Helmet title='GE: Правила' />
            {PrivilegesUtil.hasPrivilege(user, Privileges.CHANGE_RULES) && isRuleListPage() && (
                <div className='rules_admin_button'>
                    <SwitchUI onChange={showActiveRules} />
                    <span> Активные правила </span>
                </div>
            )}
            {pathname === '/rules' && (
                <ul className='rules_rules-area'>
                    {rules
                        .filter((rule) => rule.active || !showActiveRulesFlag)
                        .map((rule) => (
                            <li key={rule.id}>
                                <GELink to={rule.path}>
                                    <RuleCard
                                        name={rule.name}
                                        type={rule.type}
                                        status={rule.status}
                                        image={`${Constants.queryConst}/file/rule/image?id=${rule.imageId}`}
                                    />
                                </GELink>
                                <AdminRuleItemPanel id={rule.id} activeFlag={rule.active} />
                            </li>
                        ))}
                </ul>
            )}
            <div className='rule_rules-content'>
                <Routes>
                    {rules.map((rule) => (
                        <Route key={rule.id} path={rule.path} element={rule.component(rule)} />
                    ))}
                </Routes>
            </div>
        </div>
    )
}

export default Rules
