import { Upload } from 'antd'
import PrivilegesUtil from '../../../utils/PrivilegesUtil'
import Privileges from '../../../../const/Privileges'
import * as API_RULE from '../../../../shared/http/rule'
import CheckBox from '../../../elements/switch/CheckBox'
import { GEButton } from '../../../../common/buttons'
import { useAppSelector } from '../../../../store/hooks'
import { userSelector } from '../../../../store/global'

const AdminRuleItemPanel = (props) => {
    const { id = 0, activeFlag = false } = props
    const user = useAppSelector(userSelector)

    return (
        <>
            {PrivilegesUtil.hasPrivilege(user, Privileges.CHANGE_RULES) && (
                <div className='rule-card_image'>
                    <CheckBox
                        onChange={() => updateActive(id)} text={'Active'}
                        value={activeFlag}
                        toolTipText={'Сделать правило активным'}
                    />
                    <Upload customRequest={uploadImage} data={id} accept='image/png, image/jpeg'>
                        <GEButton>Click to Upload</GEButton>
                    </Upload>
                </div>
            )}
        </>
    )
}

const uploadImage = (info) => {
    API_RULE.updatePhoto(info.data, info.file).then(() => {
        info.onSuccess()
    })
}
const updateActive = (ruleId) => {
    API_RULE.updateActive(ruleId).then(() => {
        window.location.reload()
    })
}

export default AdminRuleItemPanel
