import RulesHeader from '../../blocks/RulesHeader'
import NumberBlock from '../../blocks/NumberBlock'
import TaskExample from '../../blocks/TaskExample'
import Example from '../../blocks/Example'
import FactRule from '../../blocks/FactRule'
import TableThree from '../../blocks/TableThree'
import RuleTestWrapper from '../../RuleTestWrapper'
import { test } from './test'

const PresentPerfect = (props) => {
    return (
        <div>
            <RulesHeader title='Present perfect' subtitle='Настоящее совершенное' />

            <NumberBlock numberBlock='1' />
            <TaskExample taskExample='Изучите следующую ситуацию:' />
            <Example
                engExample={['Tom is looking for his key. He can’t find it. He ', <u>has lost</u>, ' his key.']}
                rusExample='Том ищет свой ключ. Он не может его найти. Он потерял свой ключ.'
            />
            <p>
                {' '}
                He <b>has lost</b> his key = он потерял свой ключ = он потерял его недавно, у него до сих пор нет ключа.
                Present perfect — это настоящее совершенное время в английском языке. Оно обозначает действие, которое
                завершилось в настоящий момент времени. Нам важен результат этого действия.
            </p>
            <FactRule factRule='Существительное + have/has + (глагол+ed/3-форма) это the present perfect simple:' />
            <TableThree
                elements={[
                    {
                        left: 'I/we/you/they',
                        center: 'have (= I’ve etc.)',
                        right: '1-я форма правильного глагола+ed или 3-я форма неправильного глагола'
                    },
                    {
                        left: 'He/she/it',
                        center: 'has (= he’s etc.)',
                        right: '1-я форма правильного глагола+ed или 3-я форма неправильного глагола'
                    }
                ]}
            />

            <NumberBlock numberBlock='2' />
            <p>
                {' '}
                Когда мы говорим, что ‘something <b>has happened</b>’ (‘что-то <b>случилось</b>’), обычно это новая
                информация:
            </p>
            <Example
                engExample={[<u>I’ve lost</u>, ' my passport so I can’t get on the plane!']}
                rusExample={['Я ', <u>потерял</u>, ' свой паспорт, я не смогу пройти в самолет!']}
            />
            <p>
                {' '}
                Использование present perfect требует наличия связи с настоящим (now). Прошедшее действие имеет
                результат сейчас:
            </p>
            <Example
                engExample={['Where’s your key? I don’t know. ', <u>I’ve lost</u>, ' it.']}
                rusExample={['Где твой ключ? Я не знаю. Я ', <u>потерял его</u>, '. (= У меня его сейчас нет)']}
            />

            <NumberBlock numberBlock='3' />
            <p>
                {' '}
                Present perfect можно использовать со словами <b>just</b> , <b>already</b> и <b>yet</b>.
            </p>
            <p>
                {' '}
                <b>Just</b> = только что, совсем недавно:
            </p>
            <Example
                engExample={['Hello. ', <u>Have</u>, ' you ', <u>just arrived</u>, '?']}
                rusExample={['Привет. Ты ', <u>только что приехал</u>, '?']}
            />
            <p>
                {' '}
                Мы используем <b>already</b> (уже), чтобы сказать, что что-то произошло быстрее, чем ожидалось:
            </p>
            <Example
                engExample={['What time is Tomas leaving? He’s ', <u>already gone</u>, '.']}
                rusExample={['Во сколько Томас уезжает? Он ', <u>уже уехал</u>, '.']}
            />
            <p>
                {' '}
                <b>Yet</b> = до сих пор, ещё; уже (в вопр. предложениях). <b>Yet</b> показывает, что говорящий ожидает,
                что что-то должно произойти. Используйте <b>yet</b> только в вопросительных и отрицательных
                предложениях:
            </p>
            <Example
                engExample={['', <u>Has</u>, 'it ', <u>stopped</u>, ' raining ', <u>yet</u>, ' ?']}
                rusExample={['Дождь ', <u>уже прекратился</u>, '?']}
            />

            <NumberBlock numberBlock='4' />
            <p>
                {' '}
                Заметьте разницу между <b>gone (to)</b> и <b>been (to)</b>:
            </p>
            <Example
                engExample={['Vlad is on holiday. He ', <u>has gone to</u>, ' Italy.']}
                rusExample={[
                    'Влад в отпуске. Он ',
                    <u>уехал в</u>,
                    ' Италию. (= он сейчас в Италии или на пути в Италию)'
                ]}
            />
            <Example
                engExample={['Alex is back home now. He ', <u>has been to</u>, ' Italy.']}
                rusExample={[
                    'Алекс уже вернулась домой. Он ',
                    <u>был в</u>,
                    ' Италии. (= сейчас он вернулся из Италии)'
                ]}
            />

            <RuleTestWrapper value={test} rule={props} />
        </div>
    )
}

export default PresentPerfect
