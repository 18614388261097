const Card = (props) => {
    const { className = '', title = '', text = '', image = null, imgType = 'container' } = props
    const backgroundImage = imgType === 'background' ? { background: `url('${image}')` } : {}

    return (
        <div className={'cards__card ' + className} style={backgroundImage}>
            {imgType === 'container' ? (
                <>
                    <h3 className={'cards__title'}>{title}</h3>
                    <img className={'cards__image'} src={image} alt={'Test Eng-Rus'} />
                    <p className={'cards__text'}>{text}</p>
                </>
            ) : (
                <>
                    <h3 className={'cards__title background-card-title'}>{title}</h3>
                    <p className={'cards__text background-card-text'}>{text}</p>
                </>
            )}
        </div>
    )
}

export default Card
