import { Input, Modal, Flex } from 'antd'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { translateCardText } from '../../../../../shared/http/textTranslator'
import '../../../../../styles/scss/word-item/worditem.scss'
import WordUtils from '../../../../utils/WordUtils'
import { debounce } from '../../../../utils/debounce'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { premiumStatusSelector } from '../../../../../store/global'
import { GEButton } from '../../../../../common/buttons'
import { setWordsRoutine, wordsDataSelector } from '../../../../../store/words'
import { createNewWord, deleteWord, updateWord } from '../../../../../store/words/extraReducers'
import ConfirmModalDialog from '../../../../modalwindows/ConfirmModalDialog'
import SaveAllWordButton from './SaveAllWordButton'
import * as API_WORD from '../../../../../shared/http/word'

const WordItem = (props) => {
    const words = useAppSelector(wordsDataSelector)
    const dispatch = useAppDispatch()
    const setWordsRedux = (w) => dispatch(setWordsRoutine(w))
    const isPremium = useAppSelector(premiumStatusSelector)
    const { sideOneLang, sideTwoLang } = props.cardList
    const { id, rusWord, engWord, editMode, loading } = props.wordItem
    const [validRus, setValidRus] = useState(true)
    const [validEng, setValidEng] = useState(true)
    const [firstTranslateValue, setFirstTranslateValue] = useState(null)
    const [secondTranslateValue, setSecondTranslateValue] = useState(null)
    const [showConfirmModal, setShowConfirmModal] = useState(false)

    const updateWordRedux = (wordListId, word) =>
        dispatch(
            updateWord({
                wordListId: wordListId,
                word: word
            })
        )
    const createNewWordRedux = (wordListId, word) =>
        dispatch(
            createNewWord({
                wordListId: wordListId,
                word: word
            })
        )


    /**
     * Перевести слово в режим редактирования
     * @param id слова
     */
    const editWorld = (id) => {
        let wordsMap = words.map((w) => {
            if (w.id === id) {
                return {
                    ...w,
                    editMode: true
                }
            }
            return w
        })
        setWordsRedux(wordsMap)
    }

    /**
     * Изменить слово
     * @param value - новое значение
     * @param id слова
     * @param lang - [engValue || rusValue] название поля для смены
     */
    const onChange = (value, id, lang) => {
        let wordsMap = words.map((w) => {
            if (w.id === id) {
                const updated = Object.assign({}, w)
                updated[lang] = value
                return updated
            }
            return w
        })
        setWordsRedux(wordsMap)
    }

    /**
     * Отменить режим редактирования слова
     * @param id слова
     */
    const cancelEdit = (id) => {
        if (WordUtils.isNewEntity(id)) {
            let wordsFilter = words.filter((w) => w.id !== id)
            setWordsRedux(wordsFilter)
        } else {
            let wordsMap = words.map((w) => {
                if (w.id === id) {
                    return {
                        ...w,
                        editMode: false,
                        engWord: w.prevEngWord,
                        rusWord: w.prevRusWord
                    }
                }
                return w
            })
            setWordsRedux(wordsMap)
        }
    }

    /**
     * Применить отредактированные значения
     * @param id слова
     */
    const applyEdit = (id) => {
        let word = props.wordItem
        if (word.engWord.length > 0 && word.rusWord.length > 0) {
            if (!WordUtils.isNewEntity(id)) {
                updateWordRedux(props.cardList.id, word)
            } else {
                createNewWordRedux(props.cardList.id, word)
            }
        } else {
            Modal.error({
                title: 'Ошибка!',
                content: 'Заполните стороны карточки'
            })
        }
    }

    const deleteWordRedux = (wordListId, wordId) =>
        dispatch(
            deleteWord({
                wordListId: wordListId,
                wordId: wordId
            })
        )

    /**
     * Удалить слово Word
     * @param id - id слова
     */
    const delWord = (wid) => {
        deleteWordRedux(props.cardList.id, wid)
    }

    /**
     * Показать предупреждение перед удалением слова
     * @param id
     */
    const delWordWindow = () => {
        setShowConfirmModal(true)
    }

    const onChangeWord = (e, lang) => {
        const value = typeof e !== 'string' ? e.target.value : e
        const valid = WordUtils.validateWordString(value)
        if (lang === 'engWord') {
            setValidEng(valid)
        } else {
            setValidRus(valid)
        }
        onChange(value, id, lang)
    }

    const changeSecondTranslateValue = async (e) => {
        if (e.target.value) {
            const resp = await translateCardText(e.target.value, sideOneLang, sideTwoLang)
            if (resp.success) {
                setSecondTranslateValue(resp.data.text)
            }
        } else {
            setSecondTranslateValue(null)
        }
    }
    const changeFirstTranslateValue = async (e) => {
        if (e.target.value) {
            const resp = await translateCardText(e.target.value, sideTwoLang, sideOneLang)
            if (resp.success && isPremium) {
                setFirstTranslateValue(resp.data.text)
            }
        } else {
            setFirstTranslateValue(null)
        }
    }
    const debChangeSecond = useMemo(() => debounce(changeSecondTranslateValue, 1000), [sideOneLang, sideTwoLang])
    const debChangeFirst = useMemo(() => debounce(changeFirstTranslateValue, 1000), [sideTwoLang, sideTwoLang])
    /**
     * Отменить изменения слова
     */
    const cancelChanges = () => {
        setValidEng(true)
        setValidRus(true)
        cancelEdit(id)
    }
    /**
     * Рассчитать стили для слова
     * @returns {string}
     */
    const getRusWordClass = () => {
        return getWordClass(validRus)
    }

    const getEngWordClass = () => {
        return getWordClass(validEng)
    }

    const getWordClass = (isValid) => {
        let wordClass = ''
        if (editMode) {
            wordClass += ' word-item__input-word-name'
            if (!isValid) {
                wordClass += ' word-item__input-word-name_error'
            }
        }
        return wordClass
    }

    const closeConfirmModal = () => {
        setShowConfirmModal(false)
    }

    /**
     * Сохранить сразу несколько слов
     */
    const saveAllWords = () => {
        const aWordList = props.cardList
        const wlId = aWordList.id
        const wordsFilter = words.filter((w) => w.editMode)
        const wordsToSave = wordsFilter.filter((w) => WordUtils.isNewEntity(w.id))
        const wordsToUpdate = wordsFilter.filter((w) => !WordUtils.isNewEntity(w.id))
        //Todo
        API_WORD.saveWords(aWordList.id, wordsToSave, wordsToUpdate).then(() => {
            setWordsRedux(words.filter((w) => w.listId !== wlId))
            props.loadWords(wlId)
        })
    }

    return (
        <div className='word-list__container'>
            <Flex gap='8px' className={props.canEditWord ? 'word_item' : 'word_item word_item-disabled-editing'}>
                <div className='word-list__flex'>
                    <Input.TextArea
                        autoSize={true}
                        className={'word-list_eng' + getEngWordClass()}
                        value={engWord}
                        readOnly={!editMode ? 'readonly' : ''}
                        onChange={(e) => {
                            onChangeWord(e, 'engWord')
                            debChangeSecond(e)
                        }}
                        placeholder='Сторона 1'
                        maxLength={250}
                    />
                    {firstTranslateValue && (
                        <div
                            className='clue'
                            onClick={
                                () => {
                                    onChangeWord(firstTranslateValue, 'engWord')
                                    setFirstTranslateValue(null)
                                }
                            }
                        >
                            {firstTranslateValue}
                        </div>
                    )}
                </div>
                <div className='word-list__flex'>
                    <div>
                        <Input.TextArea
                            autoSize={true}
                            className={'word-list_rus' + getRusWordClass()}
                            value={rusWord}
                            readOnly={!editMode ? 'readonly' : ''}
                            onChange={(e) => {
                                onChangeWord(e, 'rusWord')
                                debChangeFirst(e)
                            }}
                            placeholder='Сторона 2'
                            maxLength={250}
                        />

                        {secondTranslateValue && (
                            <div
                                className='clue'
                                onClick={
                                    () => {
                                        onChangeWord(secondTranslateValue, 'rusWord')
                                        setSecondTranslateValue(null)
                                    }
                                }
                            >
                                {secondTranslateValue}
                            </div>
                        )}
                    </div>
                </div>
            </Flex>


            <div>
                {props.canEditWord && (
                    <div className='word_item_image'>
                        <Flex gap='4px' justify='flex-end'>
                            {!editMode && <GEButton onClick={() => editWorld(id)}>Изменить</GEButton>}
                            {!editMode && <GEButton loading={loading} danger type='primary'
                                                    onClick={delWordWindow}>Удалить</GEButton>}
                        </Flex>
                        <Flex gap='4px' justify='flex-end'>
                            {props.wordItem.editMode && words.filter((w) => w.editMode).length > 1 && (
                                <SaveAllWordButton
                                    disabled={words.filter((w) => w.editMode).some((w) => !WordUtils.validateWord(w))}
                                    saveAllWords={saveAllWords}
                                />
                            )}
                            <div className={!editMode || !(validEng && validRus) ? 'ge-invisible' : ''}>

                                <GEButton
                                    loading={loading}
                                    type='primary' onClick={() => {
                                    applyEdit(id)
                                    setFirstTranslateValue(null)
                                    setSecondTranslateValue(null)
                                }}>Сохранить</GEButton>
                            </div>
                            <div className={!editMode ? 'ge-invisible' : ''}>
                                <GEButton onClick={cancelChanges}>Отменить</GEButton>
                            </div>
                        </Flex>
                    </div>
                )}

                <ConfirmModalDialog
                    message={'Вы уверены, что хотите удалить карточку?'}
                    decline={closeConfirmModal}
                    confirm={() => delWord(id)}
                    open={showConfirmModal}
                />
            </div>
        </div>
    )
}

export default WordItem

WordItem.propTypes = {
    wordItem: PropTypes.object.isRequired,
    cardList: PropTypes.object,
    canEditWord: PropTypes.bool.isRequired,
    loadWords: PropTypes.func.isRequired
}
