import RulesHeader from '../../blocks/RulesHeader'
import NumberBlock from '../../blocks/NumberBlock'
import TaskExample from '../../blocks/TaskExample'
import Example from '../../blocks/Example'
import FactRule from '../../blocks/FactRule'
import TableThree from '../../blocks/TableThree'
import RuleTestWrapper from '../../RuleTestWrapper'
import { test } from './test'
import ExampleDialog from '../../blocks/ExampleDialog'
import TableTwoVS from '../../blocks/TableTwoVS'

const PresentPerfectContinuous = (props) => {
    return (
        <div>
            <RulesHeader title='Present perfect continuous' subtitle='Настоящее совершенное длительное' />

            <NumberBlock numberBlock='1' />
            <TaskExample taskExample='Изучите следующую ситуацию:' />
            <ExampleDialog
                elements={[
                    '- Is it raining? (Идет дождь?) ',
                    '- No, but the ground is wet. (Нет, но земля влажная.) ',
                    '- It has been raining. (Шёл дождь.) '
                ]}
            />
            <p>
                {' '}
                Мы используем present perfect continuous для действий, которые недавно закончились, или просто
                закончились. Должна быть связь с настоящим временем.
            </p>
            <FactRule factRule='Существительное + have/has + been + 1-я форма правильного глагола-ing это the present perfect continuous:' />
            <TableThree
                elements={[
                    {
                        left: 'I/we/you/they',
                        center: 'have (= I’ve etc.) + been',
                        right: '1-я форма правильного глагола+ing'
                    },
                    {
                        left: 'he/she/it',
                        center: 'has (= he’s etc.) + been',
                        right: '1-я форма правильного глагола+ing'
                    }
                ]}
            />
            <Example
                engExample={['Where have you been? ', <u>I’ve been looking</u>, ' for you everywhere.']}
                rusExample={['Где ты был? Я тебя везде ', <u>искал</u>, '.']}
            />

            <NumberBlock numberBlock='2' />
            <TaskExample taskExample='Изучите следующую ситуацию:' />
            <Example
                engExample='It began raining two hours ago and it is still raining.'
                rusExample='Дождь начался два часа назад и всё еще идет.'
            />
            <Example
                engExample={['How long ', <u>has</u>, ' it ', <u>been raining</u>, '?']}
                rusExample={['Как долго он ', <u>идет</u>, '?']}
            />
            <Example
                engExample={['It ', <u>has been raining</u>, ' for two hours.']}
                rusExample={['Он ', <u>идет</u>, ' уже 2 часа.']}
            />
            <p>
                {' '}
                В таком случае, мы используем present perfect continuous со словами <b>how long</b>, <b>for</b> ... и{' '}
                <b>since</b> ... . Действие всё еще происходит (как в примере) или только что закончилось.
            </p>
            <Example
                engExample={['', <u>How long have</u>, ' you ', <u>been learning English</u>, '?']}
                rusExample={[
                    '',
                    <u>Как давно</u>,
                    ' ты ',
                    <u>учишь английский</u>,
                    '? (= вы всё еще учите английский)'
                ]}
            />
            <p>
                {' '}
                Вы можете использовать present prefect continuous для действий, которые повторялись в течение промежутка
                времени.
            </p>
            <Example
                engExample={[
                    'Tomas is a very good tennis player. ',
                    <u>He’s been playing since he was twenty three</u>,
                    '.'
                ]}
                rusExample={['Томас очень хороший теннисист. Он ', <u>играет</u>, ' в теннис ', <u>с 23-х лет</u>, '.']}
            />

            <NumberBlock numberBlock='3' />
            <p>
                {' '}
                Сравните present continuous (<b>I am doing</b>) и present perfect continuous (<b>I have been doing</b>):
            </p>
            <TableTwoVS
                notPaint={true}
                elements={[
                    {
                        left: 'Present continuous (Действие происходит в текущий момент) ',
                        right: 'Present perfect continuous (Длительное действие)'
                    }
                ]}
            />
            <TableTwoVS
                elements={[
                    {
                        left: ['Don’t disturb me now. I’', <u>m working</u>, '. '],
                        right: ['I’', <u>ve been working</u>, ' hard. Now I’m going to have a break. ']
                    },
                    {
                        left: ['Не беспокой меня. Я ', <u>работаю</u>, '. '],
                        right: ['Я ', <u>заработался</u>, '. Сейчас я собираюсь сделать перерыв. ']
                    }
                ]}
            />
            <TableTwoVS
                elements={[
                    {
                        left: ['We need an umbrella. It’', <u>s raining</u>, '. '],
                        right: ['The ground is wet. It’', <u>s been raining</u>, '.']
                    },
                    {
                        left: ['Нам нужен зонтик. ', <u>Идет</u>, ' дождь. '],
                        right: ['Земля влажная. ', <u>Шел</u>, ' дождь. ']
                    }
                ]}
            />

            <NumberBlock numberBlock='4' />
            <TaskExample taskExample='Изучите следующую ситуацию:' />
            <TableTwoVS
                elements={[
                    {
                        left: ['Kate’s clothes are covered in paint. She ', <u>has been painting</u>, ' the ceiling. '],
                        right: ['The ceiling was white. Now it is red. She ', <u>has painted</u>, ' the ceiling. ']
                    },
                    {
                        left: ['Катина одежда покрыта краской. Она ', <u>красит</u>, ' потолок. '],
                        right: ['Потолок был белым. Теперь он красный. Она ', <u>покрасила</u>, ' потолок. ']
                    }
                ]}
            />
            <TableTwoVS
                notPaint={true}
                elements={[
                    {
                        left: ['', <u>Has been painting</u>, ' это the present perfect continuous. '],
                        right: ['', <u>Has painted</u>, ' это the present perfect simple.']
                    },
                    {
                        left: [
                            'Мы заинтересованы в самом процессе деятельности. Нам не важно закончился он или нет. В этом примере, процесс покраски потолка не закончен. '
                        ],
                        right: [
                            'Здесь, важно то, что что-то закончилось. ',
                            <u>Has painted</u>,
                            ' - покрасила - законченное действие. Мы заинтересованы в результате этого действия (покраска потолка), а не в самом действии (процесс покраски потолка). '
                        ]
                    }
                ]}
            />

            <NumberBlock numberBlock='5' />
            <TableTwoVS
                notPaint={true}
                elements={[
                    {
                        left: [
                            'Мы используем continuous, говоря о продолжительности - ',
                            <u>how long</u>,
                            ' (для деятельности, которая всё ещё происходит):'
                        ],
                        right: [
                            'Мы используем simple, чтобы сказать ',
                            <u>how much</u>,
                            ', ',
                            <u>how many</u>,
                            ' или ',
                            <u>how many times</u>,
                            ' (для завершенных действий):'
                        ]
                    }
                ]}
            />
            <TableTwoVS
                elements={[
                    {
                        left: ['How long ', <u>have</u>, ' you ', <u>been reading</u>, ' that book? '],
                        right: ['How much of that book ', <u>have</u>, ' you ', <u>read</u>, '? ']
                    },
                    {
                        left: ['Как долго ты ', <u>читаешь</u>, ' эту книгу? '],
                        right: ['Сколько из этой книги ты уже ', <u>прочел</u>, '? ']
                    }
                ]}
            />
            <TableTwoVS
                elements={[
                    {
                        left: ['They’', <u>ve been playing</u>, ' tennis ', <u>since 2 o’clock</u>, '. '],
                        right: ['They’', <u>ve played</u>, ' tennis three times this week. ']
                    },
                    {
                        left: ['Они ', <u>играют</u>, ' в теннис с 2-х часов. '],
                        right: ['Они ', <u>сыграли</u>, ' в теннис 3 раза на этой неделе. ']
                    }
                ]}
            />

            <NumberBlock numberBlock='6' />
            <p>
                {' '}
                Некоторые глаголы (например, <b>know</b>/<b>like</b>/<b>believe</b>) обычно не используются в
                continuous:
            </p>
            <Example
                engExample={['I’', <u>ve known</u>, ' about it for a long time. (', <u>NOT</u>, ' I’ve been knowing)']}
                rusExample={['Я ', <u>знаю</u>, ' об этом уже долгое время.']}
            />
            <p>
                {' '}
                Но в present perfect continuous можно использовать глаголы <b>want</b> и <b>mean</b>:
            </p>
            <Example
                engExample={['I’', <u>ve been meaning</u>, ' to phone Jane, but I keep forgetting.']}
                rusExample={['Я ', <u>собирался позвонить</u>, ' Джейн, но я все время забываю.']}
            />

            <RuleTestWrapper value={test} rule={props} />
        </div>
    )
}

export default PresentPerfectContinuous
