const Icon = (props) => {
    const { className = '', onClick = () => null } = props
    return (
        <svg
            onClick={onClick}
            className={'icon-btn ' + className}
            width='32'
            height='32'
            fill='none'
            stroke='#00abfb'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            viewBox='0 0 24 24'
        >
            <path stroke='none' d='M0 0h24v24H0z' />
            <circle cx='12' cy='12' r='9' />
            <path d='M12 8L8 12' />
            <path d='M12 8L12 16' />
            <path d='M16 12L12 8' />
        </svg>
    )
}

export default Icon
