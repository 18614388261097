import { FC } from 'react'

interface HeartFilledIconProps {
    className?: string,
    onClick: () => void
}

const HeartFilledIcon: FC<HeartFilledIconProps> = ({ className = '', onClick }) => {

    return (
        <svg
            onClick={onClick}
            style={{ color: '#ff4d4f' }}
            xmlns='http://www.w3.org/2000/svg'
            width='30'
            height='30'
            fill='none'
            stroke='#ff4d4f'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            className={'icon icon-tabler icon-tabler-heart-filled pointer-cursor' + className}
            viewBox='0 0 24 20'
        >
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <path
                fill='currentColor'
                stroke='#ff4d4f'
                d='M6.979 3.074a6 6 0 014.988 1.425l.037.033.034-.03a6 6 0 014.733-1.44l.246.036a6 6 0 013.364 10.008l-.18.185-.048.041-7.45 7.379a1 1 0 01-1.313.082l-.094-.082-7.493-7.422A6 6 0 016.979 3.074z'
            ></path>
        </svg>
    )
}

export default HeartFilledIcon
