let StringUtils = {
    deleteRuSymbols: function (str) {
        /**
         * This method removes "RU" symbols
         * @function
         */
        return str.replace(/[а-яА-Я]/g, '')
    },

    deleteEnSymbols: function (str) {
        /**
         * This method removes "EN" symbols
         * @function
         */
        return str.replace(/[a-zA-Z]/g, '')
    },

    isEmptyStr: function (str) {
        return !str
    },

    isNotEmptyStr: function (str) {
        return !this.isEmptyStr(str)
    }
}

export default StringUtils
