import '../../../../styles/scss/premiumBlock/premiumBlock.scss'
import { GEButton } from '../../../../common/buttons'
import { NavigationPages } from '../../../../common/navigation/navigation-pages'
import IconRocket from '../../../elements/svg_icons/IconRocket'
import { goToPageNotPremium } from '../../../utils/goToPage'
import { useNavigate } from 'react-router-dom'

const PremiumBlock = () => {
    const navigate = useNavigate()

    return (
        <div className='premium-block'>
            <div className='premium-block__description'>
                <IconRocket className='premium-block__icon' />
                <div className='premium-block__content'>Прокачай аккаунт до Премиум</div>
            </div>
            <GEButton onClick={() => {
                goToPageNotPremium(NavigationPages.PREMIUM, navigate)
            }} className='premium-block__btn'>
                Подключить премиум</GEButton>
        </div>
    )
}

export default PremiumBlock
