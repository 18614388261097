import '../../../../styles/css/personal-policy/personal-policy.css'
import { Helmet } from 'react-helmet'

const Terms = () => {
    return (
        <div id='personal-policy'>
            <Helmet title='Условия использования' />
            <ul>
                Последнее обновление: 6 октября 2023 года
            </ul>
            <ul>
                <b>Условия использования</b>
                <br />
                Настоящие Условия ("Условия") регулируют ваши отношения с мобильным приложением Geekenglish и сайтом
                geekenglish.ru ("Сервисы"), которыми управляет ИП Филатов Александр ОГРНИП 321623400037022 ИНН
                622901533825 ("нас", "мы", "наш" и т.п.).
                Пожалуйста, внимательно ознакомьтесь с настоящими Условиями, прежде чем пользоваться нашими Сервисами.
                Ваш доступ к Сервисам и их использование зависят от вашего согласия и соблюдения настоящих Условий.
                Настоящие Условия распространяются на всех посетителей, пользователей и других лиц, которые получают
                доступ к Сервисам или используют их.
                Получая доступ к Сервисам или используя их, вы соглашаетесь соблюдать настоящие Условия.
                Если вы не согласны с какой-либо частью Условий, вы не можете получить доступ к Сервисам.
            </ul>
            <ul>
                <b>Покупки</b>
                <br />
                Если вы хотите приобрести какой-либо продукт или услугу, доступные через Сервисы («Покупка»),
                вас могут попросить предоставить определенную информацию, относящуюся к вашей Покупке, включая,
                помимо прочего, номер вашей банковской карты, дату истечения срока действия банковской карты,
                ваш платежный адрес и информацию о доставке.
                Вы заявляете и гарантируете, что: а) у вас есть законное право использовать любые банковские
                карты или другие способы оплаты, связанные с Покупкой; и что б) информация, которую вы нам
                предоставляете, является достоверной, правильной и полной.
                Предоставляя такую информацию, вы предоставляете нам право предоставлять информацию третьим
                лицам в целях облегчения совершения Покупок.
                Мы оставляем за собой право отклонить или отменить ваш заказ в любое время по определенным
                причинам, включая, помимо прочего: доступность продукта или услуги, ошибки в описании или цене
                продукта или услуги, ошибку в вашем заказе или другие причины.
                Мы оставляем за собой право отказать или отменить ваш заказ в случае подозрения на мошенничество,
                несанкционированную или незаконную транзакцию.
            </ul>
            <ul>
                <b>Доступность, ошибки и неточности</b>
                <br />
                Мы постоянно обновляем наши предложения продуктов и услуг в Сервисах.
                Продукты или услуги, доступные в наших Сервисах, могут иметь неправильную цену,
                быть неточно описанными или недоступными, и мы можем испытывать задержки в обновлении
                информации о Сервисах и в нашей рекламе на других веб-сайтах.
                Мы не можем и не гарантируем точность и полноту любой информации, включая цены,
                изображения продуктов, спецификации, доступность и услуги. Мы оставляем за собой право
                изменять и обновлять информацию, исправлять ошибки, неточности или упущения в любое время
                без предварительного уведомления.
            </ul>
            <ul>
                <b>Подписки</b>
                <br />
                Некоторые части Сервисов оплачиваются на основе подписки («Подписка»). Подписка продлевается
                автоматически, пока автоматическое продление не будет отключено по крайней мере за 24 часа
                до окончания текущего периода ("Платёжный Цикл"). Стоимость продления спишется с вашего
                счёта в течение последних 24 часов Платёжного Цикла. Платёжные Циклы зависят от типа подписки,
                которую вы выбираете при покупке Подписки.
                Вы можете отменить продление Подписки на странице управления учётной записью в Интернете.
                Для обработки платежа за Подписку требуется действительный способ оплаты, включая
                банковскую карту или PayPal. Вы должны предоставить Александру Филатову точную и полную
                платёжную информацию, включая полное имя, адрес, почтовый индекс, номер телефона и
                информацию о действительном способе оплаты. Предоставляя такую платёжную информацию,
                вы автоматически разрешаете Александру Филатову взимать все сборы за подписку, понесённые
                через вашу учётную запись, с любых таких платёжных инструментов.
            </ul>
            <ul>
                <b>Изменения платы</b>
                <br />
                Александр Филатов по своему усмотрению и в любое время может изменять плату за Подписки.
                Любое изменение платы за Подписку вступает в силу в конце текущего Платёжного Цикла.
                Александр Филатов заблаговременно уведомит вас о любом изменении платы за Подписку,
                чтобы дать вам возможность прекратить Подписку до того, как такое изменение вступит в силу.
                Ваше дальнейшее использование Сервисов после вступления в силу изменения платы за Подписку
                означает ваше согласие оплачивать изменённую сумму платы за Подписку.
            </ul>
            <ul>
                <b>Возврат средств</b>
                <br />
                Возврат средств за покупки, совершённые в
                магазине приложений (например, App Store, Google Play, App Gallery), обрабатывается этим магазином.
                Возвратом средств за другие покупки, обработанные Сервисами, занимается Александр Филатов.
            </ul>
            <ul>
                <b>Контент</b>
                <br />
                Наши Сервисы позволяют вам публиковать, хранить, делиться и иным образом делать доступной
                определенную информацию, текст, графику, видео или другие материалы ("Контент").
                Вы несёте ответственность за Контент, который вы публикуете в Сервисах, включая его законность,
                надёжность и уместность.
                Публикуя Контент в Сервисах, вы предоставляете нам право и лицензию на использование,
                изменение, исполнение, отображение, воспроизведение и распространение такого Контента в
                Сервисах и через них. Вы сохраняете все свои права на любой Контент, который вы отправляете,
                публикуете или отображаете в Сервисах или через них, и несёте ответственность за защиту этих прав.
                Вы заявляете и гарантируете, что: а) Контент принадлежит вам (вы владеете им) или вы имеете
                право на его использование и предоставляете нам права и лицензию, предусмотренные настоящими
                Условиями, и б) размещение вашего Контента на или через Сервисы не нарушают права на
                неприкосновенность частной жизни, права на гласность, авторские права, договорные права и
                любые другие права любого лица.
            </ul>
            <ul>
                <b>Интеллектуальная собственность</b>
                <br />
                Сервисы и их исходный контент (за исключением Контента, предоставленного пользователями),
                возможности и функции являются и будут оставаться исключительной собственностью Евгения
                Степина и его лицензиаров. Сервисы защищены законодательством об авторском праве, товарных
                знаках и иными законами как Российской Федерации, так и иностранных государств. Наши товарные
                знаки и фирменный стиль нельзя использовать в связи с каким-либо продуктом или услугой без
                предварительного письменного согласия Евгения Степина.
            </ul>
            <ul>
                <b>Ссылки на другие сайты</b>
                <br />
                Наши Сервисы могут содержать ссылки на сторонние сайты или сервисы, которые не принадлежат
                Александру Филатову и не контролируются им.
                Александр Филатов не контролирует и не несет ответственности за содержание,
                политику конфиденциальности или действия любых сторонних сайтов и сервисов.
                Вы также признаёте и соглашаетесь с тем, что Александр Филатов не несёт прямой или
                косвенной ответственности за любой ущерб или убытки, вызванные, предположительно
                вызванные или связанные с использованием или доверием к любому такому контенту,
                товарам или услугам, доступным на или через любые такие сайты и сервисы.
                Мы настоятельно рекомендуем вам ознакомиться с условиями и политиками конфиденциальности
                любых сторонних сайтов и сервисов, которые вы посещаете.
            </ul>
            <ul>
                <b>Ограничение ответственности</b>
                <br />
                Ни при каких обстоятельствах ни Александр Филатов, ни его директора, сотрудники, партнёры,
                агенты, поставщики или аффилированные лица не несут ответственности за любые косвенные,
                случайные, специальные, косвенные или штрафные убытки, включая, помимо прочего,
                упущенную выгоду, потерянные данные, порчу деловой репутации, или другие нематериальные
                убытки, возникшие в результате а) вашего доступа, использования, невозможности доступа
                или невозможности использования Сервисов; б) любое поведение или контент любой третьей
                стороны в Сервисах; в) любой контент, полученный из Сервисов; и г) несанкционированный доступ,
                использование или изменение вашего контента, независимо от того, основаны ли они на гарантии,
                договоре, гражданском правонарушении (включая небрежность) или любой другой правовой теории,
                независимо от того, были ли мы проинформированы о возможности такого ущерба, и даже если установлено,
                что средство правовой защиты, изложенное в настоящем документе, не достигло своей основной цели.
            </ul>
            <ul>
                <b>Отказ от ответственности</b>
                <br />
                Вы используете Сервисы на свой страх и риск.
                Услуги предоставляются на условиях «КАК ЕСТЬ» и «КАК ДОСТУПНО».
                Сервисы предоставляются без каких-либо гарантий, явных или подразумеваемых,
                включая, помимо прочего, подразумеваемые гарантии товарной пригодности,
                пригодности для определенной цели, ненарушения прав или режима работы.
                Александр Филатов, его дочерние компании, аффилированные лица и его лицензиары
                не гарантируют, что: а) Услуги будут функционировать бесперебойно, безопасно или
                будут доступны в любое конкретное время и в любом месте; б) любые ошибки или дефекты
                будут исправлены; или c) результаты использования Сервисов будут соответствовать вашим требованиям.
            </ul>
            <ul>
                <b>Регулирующее право</b>
                <br />
                Наша неспособность обеспечить соблюдение какого-либо права или положения настоящих Условий
                не будет считаться отказом от этих прав. Если какое-либо положение настоящих
                Условий будет признано судом недействительным или не имеющим исковой силы, остальные
                положения настоящих Условий останутся в силе. Настоящие Условия представляют собой полное соглашение
                между нами в отношении наших
                Услуг и заменяют любые предыдущие соглашения, которые мы могли бы иметь между нами в отношении Сервисов.
            </ul>
            <ul>
                <b>Изменения</b>
                <br />
                Мы оставляем за собой право по собственному усмотрению изменять или заменять настоящие Условия в любое
                время.
                Продолжая получать доступ к нашим Сервисам или использовать их после того, как
                эти изменения вступят в силу, вы соглашаетесь соблюдать новые условия.
                Если вы не согласны с новыми условиями, пожалуйста, прекратите использование Сервисов.
            </ul>
            <ul>
                Контакты
                <br />
                geekenglish@yandex.ru
            </ul>
        </div>
    )
}

export default Terms