import { Button, Input, message, Modal, Upload } from 'antd'
import { FC, useState } from 'react'
import * as API_TEACHER from '../../shared/http/teacher'
import { UploadOutlined } from '@ant-design/icons'
import { UploadFile } from 'antd/es/upload/interface'
import StringUtils from '../utils/StringUtils'

interface EditProfileProps {
    isOpen: boolean,
    close: () => void
}

const SwitchToTeacherDialog: FC<EditProfileProps> = ({ isOpen, close }) => {

    const [firstName, setFirstName] = useState<string | undefined>(undefined)
    const [middleName, setMiddleName] = useState<string | undefined>(undefined)
    const [lastName, setLastName] = useState<string | undefined>(undefined)
    const [inn, setInn] = useState<string | undefined>(undefined)
    const [personalAccountName, setPersonalAccountName] = useState<string | undefined>(undefined)
    const [bicBank, setBicBank] = useState<string | undefined>(undefined)
    const [bankName, setBankName] = useState<string | undefined>(undefined)
    const [bankAccountNumber, setBankAccountNumber] = useState<string | undefined>(undefined)
    const [selectedFile, setSelectedFile] = useState<UploadFile | undefined>(undefined)
    const [fileList, setSetFileList] = useState<Array<any> | undefined>([])


    const clearAndClose = () => {
        setFirstName('')
        setMiddleName('')
        setLastName('')
        setInn('')
        setPersonalAccountName('')
        setBicBank('')
        setBankName('')
        setBankAccountNumber('')
        setSelectedFile(undefined)
        setSetFileList([])
        close()
    }

    const notDisabledBtn = StringUtils.isNotEmptyStr(firstName) &&
        StringUtils.isNotEmptyStr(lastName) &&
        StringUtils.isNotEmptyStr(middleName) &&
        StringUtils.isNotEmptyStr(inn) &&
        StringUtils.isNotEmptyStr(personalAccountName) &&
        StringUtils.isNotEmptyStr(bicBank) &&
        StringUtils.isNotEmptyStr(bankName) &&
        StringUtils.isNotEmptyStr(bankAccountNumber) &&
        selectedFile

    const updateData = () => {

        const data = {
            firstName: firstName, lastName: lastName,
            middleName: middleName, inn: inn, personalAccountName: personalAccountName,
            bicBank: bicBank, bankName: bankName, bankAccountNumber: bankAccountNumber
        }

        if (selectedFile) {
            API_TEACHER.submitApplication(data, selectedFile).then(s => {
                message.success('Данные успешно сохранены')
                clearAndClose()
            })
        }
    }

    const beforeUpload = (file: any) => {
        const isValid = file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'application/pdf'
        if (!isValid) {
            message.error(`${file.name} поддерживаем только jpeg, png и pdf форматы`)
        }
        return isValid
    }

    const uploadFile = (info: any) => {
        setSelectedFile(info.file as UploadFile)
        info.onSuccess()
        setSetFileList([info.file])
    }

    return (
        <Modal title='Заполните поля ниже'
               onCancel={clearAndClose}
               footer={[
                   <Button key='submit' onClick={clearAndClose}>
                       Отмена
                   </Button>,
                   <Button
                       disabled={!notDisabledBtn}
                       onClick={updateData}
                       type='primary'
                   >
                       Перейти на аккаунт преподавателя
                   </Button>
               ]}
               open={isOpen}>
            <div className='modal-input-container'>
                <Input
                    size='large'
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder='Фамилия'
                    value={lastName}
                />
                <Input size='large'
                       onChange={(e) => setFirstName(e.target.value)}
                       placeholder='Имя'
                       value={firstName}
                />
                <Input size='large'
                       onChange={(e) => setMiddleName(e.target.value)}
                       placeholder='Отчество'
                       value={middleName}
                />
                <Input size='large'
                       onChange={(e) => setInn(e.target.value)}
                       placeholder='ИНН'
                       value={inn}
                />
                <span>Копия (скан) паспорта (иного удостоверяющего личность документа), содержащая
(-ий) информацию о фамилии, имени, отчестве (при наличии), дате рождения, дате выдачи,
выдавшем органе, о месте жительства (при наличии):</span>
                <Upload beforeUpload={beforeUpload} fileList={fileList} maxCount={1} customRequest={uploadFile}>
                    <Button icon={<UploadOutlined />}>Загрузить</Button>
                </Upload>
                <span>Банковские реквизиты:</span>
                <Input size='large'
                       onChange={(e) => setPersonalAccountName(e.target.value)}
                       placeholder='Номере лицевого счета'
                       value={personalAccountName}
                />
                <Input size='large'
                       onChange={(e) => setBicBank(e.target.value)}
                       placeholder='БИК банка'
                       value={bicBank}
                />
                <Input size='large'
                       onChange={(e) => setBankName(e.target.value)}
                       placeholder='Наименование банка'
                       value={bankName}
                />
                <Input size='large'
                       onChange={(e) => setBankAccountNumber(e.target.value)}
                       placeholder='Корреспондентский счет банка'
                       value={bankAccountNumber}
                />
            </div>
        </Modal>
    )
}
export default SwitchToTeacherDialog
