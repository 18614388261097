import { FC, useState } from 'react'
import '../../styles/css/dictionary/modalwindow/main_dialog.css'
import { Button, Modal } from 'antd'
import { CommunityDto, WordList } from '../../shared/types/api'
import SimpleSelector from '../elements/selectors/SimpleSelector'
import * as API_COMMUNITY from '../../shared/http/community'
import NotificationsUtils from '../utils/NotificationsUtils'
import { fetchCommunityWorldListsById } from '../../store/wordLists/extraReducers'
import { useAppDispatch } from '../../store/hooks'

interface CloneToCommunityModalDialogProps {
    cardList: WordList,
    communities: Array<CommunityDto>,
    close: () => void,
    open: boolean;

}

const CloneToCommunityModalDialog: FC<CloneToCommunityModalDialogProps> = ({ communities, close, open, cardList }) => {

    const [communityId, setCommunityId] = useState<undefined | number>(undefined)
    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    const onChange = (e: any) => {
        if (e?.target?.value) {
            setCommunityId(e.target.value)
        }
    }

    const loadCommunityWordLists = (id: number) => {
        dispatch(fetchCommunityWorldListsById(id))
    }


    const confirmFn = () => {
        setLoading(true)
        API_COMMUNITY.cloneListToCommunity(communityId as number, cardList.id as number).then(resp => {
            loadCommunityWordLists(communityId as number)
            NotificationsUtils.openSuccessNotification('Список скопирован в сообщество')
        }).finally(() => {
            setLoading(false)
            close()
        })

    }

    return (
        <Modal onCancel={close}
               footer={[
                   <Button key='submit' danger onClick={close}>
                       Отмена
                   </Button>,
                   <Button
                       loading={loading}
                       onClick={confirmFn}
                       type='primary'
                       disabled={!communityId}
                   >
                       Копировать
                   </Button>
               ]}
               open={open}>
            <div>
                <SimpleSelector
                    title={'Списки карточек:'}
                    onChange={onChange}
                    dataList={communities}
                    dataValue={'name'}
                    defaultValue={'Выберите сообщество'}
                    selectableDefaultValue={false}
                />
            </div>

        </Modal>
    )
}

export default CloneToCommunityModalDialog
