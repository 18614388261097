import PropTypes from 'prop-types'

const IconArrowBorderedLeft = (props: { onClick?: (() => void) }) => {
    const { onClick = () => null } = props
    return (
        <svg
            onClick={onClick}
            xmlns='http://www.w3.org/2000/svg'
            width='30'
            height='30'
            fill='none'
            stroke='#000'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            className='icon icon-tabler icon-tabler-arrow-big-left pointer-cursor'
            viewBox='0 0 24 24'
        >
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <path
                d='M20 15h-8v3.586a1 1 0 01-1.707.707l-6.586-6.586a1 1 0 010-1.414l6.586-6.586A1 1 0 0112 5.414V9h8a1 1 0 011 1v4a1 1 0 01-1 1z'></path>
        </svg>
    )
}
export default IconArrowBorderedLeft

IconArrowBorderedLeft.propTypes = {
    onClick: PropTypes.func
}
