import '../../../styles/css/elements/selector/simple_select.css'
import LineLoading from '../loading/LineLoading'
import PropTypes from 'prop-types'
import { FC } from 'react'

interface SimpleSelectorProps {
    dataList: Array<any>
    dataValue: string,
    onChange: (e: any) => void,
    title: string,
    defaultId?: any,
    defaultValue?: string,
    selectableDefaultValue?: boolean,
    showLoader?: boolean,
    disabled?: boolean
}

const SimpleSelector: FC<SimpleSelectorProps> = (props) => {
    const disabled = props.disabled ? props.disabled : false
    return (
        <div id='simple-selector'>
            {props.showLoader ? (
                <LineLoading />
            ) : (
                <>
                    <select
                        disabled={disabled}
                        className='simple-selector__select-text'
                        onChange={(e) => props.onChange(e)}
                    >
                        {props.defaultValue && (
                            <option defaultValue={props.defaultValue} value={props.defaultId}
                                    hidden={!props.selectableDefaultValue}>
                                {props.defaultValue}
                            </option>
                        )}
                        {props.dataList &&
                        props.dataList.map((data) => (
                            <option key={data.id} value={data.id}>
                                {data[props.dataValue]}
                            </option>
                        ))}
                    </select>
                    <span className='simple-selector__select-highlight' />
                    <span className='simple-selector__select-bar' />
                    <label className={'simple-selector__select-label ' + disabled ? 'disabled' : ''}>
                        {props.title}
                    </label>
                </>
            )}
        </div>
    )
}

export default SimpleSelector

SimpleSelector.propTypes = {
    dataList: PropTypes.array.isRequired,
    dataValue: PropTypes.string.isRequired,
    defaultId: PropTypes.any,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    selectableDefaultValue: PropTypes.bool,
    showLoader: PropTypes.bool,
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool
}
