import '../../../styles/css/blocks/wordlist_combine_selector.css'
import { Link } from 'react-router-dom'
import Constants from '../../../const/Constants'
import { CommunityDto, ID, WordList } from '../../../shared/types/api'
import { FC } from 'react'
import { Flex, Select, TreeSelect } from 'antd'
import useTreeSelector from '../../../hooks/useTreeSelector'
import {GELink} from "../../../common/link/GELink";

interface CardWordsListCombineSelectorProps {
    changeSide: (sideValue: string) => void,
    changeWordList: (id: number) => void,
    communities: Array<CommunityDto>,
    showWordListLoader: boolean,
    selectedWordListId?: ID
}


const CardWordsListCombineSelector: FC<CardWordsListCombineSelectorProps> = ({
                                                                                 changeSide,
                                                                                 changeWordList,
                                                                                 communities,
                                                                                 showWordListLoader,
                                                                                 selectedWordListId
                                                                             }) => {

    const {
        onChange,
        onLoadData,
        value,
        treeData
    } = useTreeSelector(selectedWordListId, communities, changeWordList)

    return (
        <div id='wordlist-combine-selector'>
            <Flex vertical gap={10}>
                <TreeSelect
                    treeLine={true}
                    loading={showWordListLoader}
                    treeDataSimpleMode
                    style={{ width: '250px' }}
                    value={value}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder='Выберите список карточек'
                    onChange={onChange}
                    loadData={onLoadData}
                    treeData={treeData}
                />
                <Select
                    defaultValue={Constants.CARD_SIDE_ONE}
                    style={{ width: '250px' }}
                    onChange={changeSide}
                    options={[
                        { value: Constants.CARD_SIDE_ONE, label: 'Сторона 1' },
                        { value: Constants.CARD_SIDE_TWO, label: 'Сторона 2' }
                    ]}
                />
            </Flex>
            <span className='wordlist-combine-selector__help'>
                Нет списков слов, добавьте его в <GELink className='wordlist-combine-selector__link' to='/dictionary'>словаре</GELink> или возьмите
                <GELink to='/donewordslists'> готовый список</GELink>
            </span>
        </div>
    )
}

export default CardWordsListCombineSelector
