import RulesHeader from '../../blocks/RulesHeader'
import NumberBlock from '../../blocks/NumberBlock'
import TaskExample from '../../blocks/TaskExample'
import Example from '../../blocks/Example'
import FactRule from '../../blocks/FactRule'
import TableTwo from '../../blocks/TableTwo'
import TableThree from '../../blocks/TableThree'
import { test } from './test'
import RuleTestWrapper from '../../RuleTestWrapper'

const PresentSimpleRule = (props) => {
    return (
        <div>
            <RulesHeader title='Present Simple' subtitle='Настоящее простое' />

            <NumberBlock numberBlock='1' />
            <TaskExample taskExample='Изучите следующую ситуацию:' />
            <Example
                engExample={['Tomas ', <u>teaches</u>, ' english language']}
                rusExample='Томас преподает английский язык'
            />
            <p>
                {' '}
                Мы используем present simple, когда говорим о событиях в общем, как о фактах. Мы используем его, когда
                что-то происходит постоянно или неоднократно, или когда что-либо является неоспоримой истиной.
            </p>
            <FactRule factRule='Существительное + 1-я форма глагола(-s) это the present simple:' />
            <TableTwo
                elements={[
                    { left: 'I/we/you/they ', right: '1-я форма глагола' },
                    { left: 'he/she/it ', right: '1-я форма глагола + -s' }
                ]}
            />

            <NumberBlock numberBlock='2' />
            <p>
                {' '}
                Мы используем <b>do/does</b> в вопросительных и отрицательных предложениях.
            </p>
            <TableThree
                elements={[
                    { left: 'do', center: 'I/we/you/they', right: 'инфинитив?' },
                    { left: 'does', center: 'he/she/it', right: 'инфинитив?' }
                ]}
            />
            <TableThree
                elements={[
                    { left: 'I/we/you/they', center: 'don’t', right: 'инфинитив' },
                    { left: 'he/she/it', center: 'doesn’t', right: 'инфинитив' }
                ]}
            />
            <Example
                engExample={['What ', <u>does</u>, ' this word ', <u>mean</u>, '?']}
                rusExample='Что означает это слово?'
            />
            <Example
                engExample={['I ', <u>don’t</u>, ' go away very often.']}
                rusExample='Я не очень часто куда-нибудь уезжаю.'
            />
            <p>
                {' '}
                В следующих примерах <b>do</b> также и главный глагол (<b>do</b> you <b>do</b> / <b>doesn’t do</b> и
                т.д.):
            </p>
            <Example engExample={['What ', <u>do</u>, ' you ', <u>do</u>, '?']} rusExample='Чем ты занимаешься?' />
            <Example engExample={['He ', <u>doesn’t do</u>, ' anything.']} rusExample='Он ничего не делает.' />

            <NumberBlock numberBlock='3' />
            <p> Мы используем present simple, чтобы сказать как часто мы делаем определенные вещи:</p>
            <Example
                engExample={['I ', <u>get</u>, ' up at 8 o’clock ', <u>every morning</u>, '.']}
                rusExample='Я встаю в 8 часов каждое утро.'
            />

            <NumberBlock numberBlock='4' />
            <p>
                {' '}
                Иногда мы говорим слова, предполагающие, что мы сделаем что-либо. Например, когда вы обещаете сделать
                что-либо, вы можете сказать <b>‘I promise’</b>; когда вы предлагаете что-нибудь, вы можете сказать{' '}
                <b>‘I suggest’</b>. То же самое и с другими словами: <b>I apologise</b> (я извиняюсь) ... /{' '}
                <b>I advise</b> (я советую) ... / <b>I insist</b>
                (я настаиваю) ... / <b>I agree</b> (я согласен) ... / <b>I refuse</b> (я отказываюсь) ... и др.
            </p>
            <Example
                engExample={['', <u>I promise</u>, ' I won’t be late. (', <u>NOT</u>, ' I’m promising).']}
                rusExample='Я обещаю, что не буду опаздывать.'
            />
            <RuleTestWrapper value={test} rule={props} />
        </div>
    )
}

export default PresentSimpleRule
