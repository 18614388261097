import {FC} from "react";
import '../../../../../styles/scss/profile_page/profile_page.scss'
import MonthWordMistakes from "../../statistic/MonthWordMistakes";

interface ErrorsInWordsCardProps {
}

export const ErrorsInWordsCard: FC<ErrorsInWordsCardProps> = () => {
    return (
        <div className='errors-in-words-card-container grid-2'>
            <div className='errors-in-words-card-text'>Ошибки в словах:</div>
            <MonthWordMistakes />
        </div>
    )
}
