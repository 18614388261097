import WordUtils from '../utils/WordUtils'
import { setWordsLoadingRoutine, setWordsRoutine } from '../../store/words'
import { Dispatch } from '@reduxjs/toolkit'
import WordListUtils from '../utils/WordListUtils'
import { setSelectedWordListRoutine, setWordListsRoutine } from '../../store/wordLists'
import { fetchWords } from '../../store/words/extraReducers'

/**
 * Выбрать список слова в словаре
 * @param dispatch
 * @param id
 * @param cardLists
 * @param words
 */
export const selectCardList = (dispatch: Dispatch, id: number | string | undefined, cardLists: Array<any>, words: Array<any>) => {
    clearCardChanges(dispatch, words)
    if (!WordListUtils.isNewEntity(id) || id === 'null-list') {
        clearCardListChanges(dispatch, cardLists)
        dispatch(setWordsLoadingRoutine(false))
        dispatch(setSelectedWordListRoutine(id))
        if (!words.some((w) => w.listId === id) && Number.isInteger(id)) {
            // @ts-ignore
            dispatch(fetchWords(id))
        }
    }
}

/**
 * Почистить все изменения в cardlist
 * @param dispatch
 * @param cardLists
 */
export const clearCardListChanges = (dispatch: Dispatch, cardLists: Array<any>) => {
    const newWordList = cardLists
        .map((cardList) => {
            if (cardList.editMode) {
                return {
                    ...cardList,
                    listName: cardList.prevListName,
                    editMode: false
                }
            }
            if (cardList.checked) {
                return {
                    ...cardList,
                    checked: false
                }
            }
            return cardList
        })
        .filter((wordList) => !WordListUtils.isNewEntity(wordList.id))
    dispatch(setWordListsRoutine(newWordList))
}

/**
 * Почистить все изменения в card
 * @param dispatch
 * @param cards
 */
export const clearCardChanges = (dispatch: Dispatch, cards: Array<any>) => {
    const newWords = cards
        .map((w) => {
            if (w.editMode) {
                return {
                    ...w,
                    engWord: w.prevEngWord,
                    rusWord: w.prevRusWord,
                    editMode: false
                }
            }
            if (w.editMode) {
                return {
                    ...w,
                    editMode: false
                }
            }
            return w
        })
        .filter((w) => !WordUtils.isNewEntity(w.id))
    dispatch(setWordsRoutine(newWords))
}