import {FC, useState} from 'react'
import '../../styles/css/dictionary/modalwindow/main_dialog.css'
import {Button, Input, Modal} from 'antd'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {communitiesDataSelector} from '../../store/communities'
import {updateInCommunity, updateWordList} from '../../store/wordLists/extraReducers'
import {CommunityDto, ID, WordList} from '../../shared/types/api'
import WordListUtils from '../utils/WordListUtils'
import PrivilegesUtil from '../utils/PrivilegesUtil'
import Privileges from '../../const/Privileges'
import Constants from '../../const/Constants'
import {userSelector} from '../../store/global'
import {setWordListsRoutine, wordListsDataSelector} from '../../store/wordLists'

interface EditCardListDialogProps {
    cardList: WordList,
    decline: () => void,
    open: boolean;
}

const EditCardListDialog: FC<EditCardListDialogProps> = ({decline, open, cardList}) => {
    const communities = useAppSelector(communitiesDataSelector)
    const dispatch = useAppDispatch()
    const user = useAppSelector(userSelector)
    const wordLists = useAppSelector(wordListsDataSelector)

    const [name, setName] = useState<string>(cardList ? cardList.listName : '')
    const [valid, setValid] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)

    /**
     * Обновить список слов
     * @param data
     * @param id
     */
    const updateCardList = (data: WordList, id: ID) => dispatch(updateWordList({data, id})).catch((err) =>
        Modal.error({
            title: 'Ошибка!',
            content: 'Не удалось обновить список слов'
        })
    ).finally(() => {
        close()
    })


    /**
     * Обновить список слов в сообществе
     * @param communityId
     * @param data
     * @param id
     */
    const updateCardListInCommunity = (communityId: ID, data: WordList, id: ID) =>
        /*     @ts-ignore*/
        dispatch(updateInCommunity({communityId, data, id})).catch((err) =>
            Modal.error({
                title: 'Ошибка!',
                content: 'Не удалось обновить список слов'
            })
        ).finally(() => {
            close()
        })

    const getActiveCommunity = () => {
        return communities.find((c: CommunityDto) => c.active)
    }

    /**
     * Изменить уровень списка слов
     * @param e
     * @param id
     */
    const onChangeListLevel = (e: any, id: ID) => {
        const level = e.target.value
        const newWordList = wordLists.map((wordList: WordList) => {
            if (wordList.id === id) {
                return {...wordList, level}
            }
            return wordList
        })
        dispatch(setWordListsRoutine(newWordList))
    }

    const update = () => {
        setLoading(true)
        let data = {...cardList, listName: name}
        if (!getActiveCommunity()) {
            /*     @ts-ignore*/
            updateCardList(data, cardList?.id)
        } else {
            const activeCommunityId = getActiveCommunity()?.id
            /*     @ts-ignore*/
            updateCardListInCommunity(activeCommunityId, data, cardList?.id)
        }
    }

    const confirmFn = () => {
        update()
        close()
    }

    const close = () => {
        setLoading(false)
        setName(cardList ? cardList.listName : '')
        decline()
    }

    const onChangeName = (e: any) => {
        const value = e.target.value
        setValid(value.length !== 0)
        setName(value)
    }

    return (
        <Modal onCancel={close}
               destroyOnClose={true}
               title={'Редактирование списка карточек'}
               footer={[
                   <Button key='submit' danger onClick={close}>
                       Отмена
                   </Button>,
                   <Button
                       loading={loading}
                       onClick={confirmFn}
                       type='primary'
                       disabled={!valid}
                   >
                       Сохранить
                   </Button>
               ]}
               open={open}>
            <div>
                <Input status={!valid ? 'error' : ''} value={name} onChange={onChangeName} size='large'
                       placeholder='Название списка карточек'
                       maxLength={50}
                />
                {WordListUtils.isDoneType(cardList.type) &&
                    PrivilegesUtil.hasPrivilege(user, Privileges.EDIT_DONE_WORD_LIST) &&
                    (
                        <select onChange={(e) => onChangeListLevel(e, cardList.id)}>
                            {Constants.CEFR_LEVELS.map((item, i) => (
                                <option key={i}>{item}</option>
                            ))}
                        </select>
                    )}
            </div>
        </Modal>
    )
}

export default EditCardListDialog
