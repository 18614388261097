import Footer from '../footer/Footer'
import MobileSideBar from '../sidebar/MobileSideBar'
import '../../../styles/scss/side-bar/mobile-side-bar.scss'
import NavigationBar from '../navigation-bar/navigation-bar'
import { useState } from 'react'
import { useAppSelector } from '../../../store/hooks'
import { userSelector } from '../../../store/global'
import NoAuthNavigationBarProps from '../navigation-bar/no-auth-navigation-bar'

const MainLayout = (props) => {
    const currentUser = useAppSelector(userSelector)

    const { sidebar = false, footer = false, children = null, navbar = false } = props
    const [mobileMenuVisibility, setMenuVisibility] = useState(false)
    const changeVisibility = () => {
        setMenuVisibility((prev) => !prev)
    }
    const resetVisibility = () => {
        setMenuVisibility(false)
    }
    if (currentUser) {
        return (
            <div className={'main-layout'}>
                <div className={'main-layout__main-aside'}>
                    {!!navbar && (
                        <NavigationBar
                            className={'main-layout__sidebar'}
                            visible={mobileMenuVisibility}
                            resetVisibility={resetVisibility}
                        />
                    )}
                    <main className={'main-layout__main'}>{children}</main>
                </div>
                {!!sidebar && (
                    <MobileSideBar
                        visible={mobileMenuVisibility}
                        resetVisibility={resetVisibility}
                        className={'mobile-layout__sidebar'}
                        changeVisibility={changeVisibility}
                    />
                )}
                {!!footer && <Footer className={'main-layout__footer'} />}
            </div>
        )
    }
    return (
        <div className={'main-layout'}>

            <div className={'main-layout__main-aside'}>
                {/*   <NoAuthNavigationBarProps
                    className={'main-layout__sidebar'}
                    visible={mobileMenuVisibility}
                    resetVisibility={resetVisibility}
                />*/}
                <main className={'main-layout__main'}>{children}</main>
            </div>
            {!!footer && <Footer className={'main-layout__footer'} />}
        </div>
    )
}

export default MainLayout
