import { useEffect, useState } from 'react'
import * as API_STATISTIC from '../../../../shared/http/statistic'
import Loading from '../../../elements/loading/Loading'
import '../../../../styles/css/statistic/chart.css'
import { Bar, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, BarChart, Brush, Tooltip } from 'recharts'

const Chart = () => {
    const [graphData, setGraphData] = useState([])

    const formatData = (data) =>
        data.map((item) => ({
            date: item.date,
            'Всего повторил слов': item.countWords,
            Ошибок: item.countMistakeWords
        }))

    useEffect(() => {
        API_STATISTIC.getGraphStatistic().then((resp) => setGraphData(formatData(resp)))
    }, [])

    return (
        <div className='chart' style={{ width: '100%', padding: '24px 48px' }}>
            {!graphData.length ? (
                <Loading />
            ) : (
                <ResponsiveContainer width='100%' height={'100%'} className={'chart__wrapper'}>
                    <BarChart data={graphData} margin={{ top: 38 }}>
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='date' angle={-30} tickMargin={20} padding={{ right: 20 }} />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey='Всего повторил слов' fill='#0088FE' unit={' слов'} />
                        <Bar dataKey='Ошибок' fill='#FF8042' unit={' слов'} />
                        <Brush dataKey='date' height={30} stroke='#8884d8' y={0} />
                    </BarChart>
                </ResponsiveContainer>
            )}
        </div>
    )
}

export default Chart
