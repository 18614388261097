import '../../../styles/scss/buttons/add-premium-btn-in-profile.scss'
import { GEButton } from '../../../common/buttons'
import { openPremiumPage } from '../../../common/utils/promocode'
import { useNavigate } from 'react-router-dom'
import IconCrownWhite from "../svg_icons/IconCrownWhite";

const AddPremiumButtonInProfile = () => {
    const navigate = useNavigate()

    const openPremium = () => {
        openPremiumPage(navigate)
    }

    return (
        <div className='premium-button-container'>
            <GEButton className='premium_button' onClick={openPremium} icon={<IconCrownWhite/>}>
                Подключить премиум
            </GEButton>
        </div>
    )
}

export default AddPremiumButtonInProfile
