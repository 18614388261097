import { FC, useEffect, useReducer } from 'react'

interface YandexFloorAdProps {
    blockId: string,
    type: string
}

const YandexFloorAd: FC<YandexFloorAdProps> = ({ blockId, type }) => {
    // @ts-ignore
    const ref = useReducer()

    useEffect(() => {
        const yaScript = document.createElement('script')
        yaScript.setAttribute('type', 'text/javascript')
        yaScript.innerHTML = `window.yaContextCb.push(()=>{
               Ya.Context.AdvManager.render({
                   'blockId': '${blockId}',
                   'type': '${type}'
               })
           })`
        // @ts-ignore
        ref.current.appendChild(yaScript)

    }, [])

    return (
        // @ts-ignore
        <div ref={ref}>
        </div>
    )
}

export default YandexFloorAd