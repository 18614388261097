let UrlUtils = {
    /**
     * Сгенерировать ссылку для шарига слов из сообщества
     * @returns {string}
     * @param communityKey
     * @param wlId
     */
    createShareListUrl: (communityKey, wlId) => {
        return window.location.origin + '/share-list?communityKey=' + communityKey + '&wl=' + wlId
    },

    /**
     * Сгенерировать реферальную ссылку
     * @returns {string}
     * @param userId
     */
    createReferralUrl: (userId) => {
        return window.location.origin + '?referral-user-id=' + userId
    }
}

export default UrlUtils
