import MessageType from '../../const/MessageType'

const DialogUtils = {
    /**
     * This will return dialog Title removes
     * @function
     */
    getTitle: function (dialog, currentUser) {
        return dialog?.title.replace(currentUser?.login, '').trim()
    },
    /**
     * Return notification title depends on message type
     * @param msg
     * @returns {string}
     */
    getNotificationTitle: function (msg) {
        const type = msg.type
        if (MessageType.FRIEND_REQUEST === type) {
            return 'Запрос дружбы'
        } else {
            return 'Вам сообщение'
        }
    }
}

export default DialogUtils
