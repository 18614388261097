import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts'
import MotivationalQuotesUtil from '../utils/MotivationalQuotesUtil'
import { GEButton } from '../../common/buttons'
import { FC } from 'react'
import { Flex, Modal, Tag } from 'antd'
import { ID, WordDto, WordList } from '../../shared/types/api'
import { goToExercisePageWithReloading } from '../utils/goToPage'
import { NavigationPages } from '../../common/navigation/navigation-pages'
import { useNavigate } from 'react-router-dom'
import useTestRepeatCardLists from '../../hooks/useTestRepeatCardLists'

interface CompletedTestDialogProps {
    open: boolean,
    cancelWindow: () => void,
    showWongWords: () => void,
    repeat: () => void,
    rightAnswers: number,
    amountWordsInTest: number,
    selectedWordListId: ID | undefined,
    wrongAnswers: Array<WordDto>
}


const CompletedTestDialog: FC<CompletedTestDialogProps> = ({
                                                               open,
                                                               cancelWindow,
                                                               repeat,
                                                               rightAnswers,
                                                               amountWordsInTest,
                                                               showWongWords,
                                                               selectedWordListId,
                                                               wrongAnswers
                                                           }) => {

    const { repeatSuggestion } = useTestRepeatCardLists(amountWordsInTest, rightAnswers, wrongAnswers, open, selectedWordListId)

    const COLORS = ['#1890FF', '#e33a4e']

    const navigate = useNavigate()

    const formatGraphData = (right: number, amount: number) => {
        if (typeof right === 'number' && typeof amount === 'number') {
            return [
                {
                    name: 'Верных ответов',
                    value: right
                },
                {
                    name: 'Ошибок',
                    value: amount - right
                }
            ]
        }
        return []
    }

    const openSuggestionList = (rs: WordList) => {
        cancelWindow()
        goToExercisePageWithReloading(NavigationPages.TEST, navigate, rs)
    }


    return (
        <Modal
            destroyOnClose={true}
            footer={[
                <>
                    {amountWordsInTest !== rightAnswers &&
                    <GEButton onClick={showWongWords}>Показать ошибки</GEButton>}
                    <GEButton type='primary' onClick={repeat}>Повторить</GEButton>
                </>
            ]}

            title={'Верных ответов: ' + Math.round((rightAnswers / amountWordsInTest) * 100) + '%'} open={open}
            onCancel={cancelWindow}>
            <div>
                <span>{MotivationalQuotesUtil.generateRandomMotivationQuote()}</span>
                <PieChart style={{ fontSize: '1rem', margin: '0 auto' }} width={300} height={300}>
                    <Tooltip />
                    <Legend />
                    <Pie
                        data={formatGraphData(rightAnswers, amountWordsInTest)}
                        dataKey='value'
                        nameKey='name'
                        cx='50%'
                        cy='50%'
                        outerRadius={120}
                        fill='#92e4a4'
                    >
                        {formatGraphData(rightAnswers, amountWordsInTest).map(
                            (entry, index) => (
                                <Cell key={'cell' + index} fill={COLORS[index % COLORS.length]} />
                            )
                        )}
                    </Pie>
                </PieChart>
                {repeatSuggestion.length > 0 &&
                <>
                    <div>
                        <span>Рекомендуем повторить:</span>
                    </div>
                    <Flex gap={5} wrap={'wrap'}>
                        {repeatSuggestion.map((rs) => {
                            return <Tag
                                onClick={() => openSuggestionList(rs)}
                                className={'pointer-cursor'}>{rs.listName}</Tag>
                        })}
                    </Flex>
                </>}
            </div>
        </Modal>
    )
}


export default CompletedTestDialog
