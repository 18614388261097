export const test = [
    {
        id: 1,
        title: 'I ______ (to wait) for her for about 3 hours when at last I _____ ( to see) her near the monument.',
        variants: [
            { id: 1, title: 'was waiting; saw', isTrue: false },
            { id: 2, title: 'had been waiting; saw', isTrue: true },
            { id: 3, title: 'had been waited; had seen', isTrue: false }
        ]
    },
    {
        id: 2,
        title: 'They ______ (to chat) for over a month when they ______ (to meet) in the park.',
        variants: [
            { id: 1, title: 'did not chat; met', isTrue: false },
            { id: 2, title: 'had not been chatting; met', isTrue: true },
            { id: 3, title: 'had not been chatted; had met', isTrue: false }
        ]
    },
    {
        id: 3,
        title: 'They ______ (to sleep) in the afternoon as they _____ (to extinguish) the fire all night.',
        variants: [
            { id: 1, title: 'were sleeping; were extinguishing', isTrue: false },
            { id: 2, title: 'were sleeping; had been extinguishing ', isTrue: true },
            { id: 3, title: 'has slept; had been extinguishing', isTrue: false }
        ]
    },
    {
        id: 4,
        title: 'When I ______ (to leave) home, it ______ (to rain).',
        variants: [
            { id: 1, title: 'left; rained', isTrue: false },
            { id: 2, title: 'had been leaving; was raining', isTrue: false },
            { id: 3, title: 'left; was raining', isTrue: true }
        ]
    },
    {
        id: 5,
        title: 'Marc Chagall ______ (to live) in Paris for 30 years when his paintings ______ (to exhibit) in Louvre.',
        variants: [
            { id: 1, title: 'had been living; were exhibited', isTrue: true },
            { id: 2, title: 'was living; exhibited', isTrue: false },
            { id: 3, title: 'has been living; were exhibited', isTrue: false }
        ]
    },
    {
        id: 6,
        title: 'Mr. Smith ______ (to be) very tired in the evening because he _____ (to help) his wife all day.',
        variants: [
            { id: 1, title: 'had been; was helping', isTrue: false },
            { id: 2, title: 'had been; helped', isTrue: false },
            { id: 3, title: 'was; had been helping', isTrue: true }
        ]
    },
    {
        id: 7,
        title: 'She _____ (to know) him for 16 years before they ______ (to get) married.',
        variants: [
            { id: 1, title: 'knew; got', isTrue: false },
            { id: 2, title: 'had known; had been getting', isTrue: false },
            { id: 3, title: 'had been knowing; got', isTrue: true }
        ]
    }
]
