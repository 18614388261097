const TEXT_SIZE_INDEX = 0.044
const CANVAS_PERSENT_SIZE = 0.7
const BACKGROUND_COLOR = '#e9e9e9'
const SELECT_COLOR = 'rgba(80,248,21,0.84)'
const TEXT_COLOR = 'rgba(17,17,17,0.84)'

class CrosswordController {
    mousePressed = false
    rectWidth = 0
    crosswordLength = 0
    textSize = 0
    array = []
    selectedCoordinates = []
    words = []
    fields = []

    crosswordWidth = 0
    crosswordHeight = 0

    isGameEnd = true
    endGameCallback = null

    generateCrossword = (resp) => {
        this.isGameEnd = false
        this.words = resp.engWords
        this.fields = resp.field
        let crosswordLength = this.fields.length
        let viewArray = Array.from(Array(crosswordLength), () => new Array(crosswordLength))

        for (let i = 0; i < crosswordLength; i++) {
            for (let j = 0; j < crosswordLength; j++) {
                let wordNumber = this.fields[i][j].wordNumber
                let charNumber = this.fields[i][j].charNumber
                let word = this.words[wordNumber]
                viewArray[i][j] = { char: word.charAt(charNumber), isGuess: false, wordNumber: wordNumber }
            }
        }
        return viewArray
    }

    getXTextCoordinate(x) {
        let textMargin = this.textSize / 5.5
        return this.rectWidth * x + this.rectWidth / 2 - this.textSize / 2 + textMargin
    }

    getYTextCoordinate(y) {
        let textMargin = this.textSize / 5.5
        return this.rectWidth * y + this.rectWidth / 2 + this.textSize / 2 - textMargin
    }

    createCrosswordField = (p5, viewArray, endGameCallback) => {
        this.endGameCallback = endGameCallback
        this.crosswordWidth = this.getCrosswordPixelSize()
        this.crosswordHeight = this.getCrosswordPixelSize()

        p5.createCanvas(this.crosswordWidth, this.crosswordHeight)
        p5.background(BACKGROUND_COLOR)

        this.textSize = this.getCrosswordTextSize()
        p5.textSize(this.textSize)

        this.array = this.generateCrossword(viewArray)
        this.crosswordLength = this.array.length
        this.rectWidth = this.crosswordWidth / this.crosswordLength
        this.drawCrossword(p5)
    }

    drawCrossword(p5) {
        for (let i = 0; i < this.crosswordLength; i++) {
            for (let j = 0; j < this.crosswordLength; j++) {
                let x = this.getXTextCoordinate(j)
                let y = this.getYTextCoordinate(i)
                this.array[i][j].isGuess
                    ? p5.fill(this.getGuessColor(this.array[i][j].wordNumber))
                    : p5.fill(BACKGROUND_COLOR)
                p5.rect(j * this.rectWidth, i * this.rectWidth, this.rectWidth, this.rectWidth)
                p5.fill(TEXT_COLOR)
                p5.text(this.array[i][j].char, x, y)
            }
        }
    }

    getCrosswordPixelSize = () => {
        let width = window.screen.width
        let height = window.screen.height
        return width >= height ? height * CANVAS_PERSENT_SIZE : width * CANVAS_PERSENT_SIZE
    }

    getCrosswordTextSize = () => {
        return this.getCrosswordPixelSize() * TEXT_SIZE_INDEX
    }

    draw = (p5) => {
        if (this.mousePressed && !this.isGameEnd) {
            let rectX = 0
            let rectY = 0
            let columnNumber = 0
            let rowNumber = 0

            if (
                p5.mouseX <= this.crosswordWidth &&
                p5.mouseX >= 0 &&
                p5.mouseY >= 0 &&
                p5.mouseY <= this.crosswordHeight
            ) {
                for (let i = 0; i < this.crosswordLength; i++) {
                    if (p5.mouseX > i * this.rectWidth && p5.mouseX < (i + 1) * this.rectWidth) {
                        columnNumber = i
                        rectX = i * this.rectWidth
                    }
                    if (p5.mouseY > i * this.rectWidth && p5.mouseY < (i + 1) * this.rectWidth) {
                        rectY = i * this.rectWidth
                        rowNumber = i
                    }
                }

                p5.fill(SELECT_COLOR)
                p5.rect(rectX, rectY, this.rectWidth, this.rectWidth)

                let coordinates = { x: columnNumber, y: rowNumber }
                if (
                    this.selectedCoordinates.findIndex((cor) => cor.x === coordinates.x && cor.y === coordinates.y) ===
                    -1
                ) {
                    this.selectedCoordinates.push(coordinates)
                }

                let x = this.getXTextCoordinate(columnNumber)
                let y = this.getYTextCoordinate(rowNumber)
                p5.fill(TEXT_COLOR)
                p5.text(this.array[rowNumber][columnNumber].char, x, y)
            }
        }
    }

    updateMousePressed = (p5, isMousePressed) => {
        this.mousePressed = isMousePressed
        if (!isMousePressed && this.selectedCoordinates.length > 0) {
            if (this.isCorrectWord()) {
                this.markGuessWords()
                if (this.isAllWordsGuessed()) {
                    this.endGame()
                }
            }
        } else {
            this.selectedCoordinates = []
        }
        this.drawCrossword(p5)
    }

    markGuessWords = () => {
        this.selectedCoordinates.forEach((coord) => {
            this.array[coord.y][coord.x].isGuess = true
        })
    }

    /**
     * Смортрим, все ли слова угадал пользователь
     */
    isAllWordsGuessed = () => {
        return !this.array.flat().some((char) => char.isGuess === false)
    }

    endGame = () => {
        this.isGameEnd = true
        this.endGameCallback()
    }

    /**
     * Проверяет, правильное ли слово угадано
     * @returns {boolean}
     */
    isCorrectWord = () => {
        let result = false
        //Получаем номер слова по первой координате
        let firsCoord = this.selectedCoordinates[0]
        let wordNumber = this.fields[firsCoord.y][firsCoord.x].wordNumber
        if (this.words[wordNumber].length === this.selectedCoordinates.length) {
            for (let i = 1; i < this.selectedCoordinates.length; i++) {
                let coord = this.selectedCoordinates[i]
                result = this.fields[coord.y][coord.x].wordNumber === wordNumber
                if (!result) {
                    break
                }
            }
        }
        return result
    }

    getGuessColor = (guessWordNumber) => {
        const COLORS = [
            'rgba(110,215,128,0.84)',
            'rgba(153,21,248,0.84)',
            'rgba(217,86,86,0.84)',
            'rgba(246,230,154,0.84)',
            'rgba(231,138,174,0.84)',
            'rgba(219,131,131,0.84)',
            'rgba(117,132,234,0.84)',
            'rgba(67,153,143,0.84)',
            'rgba(252,159,110,0.84)',
            'rgba(226,153,211,0.84)',
            'rgba(95,194,196,0.84)',
            'rgba(82,94,206,0.84)',
            'rgba(222,81,113,0.84)',
            'rgba(118,229,185,0.84)',
            'rgba(93,185,222,0.84)',
            'rgba(174,159,220,0.84)',
            'rgba(206,92,103,0.84)',
            'rgba(130,201,106,0.84)',
            'rgba(117,208,238,0.84)',
            'rgba(186,133,96,0.84)',
            'rgba(196,158,125,0.84)',
            'rgba(105,58,229,0.84)',
            'rgba(85,214,219,0.84)',
            'rgba(206,142,231,0.84)',
            'rgba(220,140,198,0.84)',
            'rgba(185,187,119,0.84)',
            'rgba(104,183,186,0.84)',
            'rgba(104,148,199,0.84)',
            'rgba(198,116,187,0.84)',
            'rgba(81,189,189,0.84)',
            'rgba(201,198,104,0.84)',
            'rgba(99,132,210,0.84)'
        ]
        return COLORS[guessWordNumber]
    }
}

export default CrosswordController
