import { FC } from 'react'


interface IconUserProps {
    className?: string
    onClick?: () => void
}

const IconUser: FC<IconUserProps> = ({
                                         className, onClick = () => {
    }
                                     }) => {
    return (
        <svg
            onClick={onClick}
            xmlns='http://www.w3.org/2000/svg'
            width='32'
            height='32'
            fill='none'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            className={className}
            viewBox='0 0 32 32'
        >
            <path
                fill='#4174F8'
                d='M9 10a7 7 0 1014 0 7 7 0 00-14 0zm17 19H5a1 1 0 01-1-1v-3a5 5 0 015-5h14a5 5 0 015 5v3a1 1 0 01-1 1h-1z'
            ></path>
        </svg>
    )
}

export default IconUser
