import '../../../styles/css/loading/loading.css'
import { FC } from 'react'

interface LoadingProps {
    className?: string
}

const Loading: FC<LoadingProps> = ({ className }) => {
    const classes = className ? `${className} loading_cycle` : 'loading_cycle'
    return (
        <div className={classes}>
            <div className='loader'>
                <div className='circle' />
                <div className='circle' />
                <div className='circle' />
                <div className='circle' />
                <div className='circle' />
            </div>
        </div>
    )
}

export default Loading
