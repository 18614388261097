import { FC, useEffect, useState } from 'react'
import '../../../../styles/css/statistic/success_bar.css'
import SuccessBar from './SuccessBar'
import * as API_STATISTIC from '../../../../shared/http/statistic'

interface SuccessBarListProps {
    loadPath: string,
    params: Array<any>,
}
const SuccessBarList:FC<SuccessBarListProps> = ({loadPath, params}) => {

    const [response, setResponse] = useState(undefined)

    useEffect(() => {
        // @ts-ignore
        API_STATISTIC.getStatistic(loadPath).then((resp) => {
            setResponse(resp)
        })
    }, [])

    return (
            <div className='success-bar__cards'>
                {params.map((item, i) => (
                    <SuccessBar
                        key={i}
                        title={item.title}
                        paramName={item.paramName}
                        response={response}
                    />
                ))}
            </div>
    )
}

export default SuccessBarList