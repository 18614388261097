import '../../../../styles/scss/thanks-page/thanks-page.scss'
import {Helmet} from 'react-helmet'
import {ThanksPageIcon} from "../../../elements/svg_icons/ThanksPageIcon";
import {GEButton} from "../../../../common/buttons";
import {goToPageNotPremium} from "../../../utils/goToPage";
import {NavigationPages} from "../../../../common/navigation/navigation-pages";
import {useAppSelector} from "../../../../store/hooks";
import {userSelector} from "../../../../store/global";
import {useNavigate} from "react-router-dom";


const ThanksPage = () => {

    const navigate = useNavigate()
    const user = useAppSelector(userSelector)

    const openProfile = () => {
        goToPageNotPremium(`${NavigationPages.PROFILE}/${user.id}`, navigate)
    }

    const  iconHeight =  window.screen.width < 420 ? '200' : '496';
    const iconWidth = window.screen.width < 420 ? '200' : '496';

    return (
        <div className='thanks-page__container'>
            <Helmet title='Благодарность'/>
            <div className='thanks-page-container__icon'>
                <ThanksPageIcon width={iconWidth} height={iconHeight} />
            </div>
            <div className='thanks-page__text-container'>
                <span className='thanks-page__text'>Благодарим за покупку премиум!</span>
                <GEButton onClick={openProfile} type={"primary"} className='central-body-text__btn'>Вернуться в
                    профиль</GEButton>
            </div>

        </div>
    )
}

export default ThanksPage
