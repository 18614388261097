import { createAsyncThunk } from '@reduxjs/toolkit'
import * as API_WORDLIST from '../../shared/http/word-list'
import { ID, WordList } from '../../shared/types/api'

export const fetchWorldLists = createAsyncThunk('worldLists/fetchAll', () => API_WORDLIST.getWordLists())

export const fetchCommunityWorldListsById = createAsyncThunk('worldLists/fetchByCommunityId', (id: number) =>
    API_WORDLIST.getCommunityWordList(id)
)

export const createWordList = createAsyncThunk('worldLists/createWordList', ({
                                                                                 savedWordList,
                                                                                 id
                                                                             }: { savedWordList: WordList, id: number | string }) =>
    API_WORDLIST.create(savedWordList).then((res) => ({
        savedWordList: res,
        id
    }))
)

export const deleteWordList = createAsyncThunk('worldLists/deleteWordList', (id: number) =>
    API_WORDLIST.deleteWordList(id).then(() => ({ id }))
)

export const updateWordList = createAsyncThunk('worldLists/updateWordList', ({
                                                                                 data,
                                                                                 id
                                                                             }: { data: WordList, id: ID }) =>
    API_WORDLIST.update(data).then(() => ({ id, data }))
)

export const createInCommunity = createAsyncThunk('worldLists/createInCommunity', ({
                                                                                       communityId,
                                                                                       data,
                                                                                       id
                                                                                   }: { communityId: number, data: WordList, id: ID }) =>
    API_WORDLIST.createInCommunity(communityId, data).then((res) => ({
        wordList: res,
        id
    }))
)

export const updateInCommunity = createAsyncThunk('worldLists/updateInCommunity', ({
                                                                                       communityId,
                                                                                       data,
                                                                                       id
                                                                                   }: { communityId: number, data: WordList, id: number | string }) =>
    API_WORDLIST.updateInCommunity(communityId, data).then(() => ({ id, data }))
)

export const updateWordListLang = createAsyncThunk('worldLists/updateWordListLang', ({
                                                                                         data,
                                                                                         id
                                                                                     }: { data: WordList, id: ID }) =>
    API_WORDLIST.update(data).then(() => ({ id, data }))
)
