import RulesHeader from '../../blocks/RulesHeader'
import NumberBlock from '../../blocks/NumberBlock'
import TaskExample from '../../blocks/TaskExample'
import Example from '../../blocks/Example'
import FactRule from '../../blocks/FactRule'
import RuleTestWrapper from '../../RuleTestWrapper'
import { test } from './test'
import TableFour from '../../blocks/TableFour'

const FutureContinuous = (props) => {
    return (
        <div>
            <RulesHeader title='Future continuous' subtitle='Будущее длительное время' />

            <NumberBlock numberBlock='1' />
            <TaskExample taskExample='Изучите следующую ситуацию:' />
            <Example
                engExample={['Tomorrow this time I ', <u>will be flying</u>, ' to Ryzan.']}
                rusExample={['Завтра в это же время я ', <u>буду лететь</u>, ' в Рязань.']}
            />
            <p>
                {' '}
                Мы используем future continuous, когда указываем на процесс, который будет длиться в определенный момент
                в будущем. В отличие от времени future simple, этот момент в будущем должен быть назван прямо (tomorrow
                at 4 o'clock, when we meet) или быть очевидным из контекста.
            </p>
            <FactRule factRule='Существительное + will + be + Инфинитив + -ing это the future continuous:' />

            <NumberBlock numberBlock='2' />

            <p>
                {' '}
                В вопросительном предложении <b>will</b> выносится на место перед подлежащим, а <b>to be</b> и смысловой
                глагол остаются после него.
            </p>
            <TableFour elements={[{ first: 'Will', second: 'I/she/they', third: 'be', fourth: 'инфинитив + ing?' }]} />
            <Example
                engExample={['', <u>Will</u>, ' I ', <u>be driving</u>, ' all night long?']}
                rusExample={['Я ', <u>буду вести</u>, ' машину всю ночь?']}
            />
            <p>
                {' '}
                В отрицательных предложениях после <b>will</b> ставится отрицательная частица <b>not</b>. Вместе они
                могут быть сокращены до формы <b>won't</b>.
            </p>
            <TableFour
                elements={[{ first: 'I/she/they', second: 'will not', third: 'be', fourth: 'инфинитив + ing' }]}
            />
            <Example
                engExample={['At 4 I ', <u>will not be sleeping</u>, ' yet.']}
                rusExample={['В шесть я еще ', <u>не буду спать</u>, '.']}
            />

            <NumberBlock numberBlock='3' />
            <p> Случаи употребления Future Continuous:</p>
            <FactRule factRule='Указание на процесс, который будет происходить в определенный момент в будущем' />
            <Example
                engExample={['At 22 o’clock we ', <u>will be having</u>, ' party, you are welcome to join.']}
                rusExample={['В 22 часа у нас ', <u>будет</u>, ' вечеринка, вы можете присоединиться']}
            />

            <RuleTestWrapper value={test} rule={props} />
        </div>
    )
}

export default FutureContinuous
