import { FC, useState } from 'react'
import '../../styles/css/dictionary/modalwindow/modalw.css'
import { Button, Form, Input, Modal, Select } from 'antd'
import { WordDto, WordList } from '../../shared/types/api'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { userSelector } from '../../store/global'
import PrivilegesUtil from '../utils/PrivilegesUtil'
import Privileges from '../../const/Privileges'
import * as API_TEXT_TRANSLATOR from '../../shared/http/textTranslator'
import SwitchTooltip from '../elements/switch/SwitchTooltip'
import { setWordsRoutine, wordsDataSelector } from '../../store/words'
import WordUtils from '../utils/WordUtils'

interface GenerateWordsDictionaryDialogProps {
    open: boolean
    activeWordList: WordList,
    cancelWindow: () => void
}

const GenerateWordsDictionaryDialog: FC<GenerateWordsDictionaryDialogProps> = ({
                                                                                   open,
                                                                                   activeWordList,
                                                                                   cancelWindow
                                                                               }) => {

    const dispatch = useAppDispatch()
    const user = useAppSelector(userSelector)
    const words = useAppSelector(wordsDataSelector)
    const setWordsRedux = (w: Array<WordDto>) => dispatch(setWordsRoutine(w))

    const [loading, setLoading] = useState(false)
    const [level, setLevel] = useState('A1')
    const [text, setText] = useState('')
    const [deleteExistingWords, setDeleteExistingWords] = useState(false)
    const [deleteWordsLevel, setDeleteWordsLevel] = useState(false)
    const [splitByEnter, setSplitByEnter] = useState(false)
    const [isValidText, setIsValidText] = useState(false)
    const [messageCorrectTa, setMessageCorrectTa] = useState('')
    const [symbolsNumber, setSymbolsNumber] = useState(0)

    const changeLevelHandler = (value: string) => {
        setLevel(value)
    }

    const changeTa = (inpText: string) => {
        setSymbolsNumber(inpText.length)
        setText(inpText)

        if (inpText.length < 1) {
            setIsValidText(false)
        } else if (
            inpText.length > 5000 &&
            !PrivilegesUtil.hasPrivilege(user, Privileges.LONG_TEXT_TRANSLATOR)
        ) {
            setIsValidText(false)
            setMessageCorrectTa('Вы превысили допустимое количество символов текста')

        } else {
            setIsValidText(true)
            setMessageCorrectTa('')
        }
    }

    const changeTextHandler = (e: any) => {
        let inpText = e.target.value
        changeTa(inpText)
    }

    const changeExistingWords = (value: boolean) => {
        setDeleteExistingWords(value)
    }

    const changeWordsLevel = (value: boolean) => {
        setDeleteWordsLevel(value)
    }

    const changeSplitByEnter = (value: boolean) => {
        setSplitByEnter(value)
    }

    const generateWordList = () => {
        setLoading(true)
        API_TEXT_TRANSLATOR.generateTextToWord(
            text,
            activeWordList.id,
            deleteExistingWords,
            deleteWordsLevel,
            level,
            splitByEnter
            //@ts-ignore
        ).then((resp) => {
            const cards = WordUtils.parseWorlds(resp.data.content)
            const newWords = [...words, ...cards]
            setWordsRedux(newWords)
            clearAndClose()
        })
            .catch((err: any) => {
                Modal.error({
                    title: 'Ошибка!',
                    content: 'Не удалось сгенерировать карточки'
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const clearAndClose = () => {
        setLevel('A1')
        setText('')
        setDeleteExistingWords(false)
        setDeleteWordsLevel(false)
        setSplitByEnter(false)
        setIsValidText(false)
        setMessageCorrectTa('')
        setSymbolsNumber(0)
        cancelWindow()
    }

    const saveWords = () => {
        generateWordList()
    }

    return (
        <Modal title='Автоматическая генерация карточек'
               onCancel={clearAndClose}
               footer={[
                   <Button key='submit' onClick={clearAndClose}>
                       Отмена
                   </Button>,
                   <Button
                       disabled={!isValidText}
                       onClick={saveWords}
                       loading={loading}
                       type='primary'
                   >
                       Сгенерировать слова
                   </Button>
               ]}
               open={open}>
            <div className='row-ta'>
                <span className='ta-name'>Ваш текст на английском:</span>
            </div>
            <div className='row-ta'>
                <Form name={'text'} style={{ width: '100%' }}>
                    <Form.Item>
                        <Input.TextArea
                            className='ta-auto'
                            autoSize={{ minRows: 5 }}
                            placeholder={'Ваш текст ...'}
                            value={text}
                            onChange={changeTextHandler}
                        />
                        <span
                            className={
                                isValidText ? 'valid-symbols-number-text' : 'invalid-symbols-number-text'
                            }
                        >
                                {symbolsNumber + '/5000 '}
                            </span>
                        <span>«Переведено сервисом<a href='https://aws.com'> «AWS»</a>
                </span>
                    </Form.Item>
                </Form>
            </div>

            <div className='row-valid'>
                <span style={{ color: 'red' }} className='valid-text'>{messageCorrectTa}</span>
            </div>
            <div className='checkbox-container'>
                <SwitchTooltip toolTipText={'В итоговую подборку не попадут слова, которые у вас уже есть'}
                               text={'Исключить имеющиеся слова'}
                               onChange={changeExistingWords}
                               value={deleteExistingWords} />
                <SwitchTooltip
                    onChange={changeSplitByEnter}
                    value={splitByEnter}
                    text={'Разделять фразы через Enter'}
                    toolTipText={'Каждая фраза с новой стоки будет отдельной карточкой'}
                />
                <SwitchTooltip
                    onChange={changeWordsLevel}
                    value={deleteWordsLevel}
                    text={'Исключить слова по уровню'}
                    toolTipText={'В подборку не пойдут слова из предустановленного списка по уровню'}
                />
                <Select
                    disabled={!deleteWordsLevel}
                    defaultValue='A1'
                    style={{ marginTop: '10px' }}
                    onChange={changeLevelHandler}
                    options={[
                        { value: 'A1', label: 'A1' },
                        { value: 'A2', label: 'A2' },
                        { value: 'B1', label: 'B1' },
                        { value: 'B2', label: 'B2' }
                    ]}
                />
            </div>
        </Modal>
    )
}

export default GenerateWordsDictionaryDialog
