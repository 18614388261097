import { useState } from 'react'
import '../../../../styles/scss/change-password/change-password.scss'
import * as API_USER from '../../../../shared/http/user'
import MainModel from '../../../../model/MainModel'
import ValidateUser from '../../../utils/ValidateUser'
import StringUtils from '../../../utils/StringUtils'
import { notification } from 'antd'
import { Helmet } from 'react-helmet'
import { NavigationPages } from '../../../../common/navigation/navigation-pages'
import { GEButton } from '../../../../common/buttons'
import { goToPageNotPremium } from '../../../utils/goToPage'
import { useNavigate } from 'react-router-dom'

const ChangePassword = () => {
    const navigate = useNavigate()
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [validationMessage, setValidationMessage] = useState('')

    const updatePassword = (event) => {
        setPassword(event.target.value)
    }
    const updatePasswordConfirm = (event) => {
        setPasswordConfirm(event.target.value)
    }

    const resetPassword = (event) => {
        event.preventDefault()
        if (isPasswordValidFun()) {
            let urlParams = new URLSearchParams(window.location.search)
            let passwordDto = {
                userId: parseInt(urlParams.get('id')),
                userToken: urlParams.get('token'),
                password: password,
                passwordConfirm: passwordConfirm
            }
            API_USER.changePassword(passwordDto)
                .then(() => {
                    notification.open({
                        message: 'Пароль успешно изменен'
                    })
                    goToPageNotPremium(NavigationPages.LANDING, navigate)
                })
                .catch((e) => {
                    setValidationMessage(MainModel.getMessageFromGeekException(e))
                })
        }
    }

    const isPasswordValidFun = () => {
        let errors = ValidateUser.getPasswordErrors(password, passwordConfirm)
        let isPasswordValid = errors.length === 0
        if (!isPasswordValid) {
            let errorsStr = errors.filter((error) => StringUtils.isNotEmptyStr(error)).join('. ')
            this.setState({ validationMessage: errorsStr })
        }
        return isPasswordValid
    }

    return (
        <div id='change-password'>
            <Helmet title='GE: Смена пароля' />
            <form className='change-password__form'>
                <h1>Geekenglish</h1>
                <input
                    className='form__input-new-password'
                    type='password'
                    name='pass1'
                    placeholder='New password'
                    onChange={(event) => updatePassword(event)}
                />
                <input
                    className='form__input-new-password'
                    type='password'
                    name='pass2'
                    placeholder='Confirm new password'
                    onChange={(event) => updatePasswordConfirm(event)}
                />
                <i className='change-password__error-message'>{validationMessage}</i>
                <GEButton type='primary' className='margin10top' onClick={resetPassword}>
                    Сменить пароль
                </GEButton>
            </form>
        </div>
    )
}

export default ChangePassword
