import RulesHeader from '../../blocks/RulesHeader'
import NumberBlock from '../../blocks/NumberBlock'
import TaskExample from '../../blocks/TaskExample'
import Example from '../../blocks/Example'
import TableUniqOne from '../../blocks/TableUniqOne'
import TableUniqTwo from '../../blocks/TableUniqTwo'
import { test } from './test'
import RuleTestWrapper from '../../RuleTestWrapper'

const MustAndCant = (props) => {
    return (
        <div>
            <RulesHeader title='Модальные глаголы' subtitle='must и can’t' />

            <NumberBlock numberBlock='Must и Can’t' />
            <p>
                {' '}
                Вы можете использовать модальный глагол <b>must</b>, когда вы уверены в чем-то. Дословно на русский язык{' '}
                <b>must</b> be переводится как <b>должно быть</b>, но можно также использовать{' '}
                <b>‘возможно, вероятно, наверняка, наверное и др.’</b>:
            </p>
            <Example
                engExample={['Tomas ', <u>must</u>, ' get very bored in his job.']}
                rusExample='Томас, должно быть, очень скучает на своей работе.'
            />
            <p>
                {' '}
                Вы можете использовать <b>can’t</b> для обратного значения, т.е. когда убеждены, что что-то невозможно:
            </p>
            <Example
                engExample={['I ', <u>can’t</u>, ' come to the party.']}
                rusExample='Я не могу прийти на вечеринку.'
            />
            <TaskExample taskExample={['Изучите структуру предложений с ', <b>must</b>, ' и ', <b>can’t</b>, ':']} />
            <TableUniqOne />

            <NumberBlock numberBlock='Must have (done) и Can’t have (done)' />
            <p>
                {' '}
                Для прошедшего времени мы используем <b>must have (done)</b> и <b>can’t have (done)</b>:
            </p>
            <Example
                engExample={['I didn’t hear the phone. I ', <u>must</u>, ' have been asleep.']}
                rusExample='Я не слышал, как зазвонил телефон. Должно быть, я спал'
            />
            <Example
                engExample={['Tomas hasn’t contacted me. He ', <u>can’t</u>, ' have got my message.']}
                rusExample='Томас не связывался со мной. Он мог не получить мое сообщение.'
            />
            <TaskExample taskExample={['Изучите структуру предложений с ', <b>must</b>, ' и ', <b>can’t</b>, ':']} />
            <TableUniqTwo />
            <p>
                {' '}
                Вместо <b>can’t have</b> можно использовать <b>couldn’t have</b>:
            </p>
            <Example
                engExample={['Tomas ', <u>couldn’t have</u>, ' got my message.']}
                rusExample='Томас мог не получить мое сообщение.'
            />
            <RuleTestWrapper value={test} rule={props} />
        </div>
    )
}

export default MustAndCant
