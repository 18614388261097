import { Helmet } from 'react-helmet'
import '../../../../../styles/scss/course/course.scss'
import { Button, Flex, Form, FormProps, Input, message } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { notifyTelegramBot } from '../../../../../shared/http/telegram-notification'

export const CreateCourse = () => {


    type FieldType = {
        title?: string;
        email?: string;
        description?: string;
        price?: string;
        lessonsCount?: string;
        levels?: string;
        duration?: string;
        views?: string;
        modules?: string;

    };

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        message.success('Данные успешно сохранены! Скоро мы свяжемся с вами')
        notifyTelegramBot(JSON.stringify(values))
    }

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }


    return (
        <div id='create-course'>
            <Helmet title='Страница создание курса' />
            <Flex gap='16px' justify={'center'}>

                <Form
                    name='basic'
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 16 }}
                    style={{ width: 900 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='off'
                >
                    <Flex justify={'space-between'} align={'center'}>
                        <h1>Основная информация </h1>
                        <a href='https://geekenglish.ru/courses-overview/1' target='_blank'>Пример курса</a>
                    </Flex>


                    <Form.Item<FieldType>
                        label='Название курса'
                        name='title'
                        rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item<FieldType>
                        label='Описание  курса'
                        name='description'
                        rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
                    >
                        <TextArea maxLength={600} rows={4} />
                    </Form.Item>

                    <Form.Item<FieldType>
                        name='price'
                        label='Стоимость курса'
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item<FieldType>
                        label='Ваша электронная почта'
                        name='email'
                        rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
                    >
                        <Input />
                    </Form.Item>

                    <h2>Детали курса</h2>
                    <Form.Item<FieldType>
                        label='Число уроков (модулей)'
                        name='lessonsCount'
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item<FieldType>
                        label='Уровни (A1-C2)'
                        name='levels'
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item<FieldType>
                        label='Длительность курса'
                        name='duration'
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type='primary' htmlType='submit'>
                            Отправить
                        </Button>
                    </Form.Item>
                </Form>
            </Flex>
        </div>
    )
}