import { FC } from 'react'

interface HeartFilledIconProps {
    className?: string,
    onClick: () => void
}


const HeartIcon: FC<HeartFilledIconProps> = ({ onClick }) => {

    return (
        <svg
            onClick={onClick}
            xmlns='http://www.w3.org/2000/svg'
            width='30'
            height='30'
            fill='none'
            stroke='#ff4d4f'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            className='icon icon-tabler icon-tabler-heart pointer-cursor'
            viewBox='0 0 24 20'
        >
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <path d='M19.5 12.572L12 20l-7.5-7.428A5 5 0 1112 6.006a5 5 0 117.5 6.572'></path>
        </svg>
    )
}

export default HeartIcon
