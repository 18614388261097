import '../../../../styles/css/statistic/month_word_mistakes.css'
import * as API_STATISTIC from '../../../../shared/http/statistic'
import Loading from '../../../elements/loading/Loading'
import {useState, useEffect} from "react";
import {Table} from "antd";

const MonthWordMistakes = () => {

  const [response, setResponce] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    API_STATISTIC.getMistakesStatistic()
      .then((resp) => {
        setResponce(resp)
      })
      .finally(() => {
        setLoading(false)
      })
  }, []);

  const columns = [
    {
      title: 'Сторона 1',
      dataIndex: 'engWord',
      key: 'engWord',
      align: 'center',
    },
    {
      title: 'Сторона 2',
      dataIndex: 'rusWord',
      key: 'rusWord',
      align: 'center'
    },
    {
      title: 'Количество ошибок',
      dataIndex: 'countMistakes',
      key: 'countMistakes',
      align: 'center',
    }
  ]

  return (
    <div className='month-stats'>
      {!response ? (
        <Loading/>
      ) : !response.length ? (
        <span className={'month-stats__warning'}>У вас пока нет изученных слов</span>
      ) : (
        <Table
          columns={columns}
          dataSource={response}
          pagination={false}
          locale={{ emptyText: 'Не удалось загрузить слова' }}
          className='month-stats__table'
          scroll={{ y: 400}}
        />
      )}
    </div>
  )
}

export default MonthWordMistakes
