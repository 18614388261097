import { useEffect, useState } from 'react'
import * as API_TEACHERS from '../../../../../shared/http/teacher'
import { Table } from 'antd'

const Teachers = () => {

    const [data, setData] = useState()
    const [loading, setLoading] = useState()

    /**
     * Загрузить пользователей с их привилегиями
     */
    const loadTeacherDocuments = () => {
        setLoading(true)
        API_TEACHERS.getTeacherInfo().then((teachers) => {
            setData(teachers)
            setLoading(false)
        })
    }

    const columns = [
        {
            title: 'ID преподавателя',
            dataIndex: 'userId',
            key: 'userId',
            render: (text) => <a>{text}</a>
        },
        {
            title: 'Имя',
            dataIndex: 'firstName',
            key: 'firstName',
            render: (text) => <a>{text}</a>
        },
        {
            title: 'Отчество',
            dataIndex: 'middleName',
            key: 'middleName',
            render: (text) => <a>{text}</a>
        },
        {
            title: 'Фамилия',
            dataIndex: 'lastName',
            key: 'lastName',
            render: (text) => <a>{text}</a>
        },
        {
            title: 'ИНН',
            dataIndex: 'inn',
            key: 'inn',
            render: (text) => <a>{text}</a>
        },
        {
            title: 'BIC',
            dataIndex: 'bicBank',
            key: 'bicBank',
            render: (text) => <a>{text}</a>
        },
        {
            title: 'Банк',
            dataIndex: 'bankName',
            key: 'bankName',
            render: (text) => <a>{text}</a>
        },
        {
            title: 'Номер счета',
            dataIndex: 'bankAccountNumber',
            key: 'bankAccountNumber',
            render: (text) => <a>{text}</a>
        },
        {
            title: 'Файл',
            key: 'fileType',
            render: (item) => <a
                href={`data:${item.file};base64,` + item.fileType}
                target='_blank'
                download={item.fileName}
            >{item.fileType}</a>
        }
    ]

    useEffect(() => {
        loadTeacherDocuments()
    }, [])

    return <>
        <Table columns={columns} dataSource={data} loading={loading} />
    </>
}

export default Teachers
