import GoogleAnalyticsConsts from '../../const/GoogleAnalyticsConsts'
import GoogleAnalyticsUtils from '../../components/utils/GoogleAnalyticsUtils'
import { NavigationPages } from '../navigation/navigation-pages'
import { notifyTelegramBot } from '../../shared/http/telegram-notification'
import { goToPageNotPremium } from '../../components/utils/goToPage'
import { NavigateFunction } from 'react-router/dist/lib/hooks'

export const buyPromoCode30 = (): void => {
    notifyTelegramBot('Хочет купить 30 дней')
    const url: string = 'https://auth.robokassa.ru/RecurringSubscriptionPage/Subscription/Subscribe?SubscriptionId=9e72ac0d-aa64-45c4-a8d7-462790f5c4e3'
    window.open(url, '_blank')?.focus()
    GoogleAnalyticsUtils.createEvent(GoogleAnalyticsConsts.PREMIUM_OPEN_BUY_PREMIUM_30)
}

export const buyPromoCode365 = (): void => {
    notifyTelegramBot('Хочет купить 365 дней')
    const url: string = 'https://auth.robokassa.ru/RecurringSubscriptionPage/Subscription/Subscribe?SubscriptionId=723140e5-bff8-4d1d-87b0-19620fc96a31'
    window.open(url, '_blank')?.focus()
    GoogleAnalyticsUtils.createEvent(GoogleAnalyticsConsts.PREMIUM_OPEN_BUY_PREMIUM_365)
}

export const buyPromoCode90 = (): void => {
    notifyTelegramBot('Хочет купить 3 месяца по акции')
    const url: string = 'https://auth.robokassa.ru/RecurringSubscriptionPage/Subscription/Subscribe?SubscriptionId=f0d2d8ba-10be-4977-aa23-d88327595dc7'
    window.open(url, '_blank')?.focus()
    GoogleAnalyticsUtils.createEvent(GoogleAnalyticsConsts.PREMIUM_OPEN_BUY_PREMIUM_365)
}

export const openPremiumPage = (navigate: NavigateFunction): void => {
    goToPageNotPremium(NavigationPages.PREMIUM, navigate)
}