import { useRef, useState } from 'react'
import SockJsClient from 'react-stomp'
import UserUtil from '../utils/UserUtil'
import '../../styles/css/dialogs/dialogs.css'
import { notification } from 'antd'
import DialogUtils from './DialogUtils'
import Constants from '../../const/Constants'
import { addDialogMessageRoutine } from '../../store/dialogs'
import { __PRODUCTION__ } from '../../env'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { userSelector } from '../../store/global'

const WebSocket = (props) => {
    const dispatch = useAppDispatch()
    const addMessage = (msg) => dispatch(addDialogMessageRoutine(msg))
    const currentUser = useAppSelector(userSelector)

    const [clientConnected, setClientConnected] = useState(false)
    // Раньше было createRef()
    const stomClient = useRef()

    /**
     * Обработка приходящих сообщений
     * @param stompMessage
     */
    const handleMessage = (stompMessage) => {
        openNotification(stompMessage)
        props.addMessage(stompMessage)
    }

    /**
     * Обработка приходящих сообщений
     * @param stompMessage
     */
    const connected = () => {
        if (!__PRODUCTION__) {
            notification.open({
                message: 'WebSockets',
                description: 'Подключены!!'
            })
        }
    }

    const sendMessage = (msg) => {
        try {
            stomClient.current.sendMessage('/app/all', JSON.stringify(msg))
            return true
        } catch (e) {
            return false
        }
    }

    const openNotification = (msg) => {
        const text = msg.senderLogin ? msg.senderLogin + ': ' + msg.text : msg.text
        notification.open({
            message: DialogUtils.getNotificationTitle(msg),
            description: text
        })
    }

    const host = __PRODUCTION__ ? window.location.host : 'localhost:8081'
    const wsSourceUrl =
        window.location.protocol + '//' + host + `${Constants.queryConst}/ge-chat?token=` + localStorage.getItem('token')
    return (
        <>
            {currentUser ? (
                <SockJsClient
                    url={wsSourceUrl}
                    topics={['/topic/all/' + UserUtil.getId(currentUser.user)]}
                    onMessage={handleMessage}
                    ref={stomClient}
                    onDisconnect={() => {
                        setClientConnected(false)
                    }}
                    onConnect={connected}
                    debug={false}
                />
            ) : (
                ''
            )}
        </>
    )
}

export default WebSocket
