import '../../../styles/css/elements/svg_icons/icon-btn.css'

const IconChecked = ({ width = '60', height = '60' }) => {

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            fill='none'
            stroke='#7bc62d'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            className='icon icon-tabler icon-tabler-circle-check'
            viewBox='0 0 24 24'
        >
            <title>Завершено</title>
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <circle cx='12' cy='12' r='9'></circle>
            <path d='M9 12l2 2 4-4'></path>
        </svg>
    )
}

export default IconChecked
