export const test = [
    {
        id: 1,
        title: 'At the end of this school year, she ____ for 51 years. (to teach)',
        variants: [
            { id: 1, title: 'will have teaching', isTrue: false },
            { id: 2, title: 'will have been teaching', isTrue: true },
            { id: 3, title: 'will have being teaching', isTrue: false }
        ]
    },
    {
        id: 2,
        title: 'Next year I ___ in the company for 30 years. (to work)',
        variants: [
            { id: 1, title: 'will have working', isTrue: false },
            { id: 2, title: 'will have been working', isTrue: true },
            { id: 3, title: 'will have being working', isTrue: false }
        ]
    },
    {
        id: 3,
        title: 'Next month my boyfriend and I ____ exclusively for nine years. (to date)',
        variants: [
            { id: 1, title: 'will have been dating', isTrue: true },
            { id: 2, title: 'will have being dating', isTrue: false },
            { id: 3, title: 'will have dating', isTrue: false }
        ]
    },
    {
        id: 4,
        title: ' People ____ "let is have a look at them now." (to say)',
        variants: [
            { id: 1, title: 'will have been saying', isTrue: false },
            { id: 2, title: 'will have being saying', isTrue: true },
            { id: 3, title: 'will have saying', isTrue: false }
        ]
    },
    {
        id: 5,
        title: 'What ____ here? (they, to do)',
        variants: [
            { id: 1, title: 'will they have doing', isTrue: false },
            { id: 2, title: 'will they doing', isTrue: false },
            { id: 4, title: 'will they have been doing', isTrue: true }
        ]
    }
]
