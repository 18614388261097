export const test = [
    {
        id: 1,
        title: 'It’s late. I think I ______ a taxi.',
        variants: [
            { id: 1, title: 'will take', isTrue: true },
            { id: 2, title: 'am take', isTrue: false },
            { id: 3, title: 'takes', isTrue: false }
        ]
    },
    {
        id: 2,
        title: 'We don’t know their address. What ______ ?',
        variants: [
            { id: 1, title: 'are we do', isTrue: false },
            { id: 2, title: 'will we do', isTrue: true },
            { id: 3, title: 'did we do', isTrue: false }
        ]
    },
    {
        id: 3,
        title: 'You ______ in Paris tomorrow evening.',
        variants: [
            { id: 1, title: 'will arrive', isTrue: true },
            { id: 2, title: 'arriving', isTrue: false },
            { id: 3, title: 'arrive', isTrue: false }
        ]
    },
    {
        id: 4,
        title: 'The boy ______ this day all his life.',
        variants: [
            { id: 1, title: 'remembers', isTrue: false },
            { id: 2, title: 'will remember', isTrue: true },
            { id: 3, title: 'should remember', isTrue: false }
        ]
    },
    {
        id: 5,
        title: 'I’m not sure I ______ Jim at the hotel.',
        variants: [
            { id: 1, title: 'shall found', isTrue: false },
            { id: 2, title: 'found', isTrue: false },
            { id: 3, title: 'shall find', isTrue: true }
        ]
    },
    {
        id: 6,
        title: 'We ______ the tickets in advance.',
        variants: [
            { id: 1, title: 'shalln’t book', isTrue: false },
            { id: 2, title: 'shan’t book', isTrue: true },
            { id: 3, title: 'not book', isTrue: false }
        ]
    },
    {
        id: 7,
        title: 'Do you think it ______ ?',
        variants: [
            { id: 1, title: 'rained', isTrue: false },
            { id: 2, title: 'will be rain', isTrue: false },
            { id: 3, title: 'will rain', isTrue: true }
        ]
    },
    {
        id: 8,
        title: 'Everybody thinks they ______ married.',
        variants: [
            { id: 1, title: 'won’t get', isTrue: true },
            { id: 2, title: 'not will get', isTrue: false },
            { id: 3, title: 'did not get', isTrue: false }
        ]
    },
    {
        id: 9,
        title: 'There ______ any wars in the world.',
        variants: [
            { id: 1, title: 'aren’t wars', isTrue: false },
            { id: 2, title: 'will not be', isTrue: true },
            { id: 3, title: 'not’ll be', isTrue: false }
        ]
    },
    {
        id: 10,
        title: 'I ______ it before dinner.',
        variants: [
            { id: 1, title: 'not do', isTrue: false },
            { id: 2, title: '‘ll not do', isTrue: true },
            { id: 3, title: 'am not do', isTrue: false }
        ]
    }
]
