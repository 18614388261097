import { FC, useEffect, useState } from 'react'
import '../../../../../styles/scss/profile_page/profile_page.scss'
import Constants from '../../../../../const/Constants'
import UserUtil from '../../../../utils/UserUtil'
import { getFirstName, getLastName, getLoginByUser, isPremium } from '../../../../../common/utils/user'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { setUserRoutine, userSelector } from '../../../../../store/global'
import * as API_USER from '../../../../../shared/http/user'
import NotificationsUtils from '../../../../utils/NotificationsUtils'
import { Button, Dropdown, Flex, MenuProps, Space } from 'antd'
import IconCrown from '../../../../elements/svg_icons/IconCrown'
import { BurgerMenuIcon } from '../../../../elements/svg_icons/BurgerMenuIcon'
import AddPremiumButtonInProfile from '../../../../elements/buttons/AddPremiumButtonInProfile'
import * as API_WORDLIST from '../../../../../shared/http/word-list'
import { DropdownProfileMenu } from './DropdownProfileMenu'
import UrlUtils from '../../../../utils/UrlUtils'
import { goToPageNotPremium } from '../../../../utils/goToPage'
import { NavigationPages } from '../../../../../common/navigation/navigation-pages'
import { useNavigate } from 'react-router-dom'
import EditProfile from '../../../../modalwindows/EditProfile'
import GetNotificationsInProfile from '../../../../modalwindows/GetNotificationsInProfile'
import InterPromoCode from '../../../../modalwindows/InterPromoCode'
import ConfirmModalDialog from '../../../../modalwindows/ConfirmModalDialog'
import BindTelegramCodeDialog from '../../../../modalwindows/BindTelegramCodeDialog'
import SwitchToTeacherDialog from '../../../../modalwindows/SwitchToTeacherDialog'
import EditProfileImage from '../../../../modalwindows/EditProfileImage'

interface UserCardProps {
}

export const UserCard: FC<UserCardProps> = () => {
    const currentUser = useAppSelector(userSelector)
    const [countUserList, setCountUserList] = useState(0)
    const [showInterPromoCode, setShowInterPromoCode] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showEditProfileModal, setShowEditProfileModal] = useState(false)
    const [showEditProfileImageModal, setShowEditProfileImageModal] = useState(false)
    const [showSwitchToTeacherModal, setShowSwitchToTeacherModal] = useState(false)
    const [showBindTelegramCodeModal, setShowBindTelegramCodeModal] = useState(false)
    const [showGetNotificationsInProfileModal, setShowGetNotificationsInProfileModal] = useState(false)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        API_WORDLIST.getCountWordsListsUser().then(c => {
            setCountUserList(c.data)
        })

    }, [])

    const createReferenceLink = () => {
        navigator.clipboard
            .writeText(UrlUtils.createReferralUrl(currentUser.id))
            .then(() => {
                NotificationsUtils.openSuccessNotification('Ссылка скопирована!')
            })
            .catch(() => {
                NotificationsUtils.openErrorNotification('Не удалось скопировать ссылку')
            })
    }

    const openWritePromoCodeDialog = () => {
        setShowInterPromoCode(true)
    }
    const openBindTelegramCodeDialog = () => {
        setShowBindTelegramCodeModal(true)
    }
    const closeBindTelegramCodeDialog = () => {
        setShowBindTelegramCodeModal(false)
    }
    const getAuthCode = () => {
        goToPageNotPremium(NavigationPages.AUTH_BY_CODE, navigate)
    }
    const openTelegram = () => {
        window.open('https://t.me/GeekenglishBot', '_blank')?.focus()
    }
    const exitButtonClick = () => {
        API_USER.logout()
            .then(() => {
                localStorage.removeItem('token')
            })
            .finally(() => {
                setTimeout(() => {
                    dispatch(setUserRoutine(null))
                    goToPageNotPremium(NavigationPages.LANDING, navigate)
                }, 1000)
            })
    }
    const openConfirmModal = () => {
        setShowConfirmModal(true)
    }
    const closeConfirmModal = () => {
        setShowConfirmModal(false)
    }
    const deleteAccount = () => {
        API_USER.deleteAccount().then(() => {
            localStorage.removeItem('token')
        })
            .finally(() => {
                setTimeout(() => {
                    dispatch(setUserRoutine(null))
                    goToPageNotPremium(NavigationPages.LANDING, navigate)
                }, 1000)
            })
    }

    const openEditProfileModal = () => {
        setShowEditProfileModal(true)
    }
    const openGetNotificationsInProfileModal = () => {
        setShowGetNotificationsInProfileModal(true)
    }

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        switch (e.key) {
            case DropdownProfileMenu.INVITE_FRIEND:
                createReferenceLink()
                break

            case DropdownProfileMenu.INTER_PROMOCODE:
                openWritePromoCodeDialog()
                break

            case DropdownProfileMenu.GET_NOTIFICATIONS:
                openGetNotificationsInProfileModal()
                break

            case DropdownProfileMenu.GET_AUTHORIZATION_CODE:
                getAuthCode()
                break

            case DropdownProfileMenu.GO_TO_BOT:
                openTelegram()
                break
            case DropdownProfileMenu.BIND_BOT:
                openBindTelegramCodeDialog()
                break

            case DropdownProfileMenu.EDIT_PROFILE:
                openEditProfileModal()
                break

            case DropdownProfileMenu.SWITCH_TO_TUTOR:
                setShowSwitchToTeacherModal(true)
                break

            case DropdownProfileMenu.DELETE_PROFILE:
                openConfirmModal()
                break

            case DropdownProfileMenu.EXIT:
                exitButtonClick()
                break
        }
    }

    const createItems = (): MenuProps['items'] => {
        const result = []
        result.push({
            label: 'Пригласить друга',
            key: DropdownProfileMenu.INVITE_FRIEND
        })
        result.push({
            label: 'Ввести промокод',
            key: DropdownProfileMenu.INTER_PROMOCODE
        })
        result.push({
            label: 'Получать уведомления',
            key: DropdownProfileMenu.GET_NOTIFICATIONS
        })
        result.push({
            label: 'Получить код авторизации',
            key: DropdownProfileMenu.GET_AUTHORIZATION_CODE
        })
        result.push({
            label: 'Перейти в бот telegram',
            key: DropdownProfileMenu.GO_TO_BOT
        })
        if (!UserUtil.getTelegramChannel(currentUser)) {
            result.push({
                label: 'Привязать бот telegram к аккаунту',
                key: DropdownProfileMenu.BIND_BOT
            })
        }
        result.push({
            label: 'Редактировать аккаунт',
            key: DropdownProfileMenu.EDIT_PROFILE
        })
        result.push({
            label: 'Переключиться на аккаунт преподавателя',
            key: DropdownProfileMenu.SWITCH_TO_TUTOR
        })
        result.push({
            label: 'Удалить аккаунт',
            key: DropdownProfileMenu.DELETE_PROFILE
        })
        result.push({
            label: 'Выйти из аккаунта',
            key: DropdownProfileMenu.EXIT
        })

        return result
    }

    const items = createItems()

    const menuProps = {
        items,
        onClick: handleMenuClick
    }

    return (
        <div className='user-card-container grid-1'>
            <InterPromoCode isOpen={showInterPromoCode} close={() => setShowInterPromoCode(false)} />
            <div className='user-card__dropdown'>
                <Dropdown menu={menuProps}>
                    <Button type={'primary'}>
                        <Space>
                            Меню
                            <BurgerMenuIcon onClick={() => {
                            }} />
                        </Space>
                    </Button>
                </Dropdown>
            </div>


            <label htmlFor='user_photo' className='profile_photo' onClick={() => {
                setShowEditProfileImageModal(true)
            }}>
                <img
                    src={`${Constants.queryConst}/file/profile/photo?id=` + UserUtil.getPhotoId(currentUser)}
                    alt='Profile img'
                    title='Edit photo'
                    className={isPremium(currentUser) ? 'premium-profile' : ''}
                />
            </label>
            {isPremium(currentUser) && (
                <div className='profile_page_profile__status'>
                    <IconCrown />
                    <span>Premium</span>
                </div>
            )}
            <Flex>
                <div className='user-card__text'>
                    <div>Логин</div>
                    <div>Имя</div>
                    <div>Фамилия</div>
                    <div>Список карточек</div>
                </div>
                <div className='user-card__text-user'>
                    <div className='ellipse-text'>{getLoginByUser(currentUser)}</div>
                    <div className='ellipse-text'>{getFirstName(currentUser)}</div>
                    <div className='ellipse-text'>{getLastName(currentUser)}</div>
                    <div>{isPremium(currentUser) ? `${countUserList} из ∞` : `${countUserList} из ${Constants.MAX_AMOUNT_WORD_LISTS}`}</div>
                </div>
                <ConfirmModalDialog
                    message={'Вы уверены, что хотите удалить аккаунт?'}
                    decline={closeConfirmModal}
                    confirm={deleteAccount}
                    open={showConfirmModal}

                />
                <EditProfile isOpen={showEditProfileModal} close={() => setShowEditProfileModal(false)} />
                <EditProfileImage isOpen={showEditProfileImageModal}
                                  close={() => setShowEditProfileImageModal(false)} />
                <SwitchToTeacherDialog isOpen={showSwitchToTeacherModal}
                                       close={() => setShowSwitchToTeacherModal(false)} />
                <GetNotificationsInProfile isOpen={showGetNotificationsInProfileModal}
                                           close={() => setShowGetNotificationsInProfileModal(false)} />
                {showBindTelegramCodeModal && <BindTelegramCodeDialog
                    closeDialog={closeBindTelegramCodeDialog}
                    open={showBindTelegramCodeModal}
                />}
            </Flex>
            {!isPremium(currentUser) && <AddPremiumButtonInProfile />}
        </div>

    )
}
