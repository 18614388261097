import RulesHeader from '../../blocks/RulesHeader'
import NumberBlock from '../../blocks/NumberBlock'
import TaskExample from '../../blocks/TaskExample'
import Example from '../../blocks/Example'
import FactRule from '../../blocks/FactRule'
import TableThree from '../../blocks/TableThree'
import RuleTestWrapper from '../../RuleTestWrapper'
import { test } from './test'

const PastPerfect = (props) => {
    return (
        <div>
            <RulesHeader title='Past perfect' subtitle='Прошедшее совершенное время' />

            <NumberBlock numberBlock='1' />
            <TaskExample taskExample='Изучите следующую ситуацию:' />
            <Example
                engExample={['We ', <u>had lived</u>, ' in Ryazan for 20 years before we moved to Switzerland.']}
                rusExample='До переезда в Рязань мы прожили в Швейцарии 20 лет.'
            />
            <p>
                {' '}
                <b>Before</b> - это начальная точка отсчета нашей истории. Затем, если мы хотим поговорить о событиях,
                случившихся до этого времени, мы используем <b>past perfect</b>. Оно употребляется в случаях, когда мы
                рассказываем о каком-то действии, которое уже закончилось к определенному моменту времени в прошлом.
            </p>
            <FactRule factRule='Существительное + had + 1-я форма правильного глагола+ed или 3-я форма неправильного глагола это the past perfect.' />

            <NumberBlock numberBlock='2' />
            <p>
                {' '}
                Past perfect это <b>had</b> + past participle (причастие прошедшего времени) или 3-я форма неправильного
                глагола. Причастие прошедшего времени часто имеет окончание <b>-ed</b> (finish<b>ed</b>/deci<b>ed</b> и
                др.), но очень много важных глаголов являются неправильными (<b>-lost/done/written</b> и др.).
            </p>
            <TableThree
                elements={[
                    {
                        left: 'I/he/they',
                        center: 'had',
                        right: '1-я форма правильного глагола+ed или 3-я форма неправильного глагола'
                    }
                ]}
            />
            <Example
                engExample={['I called Alexandr too late, he ', <u>had</u>, ' already ', <u>left</u>, '.']}
                rusExample={['Я позвонил Александру слишком поздно, он уже ', <u>ушел</u>, '.']}
            />
            <p>
                {' '}
                В вопросительном предложении вспомогательный глагол выносится на место перед подлежащим, а значимый
                глагол остается после него.
            </p>
            <TableThree
                elements={[
                    {
                        left: 'Had',
                        center: 'I/he/they',
                        right: '1-я форма правильного глагола+ed или 3-я форма неправильного глагола'
                    }
                ]}
            />
            <Example
                engExample={['', <u>Had</u>, ' you ', <u>brushed</u>, ' your teeth before you went to bed?']}
                rusExample={['Ты ', <u>почистил</u>, ' зубы, прежде чем пойти спать?']}
            />
            <p>
                {' '}
                В отрицательных предложениях за вспомогательным глаголом следует отрицательная частица <b>not</b>. При
                этом они могут быть сокращены до формы <b>hadn't</b>.
            </p>
            <TableThree
                elements={[
                    {
                        left: 'I/he/they',
                        center: 'had not',
                        right: '1-я форма правильного глагола+ed или 3-я форма неправильного глагола'
                    }
                ]}
            />
            <Example
                engExample={['I ', <u>had not finished</u>, ' my work when he came here.']}
                rusExample={['Я ', <u>не закончил свою работу</u>, ', когда он сюда пришел.']}
            />

            <NumberBlock numberBlock='3' />
            <p> Мы используем Past Perfect в следующих ситуациях:</p>
            <FactRule factRule='Действие, закончившееся до определенного момента в прошлом, на которое может указывать точная дата или час, начало другого действия или контекст.' />
            <Example
                engExample={['After the Sun ', <u>had set</u>, ', we saw thousands of fireflies.']}
                rusExample={['После того, как ', <u>зашло</u>, ' солнце, мы увидели тысячи светлячков.']}
            />
            <FactRule factRule='Перечисление действий в прошлом, произошедших до времени повествования в целом.' />
            <Example
                engExample={[
                    'I finally caught Bonya and looked around. The cat ',
                    <u>had scratched</u>,
                    ' the furniture, ',
                    <u>had torn</u>,
                    ' the wallpapers and ',
                    <u>had eaten</u>,
                    ' my fish from the aquarium.'
                ]}
                rusExample={[
                    'Я наконец поймал Боню и осмотрелся вокруг. Кот ',
                    <u>исцарапал</u>,
                    'мебель, ',
                    <u>порвал</u>,
                    ' обои и ',
                    <u>съел</u>,
                    ' мою рыбку из аквариума.'
                ]}
            />

            <RuleTestWrapper value={test} rule={props} />
        </div>
    )
}

export default PastPerfect
