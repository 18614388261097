import { useEffect } from 'react'
import * as API_USER from '../../../../shared/http/user'
import '../../../../styles/css/unsubscribe-email/unsubscribe.css'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Unsubscribe = () => {
    const location = useLocation()

    const beforeMount = () => {
        unsubscribe()
    }

    /**
     * Сохранить изменения
     */
    const unsubscribe = () => {
        const urlParams = new URLSearchParams(location.search)
        const key = urlParams.get('token')
        API_USER.unsubscribeEmail(key)
    }

    useEffect(() => {
        beforeMount()
        return () => {}
    }, [])

    return (
        <div id='unsubscribe-email'>
            <Helmet title='GE: Отписаться от рассылки' />
            <h1>Вы успешно отписались от уведомлений geekenglish.ru</h1>
        </div>
    )
}

export default Unsubscribe
