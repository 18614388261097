import '../../../styles/css/elements/svg_icons/icon-btn.css'

function IconEye(props) {
    const { onClick = () => null } = props
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='30'
            height='30'
            fill='none'
            onClick={onClick}
            stroke='#000'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            className='icon icon-tabler icon-tabler-eye'
            viewBox='0 0 24 24'
        >
            <title>Показать перевод</title>
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <circle cx='12' cy='12' r='2'></circle>
            <path d='M22 12c-2.667 4.667-6 7-10 7s-7.333-2.333-10-7c2.667-4.667 6-7 10-7s7.333 2.333 10 7'></path>
        </svg>
    )
}
export default IconEye
