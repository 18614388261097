import '../../../styles/scss/google-auth-btn/style.scss'
import {GEButton} from '../../../common/buttons'
import IconNumbers from "../svg_icons/IconNumbers";
import {useState} from "react";
import LoginCodeDialog from "../../modalwindows/LoginCodeDialog";


const AppAuthButton = () => {

    const [showConfirmModal, setShowConfirmModal] = useState(false)


    const openConfirmModal = () => {
        setShowConfirmModal(true)
    }
    const closeConfirmModal = () => {
        setShowConfirmModal(false)
    }

    return (
        <>
            <GEButton onClick={openConfirmModal}>
                <IconNumbers/>
                <span className={'google-auth-btn-text width124px'}>Войти по коду</span>
            </GEButton>

            <LoginCodeDialog
                closeDialog={closeConfirmModal}
                open={showConfirmModal}
            />
        </>
    )
}

export default AppAuthButton