import '../../../styles/css/elements/svg_icons/icon-btn.css'

function IconTelegram(props) {
    const { className = '', onClick = () => null, stroke = '#000000' } = props

    return (
        <svg
            onClick={onClick}
            xmlns='http://www.w3.org/2000/svg'
            width='28'
            height='28'
            fill='none'
            stroke={stroke}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            className={'icon icon-tabler icon-tabler-brand-telegram ' + className}
            viewBox='0 0 24 24'
        >
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <path d='M15 10l-4 4 6 6 4-16-18 7 4 2 2 6 3-4'></path>
        </svg>
    )
}

export default IconTelegram
