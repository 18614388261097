import PremiumNew from './PremiumNew'
import { buyPromoCode30, buyPromoCode365, buyPromoCode90 } from '../../../../common/utils/promocode'
import { useState } from 'react'

export const PremiumCondition = () => {

    const [offers, setOffers] = useState([
        {
            title: 'Премиум на месяц',
            price: 250,
            text: undefined,
            oldPrice: 250,
            subTitle: undefined,
            open: buyPromoCode30
        },
        {
            title: 'Премиум на 3 месяца',
            price: 170,
            text: 'в месяц',
            oldPrice: 260,
            subTitle: '510₽',
            open: buyPromoCode90
        },
        {
            title: 'Премиум на год',
            oldPrice: 300,
            price: 100,
            text: 'в месяц',
            subTitle: '1200₽',
            open: buyPromoCode365
        }
    ])

    return (
        <PremiumNew offers={offers} />
    )
}