import '../../../styles/css/elements/svg_icons/icon-btn.css'
import { FC } from 'react'

interface IconRotateProps {
    className?: string
}

const IconRotate: FC<IconRotateProps> = ({ className }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='44'
            height='44'
            fill='none'
            stroke='#000'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            className={'icon icon-tabler icon-tabler-rotate-clockwise-2 ' + className}
            viewBox='0 0 24 24'
        >
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <path d='M9 4.55a8 8 0 016 14.9M15 15v5h5'></path>
            <path d='M5.63 7.16L5.63 7.17'></path>
            <path d='M4.06 11L4.06 11.01'></path>
            <path d='M4.63 15.1L4.63 15.11'></path>
            <path d='M7.16 18.37L7.16 18.38'></path>
            <path d='M11 19.94L11 19.95'></path>
        </svg>
    )
}
export default IconRotate
