import MotivationalQuotesUtil from '../utils/MotivationalQuotesUtil'
import { FC } from 'react'
import { Button, Flex, Modal, Tag } from 'antd'
import { ID, WordList } from '../../shared/types/api'
import Pages from '../../const/Pages'
import useFillGapsRepeatCardLists from '../../hooks/useFillGapsRepeatCardLists'
import { goToExercisePageWithReloading } from '../utils/goToPage'
import { NavigationPages } from '../../common/navigation/navigation-pages'
import { useNavigate } from 'react-router-dom'

interface CompletedExerciseDialogProps {
    cardListId: ID | undefined;
    close: () => void;
    open: boolean
}

const FillGapsCompletedExerciseDialog: FC<CompletedExerciseDialogProps> = ({
                                                                               cardListId,
                                                                               close,
                                                                               open
                                                                           }) => {

    const navigate = useNavigate()

    const { repeatSuggestion } = useFillGapsRepeatCardLists(Pages.FILL_GAPS, open, cardListId)

    const clearAndClose = () => {
        close()
    }

    const openSuggestionList = (rs: WordList) => {
        clearAndClose()
        goToExercisePageWithReloading(NavigationPages.GUESS_WORD, navigate, rs)
    }

    return (
        <Modal
            title={'Молодец! Все карточки в списке пройдены!'}
            onCancel={clearAndClose}
            footer={[
                <Button danger onClick={clearAndClose}>
                    Закрыть
                </Button>
            ]}
            open={open}>
            <div>
                <span>{MotivationalQuotesUtil.generateRandomMotivationQuote()}</span>
            </div>
            {repeatSuggestion.length > 0 &&
            <>
                <div>
                    <span>Рекомендуем повторить:</span>
                </div>
                <Flex gap={5} wrap={'wrap'}>
                    {repeatSuggestion.map((rs) => {
                        return <Tag onClick={() => openSuggestionList(rs)}
                                    className={'pointer-cursor'}>{rs.listName}</Tag>
                    })}
                </Flex>
            </>}
        </Modal>
    )
}

export default FillGapsCompletedExerciseDialog