import {
    executeDeleteMethod,
    executeFilePostMethod,
    executeJsonGetMethod,
    executeJsonPostMethod,
    executeJsonPutMethod,
    executePostMethod,
    executePutMethod
} from '../api/rest/restApiUtils'
import {
    LoginAndAuthCode,
    LoginUserDto,
    RegistrationUserFormDto,
    ResetPasswordDto,
    TelegramUserDto,
    VerificationEmailDto
} from '../types/api'

/**
 * Логин пользователя
 * @method login
 * @param {object} user
 */
export function login(user: LoginUserDto) {
    return executeJsonPostMethod('/user/session', user)
}

/**
 * Регистрация пользователя
 * @method registration
 * @param {object} user
 * @return {Response} Fetch Response
 */
export function registration(user: RegistrationUserFormDto) {
    return executeJsonPostMethod('/user', user)
}

/**
 * Проверка авторизации пользователя
 * @method checkSession
 */
export function checkSession() {
    return executeJsonGetMethod('/session')
}

/**
 * Получение пользователя по id
 * @method getUserById
 * @param {number} id - id пользователя
 * @return {Response} Fetch Response
 */
export function getUserById(id: number) {
    return executeJsonGetMethod('/user/' + id).then((resp) => {
        return Promise.resolve(resp.data)
    })
}

/**
 * Поиск пользователя по логину
 * @method findUserByLogin
 * @param {string} findingLogin - findingLogin пользователя
 * @return {Response} Fetch Response
 */
export function findUserByLogin(findingLogin: string) {
    return executeJsonGetMethod('/friends/find/' + findingLogin).then((resp) => {
        return Promise.resolve(resp.data.content)
    })
}

/**
 * Получение списка друзей пользователя
 * @method getUserFriends
 * @return {Response} Fetch Response
 */
export function getUserFriends() {
    return executeJsonGetMethod('/friends').then((resp) => {
        return Promise.resolve(resp.data.content)
    })
}

/**
 * Подтверждение пользователя
 * @param {object} sendingData - токен + userId
 * @method verificate
 * @return {Response} Fetch Response
 */
export function verificate(sendingData: VerificationEmailDto) {
    return executeJsonPostMethod('/verification', sendingData).then((resp) => {
        return Promise.resolve(resp.data)
    })
}

/**
 * Подтверждение пользователя telegram
 * @param {object} sendingData - токен + userId
 * @method authTelegram
 * @return {Response} Fetch Response
 */
export function authTelegram(sendingData: TelegramUserDto) {
    return executeJsonPostMethod('/oath/telegram', sendingData).then((resp) => {
        return Promise.resolve(resp.data)
    })
}

/**
 * Отправить повторно сообщение для подтверждения пользователя
 * @param {object} email - email
 * @method sendVerificationEmailRepeated
 * @return {Response} Fetch Response
 */
export function sendVerificationEmailRepeated(email: string) {
    return executePostMethod('/user/email', { email: email })
}

/**
 * Выход пользователя из сессии
 * @method logout
 * @return {Response} Fetch Response
 */
export function logout() {
    return executeDeleteMethod('/user/logout')
}

/**
 * Сбросить пароль
 * @param {string} email - email пользователя
 * @method resetPassword
 * @return {Response} Fetch Response
 */
export function resetPassword(email: string) {
    return executePostMethod('/user/resetPassword?' + new URLSearchParams({ email: email }))
}

/**
 * Сменить пароль
 * @param {object} data
 * @method changePassword
 * @return {Response} Fetch Response
 */
export function changePassword(data: ResetPasswordDto) {
    return executePostMethod('/user/changePassword', data)
}

/**
 * Смена изображения профиля
 * @param {object} photo
 * @method updatePhoto
 * @return {Response} Fetch Response
 */
export function updatePhoto(photo: File) {
    let formData = new FormData()
    formData.append('photo', photo)
    return executeFilePostMethod('/user/photo', formData)
}

/**
 * метод удаления аккаунта
 * @method deleteAccount
 * @return {Response} Fetch Response
 */
export function deleteAccount() {
    return executeDeleteMethod('/user/account')
}

/**
 * Обновление профиля
 * @param {String} firstName
 * @param {String} lastName
 * @method updatePhoto
 * @return {Response} Fetch Response
 */
export function updateProfile(firstName: string, lastName: string) {
    let user = {
        firstName: firstName,
        lastName: lastName
    }
    return executeJsonPutMethod('/user', user).then((resp) => {
        return Promise.resolve(resp.data.content)
    })
}

/**
 * Отписаться от рассылки уведомлений
 * @param {String} decodedId
 * @method unsubscribeEmail
 * @return {Response} Fetch Response
 */
export function unsubscribeEmail(decodedId: string) {
    return executePutMethod('/user/notification/disabling/' + decodedId)
}

/**
 * Отправить запрос дружбы
 * @param {String} recipientId -  userId которому отправляем запрос
 * @method sendFriendRequest
 * @return {Response} Fetch Response
 */
export function sendFriendRequest(recipientId: string) {
    const userId = parseInt(recipientId)
    // @ts-ignore
    return executeJsonPostMethod('/friends/send-request?' + new URLSearchParams({ recipientId: userId })).then(
        (resp) => {
            return Promise.resolve(resp.data)
        }
    )
}

/**
 * Принять запрос дружбы
 * @param {String} recipientId -  userId которому отправляем запрос
 * @param dialogIdString
 * @method sendFriendRequest
 * @return {Response} Fetch Response
 */
export function acceptFriendRequest(recipientId: string, dialogIdString: string) {
    const userId = parseInt(recipientId)
    const dialogId = parseInt(dialogIdString)
    return executeJsonPostMethod(
        '/friends/accept-request?' +
        // @ts-ignore
        new URLSearchParams({ recipientId: userId, dialogId: dialogId })
    ).then((resp) => {
        return Promise.resolve(resp.data)
    })
}

/**
 * Проверить, является ли пользователь другом
 * @param {Number} friendId -  userId которому отправляем запрос
 * @method checkFriend
 * @return {Response} Fetch Response
 */
export function checkFriend(friendId: number) {
    return executeJsonGetMethod('/friends/check-friends/' + friendId).then((resp) => {
        return Promise.resolve(resp.data)
    })
}

/**
 * Включение/отключение уведомлений о статистике для авторизованного пользователя
 * @param {Boolean} notificationFlag -  новое значение флага
 * @param time
 * @method updateNotificationFlag
 */
export function updateNotificationFlag(notificationFlag: boolean, time?: number | null) {
    let notification = {
        notificationFlag: notificationFlag,
        notificationTime: time ? time : 12
    }
    return executePutMethod('/user/notification', notification)
}

/**
 * Получить список всех пользователей сайта
 * @method getAllUsers
 * @return {Response} Fetch Response
 */
export function getAllUsers() {
    return executeJsonGetMethod('/users/all').then((resp) => {
        return Promise.resolve(resp.data)
    })
}

/**
 * Получить число всех пользователей сайта
 * @method getAllUsers
 * @return {Response} Fetch Response
 */
export function getCountAllUsers() {
    return executeJsonGetMethod('/users/all/count').then((resp) => {
        return Promise.resolve(resp.data)
    })
}

/**
 * Получить совпадение логинов пользователя
 * @method getLoginCoincidence
 * @return {Response} Fetch Response
 */
export function getLoginCoincidence(loginCoincidence: string) {
    return executeJsonGetMethod('/users/exist/login?' + new URLSearchParams({ login: loginCoincidence }))
        .then(resp => {
            return Promise.resolve(resp.data)
        })
}

/**
 * Добавить/Удалить премиум
 * @method switchPremium
 * @return {Response} Fetch Response
 */
export function switchPremium() {
    return executePutMethod('/users/switch-premium')
}

/**
 * Получить код авторизации
 * @return {Response} Fetch Response
 */
export function getAuthCode() {
    return executeJsonGetMethod('/user/authcode')
}

/**
 * Логин пользователя
 * @method login
 * @param {object} user
 */
export function authByCode(user: LoginAndAuthCode) {
    return executeJsonPostMethod('/user/authcode', user)
}
