export enum NavigationPages {
    LANDING = '/',
    CARDS = '/cards',
    TEST = '/test',
    DICTIONARY = '/dictionary',
    DONE_WORDS_LISTS = '/donewordslists',
    STATISTIC = '/statistic',
    GUESS_WORD = '/guess-word',
    RULES = '/rules',
    FILL_WORDS = '/fillwords',
    PROFILE = '/profile',
    SHARE_LIST = '/share-list',
    RESET_PASSWORD = '/reset_password',
    CHANGE_PASSWORD = '/change_password',
    CONFIRM_EMAIL = '/confirm_email',
    UNSUBSCRIBE_EMAIL = '/unsubscribe-email',
    PERSONAL_POLICY = '/personal-policy',
    PERSONAL_DATA_AGREEMENT = '/personal-data-agreement',
    TERMS = '/terms',
    CREATE_COURSE = '/create-course',
    DOWNLOAD_APP = '/download-app',
    TEACHERS_LP = '/teachers-lp',
    AFERTA = '/aferta',
    PREMIUM = '/premium',
    DIALOGS = '/dialogs',
    AUTH_BY_CODE = '/auth-by-code',
    LOGIN_BY_CODE = '/login-by-code',
    ADMIN_PAGE = '/admin-page',
    MOBILE_AUTH = '/mobile-auth',
    PREMIUM_HELP = '/premium-help',
    COMMUNITIES = '/communities',
    COURSES_LIST = '/courses-list',
    COURSES = '/courses',
    COURSES_OVERVIEW = '/courses-overview',
    COMMUNITY_STATISTIC = '/community/statistic',
    THANKS_PAGE = '/thanks-page'
}