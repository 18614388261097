/**
 * Project geekenglish
 *
 * @author Vlad Wild on 21.04.2020
 * Дублируются в файле src/main/java/ru/geekenglish/constants/WordListType.java
 */
let WordListType = {
    USER: 'USER',
    DONE: 'DONE',
    COMMUNITY: 'COMMUNITY'
}

export default WordListType
