import RulesHeader from '../../blocks/RulesHeader'
import NumberBlock from '../../blocks/NumberBlock'
import TaskExample from '../../blocks/TaskExample'
import Example from '../../blocks/Example'
import FactRule from '../../blocks/FactRule'
import TableThree from '../../blocks/TableThree'
import { test } from './test'
import RuleTestWrapper from '../../RuleTestWrapper'
import VeryImportantWords from '../../blocks/VeryImportantWords'

const FutureSimple = (props) => {
    return (
        <div>
            <RulesHeader title='Future simple' subtitle='Простое будущее' />

            <NumberBlock numberBlock='1' />
            <TaskExample taskExample='Изучите следующую ситуацию:' />
            <Example
                engExample={['I ', <u>will</u>, ' go to Ryzan next summer.']}
                rusExample={['Следующим летом я ', <u>поеду</u>, ' в Рязань.']}
            />
            <p>
                {' '}
                Мы используем future simple, когда говорим о действие, которое совершится в неопределенном или
                отдаленном будущем.
            </p>
            <FactRule factRule='Существительное + will + Инфинитив это the future simple:' />

            <NumberBlock numberBlock='2' />
            <p>
                {' '}
                Для того, чтобы поставить глагол во временную форму Future Simple, нужно использовать его начальную
                форму и вспомогательный глагол <b>will</b>. В устной речи <b>will</b> чаще всего сокращается до формы{' '}
                <b>‘ll</b>, которая может использоваться во всех лицах.
            </p>
            <TableThree elements={[{ left: 'Will', center: 'I/she/they', right: 'инфинитив?' }]} />
            <TableThree elements={[{ left: 'I/she/they', center: 'will + not', right: 'инфинитив' }]} />
            <Example
                engExample={['', <u>Will</u>, ' we ', <u>go</u>, ' to the beach?']}
                rusExample={['Мы ', <u>пойдем</u>, ' на пляж?']}
            />
            <Example
                engExample={['I ', <u>will not let</u>, ' you down.']}
                rusExample={['Я вас ', <u>не подведу</u>, '.']}
            />

            <NumberBlock numberBlock='3' />
            <p>
                {' '}
                Ранее при употреблении будущего времени для первого лица (<b>I</b>, <b>we</b>) было принято использовать
                вспомогательный глагол <b>shall</b>.
            </p>
            <Example
                engExample={['I ', <u>shall</u>, ' write to you every day.']}
                rusExample={['Я буду писать тебе каждый день.']}
            />
            <p>
                {' '}
                Однако, в современном английском, особенно в устной речи, <b>will</b> стал использоваться для всех лиц.
            </p>
            <Example
                engExample={['I ', <u>will</u>, ' visit you every day.']}
                rusExample={['Я буду навещать тебя каждый день.']}
            />
            <p>
                {' '}
                Глагол <b>shall</b> в настоящее время используется довольно редко в качестве модального глагола в
                предложении что-то сделать или помочь.
            </p>
            <Example engExample={['', <u>Shall</u>, ' we dance?']} rusExample={['Может, потанцуем?']} />
            <p>
                {' '}
                Сравните <b>shall</b> I ... ? и <b>will</b> you ... ?.
            </p>
            <Example
                engExample={[
                    '',
                    <u>Shall</u>,
                    ' I shut the door? (= Мне закрыть дверь? = Вы хотите, чтобы я закрыл дверь?)'
                ]}
                rusExample={[
                    '',
                    <u>Will</u>,
                    ' you shut the door? (= Вы закроете дверь? = Я хочу, чтобы Вы закрыли дверь.)'
                ]}
            />

            <NumberBlock numberBlock='4' />
            <p> Случаи употребления Future Simple:</p>
            <FactRule factRule='Указание на простое действие в будущем' />
            <Example
                engExample={['We ', <u>will return</u>, ' in 4 hours.']}
                rusExample={['Мы ', <u>вернемся</u>, ' через 4 часа.']}
            />
            <FactRule factRule='Регулярные, повторяющиеся действия в будущем' />
            <Example
                engExample={['I promise that I ', <u>will learn</u>, ' English every day.']}
                rusExample={['Обещаю, что буду ', <u>учить</u>, ' английский каждый день.']}
            />
            <FactRule factRule='При перечислении последовательности действий в будущем' />
            <Example
                engExample={[
                    'I cannot wait for my vacation. I ',
                    <u>will go</u>,
                    ' to the river, ',
                    <u>swim</u>,
                    ' and ',
                    <u>fish</u>,
                    ' every day.'
                ]}
                rusExample={[
                    'Не могу дождаться своего отпуска. Буду каждый день ',
                    <u>ходить</u>,
                    ' к речке, ',
                    <u>купаться</u>,
                    ' и ',
                    <u>ловить</u>,
                    ' рыбу.'
                ]}
            />

            <NumberBlock numberBlock='5' />
            <p> Мы часто используем Future Simple в следующих ситуациях:</p>
            <FactRule factRule='Предлагая что-нибудь сделать' />
            <Example
                engExample={['That bag looks heavy. I’', <u>ll help</u>, ' you with it. (', <u>NOT</u>, ' I help)']}
                rusExample={['Эта сумка выглядит тяжелой. Я ', <u>помогу</u>, ' тебе с ней.']}
            />
            <FactRule factRule='Соглашаясь что-нибудь сделать' />
            <Example
                engExample={[
                    'A: Can you give Tomas this book?',
                    <br />,
                    'B: Sure, I’',
                    <u>ll give</u>,
                    ' it to him when I see him this afternoon.'
                ]}
                rusExample={[
                    'А: Не могли бы вы дать Томасу эту книгу?',
                    <br />,
                    'Б: Конечно, я ',
                    <u>отдам</u>,
                    ' ему это, когда увижу его сегодня днем.'
                ]}
            />
            <FactRule factRule='Обещая что-нибудь сделать' />
            <Example
                engExample={['Thanks for lending me the money. I’', <u>ll pay</u>, ' you back on Friday.']}
                rusExample={['Спасибо, что одолжил мне деньги. Я ', <u>верну</u>, ' тебе назад их в пятницу.']}
            />
            <FactRule factRule='Спрашивая кого-нибудь сделать что-нибудь' />
            <Example
                engExample={[
                    '',
                    <u>Will</u>,
                    ' you please ',
                    <u>turn</u>,
                    ' the music down? I’m trying to concentrate.'
                ]}
                rusExample={['Не могли бы вы, пожалуйста, ', <u>выключить</u>, ' музыку? Я пытаюсь сосредоточиться.']}
            />
            <FactRule factRule='Вы можете использовать won’t, когда кто-то отказывается что-то сделать' />
            <Example
                engExample={['The car ', <u>won’t start</u>, '.']}
                rusExample={['Машина ', <u>не заводится</u>, '.(= машина ‘отказывается’ заводиться)']}
            />

            <NumberBlock numberBlock='6' />
            <p>
                {' '}
                Не используйте <b>will</b>, когда вы говорите о том, что уже решили или запланировали сделать.
            </p>
            <Example
                engExample={['I’', <u>m going</u>, ' on holiday next Saturday. (', <u>NOT</u>, ' I’ll go).']}
                rusExample={['В следующую субботу я ', <u>уезжаю</u>, ' в отпуск.']}
            />

            <NumberBlock numberBlock='7' />
            <p>
                {' '}
                Мы не используем <b>will</b>, чтобы сказать о том, что мы что-то запланировали или решили.
            </p>
            <Example
                engExample={['Vlad ', <u>is working</u>, ' next week. (', <u>NOT</u>, '  Vlad will work).']}
                rusExample={['Влад работает на следующей неделе.']}
            />
            <p> Но часто, говоря о будущем, мы не говорим о том, что кто-то решил что-то сделать. Например:</p>
            <Example
                engExample={['Dmitry is doing an exam next week. Nikita and Alex are talking about it.']}
                rusExample={['Дмитрий сдает экзамен на следующей неделе. Никита и Алекс говорят об этом.']}
            />
            <Example
                engExample={[
                    'Nikita: Do you think Dmitry ',
                    <u>will pass</u>,
                    ' the exam?',
                    <br />,
                    'Alex: Yes, he’',
                    <u>ll pass</u>,
                    ' easily.'
                ]}
                rusExample={[
                    'Никита: Думаешь, Дмитрий ',
                    <u>сдаст</u>,
                    ' экзамен?',
                    <br />,
                    'Алекс: Да, он легко ',
                    <u> сдаст</u>,
                    '.'
                ]}
            />
            <p>
                {' '}
                <b>He’ll</b> pass не означает ‘он решил сдать’. Алекс говорит о том, что случится, по его мнению, в
                будущем. Он прогнозирует будущее.
            </p>
            <p>
                {' '}
                Когда мы прогнозируем, пытаемся предсказать будущие события или ситуации, мы используем{' '}
                <b>will/won’t</b>. То есть точно мы о будущем сказать не можем, оно не определено, отсюда и второе
                название для <b>future simple - это future indefinite</b> (неопределенное будущее)
            </p>
            <p> Еще немного примеров:</p>
            <Example
                engExample={['That plate is hot. If you touch it, you’', <u>ll burn</u>, ' yourself.']}
                rusExample={['Эта тарелка горячая. Если ты прикоснешься к нему, то ', <u>обожжешься</u>, '.']}
            />
            <Example
                engExample={['When ', <u>will</u>, ' you ', <u>get</u>, ' your exam results?']}
                rusExample={['Когда вы ', <u>получите</u>, ' результаты экзамена?']}
            />

            <NumberBlock numberBlock='8' />
            <p>
                {' '}
                Мы часто используем <b>will</b> с:
            </p>
            <VeryImportantWords
                veryImportantWords={['probably', 'I expect', '(I’m) sure', '(I) think', '(I) don’t think', 'I wonder']}
            />
            <Example
                engExample={['I’', <u>ll probably be</u>, ' home late tonight.']}
                rusExample={['Я, ', <u>наверное</u>, ', ', <u>вернусь</u>, ' домой поздно вечером.']}
            />
            <Example
                engExample={['I ', <u>don’t think</u>, ' the exam ', <u>will be</u>, ' very difficult.']}
                rusExample={['Я ', <u>не думаю</u>, ', что экзамен ', <u>будет</u>, ' очень сложным.']}
            />
            <p>
                {' '}
                После <b>I hope</b> мы, как правило, используем настоящее время (<b>will</b> также возможно)
            </p>
            <Example
                engExample={[
                    'I hope Dmitry ',
                    <u>passes</u>,
                    ' the exam. (или: I hope Dmitry ',
                    <u>will pass</u>,
                    ' the exam)'
                ]}
                rusExample={[
                    'Я надеюсь, что Дмитрий ',
                    <u>сдаст</u>,
                    ' экзамен. (или: Я надеюсь, что Дмитрий ',
                    <u>сдаст</u>,
                    ' экзамен)'
                ]}
            />

            <NumberBlock numberBlock='9' />
            <p>
                {' '}
                Обычно мы используем <b>will</b>, когда говорим о будущем, но иногда мы используем <b>will</b>, когда
                говорим о данном моменте времени. Например:
            </p>
            <Example
                engExample={['Don’t phone Thomas now. He’', <u>ll </u>, ' busy. (= he’ll be busy now)']}
                rusExample={['Не звони сейчас Томасу. Он ', <u>будет</u>, ' занят.']}
            />

            <RuleTestWrapper value={test} rule={props} />
        </div>
    )
}

export default FutureSimple
