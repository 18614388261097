import RulesHeader from '../../blocks/RulesHeader'
import NumberBlock from '../../blocks/NumberBlock'
import TaskExample from '../../blocks/TaskExample'
import Example from '../../blocks/Example'
import FactRule from '../../blocks/FactRule'
import RuleTestWrapper from '../../RuleTestWrapper'
import { test } from './test'
import TableFour from '../../blocks/TableFour'

const FuturePerfect = (props) => {
    return (
        <div>
            <RulesHeader title='Future Perfect' subtitle='Будущее совершенное время' />

            <NumberBlock numberBlock='1' />
            <TaskExample taskExample='Изучите следующую ситуацию:' />
            <Example
                engExample={['Next year we ', <u>will have been maked</u>, ' a website for 5 years']}
                rusExample={['В следующем году мы ', <u>будем делать</u>, ' сайт уже 5 лет.']}
            />
            <p>
                {' '}
                Мы используем future perfect, когда обозначаем действие, которое закончится до определенного момента или
                начала другого действия в будущем или будет продолжать длиться после него. Данное время используется
                довольно редко.
            </p>
            <FactRule factRule='Существительное + will + have + Инфинитив + -ed' />
            <p>Или</p>
            <FactRule factRule='Существительное + will + have + 3-я форма неправильного глагола:' />

            <NumberBlock numberBlock='2' />

            <p>
                {' '}
                В вопросительном предложении вспомогательный глагол выносится на место перед подлежащим, а значимый
                глагол остается после него.
            </p>
            <TableFour
                elements={[
                    {
                        first: 'Will',
                        second: 'I/she/they',
                        third: 'have',
                        fourth: 'Инфинитив + -ed или 3-я форма неправильного глагола?'
                    }
                ]}
            />
            <Example
                engExample={['', <u>Will you have read</u>, ' all these books by the exam time?']}
                rusExample={['Ты ', <u>прочтешь</u>, ' все эти книги до начала экзаменов?']}
            />
            <p>
                {' '}
                В отрицательных предложениях за вспомогательным глаголом следует отрицательная частица <b>not</b>. При
                этом они могут быть сокращены до формы <b>won't</b>.
            </p>
            <TableFour
                elements={[
                    {
                        first: 'I/she/they',
                        second: 'will not',
                        third: 'have',
                        fourth: 'Инфинитив + -ed или 3-я форма неправильного глагола'
                    }
                ]}
            />
            <Example
                engExample={['I guess, I ', <u>will not have received</u>, ' your next letter before New Year.']}
                rusExample={['Думаю, я ', <u>не получу</u>, ' твое следующее письмо раньше Нового года.']}
            />

            <NumberBlock numberBlock='3' />
            <p> Случаи употребления Future Perfect:</p>
            <FactRule factRule='Действие, которое начнется и закончится до определенного момента в будущем' />
            <Example
                engExample={['You ', <u>will have spent</u>, ' much efforts before you can run a marathon.']}
                rusExample={['Ты ', <u>потратишь</u>, ' много усилий, прежде чем сможешь пробежать марафон.']}
            />

            <RuleTestWrapper value={test} rule={props} />
        </div>
    )
}

export default FuturePerfect
