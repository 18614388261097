import { FC } from 'react'

interface BurgerMenuIconProps {
    className?: string,
    onClick?: () => void
}

export const BurgerMenuIcon: FC<BurgerMenuIconProps> = ({ className = '', onClick }) => {

    return (
        <svg onClick={onClick} width='16' height='16' viewBox='0 0 16 16' fill='none'
             xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M7.99998 13.136L2.18198 7.47467C2.13509 7.42806 2.07943 7.39121 2.01821 7.36625C1.95699 7.3413 1.89142 7.32873 1.82531 7.32928C1.7592 7.32983 1.69386 7.34348 1.63306 7.36945C1.57226 7.39542 1.51722 7.43319 1.47111 7.48057C1.42501 7.52796 1.38876 7.58401 1.36446 7.6455C1.34016 7.70698 1.3283 7.77268 1.32956 7.83878C1.33082 7.90488 1.34517 7.97007 1.37179 8.03059C1.39841 8.09111 1.43677 8.14574 1.48465 8.19134L7.65131 14.1913C7.74466 14.2822 7.86975 14.333 7.99998 14.333C8.13021 14.333 8.2553 14.2822 8.34865 14.1913L14.5153 8.19134C14.5632 8.14574 14.6015 8.09111 14.6282 8.03059C14.6548 7.97007 14.6691 7.90488 14.6704 7.83878C14.6717 7.77268 14.6598 7.70698 14.6355 7.6455C14.6112 7.58401 14.575 7.52796 14.5288 7.48057C14.4827 7.43319 14.4277 7.39542 14.3669 7.36945C14.3061 7.34348 14.2408 7.32983 14.1746 7.32928C14.1085 7.32873 14.043 7.3413 13.9818 7.36625C13.9205 7.39121 13.8649 7.42806 13.818 7.47467L7.99998 13.136Z'
                fill='#ffffff' />
        </svg>
    )
}
