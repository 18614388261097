export const test = [
    {
        id: 1,
        title: 'This time last year I ____ living in Brazil.',
        variants: [
            { id: 1, title: 'are', isTrue: false },
            { id: 2, title: 'was', isTrue: true },
            { id: 3, title: 'were', isTrue: false },
            { id: 4, title: 'is', isTrue: false }
        ]
    },
    {
        id: 2,
        title: 'I was ____ home when I met Alex.',
        variants: [
            { id: 1, title: 'walk', isTrue: false },
            { id: 2, title: 'walked', isTrue: false },
            { id: 3, title: 'are walk', isTrue: false },
            { id: 4, title: 'walking', isTrue: true }
        ]
    },
    {
        id: 3,
        title: 'We were good friends. We ____ each other well.',
        variants: [
            { id: 1, title: 'know', isTrue: false },
            { id: 2, title: 'knew', isTrue: true },
            { id: 3, title: 'was know', isTrue: false },
            { id: 4, title: 'were know', isTrue: false }
        ]
    },
    {
        id: 4,
        title: 'Jenny ____ for me when I ____',
        variants: [
            { id: 1, title: 'was waiting, was arriving', isTrue: false },
            { id: 2, title: 'waited, arrived', isTrue: false },
            { id: 3, title: 'was waiting, arrived', isTrue: true },
            { id: 4, title: 'waited,was arriving', isTrue: false }
        ]
    },
    {
        id: 5,
        title: 'How fast ____ when the accident ____ .',
        variants: [
            { id: 1, title: 'were you driving, happened', isTrue: true },
            { id: 2, title: 'were you driving, was happening', isTrue: false },
            { id: 3, title: 'you drived, happened', isTrue: false },
            { id: 4, title: 'you drived, was happening', isTrue: false }
        ]
    },
    {
        id: 6,
        title: 'When I was young, I ____ to be a pilot.',
        variants: [
            { id: 1, title: 'was wanting', isTrue: false },
            { id: 2, title: 'were wanting', isTrue: false },
            { id: 3, title: 'was want', isTrue: false },
            { id: 4, title: 'wanted', isTrue: true }
        ]
    },
    {
        id: 7,
        title: 'When Karen ____ , we ____ dinner.',
        variants: [
            { id: 1, title: 'arrived, were having', isTrue: true },
            { id: 2, title: 'arrived, haved', isTrue: false },
            { id: 3, title: 'were arrving, were having', isTrue: false },
            { id: 4, title: 'was arrving, were having', isTrue: false }
        ]
    }
]
