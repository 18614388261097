import '../../styles/css/dictionary/modalwindow/modalw.css'
import { FC, useState } from 'react'
import { Button, Flex, Input, Modal, Switch } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import * as API_COMMUNITY from '../../shared/http/community'
import { communitiesDataSelector, setCommunitiesRoutine } from '../../store/communities'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { CommunityType } from '../../const/CommunityType'
import NotificationsUtils from '../utils/NotificationsUtils'
import CommunityUtils from '../utils/CommunityUtils'
import { userSelector } from '../../store/global'
import { UserDto } from '../../shared/types/api'
import { openPremiumPage } from '../../common/utils/promocode'
import { useNavigate } from 'react-router-dom'
import CreateTagElement from '../blocks/create-tag-element/CreateTagElement'
import { handleHttpError } from '../../common/utils/errors'


interface CreateCommunityDialogProps {
    showWindow: boolean,
    cancelWindow: () => void,
}

const CreateCommunityDialog: FC<CreateCommunityDialogProps> = ({ showWindow, cancelWindow }) => {

    const dispatch = useAppDispatch()
    const communities = useAppSelector(communitiesDataSelector)
    const user = useAppSelector(userSelector)
    const navigate = useNavigate()

    const [tags, setTags] = useState<Array<string>>([])
    const [name, setName] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [publicCommunity, setPublicCommunity] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)


    const cleanAndCancel = () => {
        setPublicCommunity(true)
        setName('')
        setDescription('')
        setTags([])
        cancelWindow()
    }

    const addCommunity = () => {
        if (CommunityUtils.canAddCommunity(user as UserDto)) {
            setLoading(true)
            API_COMMUNITY.create(name, description, publicCommunity ? CommunityType.PUBLIC : CommunityType.PRIVATE, tags)
                .then((resp) => {
                    dispatch(setCommunitiesRoutine([...communities, resp]))
                })
                .catch((err) => {
                    handleHttpError(err, navigate)
                })
                .finally(() => {
                    cleanAndCancel()
                    setLoading(false)
                })
        } else {
            openPremiumPage(navigate)
        }
    }

    const handleChangeName = (event: any) => {
        setName(event.target.value)
    }

    const handleChangeDescription = (event: any) => {
        setDescription(event.target.value)
    }

    return (
        <Modal destroyOnClose={true} title='Создать сообщество'
               onCancel={cleanAndCancel}
               footer={[
                   <Button key='submit' onClick={cleanAndCancel}>
                       Отмена
                   </Button>,
                   <Button
                       disabled={name.length === 0}
                       onClick={addCommunity}
                       loading={loading}
                       type='primary'
                   >
                       Создать
                   </Button>
               ]}
               open={showWindow}>
                <span>
                        Сообщества позволяют учить списки слов группой. Создайте сообщество и добавьте списки слов,
                        которые будут доступны всем пользователям, которые находятся в сообществе.
                    </span>
            <Flex className='margin10top' gap={'20px'} vertical>
                <Input maxLength={42} placeholder='Название сообщества' value={name}
                       onChange={(e) => handleChangeName(e)} />
                <TextArea maxLength={600} rows={4} placeholder='Краткое описание...'
                          value={description}
                          onChange={(e) => handleChangeDescription(e)} />
                <Flex gap='10px'>
                    <Switch checked={publicCommunity} onChange={(checked) => {
                        setPublicCommunity(checked)
                    }} />
                    <span>Публичное</span>
                </Flex>
                <CreateTagElement tags={tags} setTags={setTags} />
            </Flex>
        </Modal>
    )
}

export default CreateCommunityDialog
