import Constants from '../../const/Constants'

let DeviceUtils = {
    getMobileOperatingSystem: (): string => {
        //@ts-ignore
        const userAgent = navigator.userAgent || window.opera
        if (/android/i.test(userAgent)) {
            return Constants.ANDROID
        }
        //@ts-ignore
        if (/iPad|iPhone/.test(userAgent) && !window.MSStream) {
            return Constants.IPHONE
        }
        return Constants.UNKNOWN
    }

}

export default DeviceUtils
