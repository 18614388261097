import '../../../../../styles/scss/buttons/add-word-list-btn.scss'
import { GEButton } from '../../../../../common/buttons'
import { FC, useState } from 'react'
import { CommunityDto } from '../../../../../shared/types/api'
import AddCardListDialog from '../../../../modalwindows/AddCardListDialog'

interface AddCommunityWordListButtonProps {
    community: CommunityDto
    canEdit: boolean
}

const AddCommunityWordListButton: FC<AddCommunityWordListButtonProps> = ({
                                                                             canEdit,
                                                                             community
                                                                         }) => {
    const [showAddCardListModal, setShowAddCardListModal] = useState<boolean>(false)

    const addListToCommunity = () => {
        setShowAddCardListModal(true)
    }

    return (
        <div className='add-word-list-btn'>
            {canEdit &&
            <GEButton type='primary' size='large' onClick={addListToCommunity}>
                Добавить список
            </GEButton>
            }
            <AddCardListDialog isOpen={showAddCardListModal} community={community}
                               close={() => setShowAddCardListModal(false)} />
        </div>
    )
}

export default AddCommunityWordListButton
