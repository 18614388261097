import PropTypes from 'prop-types'

const TaskExample = (props) => {
    return (
        <div className='task-example'>
            <p> {props.taskExample}</p>
        </div>
    )
}

export default TaskExample

TaskExample.propTypes = {
    taskExample: PropTypes.string.isRequired
}
