import { useEffect, useRef } from 'react'
import { Typography } from 'antd'

const { Title, Paragraph } = Typography

const ReviewCard = (props) => {
    const { data } = props
    const { author, img, text } = data

    const containerRef = useRef()

    useEffect(() => {
        async function animateUpdatingCard() {
            containerRef.current?.classList?.add('review-card-flashing')
            setTimeout(() => {
                containerRef.current?.classList?.remove('review-card-flashing')
            }, 500)
        }

        animateUpdatingCard()
    }, [data])

    return (
        <div className={'review-card'} ref={containerRef}>
            <div>
                <img className={'review-card__avatar'} src={img} alt={'Profile img'} />
            </div>
            <div className={'review-card__content'}>
                <div className={'review-card__title'}>
                    <Title className={'review-card__author'}>{author}</Title>
                    <Paragraph className={'review-card__login'} type={'secondary'}></Paragraph>
                </div>
                <div className={'review-card__body'}>
                    <Paragraph
                        className={'review-card__text'}
                        ellipsis={{ expandable: true, rows: 6, symbol: 'Раскрыть' }}
                    >
                        {text}
                    </Paragraph>
                </div>
            </div>
        </div>
    )
}

export default ReviewCard
