const IconCardsPremium = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='100'
            height='100'
            fill='none'
            viewBox='0 0 100 100'
        >
            <path
                fill='#2F88FF'
                d='M89.458 18.125l-5.583-2.333v37.625L94 29c1.708-4.25-.25-9.125-4.542-10.875zM8.208 33.542l20.667 49.791a8.374 8.374 0 007.541 5.25 8.451 8.451 0 003.292-.666l30.709-12.709c3.124-1.291 5.041-4.375 5.124-7.458.042-1.083-.166-2.292-.541-3.375L54.166 14.583a8.141 8.141 0 00-7.541-5.208c-1.084 0-2.167.25-3.209.625L12.75 22.708a8.308 8.308 0 00-4.542 10.834zM75.5 17.708a8.333 8.333 0 00-8.334-8.333h-6.041L75.5 44.125'
            ></path>
        </svg>
    )
}

export default IconCardsPremium