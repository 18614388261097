import PropTypes from 'prop-types'

const TableFour = (props) => {
    return (
        <ul className='table-four'>
            {props.elements.map((item, i) => (
                <li key={i}>
                    <span> {item.first} </span>
                    <span> {item.second} </span>
                    <span> {item.third} </span>
                    <span> {item.fourth} </span>
                </li>
            ))}
        </ul>
    )
}

export default TableFour

TableFour.propTypes = {
    elements: PropTypes.array.isRequired
}
