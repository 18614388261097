import PropTypes from 'prop-types'

const TableUniqOne = (props) => {
    return (
        <ul className='table-uniq-one'>
            <li>I/you/he (etc.)</li>
            <li>must</li>
            <li>can’t</li>
            <li>be (tired / hungry / at work etc.)</li>
            <li>be (doing / going / joking etc.)</li>
            <li>do / get / know / have etc.</li>
        </ul>
    )
}

export default TableUniqOne

TableUniqOne.propTypes = {
    elements: PropTypes.array.isRequired
}
