import '../../../../../styles/scss/courses/courses-overview-details.scss'
import {FC} from "react";

interface CourseOverviewDetailsProps {
    courseDetail: any,
    title: string
}
export const CourseOverviewDetails:FC<CourseOverviewDetailsProps> = ({courseDetail, title}) => {

    return (
        <div className='courses__details'>
            <div className='courses-details__title'>{title}</div>
            <div className='courses-details__text'>{courseDetail}</div>
        </div>
    )
}