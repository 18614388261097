import {FC} from 'react'
import '../../styles/css/dictionary/modalwindow/main_dialog.css'
import {Button, Modal} from "antd";
interface SimpleModalDialogProps {
    message: string,
    closeDialog: () => void,
    imageSrc?: string,
    closeButtonText?: string
    open: boolean;
}

const SimpleModalDialog: FC<SimpleModalDialogProps> = ({message, closeDialog, imageSrc, closeButtonText, open}) => {

    const DEFAULT_IMAGE_SRC = '../images/custom/logo.png'
    const DEFAULT_CLOSE_BUTTON_TEXT = 'ОК'

    return (
        <Modal onCancel={closeDialog}
               footer={[
                   <Button type='primary' onClick={closeDialog}>
                       {closeButtonText ? closeButtonText : DEFAULT_CLOSE_BUTTON_TEXT}
                   </Button>,
               ]}
               open={open}>
            <div id='main-dialog_message'>
                <img src={imageSrc ? imageSrc : DEFAULT_IMAGE_SRC} alt={'Image for ' + {message}}/>
                <span>{message}</span>
            </div>
        </Modal>
    )

}

export default SimpleModalDialog
