import PropTypes from 'prop-types'

const RulesHeader = (props) => {
    const { title, subtitle } = props

    return (
        <div className='rules-header'>
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
        </div>
    )
}

export default RulesHeader

RulesHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string
}
