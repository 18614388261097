import {FC} from "react";
import '../../../../../styles/scss/profile_page/profile_page.scss'
import SuccessBarList from "../../statistic/SuccessBarList";

interface WordsStatisticsCardProps {}

export const WordsStatisticsCard: FC<WordsStatisticsCardProps> = () => {
    return (
        <div className='words-statistics-card-container grid-5'>
            <div className='words-statistics-card-text'>Статистика по словам</div>
            <SuccessBarList
                loadPath={'/statistic/words'}
                params={[
                    { title: 'Добавлено слов лично', paramName: 'countUserWords' },
                    { title: 'Добавлено готовых слов', paramName: 'countDoneWords' }
                ]}
            />
        </div>
    )
}
