import { executeJsonGetMethod, executeJsonPostMethod, executePostMethod } from '../api/rest/restApiUtils'

/**
 * Испрользовать промокод
 * @method updateToPremiumByCode
 * @return {Response} Fetch Response
 */
export function updateToPremiumByCode(code) {
    return executePostMethod(`/promo/premium?promoCode=${code}`)
}

export function robokassaPay(id) {
    return executeJsonPostMethod(`/robokassa/pay/course/${id}`)
}

export function getPayStatus(id) {
    return executeJsonGetMethod(`/robokassa/pay/${id}/status`)
}

