import { createSlice } from '@reduxjs/toolkit'
import WordListUtils from '../../components/utils/WordListUtils'
import { setWlLoadedAfterUpdate, setWordListLoading } from '../utils/helpers'

import {
    createInCommunity,
    createWordList,
    deleteWordList,
    fetchCommunityWorldListsById,
    fetchWorldLists,
    updateInCommunity,
    updateWordList, updateWordListLang
} from './extraReducers'

const slice = createSlice({
    name: 'wordLists',
    isWordListsLoading: false,
    isLoaded: false,
    selectedItem: undefined,
    initialState: {
        data: []
    },
    reducers: {
        setWordListsRoutine: (state, action) => {
            state.data = action.payload
        },
        clearWordListsRoutine: (state) => {
            state.data = []
        },
        setSelectedWordListRoutine: (state, action) => {
            state.selectedItem = action.payload
        }
    },
    extraReducers: {
        [fetchWorldLists.pending]: (state) => {
            state.isWordListsLoading = true
            state.isLoaded = false
        },
        [fetchWorldLists.fulfilled]: (state, action) => {
            state.data = WordListUtils.mergeWordLists(state.data, WordListUtils.parseWorldLists(action.payload))
            state.isWordListsLoading = false
            state.isLoaded = true
        },
        [fetchWorldLists.rejected]: (state) => {
            state.isWordListsLoading = false
            state.isLoaded = true
        },

        [fetchCommunityWorldListsById.pending]: (state) => {
            state.isWordListsLoading = true
            state.isLoaded = false
        },
        [fetchCommunityWorldListsById.fulfilled]: (state, action) => {
            state.data = WordListUtils.mergeWordLists(state.data, WordListUtils.parseWorldLists(action.payload))
            state.isWordListsLoading = false
            state.isLoaded = true
        },
        [fetchCommunityWorldListsById.rejected]: (state) => {
            state.isWordListsLoading = false
            state.isLoaded = true
        },

        [deleteWordList.pending]: (state, { meta }) => {
            const id = meta.arg
            state.data = setWordListLoading(state.data, id)
        },
        [deleteWordList.fulfilled]: (state, action) => {
            state.data = [...state.data.filter((category) => category.id !== action.payload.id)]
            state.isLoaded = true
        },
        [deleteWordList.rejected]: (state) => {
            state.isLoaded = true
        },

        [updateWordList.pending]: (state, { meta }) => {
            const { id } = meta.arg
            state.data = setWordListLoading(state.data, id)
        },
        [updateWordList.fulfilled]: (state, { payload }) => {
            const { id } = payload
            state.isLoaded = true
            const { data } = payload
            state.data = setWlLoadedAfterUpdate(state.data, data, id)
        },
        [updateWordList.rejected]: (state) => {
            state.isWordListsLoading = false
        },
        [updateWordListLang.fulfilled]: (state, { payload }) => {
            const { id } = payload
            state.isLoaded = true
            const { data } = payload
            state.data = setWlLoadedAfterUpdate(state.data, data, id)
        },

        [createWordList.pending]: (state, { meta }) => {
            const { id } = meta.arg
            state.data = setWordListLoading(state.data, id)
        },
        [createWordList.fulfilled]: (state, action) => {
            const { savedWordList } = action.payload
            state.isLoaded = true
            state.data = [...state.data, WordListUtils.parseNewWordList(savedWordList)]
        },
        [createWordList.rejected]: (state) => {
            state.isWordListsLoading = false
        },

        [updateInCommunity.pending]: (state, { meta }) => {
            const { id } = meta.arg
            state.data = setWordListLoading(state.data, id)
        },
        [updateInCommunity.fulfilled]: (state, { payload }) => {
            const { id } = payload
            const { data } = payload
            state.isLoaded = true
            state.data = setWlLoadedAfterUpdate(state.data, data, id)
        },
        [updateInCommunity.rejected]: (state) => {
            state.isWordListsLoading = false
        },

        [createInCommunity.pending]: (state, { meta }) => {
            const { id } = meta.arg
            state.data = setWordListLoading(state.data, id)
        },
        [createInCommunity.fulfilled]: (state, action) => {
            const { wordList } = action.payload
            state.isLoaded = true
            state.data = [...state.data, WordListUtils.parseNewWordList(wordList)]
            state.selectedItem = wordList.id
        },
        [createInCommunity.rejected]: (state) => {
            state.isWordListsLoading = false
        }
    }
})

export const { setWordListsRoutine, clearWordListsRoutine, setSelectedWordListRoutine } = slice.actions
export const wordListsDataSelector = (state) => state.wordLists.data
export const selectedWordListIdSelector = (state) => state.wordLists.selectedItem
export const wordsListsLoadingStatusSelector = (state) => state.wordLists.isWordListsLoading
export const wordsListsIsLoadedStatusSelector = (state) => state.wordLists.isLoaded
export const wordListByIdSelector = (id) => (state) => state.wordLists.data.find((wl) => wl.id === id)
export default slice.reducer
