import {Button, Input, Modal} from 'antd'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {FC, useState} from 'react'
import {setUserRoutine, userSelector} from "../../store/global";
import * as API_USER from "../../shared/http/user";
import NotificationsUtils from "../utils/NotificationsUtils";

interface EditProfileProps {
    isOpen: boolean,
    close: () => void
}

const EditProfile: FC<EditProfileProps> = ({isOpen, close}) => {
    const currentUser = useAppSelector(userSelector)
    const [name, setName] = useState<string>(currentUser?.firstName)
    const [lastName, setLastName] = useState<string>(currentUser?.lastName)

    const dispatch = useAppDispatch()


    const clearAndClose = () => {
        setName('')
        close()
    }

    const onChangeName = (e: any) => {
        const value = e.target.value
        setName(value)
    }

    const onChangeLastName = (e: any) => {
        const value = e.target.value
        setLastName(value)
    }

    const updateData = () => {
        API_USER.updateProfile(name, lastName)
            .then(() => {
                const newUser = {...currentUser};
                newUser.lastName = lastName;
                newUser.firstName = name;
                dispatch(setUserRoutine(newUser))
                close()
            })
            .catch(() => {
                NotificationsUtils.openErrorNotification('Не удалось обновить профиль')
            })
    }

    return (
        <Modal title='Вы редактируете профиль'
               onCancel={clearAndClose}
               footer={[
                   <Button key='submit' onClick={clearAndClose}>
                       Отмена
                   </Button>,
                   <Button
                       onClick={updateData}
                       type='primary'
                   >
                       Сохранить
                   </Button>
               ]}
               open={isOpen}>
            <div className='modal-input-container'>

                <Input value={name}
                       size='large'
                       onChange={onChangeName}
                       placeholder='Имя'
                       maxLength={50}
                />
                <Input value={lastName}
                       size='large'
                       onChange={onChangeLastName}
                       placeholder='Фамилия'
                       maxLength={50}
                />
            </div>
        </Modal>
    )
}
export default EditProfile
