import View from './View'
import store from '../store'
import { Provider } from 'react-redux'
import ReactGA4 from 'react-ga4'
import Constants from '../const/Constants'
import { useEffect } from 'react'

ReactGA4.initialize(Constants.GOOGLE_ANALYTICS_ID)

const App = () => {

    useEffect(() => {
        if (window.location.hostname === 'www.geekenglish.ru') {
            window.location.href = 'https://geekenglish.ru'
        }

        //Todo временный редирект (посмотреть статистику)
        if (window.location.pathname === '/main') {
            window.location.href = 'https://geekenglish.ru'
        }
    })

    return (
        <Provider store={store}>
            <View />
        </Provider>
    )
}
export default App
