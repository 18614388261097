export const test = [
    {
        id: 1,
        title: 'Tomas has travelled a lot. He ____ speak five languages.',
        variants: [
            { id: 1, title: 'could', isTrue: false },
            { id: 2, title: 'can', isTrue: true },
            { id: 3, title: 'able to', isTrue: false },
            { id: 4, title: 'is could', isTrue: false }
        ]
    },
    {
        id: 2,
        title: 'I used to ____ run fast.',
        variants: [
            { id: 1, title: 'can', isTrue: false },
            { id: 2, title: 'could', isTrue: false },
            { id: 3, title: 'am can', isTrue: false },
            { id: 4, title: 'be able to', isTrue: true }
        ]
    },
    {
        id: 3,
        title: 'Nicole ____ drive, but she hasn’t got a car.',
        variants: [
            { id: 1, title: 'could', isTrue: false },
            { id: 2, title: 'can', isTrue: true },
            { id: 3, title: 'able to', isTrue: false },
            { id: 4, title: 'is could', isTrue: false }
        ]
    },
    {
        id: 4,
        title: 'I looked everywhere for the book, but I ____ find it.',
        variants: [
            { id: 1, title: 'able to', isTrue: false },
            { id: 2, title: 'can', isTrue: false },
            { id: 3, title: 'couldn’t', isTrue: true },
            { id: 4, title: 'is could', isTrue: false }
        ]
    },
    {
        id: 5,
        title: 'They didn’t want to come with us at first, but we ____  persuade them.',
        variants: [
            { id: 1, title: 'managed to', isTrue: true },
            { id: 2, title: 'couldn’t', isTrue: false },
            { id: 3, title: 'able to', isTrue: false },
            { id: 4, title: 'is could', isTrue: false }
        ]
    },
    {
        id: 6,
        title: 'I ____  come and see you tomorrow if you like.',
        variants: [
            { id: 1, title: 'could', isTrue: false },
            { id: 2, title: 'is can', isTrue: false },
            { id: 3, title: 'able to', isTrue: false },
            { id: 4, title: 'can', isTrue: true }
        ]
    },
    {
        id: 7,
        title: 'We looked for Alex everywhere, but we ____  find him.',
        variants: [
            { id: 1, title: 'couldn’t', isTrue: true },
            { id: 2, title: 'can', isTrue: false },
            { id: 3, title: 'able to', isTrue: false },
            { id: 4, title: 'is could', isTrue: false }
        ]
    }
]
