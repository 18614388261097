import moment from 'moment'

let UserUtil = {
    getId: function(user) {
        return user ? user.id : null
    },
    getPhotoId: function(user) {
        return user ? user.photoId : null
    },
    getNotificationFlag: function(user) {
        return user ? user.notificationFlag === true : false
    },

    getUserNotificationTime: function(user) {
        const notificationTime = user.notificationTime
        return notificationTime ? new Date('1970-01-01T' + notificationTime).getHours() + ':00' : '12:00'
    },

    convertHoursToTime: function(hours) {
        const m = moment().utcOffset(0)
        m.set({ hour: hours, minute: 0, second: 0, millisecond: 0 })
        return m.format('hh:mm:ss')
    },

    hasPayment: function(user) {
        return user && user.hasPayment
    },

    getTelegramChannel: function(user) {
        return user && user.telegramChannel ? user.telegramChannel : ''
    }
}
export default UserUtil
