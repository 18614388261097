import { FC } from 'react'

interface CardHeaderProps {
    current: number,
    max: number
}

const CardHeader: FC<CardHeaderProps> = ({ current = 0, max = 0 }) => {
    return (
        <div className='word-card__header'>
            <div id='counter'>{current} / {max}</div>
        </div>
    )
}

export default CardHeader
