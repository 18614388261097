import ReactGA4 from 'react-ga4'

let GoogleAnalyticsUtils = {
    createEvent: function (googleAnalyticsConstant) {
        ReactGA4.event({
            category: googleAnalyticsConstant.category,
            action: googleAnalyticsConstant.action,
            label: 'your label', // optional
            value: 99, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: 'xhr' // optional, beacon/xhr/image
        })
    }
}

export default GoogleAnalyticsUtils
