import RulesHeader from '../../blocks/RulesHeader'
import Example from '../../blocks/Example'
import FactRule from '../../blocks/FactRule'
import TableTwoVS from '../../blocks/TableTwoVS'
import TableFour from '../../blocks/TableFour'
import RuleTestWrapper from '../../RuleTestWrapper'
import { test } from './test'

const ActivePassiveVoice = (props) => {
    return (
        <div>
            <RulesHeader title='Залоги' />

            <TableTwoVS
                notPaint={true}
                elements={[
                    { left: 'Активный (Действительный) ', right: 'Пассивный (Страдательный)' },
                    { left: 'Объект совершает действие ', right: 'Действие производится над объектом' }
                ]}
            />
            <TableTwoVS
                elements={[
                    {
                        left: ['Tomas ', <u>baked</u>, ' a big cake '],
                        right: ['The big cake ', <u>was baked</u>, ' by Tomas']
                    },
                    { left: 'Томас испек большой пирог ', right: 'Большой пирог был испечен Томасом' }
                ]}
            />
            <TableTwoVS
                elements={[
                    {
                        left: ['Kate ', <u>has knitted</u>, ' this sweater herself '],
                        right: ['My dog ', <u>has been stolen</u>, '']
                    },
                    { left: 'Кейт сама связала этот свитер ', right: 'Мою собаку украли' }
                ]}
            />
            <FactRule
                factRule='Пассивный залог = to be (в соответствующем времени, лице и числе) и
                                причастие прошедшего времени (Participle II) глагола (3 форма):'
            />
            <FactRule
                factRule='Для отрицательной формы пассивного залога нужно добавить
                                отрицательную частицу «not» после вспомогательного глагола'
            />
            <Example
                engExample={['This building ', <u>will be</u>, ' demolished next month']}
                rusExample='Это здание будет снесено в следующем месяце'
            />
            <Example engExample={['He ', <u>has not been</u>, ' seen anywhere']} rusExample='Его нигде не видели' />
            <Example engExample={['', <u>Was</u>, ' your wallet stolen?']} rusExample='Ваш бумажник был украден?' />
            <TableFour
                elements={[
                    { first: '', second: 'Simple', third: 'Continuous', fourth: 'Perfect' },
                    {
                        first: 'Present',
                        second: ['space ', <b>is</b>, ' explored '],
                        third: ['space ', <b>is being</b>, ' explored '],
                        fourth: ['space ', <b>has been</b>, ' explored\n']
                    },
                    {
                        first: 'Past',
                        second: ['space ', <b>was</b>, ' explored '],
                        third: ['space ', <b>was being</b>, ' explored '],
                        fourth: ['space ', <b>had been</b>, ' explored\n']
                    },
                    {
                        first: 'Future',
                        second: ['space ', <b>will be</b>, ' explored '],
                        third: '-----',
                        fourth: ['space ', <b>will have been</b>, ' explored\n']
                    }
                ]}
            />
            <RuleTestWrapper value={test} rule={props} />
        </div>
    )
}

export default ActivePassiveVoice
