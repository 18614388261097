import { FC } from 'react'

interface IconLockPremiumProps {
    className?: string
    onClick?: () => void
}

const IconLockPremium: FC<IconLockPremiumProps> = ({
                                                       className, onClick = () => {
    }
                                                   }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='100'
            height='100'
            fill='none'
            viewBox='0 0 100 100'
        >
            <path
                fill='#000'
                d='M73.584 59.467a4.785 4.785 0 01-4.785-4.785V26.817c0-9.391-7.64-17.031-17.032-17.031s-17.032 7.64-17.032 17.031a4.785 4.785 0 01-9.57 0C25.165 12.15 37.098.215 51.767.215c14.668 0 26.602 11.934 26.602 26.602v27.865a4.785 4.785 0 01-4.785 4.785z'
            ></path>
            <path
                fill='#2F88FF'
                d='M80.882 99.113h-58.23c-5.437 0-9.844-4.407-9.844-9.844V55.604c0-5.437 4.407-9.844 9.844-9.844h58.23c5.436 0 9.844 4.407 9.844 9.844v33.665c0 5.437-4.407 9.844-9.844 9.844z'
            ></path>
            <path
                fill='#DFEDFF'
                d='M21.35 91.18h-.493a2.812 2.812 0 01-2.813-2.813V56.506a2.813 2.813 0 012.813-2.813h.493a2.813 2.813 0 012.812 2.813v31.861a2.812 2.812 0 01-2.812 2.813z'
            ></path>
        </svg>
    )
}

export default IconLockPremium