//Todo delete full class
let MainModel = {
    getMessageFromGeekException: function (e) {
        let message = 'Что-то пошло не так)'
        if (e !== null && e !== undefined && e.responseJSON != null) {
            message = e.responseJSON.description
        }
        return message
    },

    deleteSpecialSymbols: function (value) {
        let answer = value
        let rep = /[;":'\\=`\*++!@#$%\^&_№?><]/
        if (rep.test(value)) {
            value = value.replace(rep, '')
            answer = value
        }
        return answer
    }
}

export default MainModel
