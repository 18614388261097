import { FC } from 'react'
import { Tooltip } from 'antd'

interface MenuLabelProps {
    content: string,
    hoverTitle: string,

}

const MenuLabel: FC<MenuLabelProps> = ({ content, hoverTitle }) => {
    return <Tooltip title={hoverTitle}><span>{content}</span></Tooltip>
}

export default MenuLabel
