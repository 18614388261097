import '../../styles/css/dictionary/modalwindow/modalw.css'
import {Button, Modal} from 'antd'
import * as API_TELEGRAM from '../../shared/http/telegram'
import {FC, useEffect, useState} from 'react'

interface BindTelegramCodeDialogProps {

    closeDialog: () => void,
    open: boolean;
}

const BindTelegramCodeDialog:FC<BindTelegramCodeDialogProps> = ({closeDialog, open}) => {

    const [code, setCode] = useState()

    useEffect(() => {
        getCode()
    }, [])

    const getCode = () => {
        API_TELEGRAM.createUserBindToken()
            .then((data) => {
                setCode(data.data.code)
            })
            .catch(() => {
                Modal.error({
                    title: 'Ошибка!',
                    content: 'Не удалось сгенерировать код'
                })
            })
    }


    return (
        <Modal onCancel={closeDialog}
               footer={[
                   <Button type='primary' onClick={closeDialog}>
                       ЗАКРЫТЬ
                   </Button>,
               ]}
               open={open}>
            <span>
                    <ol>
                        <li>Перейдите в телеграмм</li>
                        <li>Найдите бота <a href='https://t.me/GeekenglishBot'>@GeekenglishBot</a></li>
                        <li> Выберите команду <b>привязать бота к сайту </b> и введите этот код</li>
                    </ol>
                  </span>
            <h2>Ваш код: {code}</h2>
        </Modal>
    )
}

export default BindTelegramCodeDialog