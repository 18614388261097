import '../../../../styles/scss/courses/courses.scss'
import { Helmet } from 'react-helmet'
import { CoursesCard } from './CoursesCard'
import { useEffect, useState } from 'react'
import * as API_COURSES from '../../../../shared/http/courses'
import { SmallCourseDto } from '../../../../shared/types/api'
import Loading from '../../../elements/loading/Loading'

export const Courses = () => {

    const [loading, setLoading] = useState<boolean>()
    const [courses, setCourses] = useState<Array<SmallCourseDto>>([])

    useEffect(() => {
        setLoading(true)

        API_COURSES.getCourses().then((resp) => {
            setCourses(resp.content)
        }).finally(() => {
            setLoading(false)
        })

    }, [])

    return (
        <div id='courses'>
            {loading && <Loading />}
            <Helmet title='Курсы английского языка' />

            {courses.map((card, key) => (
                <CoursesCard card={card} key={card.id} />
            ))}
        </div>
    )
}