import { FC, ReactNode } from 'react'
import { Menu } from 'antd'
import MenuIcon from '../../../common/menuIcon/MenuIcon'
import MenuLabel from '../../../common/menuLabel/MenuLabel'
import { premiumStatusSelector, userSelector } from '../../../store/global'
import { useAppSelector } from '../../../store/hooks'
import '../../../styles/scss/navigation-aside/navigation-aside.scss'
import { data } from '../../config/pages-data'
import IconUser from '../../elements/svg_icons/IconUser'
import { goToPage } from '../../utils/goToPage'
import PremiumBlock from './premiumBlock/premium-block'
// @ts-ignore
import { useLocation, useNavigate } from 'react-router-dom'
import Constants from '../../../const/Constants'
import IconPlayStore from '../../elements/svg_icons/IconPlayStore'
import IconLock from '../../elements/svg_icons/IconLock'
import IconAppStore from '../../elements/svg_icons/IconAppStore'


interface NavigationBarProps {
    className: string
    visible: boolean
    resetVisibility: () => {}
}

const NavigationBar: FC<NavigationBarProps> = ({ className, visible, resetVisibility }) => {
    const navigate = useNavigate()
    const isPremium = useAppSelector(premiumStatusSelector)
    const user = useAppSelector(userSelector)
    const location = useLocation().pathname
    const path = location.includes('profile') ? location.replace('/', '') : location.replaceAll('/', '')

    const getItem = (label: ReactNode, key: string, icon: ReactNode, ispremiumpage: boolean) => {
        return {
            label,
            key,
            icon,
            ispremiumpage
        }
    }

    const items = data
        .filter((item) => item.enable)
        .map((item) => {
            if (item.href === 'profile') {
                return getItem(
                    <MenuLabel content={item.title} hoverTitle={item.cardBody} />,
                    `${item.href}/${user.id}`,
                    <IconUser />,
                    item.isPremium
                )
            } else {
                return getItem(
                    <MenuLabel content={item.title} hoverTitle={item.cardBody} />,
                    item.href,
                    item.isPremium && !isPremium ? <IconLock /> : <MenuIcon path={item.imageSrc} />,
                    item.isPremium
                )
            }
        })
    const onClick = (e: any) => {
        const dataGridItem = items.find((d) => d.key === e.key)
        if (dataGridItem) {
            resetVisibility()
            goToPage(`/${dataGridItem.key}`, dataGridItem.ispremiumpage, !!isPremium, navigate)
        }
    }
    return (
        <aside
            id='navigation-bar'
            className={className}
            style={{ display: window.innerWidth < 480 && visible ? 'block' : '' }}
        >
            <div className='navigation-bar__site-name'>
                <img src='../images/custom/logo.png' alt='Logo' />
                <span>Geekenglish</span>
            </div>
            <hr />
            <Menu selectedKeys={[path]} onClick={onClick} mode='inline' inlineCollapsed={false} items={items} />
            {!isPremium && <PremiumBlock />}
            <div className={'navigation-bar__addition-info'}>
                <ul>
                    <li>
                        <a href={Constants.GOOGLE_PLAY_LINK}>
                            <IconPlayStore />
                        </a>
                    </li>
                    <li>
                        <a href={Constants.APPLE_STORE_LINK}>
                            <IconAppStore />
                        </a>
                    </li>
                </ul>
            </div>
        </aside>
    )
}

export default NavigationBar
