import {FC} from 'react'

interface PauseIconProps {
    className?: string,
    onClick?: () => void
}

export const PauseIcon: FC<PauseIconProps> = ({className = '', onClick}) => {

    return (
        <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.5625 19.6875H23.625V43.3125H27.5625V19.6875ZM39.375 19.6875H35.4375V43.3125H39.375V19.6875Z"
                  fill="#4174F8"/>
            <path
                d="M31.5 7.875C36.1726 7.875 40.7402 9.26058 44.6254 11.8565C48.5105 14.4525 51.5385 18.1422 53.3267 22.4591C55.1148 26.776 55.5826 31.5262 54.6711 36.109C53.7595 40.6918 51.5094 44.9014 48.2054 48.2054C44.9014 51.5094 40.6918 53.7595 36.109 54.6711C31.5262 55.5826 26.776 55.1148 22.4591 53.3267C18.1422 51.5385 14.4525 48.5105 11.8565 44.6253C9.26059 40.7402 7.87501 36.1726 7.87501 31.5C7.87501 25.2343 10.3641 19.2251 14.7946 14.7946C19.2252 10.3641 25.2343 7.875 31.5 7.875ZM31.5 3.9375C26.0487 3.9375 20.7197 5.55401 16.1871 8.58262C11.6545 11.6112 8.12172 15.9159 6.03558 20.9523C3.94944 25.9887 3.40361 31.5306 4.46712 36.8772C5.53062 42.2238 8.1557 47.1349 12.0104 50.9896C15.8651 54.8443 20.7762 57.4694 26.1228 58.5329C31.4694 59.5964 37.0113 59.0506 42.0477 56.9644C47.0841 54.8783 51.3888 51.3455 54.4174 46.8129C57.446 42.2803 59.0625 36.9513 59.0625 31.5C59.0625 24.19 56.1586 17.1793 50.9896 12.0104C45.8207 6.8414 38.81 3.9375 31.5 3.9375Z"
                fill="#4174F8"/>
        </svg>


    )
}
