import PropTypes from 'prop-types'
import IconCrown from '../../../../elements/svg_icons/IconCrown'

function WordUserStats(props) {
    const { login, usersCount, countWords, premium } = props

    return (
        <div className='top-stats__user'>
            {props.usersCount ? (
                <span className='top-stats__text'>{`${login} и (${usersCount})`}</span>
            ) : (
                <span className='top-stats__text'>
                    {login} {premium && <IconCrown width='14' height='12' />}
                </span>
            )}
            <span className='top-stats__text'>{countWords}</span>
        </div>
    )
}

export default WordUserStats

WordUserStats.propTypes = {
    countWords: PropTypes.any.isRequired,
    login: PropTypes.any.isRequired,
    usersCount: PropTypes.number,
    premium: PropTypes.bool
}
