import RulesHeader from '../../blocks/RulesHeader'
import NumberBlock from '../../blocks/NumberBlock'
import Example from '../../blocks/Example'
import FactRule from '../../blocks/FactRule'
import VeryImportantWords from '../../blocks/VeryImportantWords'
import TableTwoVS from '../../blocks/TableTwoVS'
import RuleTestWrapper from '../../RuleTestWrapper'
import { test } from './test'

const CanCouldAndAbleTo = (props) => {
    return (
        <div>
            <RulesHeader title='Модальные глаголы' subtitle='can, could и (be) able to' />

            <NumberBlock numberBlock='Can' />
            <p>
                {' '}
                Мы используем <b>can</b>, чтобы сказать, что либо возможно или разрешено, или что у кого-то есть
                физическая способность что-то сделать. Мы используем <b>can</b> + <b>инфинитив</b>:
            </p>
            <Example
                engExample={['We ', <u>can</u>, ' ', <u>see</u>, ' the airport from our bedroom window.']}
                rusExample='Мы можем увидеть аэропорт из окна нашей спальни.'
            />
            <Example
                engExample={[, <u>Can</u>, ' you ', <u>speak</u>, ' English language?']}
                rusExample='Вы можете говорить на английском языке?'
            />
            <FactRule factRule='Отрицательная форма - can’t (= cannot):' />
            <Example
                engExample={['I ', <u>can’t</u>, ' come to the party.']}
                rusExample='Я не могу прийти на вечеринку.'
            />

            <NumberBlock numberBlock='(Be) able to' />
            <p>
                {' '}
                Вы можете сказать, что кто-то <b>is able to</b> (способен) сделать что-то, но <b>can</b> более
                естественный вариант:
            </p>
            <Example
                engExample={['We ', <u>are able to</u>, ' see the airport from our bedroom window.']}
                rusExample='Мы можем увидеть аэропорт из окна нашей спальни.'
            />
            <p>
                {' '}
                Но у <b>can</b> есть только две формы: <b>can</b> (настоящее время) и <b>could</b> (прошедшее время).
                Поэтому иногда необходимо использовать <b>(be) able to</b>. Сравните:
            </p>
            <TableTwoVS
                elements={[
                    {
                        left: ['Tom ', <u>can</u>, ' come tomorrow. '],
                        right: ['Tom might ', <u>be able to</u>, ' come tomorrow.']
                    },
                    { left: 'Том может прийти завтра. ', right: 'Том может быть сможет прийти завтра.' }
                ]}
            />

            <NumberBlock numberBlock='Could' />
            <p>
                {' '}
                Иногда <b>could</b> используется как прошедшее время модального глагола <b>can</b>. Часто мы используем{' '}
                <b>could</b> с:
            </p>
            <VeryImportantWords
                veryImportantWords={['see', 'hear', 'smell', 'taste', 'feel', 'remember', 'understand']}
            />
            <Example
                engExample={['We had a lovely hotel room. We ', <u>could</u>, ' see the airport.']}
                rusExample='У нас был прекрасный номер в отеле. Мы могли видеть аэропорт.'
            />
            <p>
                {' '}
                Мы также используем <b>could</b>, чтобы сказать, что кто-либо обладал общей способностью или разрешением
                что-либо сделать:
            </p>
            <Example
                engExample={['My father ', <u>could</u>, ' speak two languages.']}
                rusExample='Мой отец мог говорить на двух языках.'
            />

            <NumberBlock numberBlock='Could' />
            <p>
                {' '}
                Мы используем <b>could</b>, говоря об общей способности. Но если вы хотите сказать, что кто-то сделал
                что-то в конкретной ситуации, используйте <b>was/were able to</b> или <b>managed to</b>:
            </p>
            <Example
                engExample={[
                    'We didn’t know where Tom was, but we ',
                    <u>managed to find</u>,
                    ' / ',
                    <u>were able to</u>,
                    ' find him in the end.'
                ]}
                rusExample='Мы не знали, где находится Том, но в конце концов нам удалось его найти.'
            />
            <FactRule factRule='Отрицательная форма couldn’t (could not) возможна во всех ситуациях:' />
            <Example engExample={['My father ', <u>couldn’t</u>, ' swim.']} rusExample='Мой отец не умел плавать.' />
            <RuleTestWrapper value={test} rule={props} />
        </div>
    )
}

export default CanCouldAndAbleTo
