export const test = [
    {
        id: 1,
        title: 'Tomas ___ me about his wedding',
        variants: [
            { id: 1, title: 'has been informing', isTrue: false },
            { id: 2, title: 'was informed', isTrue: false },
            { id: 3, title: 'informed', isTrue: true },
            { id: 4, title: 'is being informed', isTrue: false }
        ]
    },
    {
        id: 2,
        title: 'Nikita’ll have to wait because the dinner ___',
        variants: [
            { id: 1, title: 'is just being cooked', isTrue: true },
            { id: 2, title: 'will just being cooked', isTrue: false },
            { id: 3, title: 'is just be being cooked', isTrue: false },
            { id: 4, title: 'was just being cooked', isTrue: false }
        ]
    },
    {
        id: 3,
        title: 'What ___ to help you avoid the punishment?',
        variants: [
            { id: 1, title: 'I can do', isTrue: false },
            { id: 2, title: 'do you do', isTrue: false },
            { id: 3, title: 'can been done', isTrue: false },
            { id: 4, title: 'can be done', isTrue: true }
        ]
    },
    {
        id: 4,
        title: 'My little sister … to eat a lot of sweets',
        variants: [
            { id: 1, title: 'is not allowed', isTrue: true },
            { id: 2, title: 'must not allowed', isTrue: false },
            { id: 3, title: 'will not allowed', isTrue: false },
            { id: 4, title: 'was not being allowed', isTrue: false }
        ]
    },
    {
        id: 5,
        title: 'Tomas ___ at the University since 2013',
        variants: [
            { id: 1, title: 'has been taught', isTrue: false },
            { id: 2, title: 'is teaching', isTrue: false },
            { id: 3, title: 'has been teaching', isTrue: true },
            { id: 4, title: 'have been teaching', isTrue: false }
        ]
    },
    {
        id: 6,
        title: 'Nikita ___ to her new boss yesterday',
        variants: [
            { id: 1, title: 'was introduced', isTrue: true },
            { id: 2, title: 'introduced', isTrue: false },
            { id: 3, title: 'is introduced', isTrue: false },
            { id: 4, title: 'were introduced', isTrue: false }
        ]
    },
    {
        id: 7,
        title: 'Football ___ for hundred of years',
        variants: [
            { id: 1, title: 'has played', isTrue: false },
            { id: 2, title: 'has been played', isTrue: true },
            { id: 3, title: 'was played', isTrue: false },
            { id: 4, title: 'was playing', isTrue: false }
        ]
    },
    {
        id: 8,
        title: 'While we were on holiday, our phone ___ from our hotel room',
        variants: [
            { id: 1, title: 'were stolen', isTrue: true },
            { id: 2, title: 'has been stolen', isTrue: false },
            { id: 3, title: 'had been stolen', isTrue: false },
            { id: 4, title: 'was stolen', isTrue: true }
        ]
    }
]
