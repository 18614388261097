import PropTypes from 'prop-types'

const ExampleDialog = (props) => {
    return (
        <ul className='example-dialog'>
            {props.elements.map((item, i) => (
                <li key={i}>
                    <span> {item} </span>
                </li>
            ))}
        </ul>
    )
}

export default ExampleDialog

ExampleDialog.propTypes = {
    elements: PropTypes.array.isRequired
}
