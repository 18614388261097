import {FC} from "react";
import '../../../../../styles/scss/profile_page/profile_page.scss'
import MonthWordList from "../../statistic/MonthWordList";

interface RepeatListCardProps {
}

export const RepeatListCard: FC<RepeatListCardProps> = () => {
    return (
        <div className='repeat-list-card-container grid-3'>
            <div className='repeat-list-card-text'>Списки для повторения:</div>
            <MonthWordList />
        </div>
    )
}
