import '../../../styles/css/elements/svg_icons/icon-btn.css'

function IconBtnOk(props) {
    return (
        <svg
            onClick={() => (props.onClick ? props.onClick() : false)}
            className={'icon-btn ' + props.class}
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 48 48'
        >
            <title>Подтвердить</title>
            <circle cx='24' cy='24' r='21' fill='#000' />
            <path fill='#CCFF90' d='M34.6 14.6L21 28.2 15.4 22.6 12.6 25.4 21 33.8 37.4 17.4z' />
        </svg>
    )
}

export default IconBtnOk
