import moment from 'moment'

let DateUtils = {
    /**
     * Создание нового слова
     * @param
     * @returns number
     */
    compareDesc(dateTimeA: string, dateTimeB: string) {
        const momentA = dateTimeA ? moment(dateTimeA) : moment()
        const momentB = dateTimeB ? moment(dateTimeB) : moment()
        if (momentA > momentB) return -1
        else if (momentA < momentB) return 1
        else return 0
    }

}

export default DateUtils
