import RulesHeader from '../../blocks/RulesHeader'
import NumberBlock from '../../blocks/NumberBlock'
import TaskExample from '../../blocks/TaskExample'
import Example from '../../blocks/Example'
import FactRule from '../../blocks/FactRule'
import TableThree from '../../blocks/TableThree'
import VeryImportantWords from '../../blocks/VeryImportantWords'
import RuleTestWrapper from '../../RuleTestWrapper'
import { test } from './test'

const PresentContinuous = (props) => {
    return (
        <div>
            <RulesHeader title='Present continuous' subtitle='Настоящее длительное' />

            <NumberBlock numberBlock='1' />
            <TaskExample taskExample='Изучите следующую ситуацию:' />
            <Example
                engExample='Tomas is in his car. He is on him way to work. He is driving to work.'
                rusExample='Томас сидит в своей машине. Он уже едет на работу. Он едет на работу.'
            />
            <p>
                {' '}
                Это означает: он ведёт машину <b>сейчас</b>, в момент речи. Действие не завершено. Present continuous
                указывает на процесс, действие, длящееся в определенный момент в настоящем.
            </p>
            <FactRule factRule='Существительное + am/is/are + глагол-ing это the present continuous:' />
            <TableThree
                elements={[
                    { left: 'I', center: 'am', right: 'Глагол+ing' },
                    { left: 'He/she/it', center: 'is', right: 'Глагол+ing' },
                    { left: 'We/you/they', center: 'are', right: 'Глагол+ing' }
                ]}
            />

            <NumberBlock numberBlock='2' />
            <p>
                {' '}
                I am doing something = Я делаю что-либо = Я в процессе работы над этим (в процессе ‘делания’), т.е. я
                начал делать это, но еще не закончил:
            </p>
            <Example
                engExample={['Where’s Tomas? ', <u>He is having</u>, ' a shower.']}
                rusExample='Где Томас? Он принимает душ.'
            />
            <p>
                {' '}
                Действие не обязательно происходит в момент речи. Например: Томас говорит (is talking) с другом по
                телефону:
            </p>
            <Example
                engExample={['I ', <u>am reading</u>, ' a really good book at the moment.']}
                rusExample='Я сейчас читаю очень интересную книгу.'
            />
            <p>
                {' '}
                Томас не читает (is not reading) книгу в момент речи. Он имеет в виду, что он начал читать ее, но еще не
                закончил. Он в процессе чтения этой книги.
            </p>

            <NumberBlock numberBlock='3' />
            <p>
                {' '}
                Мы можем использовать present continuous со словами <b>today</b> / <b>this week</b> / <b>this year</b> и
                другими словами, обозначающими промежутки времени, связанными с текущим промежутком времени (сейчас),
                т.е. сегодня это сейчас, эта неделя - она идет сейчас и т.п.:
            </p>
            <Example
                engExample={['You ', <u>are studying</u>, ' hard ', <u>today</u>, '. (not You study hard today)']}
                rusExample='Ты сегодня усердно учишься.'
            />

            <NumberBlock numberBlock='4' />
            <p>
                {' '}
                Мы используем present continuous, когда говорим об изменениях, происходящих вокруг нас сейчас, особенно
                с этими глаголами:
            </p>
            <VeryImportantWords
                veryImportantWords={[
                    'get',
                    'change',
                    'become',
                    'increase',
                    'rise',
                    'fall',
                    'grow',
                    'improve',
                    'begin',
                    'start'
                ]}
            />
            <Example
                engExample={['The population of the world ', <u>is increasing</u>, ' very fast. (not increases)']}
                rusExample='Население мира растет очень быстро.'
            />

            <NumberBlock numberBlock='5' />
            <p>
                {' '}
                В вопросительном предложении вспомогательный глагол выносится на место перед подлежащим, а значимый
                глагол остается после него:
            </p>
            <TableThree
                elements={[
                    { left: 'Am', center: 'I', right: 'Глагол+ing ?' },
                    { left: 'Are', center: 'you/we/they', right: 'Глагол+ing ?' },
                    { left: 'Is', center: 'he/she/it', right: 'Глагол+ing ?' }
                ]}
            />
            <p>
                {' '}
                В отрицательных предложениях за вспомогательным глаголом следует отрицательная частица <b>not</b>. Формы{' '}
                <b>is</b> и <b>are</b> при этом могут быть сокращены до <b>isn’t</b> и <b>aren’t</b> соответственно:
            </p>
            <TableThree
                elements={[
                    { left: 'I', center: 'am not', right: 'Глагол+ing' },
                    { left: 'We/you/they', center: 'are not', right: 'Глагол+ing' },
                    { left: 'He/she/it', center: 'is not', right: 'Глагол+ing' }
                ]}
            />

            <RuleTestWrapper value={test} rule={props} />
        </div>
    )
}

export default PresentContinuous
