import { FC } from 'react'

interface MenuIconProps {
    path: string
}

const MenuIcon: FC<MenuIconProps> = ({ path }) => {
    return <img className='menu-icon' src={path} alt='icon' />
}

export default MenuIcon
