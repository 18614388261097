const RuleCardLP = (props) => {
    const { name = '', image = null } = props

    return (
        <div className='rule-card-lp-container'>
            <div className='rule-card-lp'>
                <img className='rule-card__img-lp' src={image} alt={name} />
                <h2 className='rule-card__name-lp'>{name}</h2>
            </div>
        </div>
    )
}

export default RuleCardLP
