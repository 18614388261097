import { createSlice } from '@reduxjs/toolkit'

import { fetchDialogs, fetchMessagesByDialogId } from './extraReducers'

const slice = createSlice({
    name: 'dialogs',
    isDialogsLoading: true,
    isMessagesLoading: true,
    initialState: {
        data: [],
        selectedDialog: null
    },
    reducers: {
        selectDialogRoutine: (state, action) => {
            state.selectedDialog = action.payload
        },
        addDialogMessageRoutine: (state, action) => {
            state.data = state.data.map((dialog) => {
                if (dialog.id === action.payload?.dialogId) {
                    let msgs = dialog.messages
                    msgs.push(action.payload)
                    return {
                        ...dialog,
                        messages: msgs
                    }
                }
                return dialog
            })
        }
    },
    extraReducers: {
        [fetchDialogs.fulfilled]: (state, action) => {
            state.data = action.payload
            state.isDialogsLoading = false
        },
        [fetchDialogs.pending]: (state) => {
            state.isDialogsLoading = true
        },
        [fetchDialogs.rejected]: (state) => {
            state.isDialogsLoading = false
        },

        [fetchMessagesByDialogId.fulfilled]: (state, action) => {
            state.data = state.data.map((dialog) => {
                if (dialog.id === action.payload[0]?.dialogId) {
                    return {
                        ...dialog,
                        messages: action.payload
                    }
                }
                return dialog
            })
            state.isMessagesLoading = false
        },
        [fetchMessagesByDialogId.pending]: (state) => {
            state.isMessagesLoading = true
        },
        [fetchMessagesByDialogId.rejected]: (state) => {
            state.isMessagesLoading = false
        }
    }
})

export const { selectDialogRoutine, addDialogMessageRoutine } = slice.actions

export default slice.reducer
