import { Flex, Switch, Tooltip } from 'antd'
import { FC } from 'react'

interface SwitchTooltipProps {
    onChange: (event: any) => void,
    value: boolean,
    text?: string,
    toolTipText?: string,
}

const SwitchTooltip: FC<SwitchTooltipProps> = ({ onChange, value, text, toolTipText }) => {
    return (
        <div className='simple-checkbox'>
            <Tooltip placement='topLeft' title={toolTipText}>
                <Flex style={{ marginTop: '10px' }}>
                    <Switch onChange={onChange} style={{ marginRight: '10px' }} />
                    <span>{text}</span>
                </Flex>
            </Tooltip>
        </div>
    )
}

export default SwitchTooltip
