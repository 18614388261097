import { FC, useState } from 'react'
import { Input } from 'antd'
import { ID } from '../../../../../shared/types/api'
import { letterValue } from '../utils/GuessWordUtils'

interface LetterProps {
    value: { value: string, realValue: string, id: ID },
    updateUserAnswers: (p: letterValue) => void
}

const Letter: FC<LetterProps> = ({ value, updateUserAnswers }) => {
    const [letter, setLetter] = useState()

    const onChange = (target: any) => {
        let targetInput = target.value
        const newLetter = targetInput.toUpperCase()
        setLetter(newLetter)
        //@ts-ignore
        updateUserAnswers({ letterId: value.id, value: newLetter })
        if (newLetter === value.realValue) {
            moveToNextLetter(target)
        }
    }

    const moveToNextLetter = (target: any) => {
        let targetLetter = target.parentElement
        let nextInput = getNextLetter(targetLetter.nextSibling)
        if (nextInput) {
            nextInput.focus()
        } else {
            let word = targetLetter.parentElement
            let nextWord = word.nextSibling
            while (nextWord) {
                let nextLetter = nextWord.children[0]
                let focusesLetter = getNextLetter(nextLetter)
                if (focusesLetter) {
                    focusesLetter.focus()
                    break
                }
                nextWord = nextWord.nextSibling
            }
        }
    }

    const getNextLetter = (targetLetter: any) => {
        let nextLetter = targetLetter
        while (nextLetter) {
            let nextInput = nextLetter.children[0]
            if (!nextInput.value) {
                return nextInput
            }
            nextLetter = nextLetter.nextSibling
        }
        return null
    }

    const getAdditionClassName = (): string => {
        return letter ? (letter === value.realValue ? 'guess-word__letter-guessed' : 'guess-word__letter-failed') : ''
    }

    return (
        <div className={'guess-word__letter ' + getAdditionClassName()}>
            <Input type='text' defaultValue={value.value ? value.value : ''} value={letter} maxLength={1}
                   onChange={(e) => onChange(e.target)} />
        </div>
    )
}

export default Letter
