import { createAsyncThunk } from '@reduxjs/toolkit'
import * as API_USER from '../../shared/http/user'
import { LoginAndAuthCode, LoginRespDto, LoginUserDto } from '../../shared/types/api'
import { saveToken } from '../memory/memory'

export const fetchCheckSession = createAsyncThunk<number>('global/fetchCheckSession', () => API_USER.checkSession())

export const fetchLogin = createAsyncThunk<LoginRespDto, LoginUserDto>('global/fetchLogin', (user) =>
    API_USER.login(user).then((res) => {
        saveToken(res.token)
        return res
    })
)

export const fetchUpdateNotificationFlag = createAsyncThunk('global/fetchUpdateNotificationFlag', (flagValue: boolean) =>
    API_USER.updateNotificationFlag(Boolean(flagValue), null)
)

export const fetchUpdateNotificationTime = createAsyncThunk('global/fetchUpdateNotificationTime', (time?: number | null) =>
    API_USER.updateNotificationFlag(true, time).then(() => ({ time }))
)

export const fetchAuthByCode = createAsyncThunk<LoginRespDto, LoginAndAuthCode>('global/fetchAuthByCode', (user) =>
    API_USER.authByCode(user).then((res) => {
        saveToken(res.data.token)
        return res.data
    })
)