import {FC} from "react";

interface SuccessBarProps {
    response: any,
    paramName: string,
    title: string
}
const SuccessBar:FC<SuccessBarProps> = ({ response, paramName, title }) => {
    const param = response ? response[paramName] : 0

    return (
        <div className='success-bar'>
            <div className='success-bar__heading'>{title}</div>
            <div className='success-bar__text'>{Math.floor(param)}</div>
        </div>
    )
}

export default SuccessBar