import PropTypes from 'prop-types'

const TableThree = (props) => {
    return (
        <ul className='table-three'>
            {props.elements.map((item, i) => (
                <li key={i}>
                    <span> {item.left} </span>
                    <span> {item.center} </span>
                    <span> {item.right} </span>
                </li>
            ))}
        </ul>
    )
}

export default TableThree

TableThree.propTypes = {
    elements: PropTypes.array.isRequired
}
