import '../../../styles/css/elements/svg_icons/icon-btn.css'

function Speaker(props) {
    return (
        <svg
            onClick={() => (props.onClick ? props.onClick() : false)}
            xmlns='http://www.w3.org/2000/svg'
            className={'icon-btn ' + props.class}
            width='24'
            height='24'
            viewBox='0 0 24 24'
        >
            <title>Сделать список готовым</title>
            <path
                fill='#000'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18 8a3 3 0 010 6M10 8v11a1 1 0 01-1 1H8a1 1 0 01-1-1v-5 M12 8h0l4.524-3.77A.9.9 0 0118 4.922v12.156a.9.9 0 01-1.476.692L12 14H4a1 1 0 01-1-1V9a1 1 0 011-1h8'
            />
        </svg>
    )
}

export default Speaker
