import PropTypes from 'prop-types'

const NumberBlock = (props) => {
    return (
        <div className='number-block'>
            <h3>{props.numberBlock}</h3>
        </div>
    )
}

export default NumberBlock

NumberBlock.propTypes = {
    numberBlock: PropTypes.string.isRequired
}
