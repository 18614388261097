import { useEffect, useState } from 'react'

import { ID, WordList } from '../shared/types/api'
import * as API_STATISTIC from '../shared/http/statistic'
import WordListUtils from '../components/utils/WordListUtils'


const useCardRepeatCardLists = (
    type: string,
    open: boolean,
    cardListId: ID | undefined) => {

    const [repeatSuggestion, setRepeatSuggestion] = useState<Array<WordList>>([])


    useEffect(() => {
        if (open && cardListId) {
            const req = { cardListId: cardListId }
            //@ts-ignore
            API_STATISTIC.saveUserCardStatistic(req).then((res) => {
                setRepeatSuggestion(WordListUtils.parseWorldLists(res as Array<WordList>))
            }).finally(() => {
            })
        } else {
            setRepeatSuggestion([])
        }
        return () => {
        }
    }, [open])

    return { repeatSuggestion }
}

export default useCardRepeatCardLists