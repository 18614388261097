import { NavigationPages } from '../../common/navigation/navigation-pages'
import { NavigateFunction } from 'react-router/dist/lib/hooks'
import { WordList } from '../../shared/types/api'

export const goToPage = (path: string, isPremium: boolean, userPremium: boolean, navigate: NavigateFunction) => {
    if (isPremium) {
        userPremium ? navigate(path) : navigate(NavigationPages.PREMIUM)
    } else {
        navigate(path)
    }
}

export const goToPageNotPremium = (path: string, navigate: NavigateFunction) => {
    navigate(path)
}

export const goToPageNotPremiumWithState = (path: string, state: any, navigate: NavigateFunction) => {
    navigate(path)
}

export const goToExercisePage = (path: string, navigate: NavigateFunction, cardList: WordList) => {
    navigate(path, {
        state: {
            cardList: cardList
        }
    })
}

export const goToExercisePageWithReloading = (path: string, navigate: NavigateFunction, cardList: WordList) => {
    navigate(path, {
        state: {
            cardList: cardList
        }
    })
    navigate(0)
}