import PropTypes from 'prop-types'

const IconCopy = (props: { onClick?: (() => void) }) => {
    const { onClick = () => null } = props
    return (
        <svg
            onClick={onClick}
            xmlns='http://www.w3.org/2000/svg'
            width='30'
            height='30'
            fill='none'
            stroke='#000'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            className='icon icon-tabler icon-tabler-copy pointer-cursor'
            viewBox='0 0 24 24'
        >

            <path stroke='none' d='M0 0h24v24H0z'></path>
            <path d='M8 10a2 2 0 012-2h8a2 2 0 012 2v8a2 2 0 01-2 2h-8a2 2 0 01-2-2z'></path>
            <path d='M16 8V6a2 2 0 00-2-2H6a2 2 0 00-2 2v8a2 2 0 002 2h2'></path>
        </svg>

    )
}
export default IconCopy

IconCopy.propTypes = {
    onClick: PropTypes.func
}