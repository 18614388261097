import '../../../styles/css/elements/svg_icons/icon-btn.css'

function IconYoutube(props) {
    const { className = '', onClick = () => null, stroke = '#000000' } = props

    return (

        <svg
            onClick={onClick}
            stroke={stroke}
            className={'icon icon-tabler icon-tabler-brand-telegram ' + className}
            width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_371_53)">
                <path d="M33.3828 3.74333C33.187 3.01981 32.8051 2.36019 32.2751 1.83017C31.7451 1.30015 31.0855 0.918215 30.362 0.7224C27.7132 0 17.0527 0 17.0527 0C17.0527 0 6.39162 0.0218666 3.74282 0.744267C3.01929 0.940093 2.35968 1.32205 1.82968 1.85209C1.29968 2.38214 0.917781 3.04178 0.722019 3.76533C-0.0791813 8.47173 -0.389981 15.6432 0.744019 20.1613C0.939802 20.8849 1.32171 21.5445 1.85171 22.0745C2.3817 22.6045 3.0413 22.9865 3.76482 23.1823C6.41362 23.9047 17.0744 23.9047 17.0744 23.9047C17.0744 23.9047 27.7351 23.9047 30.3838 23.1823C31.1073 22.9865 31.7669 22.6045 32.2969 22.0745C32.827 21.5445 33.2089 20.8849 33.4047 20.1613C34.2498 15.4483 34.5102 8.2812 33.3828 3.74333Z" fill="#FF0000"/>
                <path d="M13.6595 17.0749L22.5032 11.9525L13.6595 6.83008V17.0749Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_371_53">
                    <rect width="34.14" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
)
}

export default IconYoutube
