import '../../styles/css/dictionary/modalwindow/modalw.css'
import {Button, Input, Modal} from 'antd'
import {FC, useState} from 'react'

interface LoginCodeDialogProps {

    closeDialog: () => void,
    open: boolean;
}

const LoginCodeDialog: FC<LoginCodeDialogProps> = ({closeDialog, open}) => {

    const [login, setLogin] = useState<string>('')
    const [code, setCode] = useState<string>('')

    const handleChangeLogin = (event: any) => {
        setLogin(event.target.value)
    }

    const handleChangeCode = (event: any) => {
        setCode(event.target.value)
    }

    const authByCode = () => {
        window.open(`https://geekenglish.ru/login-by-code?login=${login}&code=${code}`, `_self`)
    }

    return (
        <Modal title='Вход по коду:'
               onCancel={closeDialog}
               footer={[
                   <Button key='submit' onClick={closeDialog}>
                       Отмена
                   </Button>,
                   <Button
                       onClick={authByCode}
                       type='primary'
                       disabled={login === '' || code === ''}
                   >
                       Войти
                   </Button>
               ]}
               open={open}>
            <div>1. Перейдите в профиль в мобильном приложении</div>
            <div>2. Нажмите на кнопку "Меню" -- "Получить код авторизации"</div>
            <div>3. Заполните поля ниже</div>
            <div className='modal-input-container'>

                <Input value={login}
                       size='large'
                       onChange={(e) => handleChangeLogin(e)}
                       placeholder='Введите логин'
                       maxLength={50}
                />
                <Input value={code}
                       size='large'
                       onChange={(e) => handleChangeCode(e)}
                       placeholder='Введите код'
                       maxLength={50}
                />
            </div>
        </Modal>
    )
}

export default LoginCodeDialog