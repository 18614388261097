import { useEffect, useState } from 'react'

import { ID, WordDto, WordList } from '../shared/types/api'
import * as API_STATISTIC from '../shared/http/statistic'
import WordListUtils from '../components/utils/WordListUtils'


const useTestRepeatCardLists = (
    amountWordsInTest: number,
    rightAnswers: number,
    wrongAnswers: Array<WordDto>,
    open: boolean,
    cardListId: ID | undefined) => {

    const [repeatSuggestion, setRepeatSuggestion] = useState<Array<WordList>>([])


    useEffect(() => {
        if (open && cardListId) {
            let data = {
                countWords: amountWordsInTest,
                countRightAnswers: rightAnswers,
                selectedWordListId: parseInt(cardListId as string),
                wrongWords: wrongAnswers
            }
            //@ts-ignore
            API_STATISTIC.saveUserTestStatistic(data).then((res) => {
                setRepeatSuggestion(WordListUtils.parseWorldLists(res as Array<WordList>))
            }).finally(() => {
            })
        } else {
            setRepeatSuggestion([])
        }
        return () => {
        }
    }, [open])

    return { repeatSuggestion }
}

export default useTestRepeatCardLists