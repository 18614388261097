import { Modal } from 'antd'
import Pages from '../../const/Pages'
import * as API_STATISTIC from '../../shared/http/statistic'

const NewCommerceUtil = {
    showNewCommerceModal: function(user, pageName) {
        if (!user) {
            return false
        }
        if (this.checkUserIsNew(user, pageName)) {
            switch (pageName) {
                case Pages.DICTIONARY:
                    this.showDictionaryPrompt()
                    break
                case Pages.CARD:
                    this.showCardPrompt()
                    break
                case Pages.TEST:
                    this.showTestPrompt()
                    break
                case Pages.FILL_GAPS:
                    this.showFillGapsPrompt()
                    break
                case Pages.DONE_WORDLIST:
                    this.showDoneWordsPrompt()
                    break
                case Pages.FILL_WORD:
                    this.showFillWordsPrompt()
                    break
                default:
                    break
            }
            return this.updateStatisticField(user, pageName)
        }
        return false
    },

    checkUserIsNew: function(user, pageName) {
        return user.isNew && user.statistic && !user.statistic[pageName]
    },

    showTestPrompt: function() {
        const t = {
            title: 'Тесты',
            content:
                'На данной странице вы сможете учить ваши списки из словаря с помощью тестов.' +
                ' Просто нажмите на кнопку "Выберите список карточек".'
        }
        Modal.info(t)
    },

    showCardPrompt: function() {
        const t = {
            title: 'Карточки',
            content:
                'На данной странице вы сможете учить ваши списки из словаря с помощью карточек.' +
                ' Просто нажмите на кнопку "Выберите список карточек".'
        }
        Modal.info(t)
    },

    showDictionaryPrompt: function() {
        const t = {
            title: 'Словарь',
            content:
                'Страница словарь содержит списки карточек с вашими словами/фразами.' +
                ' Тут вы сможете добавлять, редактировать карточки. ' +
                'Все эти списки отображаются на страницах для изучения слов. Чтобы добавить свой список нажмите' +
                ' на кнопку "Добавить список".'
        }
        Modal.info(t)
    },

    showFillWordsPrompt: function() {
        const t = {
            title: 'Филворды',
            content:
                'На данной странице вы сможете построить филворд из ваших списков.' +
                ' Просто нажмите на кнопку "Выберите список карточек". Для подсказки нажмите на значок глаза.'
        }
        Modal.info(t)
    },

    showFillGapsPrompt: function() {
        const t = {
            title: 'Заполни пропуски',
            content:
                'На данной странице вы сможете выучить заполняя пропуски. ' +
                ' Просто нажмите на кнопку "Выберите список карточек". Для подсказки нажмите на значок глаза.'
        }
        Modal.info(t)
    },

    showDoneWordsPrompt: function() {
        const t = {
            title: 'Готовые списки',
            content:
                'Страница содержит подборки списков по разным тематикам.' +
                ' Кнопка "Добавить список" позволяет добавить список в словарь, после чего его нельзя редактировать. ' +
                ' Кнопка "Копировать список" позволяет добавить список в словарь, после чего его можно редактировать.'
        }
        Modal.info(t)
    },


    updateStatisticField: function(user, pageName) {
        API_STATISTIC.updateNewCommerceStatistic(pageName)
        let copy = Object.assign({}, user)
        let copyS = Object.assign({}, copy.statistic)
        copyS[pageName] = true
        copy.statistic = copyS
        return copy
    }
}

export default NewCommerceUtil
