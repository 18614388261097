import { Flex, Tag } from 'antd'
import { GEButton } from '../../../../common/buttons'
import { FC, useState } from 'react'
import * as API_COMMUNITY from '../../../../shared/http/community'
import { CommunityDto } from '../../../../shared/types/api'
import UpdateCommunityDialog from '../../../modalwindows/UpdateCommunityDialog'
import { CommunityType } from '../../../../const/CommunityType'
import NotificationsUtils from '../../../utils/NotificationsUtils'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { communitiesDataSelector, setCommunitiesRoutine } from '../../../../store/communities'
import HeartIcon from '../../../elements/svg_icons/HeartIcon'
import HeartFilledIcon from '../../../elements/svg_icons/HeartFilledIcon'
import CommunityUtils from '../../../utils/CommunityUtils'
import { handleHttpError } from '../../../../common/utils/errors'
import { useNavigate } from 'react-router-dom'

interface CommunityItemProps {
    item: CommunityDto
    i: number
    userHas: boolean
    reload?: () => void
    canEdit?: boolean,
    updateCommunity: (c: CommunityDto) => void
}

export const CommunityItem: FC<CommunityItemProps> = ({
                                                          item,
                                                          i,
                                                          userHas,
                                                          reload,
                                                          canEdit = false,
                                                          updateCommunity
                                                      }) => {

    const [showEditCommunity, setShowEditCommunity] = useState<boolean>(false)
    const [joinLoading, setJoinLoading] = useState<boolean>(false)
    const [leaveLoading, setLeaveLoading] = useState<boolean>(false)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const communities = useAppSelector<Array<CommunityDto>>(communitiesDataSelector)

    const closeShowEditCommunity = () => {
        setShowEditCommunity(false)
        reload?.()
    }

    const copyCommunityId = () => {
        navigator.clipboard
            .writeText(item.inviteKey)
            .then(() => {
                NotificationsUtils.openSuccessNotification('Ключ для подключения скопирован!')
            })
            .catch(() => {
                NotificationsUtils.openErrorNotification('Не удалось скопировать')
            })
    }

    const likedOrDislikedCommunity = () => {
        API_COMMUNITY.likedOrDislikedCommunity(item.id).then(resp => {
            const communityDto = CommunityUtils.parseCommunity(resp.data)
            communityDto.canEdit = item.canEdit
            updateCommunity(communityDto)
        })
    }

    const joinToCommunity = () => {
        setJoinLoading(true)
        API_COMMUNITY.joinToCommunity({ inviteKey: item.inviteKey })
            .then(c => {
                const rData = c
                if (!communities.some(uc => uc.id === rData.id)) {
                    dispatch(setCommunitiesRoutine([...communities, rData]))
                }
            })
            .catch((err) => {
                handleHttpError(err, navigate)
            })
            .finally(() => {
                setJoinLoading(false)
                reload?.()
            })
    }

    const leaveCommunity = () => {
        setLeaveLoading(true)
        API_COMMUNITY.leaveCommunity(item.id)
            .then(() => {
                dispatch(setCommunitiesRoutine(communities.filter(c => c.id !== item.id)))
            })
            .catch(() => {
                NotificationsUtils.openErrorNotification('Не удалось покинуть сообщество')
            })
            .finally(() => {
                setLeaveLoading(false)
                reload?.()
            })
    }

    return (
        <div key={i} className='communities__main__item'>
            <div className='communities__main__item--header'>
                <h3>{item.name}</h3>
            </div>
            <div className='communities__main__item--main'>
                <img src='../images/community/community.png' alt='Logo' />
                <span>{item.description}</span>
            </div>
            <div className='communities__main__item--footer'>
                {item.tags && item.tags.map((tag, key) => (
                    <Tag key={key} color='magenta'>{tag}</Tag>
                ))}
                <div className='communities__main__item--footer__btns'>
                    <Flex align='center' gap={2}>
                        {item.hasLike ? <HeartFilledIcon onClick={likedOrDislikedCommunity} /> :
                            <HeartIcon onClick={likedOrDislikedCommunity} />}
                        <div className='communities__main__item--footer__icon-text'>{item.likes ? item.likes : 0}</div>
                    </Flex>
                    {!userHas && <GEButton loading={joinLoading} type='primary' onClick={joinToCommunity}>Вступить в
                        сообщество</GEButton>}
                    <Flex gap={5}>
                        {item.type === CommunityType.PRIVATE &&
                        <GEButton onClick={copyCommunityId} type={'primary'}>Поделиться</GEButton>}
                        {userHas &&
                        <GEButton loading={leaveLoading} onClick={leaveCommunity} danger>Покинуть</GEButton>}
                        {userHas && canEdit &&
                        <GEButton onClick={() => setShowEditCommunity(true)}>Редактировать</GEButton>}
                    </Flex>
                </div>
            </div>
            <UpdateCommunityDialog showWindow={showEditCommunity}
                                   cancelWindow={closeShowEditCommunity}
                                   community={item} />
        </div>
    )
}