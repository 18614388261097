import { executeJsonGetMethod, executePutMethod } from '../api/rest/restApiUtils'

/**
 * Получение пользователей с их привилегиями
 * @method getAllPrivilegesForAllUsers
 * @return {Response} Fetch Response
 */
export function getAllPrivilegesForAllUsers() {
    return executeJsonGetMethod('/privilege').then((resp) => {
        return resp.data
    })
}

/**
 * Изменение пользователей с их привилегиями
 * @method getAllPrivilegesForAllUsers
 * @return {Response} Fetch Response
 */
export function updatePrivilegesToUser(user) {
    return executePutMethod('/privilege', user).then((resp) => {
        return resp.data
    })
}
