const MotivationalQuotesUtil = {
    generateRandomMotivationQuote: function() {
        let quotes = [
            'Ничто так не заразительно, как энтузиазм. (Сэмюэл Кольридж)',
            'В учении нельзя останавливаться. (Сюнь-цзы)',
            'Знания, за которые платят, запоминаются лучше. (Рабби Нахман)',
            'Лучше весь век учиться, нежели пребыть незнающим. (Суворов А. В.)',
            'Учиться надо всю жизнь, до последнего дыхания! (Сюнь-Цзы)',
            'Никто по-настоящему никогда не перестаёт учиться. (Иоганн Гёте)',
            'Учитесь так, словно вы постоянно ощущаете нехватку своих знаний, и так, словно вы постоянно боитесь растерять свои знания. (Конфуций)',
            'Я никогда не учу своих учеников. Я только даю условия, при которых они могут сами учиться. (А. Эйнштейн)'
        ]
        return quotes[Math.floor(Math.random() * quotes.length)]
    }
}
export default MotivationalQuotesUtil
