import '../../../styles/css/main_page/footer.css'
import IconQiwi from '../../elements/svg_icons/IconQiwi'
import PropTypes from 'prop-types'
import IconPlayStore from '../../elements/svg_icons/IconPlayStore'
import Constants from '../../../const/Constants'

const Footer = (props) => {
    return (
        <div className={'main_footer ' + props.className}>
            <div>
                <br />
                <a href={Constants.GOOGLE_PLAY_LINK}>
                    <IconPlayStore />
                </a>
                <br />
                Поддержать проект
                <br />
                <a href='https://qiwi.com/n/JUSSU542' aria-hidden='true' className='margin10top'>
                    <IconQiwi />
                </a>
                <br />
                Остались вопросы и предложения?
                <br />
                GeekEnglish@yandex.com
                <br />
                Тех поддержка:
                <br />
                GeekEnglishTech@yandex.com
            </div>
        </div>
    )
}

Footer.propTypes = {
    className: PropTypes.string
}

export default Footer
