import { createAsyncThunk } from '@reduxjs/toolkit'
import * as API_COMMUNITY from '../../shared/http/community'
import { CommunityType } from '../../const/CommunityType'

export const fetchCommunities = createAsyncThunk('communities/fetchAll', () => API_COMMUNITY.getCommunities())

export const createCommunity = createAsyncThunk('worldLists/createWordList', ({
                                                                                  name,
                                                                                  description,
                                                                                  type,
                                                                                  tags
                                                                              }: { name: string, description: string, type: CommunityType, tags: Array<string> }) =>
    API_COMMUNITY.create(name, description, type, tags)
)
