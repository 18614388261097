import PropTypes from 'prop-types'

const VeryImportantWords = (props) => {
    return (
        <div className='very-important-words'>
            {props.veryImportantWords.map((item, i) => (
                <u key={i}> {item} </u>
            ))}
        </div>
    )
}

export default VeryImportantWords

VeryImportantWords.propTypes = {
    veryImportantWords: PropTypes.array.isRequired
}
