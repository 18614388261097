export const test = [
    {
        id: 1,
        title: 'Tomas ____ morning exercises',
        variants: [
            { id: 1, title: 'do', isTrue: false },
            { id: 2, title: 'does', isTrue: true },
            { id: 3, title: 'is', isTrue: false },
            { id: 4, title: 'are', isTrue: false }
        ]
    },
    {
        id: 2,
        title: 'Nikita ____ after dinner',
        variants: [
            { id: 1, title: 'sleep', isTrue: false },
            { id: 2, title: 'sleeps', isTrue: true },
            { id: 3, title: 'sleeping', isTrue: false },
            { id: 4, title: 'sleepes', isTrue: false }
        ]
    },
    {
        id: 3,
        title: 'Peter and his friends ___ to school by bus',
        variants: [
            { id: 1, title: 'go', isTrue: true },
            { id: 2, title: 'goes', isTrue: false },
            { id: 3, title: 'is', isTrue: false },
            { id: 4, title: 'are', isTrue: false }
        ]
    },
    {
        id: 4,
        title: "David's father  ____ in a hospital",
        variants: [
            { id: 1, title: 'work', isTrue: false },
            { id: 2, title: 'works', isTrue: true },
            { id: 3, title: 'is', isTrue: false },
            { id: 4, title: 'am', isTrue: false }
        ]
    },
    {
        id: 5,
        title: '___ they ____ them every day?',
        variants: [
            { id: 1, title: 'Does, watch', isTrue: false },
            { id: 2, title: 'Do, watches', isTrue: false },
            { id: 3, title: 'Does, watch', isTrue: false },
            { id: 4, title: 'Do, watch', isTrue: true }
        ]
    },
    {
        id: 6,
        title: '___ you ___ French?',
        variants: [
            { id: 1, title: 'Do, speak', isTrue: true },
            { id: 2, title: 'Does, speaks', isTrue: false },
            { id: 3, title: 'Do, speaks', isTrue: false },
            { id: 4, title: 'Does, speak', isTrue: false }
        ]
    },
    {
        id: 7,
        title: '___ he ___ any brothers or sisters?',
        variants: [
            { id: 1, title: 'Do, have', isTrue: false },
            { id: 2, title: 'Do, has', isTrue: false },
            { id: 3, title: 'Does, have', isTrue: true },
            { id: 4, title: 'Does, has', isTrue: false }
        ]
    },
    {
        id: 8,
        title: 'You ___ more spelling practice',
        variants: [
            { id: 1, title: 'don’t need', isTrue: true },
            { id: 2, title: 'doesn’t need', isTrue: false },
            { id: 3, title: 'don’t needs', isTrue: false },
            { id: 4, title: 'doesn’t needs', isTrue: false }
        ]
    }
]
