import { executeJsonGetMethod, executeJsonPostMethod } from '../api/rest/restApiUtils'
import { CreateGoalDto } from '../types/api'

/**
 * Получить цель пользователя
 * @method getGoal
 */
export function getGoal() {
    return executeJsonGetMethod('/goal')
}

/**
 * Создание цели пользователя
 * @method createGoal
 * @param {object} goal
 */
export function createGoal(goal: CreateGoalDto) {
    return executeJsonPostMethod('/goal', goal)
}