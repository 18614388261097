import PropTypes from 'prop-types'
import RuleTest from './RuleTest'
import { NavigationPages } from '../../../../common/navigation/navigation-pages'
import { GEButton } from '../../../../common/buttons'
import { useAppSelector } from '../../../../store/hooks'
import { userSelector } from '../../../../store/global'
import { useNavigate } from 'react-router-dom'
import { goToPageNotPremium } from '../../../utils/goToPage'

const RuleTestWrapper = (props) => {
    const { value = [], rule } = props

    const navigate = useNavigate()
    const user = useAppSelector(userSelector)

    if (user) {
        return <RuleTest value={value} rule={rule} />
    }

    const goToMain = () => goToPageNotPremium(NavigationPages.LANDING, navigate)

    return (
        <>
            <hr />
            <h4>Желаете проверить свои знания? Зарегистрируйтесь, чтобы пройти тест!!</h4>
            <GEButton onClick={goToMain}>Регистрация</GEButton>
        </>
    )
}

export default RuleTestWrapper

RuleTest.propTypes = {
    value: PropTypes.array.isRequired,
    rule: PropTypes.object.isRequired,
    user: PropTypes.object
}
