import {FC} from 'react'

interface PlayIconProps {
    className?: string,
    onClick?: () => void
}

export const PlayIcon: FC<PlayIconProps> = ({className = '', onClick}) => {

    return (
        <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M31.5 3.9375C26.0487 3.9375 20.7197 5.55401 16.1871 8.58262C11.6545 11.6112 8.12172 15.9159 6.03558 20.9523C3.94944 25.9887 3.40361 31.5306 4.46712 36.8772C5.53062 42.2238 8.1557 47.1349 12.0104 50.9896C15.8651 54.8443 20.7762 57.4694 26.1228 58.5329C31.4694 59.5964 37.0113 59.0506 42.0477 56.9644C47.0841 54.8783 51.3888 51.3455 54.4174 46.8129C57.446 42.2803 59.0625 36.9513 59.0625 31.5C59.0625 24.19 56.1586 17.1793 50.9896 12.0104C45.8207 6.8414 38.81 3.9375 31.5 3.9375ZM46.1613 33.262L22.5363 45.0745C22.2361 45.2246 21.9025 45.2953 21.5672 45.2801C21.2319 45.265 20.9061 45.1643 20.6206 44.9878C20.3352 44.8112 20.0996 44.5646 19.9363 44.2714C19.773 43.9782 19.6874 43.6481 19.6875 43.3125V19.6875C19.6877 19.352 19.7736 19.0222 19.937 18.7293C20.1005 18.4363 20.3361 18.19 20.6215 18.0137C20.9069 17.8374 21.2326 17.7369 21.5677 17.7218C21.9028 17.7067 22.2362 17.7775 22.5363 17.9274L46.1613 29.7399C46.4879 29.9036 46.7625 30.1549 46.9544 30.4658C47.1463 30.7766 47.2479 31.1347 47.2479 31.5C47.2479 31.8653 47.1463 32.2234 46.9544 32.5342C46.7625 32.8451 46.4879 33.0964 46.1613 33.2601"
                fill="#4174F8"/>
        </svg>


    )
}
