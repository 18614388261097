import {Button, Flex, Input, Modal, Select, Switch} from 'antd'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {FC} from 'react'
import {userSelector} from "../../store/global";
import NotificationsUtils from "../utils/NotificationsUtils";
import UserUtil from "../utils/UserUtil";
import DateTimeUtils from "../utils/DateTimeUtils";
import {fetchUpdateNotificationFlag, fetchUpdateNotificationTime} from "../../store/global/extraReducers";

interface GetNotificationsInProfileProps {
    isOpen: boolean,
    close: () => void
}

const GetNotificationsInProfile: FC<GetNotificationsInProfileProps> = ({isOpen, close}) => {
    const currentUser = useAppSelector(userSelector)
    const dispatch = useAppDispatch()
    const updateNotificationTime = (time: any) => dispatch(fetchUpdateNotificationTime(time))
    const updateNotificationFlag = (flagValue: any) => dispatch(fetchUpdateNotificationFlag(flagValue))


    const clearAndClose = () => {
        close()
    }

    const changeNotificationTime = (time: any) => {
        updateNotificationTime(time).catch(() => {
            NotificationsUtils.openErrorNotification('Не удалось обновить время для уведомлений')
        })
    }
    const changeNotificationValue = () => {
        updateNotificationFlag(!UserUtil.getNotificationFlag(currentUser)).catch(() => {
            NotificationsUtils.openErrorNotification('Не удалось обновить флаг для уведомлений')
        })
    }

    return (
        <Modal title='Вы хотите получать уведомления?'
               onCancel={clearAndClose}
               footer={[
                   <Button key='submit' onClick={clearAndClose}>
                       Отмена
                   </Button>,
                   <Button
                       onClick={clearAndClose}
                       type='primary'
                   >
                       Сохранить
                   </Button>
               ]}
               open={isOpen}>

            <Flex gap={25} align='center' justify='center'>
                <Switch
                    checked={UserUtil.getNotificationFlag(currentUser)}
                    onChange={changeNotificationValue}
                />
                <Select
                    onChange={changeNotificationTime}
                    disabled={!UserUtil.getNotificationFlag(currentUser)}
                    defaultValue={UserUtil.getUserNotificationTime(currentUser)}
                    style={{width: 100}}
                    options={DateTimeUtils.getAllTimesArray()}
                />
            </Flex>
        </Modal>
    )
}
export default GetNotificationsInProfile
