import RulesHeader from '../../blocks/RulesHeader'
import NumberBlock from '../../blocks/NumberBlock'
import TaskExample from '../../blocks/TaskExample'
import Example from '../../blocks/Example'
import FactRule from '../../blocks/FactRule'
import TableThree from '../../blocks/TableThree'
import RuleTestWrapper from '../../RuleTestWrapper'
import { test } from './test'

const PastPerfectContinuous = (props) => {
    return (
        <div>
            <RulesHeader title='Past Perfect Continuous' subtitle='Прошедшее совершенное длительное время' />

            <NumberBlock numberBlock='1' />
            <TaskExample taskExample='Изучите следующую ситуацию:' />
            <Example
                engExample={['I ', <u>had been typing</u>, ' this rule for 2 hours and then found it on the Internet.']}
                rusExample='Я набирал это правило два часа, а потом нашел его в Интернете.'
            />
            <p>
                {' '}
                Мы используем Past Perfect Continuous когда указываем на действие, которое началось в прошлом,
                продолжалось в течение некоторого времени и либо закончилось непосредственно перед неким моментом в
                прошлом или все еще не закончилось к некоему моменту в прошлом.
            </p>
            <FactRule factRule='Had + been + 1-я форма правильного глагола-ing это the past perfect continuous.' />

            <Example
                engExample={['Where have you been? ', <u>I’ve been looking</u>, ' for you everywhere.']}
                rusExample={['Где ты был? Я тебя везде ', <u>искал</u>, '.']}
            />

            <NumberBlock numberBlock='2' />
            <p>
                {' '}
                Для того, чтобы поставить глагол в форму времени Past Perfect Continuous, требуется вспомогательный
                глагол <b>to be</b> во времени Past Perfect и причастие настоящего времени (форма V-ing) смыслового
                глагола.
                <b>To be</b> во времени Past Perfect имеет единственную форму <b>had been</b>. Причастие настоящего
                времени можно получить, прибавив к начальной форме значимого глагола окончание <b>-ing</b>.
            </p>
            <TableThree
                elements={[{ left: 'I/he/they', center: 'had been', right: '1-я форма правильного глагола+ing' }]}
            />
            <Example
                engExample={[
                    'I ',
                    <u>had been</u>,
                    ' waiting for his airplane for 2 hours when it was announced about delay.'
                ]}
                rusExample={['Я ', <u>ждал</u>, ' его самолет уже два часа, когда объявили о его задержке.']}
            />
            <p>
                {' '}
                В вопросительном предложении вспомогательный глагол <b>had</b> выносится на место перед подлежащим, а
                остальная часть сказуемого располагается после него.
            </p>
            <TableThree
                elements={[{ left: 'Had been', center: 'I/he/they', right: '1-я форма правильного глагола+ing' }]}
            />
            <Example
                engExample={['I saw many puddles. ', <u>Had</u>, ' it ', <u>been raining</u>, '?']}
                rusExample={['Я видел много луж. ', <u>Шел</u>, ' дождь?']}
            />
            <p>
                {' '}
                В отрицательных предложениях за вспомогательным глаголом <b>had</b> следует отрицательная частица{' '}
                <b>not</b>.
            </p>
            <TableThree
                elements={[{ left: 'I/he/they', center: 'had not been', right: '1-я форма правильного глагола+ing' }]}
            />
            <Example
                engExample={[
                    'Of course he did not want to sleep! ',
                    <u>He had not been working</u>,
                    ' like a horse all the week.'
                ]}
                rusExample={['Разумеется, ему не хотелось спать! ', <u>Он-то не работал</u>, ' как лошадь всю неделю.']}
            />

            <NumberBlock numberBlock='3' />
            <p> Мы используем Past Perfect Continuous в следующих ситуациях:</p>
            <FactRule factRule='Действие, которое началось в прошлом, продолжалось в течение некоторого времени и закончилось непосредственно перед определенным моментом в прошлом.' />
            <Example
                engExample={['We ', <u>had been working</u>, ' hard and managed to finish the project in time.']}
                rusExample={['Мы упорно ', <u>работали</u>, ' и смогли завершить проект вовремя.']}
            />
            <FactRule factRule='Действие, которое началось в прошлом, продолжалось в течение некоторого времени и все еще продолжалось в определенный момент в прошлом.' />
            <Example
                engExample={[
                    'Her voice started to tremble. I did not realize what a hard time she ',
                    <u>had been having</u>,
                    '.'
                ]}
                rusExample={['Ее голос задрожал. Я и не представлял, как тяжело ей ', <u>приходилось</u>, '.']}
            />

            <RuleTestWrapper value={test} rule={props} />
        </div>
    )
}

export default PastPerfectContinuous
