export const test = [
    {
        id: 1,
        title: 'He ____ his work yesterday, thanks God finally he can take a break and relax.',
        variants: [
            { id: 1, title: 'finished', isTrue: true },
            { id: 2, title: 'had finished', isTrue: false },
            { id: 3, title: 'was finished', isTrue: false },
            { id: 4, title: 'has finished', isTrue: false }
        ]
    },
    {
        id: 2,
        title: 'Last year Bill ____ to become a pilot. This year he wants to be a doctor or a writer.',
        variants: [
            { id: 1, title: 'had wanted', isTrue: false },
            { id: 2, title: 'has wanted', isTrue: false },
            { id: 3, title: 'want', isTrue: false },
            { id: 4, title: 'wanted', isTrue: true }
        ]
    },
    {
        id: 3,
        title: 'What ____ you do a minute ago? I saw you rummaging about among the pile of my dresses.',
        variants: [
            { id: 1, title: 'were', isTrue: false },
            { id: 2, title: 'had', isTrue: false },
            { id: 3, title: 'did ', isTrue: true },
            { id: 4, title: 'have', isTrue: false }
        ]
    },
    {
        id: 4,
        title: 'Last night they _____ about the fire in order to discuss the plans for the future.',
        variants: [
            { id: 1, title: 'gathered', isTrue: true },
            { id: 2, title: 'have gathered', isTrue: false },
            { id: 3, title: 'did gather', isTrue: false },
            { id: 4, title: 'will gather', isTrue: false }
        ]
    },
    {
        id: 5,
        title: 'We _____ him the day before yesterday, he was very depressed about something.',
        variants: [
            { id: 1, title: 'have seen', isTrue: false },
            { id: 2, title: 'seen', isTrue: false },
            { id: 3, title: 'saw', isTrue: true },
            { id: 4, title: 'seed', isTrue: false }
        ]
    },
    {
        id: 6,
        title: 'Her son ____ the window, therefore we must buy the other windowpane.',
        variants: [
            { id: 1, title: 'broke', isTrue: true },
            { id: 2, title: 'breaked', isTrue: false },
            { id: 3, title: 'has broken', isTrue: false },
            { id: 4, title: 'had break', isTrue: false }
        ]
    },
    {
        id: 7,
        title: 'Where _____ you last week? Did you attend the lectures?',
        variants: [
            { id: 1, title: 'be', isTrue: false },
            { id: 2, title: 'was', isTrue: false },
            { id: 3, title: 'were', isTrue: true },
            { id: 4, title: 'have been', isTrue: false }
        ]
    },
    {
        id: 8,
        title: 'When _____ they think good to leave for China? Was it in 1999 or in 2000?',
        variants: [
            { id: 1, title: 'were', isTrue: false },
            { id: 2, title: 'are', isTrue: false },
            { id: 3, title: 'do', isTrue: false },
            { id: 4, title: 'did', isTrue: true }
        ]
    },
    {
        id: 9,
        title: 'The other day she _____ on the door just to greet us with her beautiful smile.',
        variants: [
            { id: 1, title: 'had knocked', isTrue: false },
            { id: 2, title: 'has knocked', isTrue: false },
            { id: 3, title: 'was knocking', isTrue: false },
            { id: 4, title: 'knocked', isTrue: true }
        ]
    },
    {
        id: 10,
        title: 'How long ago _____ you understand the meaning of life?',
        variants: [
            { id: 1, title: 'were', isTrue: false },
            { id: 2, title: 'had', isTrue: false },
            { id: 3, title: 'did', isTrue: true },
            { id: 4, title: 'do', isTrue: false }
        ]
    },
    {
        id: 11,
        title: 'When they ______ , he was baking something delicious.',
        variants: [
            { id: 1, title: 'have returned', isTrue: false },
            { id: 2, title: 'returned', isTrue: true },
            { id: 3, title: 'were returned', isTrue: false },
            { id: 4, title: 'return', isTrue: false }
        ]
    }
]
