const IconCommunityPremium = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='100'
            height='100'
            fill='none'
            viewBox='0 0 100 100'
        >
            <path
                fill='#2F88FF'
                d='M39.395 37.5c2.52-3.05 6.335-5 10.605-5a13.75 13.75 0 11-10.61 5h.005zm29.355 8.75c0-3.16-.78-6.14-2.16-8.75H87.5A7.5 7.5 0 0195 45v2.5c0 7.935-6.03 16.06-16.575 18.92A12.5 12.5 0 0067.5 60h-4.75a18.7 18.7 0 006-13.75zM67.5 65a7.482 7.482 0 017.5 7.5V75c0 9.855-9.3 20-25 20S25 84.855 25 75v-2.5a7.482 7.482 0 017.5-7.5h35zM5 47.5c0 7.935 6.03 16.06 16.575 18.92A12.5 12.5 0 0132.5 60h4.75a18.7 18.7 0 01-6-13.75c0-3.16.78-6.14 2.165-8.75H12.5A7.5 7.5 0 005 45v2.5zm38.75-28.75a13.75 13.75 0 10-27.5 0 13.75 13.75 0 0027.5 0zm40 0a13.75 13.75 0 10-27.5 0 13.75 13.75 0 0027.5 0z'
            ></path>
        </svg>
    )
}

export default IconCommunityPremium