import { FC, useEffect, useReducer } from 'react'
import * as API_USER from '../../../shared/http/user'
import NotificationsUtils from '../../utils/NotificationsUtils'
import { removeToken, saveToken } from '../../../store/memory/memory'

interface TelegramLoginButtonProps {
    botName: string
    buttonSize: string
    cornerRadius: number | undefined
    requestAccess: string,
    usePic: boolean,
    dataAuthUrl: number
    lang: string,
    widgetVersion: number,
    children: undefined | null,
    className: string
}

const TelegramLoginButton: FC<TelegramLoginButtonProps> = ({
                                                               botName = 'GeekEnglishBot',
                                                               buttonSize = 'medium',
                                                               cornerRadius,
                                                               requestAccess = 'write',
                                                               usePic = true,
                                                               dataAuthUrl,
                                                               lang = 'ru',
                                                               widgetVersion = 19,
                                                               children = null,
                                                               className = ''
                                                           }) => {
    // @ts-ignore
    const ref = useReducer()
    const authTelegram = (data: any) => {
        API_USER.authTelegram(data)
            .then((resp) => {
                removeToken()
                saveToken(resp.token)
                window.location.reload()
            })
            .catch(() => {
                NotificationsUtils.openErrorNotification(
                    'Не удалось войти с помощью телеграм, ' +
                    'убедитесь что у вас заполнено имя пользователя в телеграмме!'
                )
            })
    }

    useEffect(() => {
        // @ts-ignore
        window.TelegramLoginWidget = {
            dataOnauth: (user: any) => authTelegram(user)
        }
        const script = document.createElement('script')
        script.src = 'https://telegram.org/js/telegram-widget.js?' + widgetVersion
        script.setAttribute('data-telegram-login', botName)
        script.setAttribute('data-size', buttonSize)
        if (cornerRadius !== undefined) {
            script.setAttribute('data-radius', String(cornerRadius))
        }
        script.setAttribute('data-request-access', requestAccess)
        script.setAttribute('data-userpic', String(usePic))
        script.setAttribute('data-lang', lang)
        if (dataAuthUrl !== undefined) {
            script.setAttribute('data-auth-url', String(dataAuthUrl))
        } else {
            script.setAttribute('data-onauth', 'TelegramLoginWidget.dataOnauth(user)')
        }
        script.async = true
        // @ts-ignore
        ref.current.appendChild(script)
    }, [])

    return (
        // @ts-ignore
        <div className={className} ref={ref}>
            {children}
        </div>
    )
}

export default TelegramLoginButton
