import '../../../styles/css/elements/svg_icons/icon-btn.css'
import { Tooltip } from 'antd'

function IconLock(props) {
    const { className = '' } = props
    return (
        <Tooltip placement='topLeft' title='Доступно для пользователей с премиум аккаунтом'>
            <svg
                style={{position: 'relative', right: '5px'}}
                xmlns='http://www.w3.org/2000/svg'
                width='30'
                height='30'
                fill='none'
                stroke='black'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                className='icon icon-tabler icon-tabler-lock'
                viewBox='0 0 24 24'
            >
                <path stroke='none' d='M0 0h24v24H0z'></path>
                <rect width='14' height='10' x='5' y='11' rx='2'></rect>
                <circle cx='12' cy='16' r='1'></circle>
                <path d='M8 11V7a4 4 0 018 0v4'></path>
            </svg>
        </Tooltip>
    )
}

export default IconLock
