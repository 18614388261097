import '../../../../styles/css/newconsomer/newconsomer.css'
import { Steps } from 'antd'
import { useState } from 'react'

function NewComersSections() {
    const reloadPage = () => {
        window.location.reload()
    }

    const [current, setCurrent] = useState(0)

    const onChange = (value) => {
        setCurrent(value)
    }

    return (
        <>
            <section className='block-content t-block-teal l-block-spacing'>
                <div className='l-contained'>
                    <header className='heading-group'>
                        <h2>Благодарим вас за регистрацию на нашем портале</h2>
                        <p className='subtitle'>Прежде чем приступить, взгляните на процесс добавления слов:</p>
                    </header>
                    <Steps
                        current={current}
                        onChange={onChange}
                        direction='vertical'
                        items={[
                            {
                                title: '1 Переходим в словарь',
                                description: <img className='new-comers-img' src='../images/new_comers/dictionary.png'
                                                  alt='Image' />
                            },
                            {
                                title: '2 - Добавляем список, придумываем название',
                                description: <img className='new-comers-img' src='../images/new_comers/add_list.png'
                                                  alt='Image' />
                            },
                            {
                                title: '3 - Добавляем слова в список',
                                description: <img className='new-comers-img' src='../images/new_comers/add_card.png'
                                                  alt='Image' />
                            },
                            {
                                title: '4 - Начнем? ',
                                description: <button onClick={reloadPage} className='shake-btn'>
                                    Поехали!
                                </button>
                            }
                        ]}
                    />

                </div>
            </section>
        </>
    )
}

export default NewComersSections
