import '../../../styles/css/elements/CheckBox/checkBox.css'
import { Tooltip } from 'antd'
import { FC } from 'react'

interface CheckBoxProps {
    onChange: (event: any) => void,
    value: boolean,
    text?: string,
    toolTipText?: string,
}

const CheckBox: FC<CheckBoxProps> = ({ onChange, value, text, toolTipText }) => {
    return (
        <div className='simple-checkbox'>
            <label className='checkbox'>
                <input type='checkbox' onChange={(e) => onChange(e)} checked={value} />
                <Tooltip placement='topLeft' title={toolTipText}>
                    <div className='checkbox__text'>{text}</div>
                </Tooltip>
            </label>
        </div>
    )
}

export default CheckBox
