import PropTypes from 'prop-types'
import { useState } from 'react'
import '../../../../styles/css/profile_page/info_part.css'
import * as API_USER from '../../../../shared/http/user'
import IconBtnEdit from '../../../elements/svg_icons/IconBtnEdit'
import IconBtnOk from '../../../elements/svg_icons/IconBtnOk'
import IconBtnCancel from '../../../elements/svg_icons/IconBtnCancel'
import NotificationsUtils from '../../../utils/NotificationsUtils'

const InfoPart = (props) => {
    const { name, isSessionUser, value, attribute, user } = props

    const [isEditMode, setEditMode] = useState(false)
    const [newValue, setNewValue] = useState(value)

    const setEditModeOn = () => {
        setEditMode(true)
    }

    const setEditModeOff = () => {
        setEditMode(false)
        setNewValue(value)
    }

    const updateData = () => {
        setEditModeOff()
        const firstName = attribute === 'firstName' ? newValue : user.firstName
        const lastName = attribute === 'lastName' ? newValue : user.lastName
        API_USER.updateProfile(firstName, lastName)
            .then(() => {
                window.location.reload()
            })
            .catch(() => {
                NotificationsUtils.openErrorNotification('Не удалось обновить профиль')
            })
    }

    const handleChange = (event) => {
        setEditModeOn()
        setNewValue(event.target.value)
    }

    return (
        <>
            <span className='profile_info_title'>{name}</span>
            {isSessionUser ? (
                <>
                    <span className={'data ' + (isEditMode ? 'none' : '')}>{value}</span>
                    <input
                        className={'new_data ' + (!isEditMode ? 'none' : '')}
                        type='text'
                        value={newValue}
                        onChange={handleChange}
                    />
                    <div className={'edit_btn ' + (isEditMode ? 'none' : '')}>
                        <IconBtnEdit onClick={setEditModeOn} class='fill-white' />
                    </div>
                    <div className={'cancel ' + (!isEditMode ? 'none' : '')}>
                        <IconBtnOk onClick={updateData} class='fill-green' />
                        <IconBtnCancel onClick={setEditModeOff} class='fill-red' />
                    </div>
                </>
            ) : (
                ''
            )}
        </>
    )
}

export default InfoPart

InfoPart.propTypes = {
    attribute: PropTypes.string.isRequired,
    isSessionUser: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    value: PropTypes.string
}
