import { useAppDispatch, useAppSelector } from '../store/hooks'
import { useEffect, useState } from 'react'
import { fetchCommunityWorldListsById } from '../store/wordLists/extraReducers'
import { CommunityDto, WordList } from '../shared/types/api'
import { wordListsDataSelector } from '../store/wordLists'

const useTreeSelector = (
    selectedWordListId?: number | string,
    communities?: Array<CommunityDto>,
    changeWordList?: (id: number) => void) => {

    const [value, setValue] = useState<string>()
    const wordLists = useAppSelector(wordListsDataSelector)

    const [treeData, setTreeData] = useState<Omit<any, 'label'>[]>([])
    const dispatch = useAppDispatch()

    // @ts-ignore
    const getCommunityWordLists = (cId: number) => dispatch(fetchCommunityWorldListsById(cId))

    useEffect(() => {
        if (selectedWordListId) {
            setValue(selectedWordListId.toString())
        }
        return () => {
        }
    }, [selectedWordListId])

    useEffect(() => {
        createTree()
        return () => {
        }
    }, [wordLists, communities])

    const createTree = () => {
        //Todo upgrate id/value
        const treeCommunities = communities && communities.map((c: CommunityDto) => {
            return { id: c.id + 'COM', pId: 0, value: c.id, title: c.name, isLeaf: false, selectable: false }
        })
        const treeCardLists = wordLists.map((wl: WordList) => {
            return {
                id: wl.id,
                pId: wl.communityId ? wl.communityId + 'COM' : 0,
                value: wl.id,
                title: wl.listName,
                isLeaf: true
            }
        })
        if (treeCommunities) {
            setTreeData([...treeCommunities, ...treeCardLists])
        } else {
            setTreeData(treeCardLists)
        }

    }

    const getParsedCommunity = (id: string) => {
        const comId = id.includes('COM') ? id.substring(0, id.length - 3) : id
        return getCommunityWordLists(parseInt(comId))
    }

    const onLoadData = ({ id }: any) => getParsedCommunity(id).then((res) => {
        const wlArray = res.payload
        const map = wlArray.map((wl: WordList) => {
            return { id: wl.id, pId: id, value: wl.id, title: wl.listName, isLeaf: true }
        })
        //setTreeData(treeData.concat(map))
    })

    const onChange = (newValue: string) => {
        changeWordList && changeWordList(parseInt(newValue))
        setValue(newValue)
    }

    return {
        onChange, onLoadData,
        value,
        treeData
    }
}

export default useTreeSelector