import '../../../../../styles/scss/buttons/add-word-list-btn.scss'
import { GEButton } from '../../../../../common/buttons'
import { openPremiumPage } from '../../../../../common/utils/promocode'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AddCardListDialog from '../../../../modalwindows/AddCardListDialog'
import AddAICardListDialog from '../../../../modalwindows/AddAICardListDialog'
import { MagicIcon } from '../../../../elements/svg_icons/MagicIcon'
import { useAppSelector } from '../../../../../store/hooks'
import { premiumStatusSelector } from '../../../../../store/global'
import { Flex } from 'antd'
import IconPlus from '../../../../elements/svg_icons/IconPlus'

interface AddWordListButtonProps {
    canAddUser: boolean
}

/**
 * AddCommunityWordListButton.tsx аналог
 * @param canAddUser
 * @constructor
 */
const AddWordListButton: FC<AddWordListButtonProps> = ({
                                                           canAddUser
                                                       }) => {
    const navigate = useNavigate()
    const [showAddCardListModal, setShowAddCardListModal] = useState<boolean>(false)
    const [showAddAICardListModal, setShowAddAICardListModal] = useState<boolean>(false)
    const isPremium = useAppSelector(premiumStatusSelector)

    const width = window.innerWidth

    return (
        <Flex gap={8} wrap={'wrap'} className='add-word-list-btn'>
            {canAddUser &&
            <GEButton
                icon={<IconPlus width='18' height='15' viewBox='0 0 18 19' />}
                type='primary' size='large' onClick={() => setShowAddCardListModal(true)}>
                Добавить список
            </GEButton>
            }
            {canAddUser && isPremium &&
            <GEButton
                icon={<MagicIcon />} size='large'
                onClick={() => setShowAddAICardListModal(true)}>
                {width < 735 ? '' : 'Добавить список с ИИ'}
            </GEButton>
            }
            {!canAddUser &&
            <li className='dictionary_left_bar-li-maxAmount' title='У вас максимум доступных списков'>
                Достигнут лимит:
                <GEButton onClick={() => openPremiumPage(navigate)} className={'margin10top'}>Купить
                    премиум</GEButton>
            </li>}
            <AddCardListDialog isOpen={showAddCardListModal}
                               close={() => setShowAddCardListModal(false)} />
            <AddAICardListDialog isOpen={showAddAICardListModal}
                                 close={() => setShowAddAICardListModal(false)} />
        </Flex>
    )
}

export default AddWordListButton
