import { Modal, Typography } from 'antd'
import { FC, useEffect, useState } from 'react'
import { GEButton } from '../../common/buttons'
import * as API_USER from '../../shared/http/user'

interface SuccessRegistrationModalProps {
    isVisibleSuccessModal: boolean,
    closeSuccessModal: () => void,
    registeredUserEmail: string,
    containerId: string
}

const { Paragraph } = Typography

export const SuccessRegistrationModal: FC<SuccessRegistrationModalProps> = ({
                                                                                isVisibleSuccessModal,
                                                                                closeSuccessModal,
                                                                                registeredUserEmail,
                                                                                containerId
                                                                            }) => {

    const [disableEmailSend, setDisableEmailSend] = useState(true)

    useEffect(() => {
        unDisableEmailSend()
    }, [])

    const unDisableEmailSend = () => {
        setDisableEmailSend(true)
        setTimeout(() => {
            setDisableEmailSend(false)
        }, 10000)
    }

    const sendVerificationEmailRepeated = (email: string) => {
        unDisableEmailSend()
        API_USER.sendVerificationEmailRepeated(email)
    }


    return (
        <Modal
            title='Регистрация прошла успешно!'
            centered={true}
            destroyOnClose={true}
            visible={isVisibleSuccessModal}
            okText='Сообщение пришло!'
            onCancel={closeSuccessModal}
            onOk={closeSuccessModal}
            //@ts-ignore
            getContainer={() => document.querySelector(`#${containerId}`)}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <Paragraph style={{ margin: '0 0 0 30px' }}>
                Сообщение с подтверждением выслано вам на почту: {registeredUserEmail}
                <br />
                <br />
                <GEButton
                    loading={disableEmailSend}
                    type='primary'
                    onClick={() => sendVerificationEmailRepeated(registeredUserEmail)}
                >
                    Отправить повторно
                </GEButton>
            </Paragraph>
        </Modal>
    )
}