export const test = [
    {
        id: 1,
        title: 'I _____ my speech by the time you brought me my order.',
        variants: [
            { id: 1, title: 'have finished', isTrue: false },
            { id: 2, title: 'finished', isTrue: false },
            { id: 3, title: 'had finished', isTrue: true },
            { id: 4, title: 'was finishing', isTrue: false }
        ]
    },
    {
        id: 2,
        title: 'By eleven o’clock he _____ the train.',
        variants: [
            { id: 1, title: 'had caught', isTrue: true },
            { id: 2, title: 'have catched', isTrue: false },
            { id: 3, title: 'had catched', isTrue: false },
            { id: 4, title: 'catched', isTrue: false }
        ]
    },
    {
        id: 3,
        title: 'They _____ by the time they came home.',
        variants: [
            { id: 1, title: 'had washed up', isTrue: true },
            { id: 2, title: 'have washed up', isTrue: false },
            { id: 3, title: 'were washing', isTrue: false },
            { id: 4, title: 'washed', isTrue: false }
        ]
    },
    {
        id: 4,
        title: 'We ___ the guitar now',
        variants: [
            { id: 1, title: 'are playing', isTrue: true },
            { id: 2, title: 'is not playing', isTrue: false },
            { id: 3, title: "don't playing", isTrue: false },
            { id: 4, title: 'are play', isTrue: false }
        ]
    },
    {
        id: 5,
        title: 'He _____ shooting a film, so we couldn’t speak.',
        variants: [
            { id: 1, title: 'was starting', isTrue: false },
            { id: 2, title: 'had starting', isTrue: false },
            { id: 3, title: 'had started', isTrue: true },
            { id: 4, title: 'have started', isTrue: false }
        ]
    },
    {
        id: 6,
        title: 'I _____ the breakfast ready when you came.',
        variants: [
            { id: 1, title: 'have already got', isTrue: false },
            { id: 2, title: 'was already got', isTrue: false },
            { id: 3, title: 'had already got', isTrue: true },
            { id: 4, title: 'has already got', isTrue: false }
        ]
    },
    {
        id: 7,
        title: 'She went away after she ____ her work.',
        variants: [
            { id: 1, title: 'had finished', isTrue: true },
            { id: 2, title: 'have finished', isTrue: false },
            { id: 3, title: 'had finishing', isTrue: false }
        ]
    },
    {
        id: 8,
        title: 'Tom _____ his homework by the time I got the dinner for him.',
        variants: [
            { id: 1, title: 'has done', isTrue: false },
            { id: 2, title: 'did', isTrue: false },
            { id: 3, title: 'had done', isTrue: true },
            { id: 4, title: 'had did', isTrue: false }
        ]
    }
]
