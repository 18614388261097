import RulesHeader from '../../blocks/RulesHeader'
import NumberBlock from '../../blocks/NumberBlock'
import TaskExample from '../../blocks/TaskExample'
import Example from '../../blocks/Example'
import FactRule from '../../blocks/FactRule'
import TableTwoVS from '../../blocks/TableTwoVS'
import { test } from './test'
import RuleTestWrapper from '../../RuleTestWrapper'

const CouldCouldHave = (props) => {
    return (
        <div>
            <RulesHeader title='Разница между' subtitle='could(do) и could have(done)' />

            <NumberBlock numberBlock='1' />
            <p>
                {' '}
                Мы используем <b>could</b> по-разному. Иногда <b>could</b> это прошедшее время модального глагола{' '}
                <b>can</b>:
            </p>
            <Example engExample={['I ', <u>can</u>, ' hear something. (now)']} rusExample='Я могу что-то услышать.' />
            <Example engExample={['I ', <u>could</u>, ' hear something. (past)']} rusExample='Я что-то слышал' />
            <p>
                {' '}
                Но это не единственный способ использования <b>could</b>. Мы также используем <b>could</b>, говоря о
                возможных действиях в настоящем или будущем (особенно выступая с предложениями (suggestions)).
            </p>
            <Example
                engExample={[
                    'A: When you go to Ryazan next month, you could stay with Tomas.',
                    <br />,
                    'B: Yes, I suppose I ',
                    <u>could</u>,
                    '.'
                ]}
                rusExample={[
                    'А: Когда ты поедешь в Рязань в следующем месяце, ты можешь остаться с Томасом.',
                    <br />,
                    'Б: Да, я думаю, что смогу.'
                ]}
            />
            <FactRule
                factRule={[
                    <u>Can</u>,
                    ' тоже возможно в этих предложениях. С ',
                    <u>could</u>,
                    ' предложение (suggestion)',
                    'менее реалистичное (less sure), т.е. меньше уверенности в таком развитии событий.'
                ]}
            />

            <NumberBlock numberBlock='2' />
            <p>
                {' '}
                Мы также используем <b>could</b> (не <b>can</b>) для не реалистичных действий:{' '}
            </p>
            <Example
                engExample={['I’m so tired, I ', <u>could</u>, ' sleep for a week.(not I can sleep for a week)']}
                rusExample='Я так устал, что мог бы проспать целую неделю.'
            />
            <TaskExample taskExample={['Сравните ', <b>can</b>, ' и ', <b>could</b>, ' :']} />
            <Example
                engExample={['I ', <u>can</u>, ' stay with Tomas when I go to Ryazan.']}
                rusExample='Я могу остаться с Томасом, когда поеду в Рязань. (реалистично)'
            />
            <Example
                engExample={['Maybe I ', <u>could</u>, ' stay with Tomas when I go to Ryazan.']}
                rusExample='Может быть, я смогу остаться с Томасом, когда поеду в Рязань. (возможно, но меньше уверенности)'
            />
            <Example
                engExample={['Ryazan is a wonderful city. I ', <u>could</u>, ' stay here for ever.']}
                rusExample='Рязань замечательный город. Я мог бы остаться здесь навсегда. (нереалистично)'
            />

            <NumberBlock numberBlock='3' />
            <p>
                {' '}
                Мы также используем <b>could</b> (не <b>can</b>), чтобы сказать, что что-то возможно сейчас или в
                будущем:{' '}
            </p>
            <Example
                engExample={['The story ', <u>could</u>, ' be true, but I don’t think it is.']}
                rusExample='Эта история может быть правдой, но я так не думаю'
            />

            <NumberBlock numberBlock='4' />
            <p>
                {' '}
                Мы используем <b>could have (done)</b>, когда говорим о прошлом. Сравните:{' '}
            </p>
            <TableTwoVS
                elements={[
                    {
                        left: ['I’m so tired, I ', <u>could</u>, ' sleep for a week.(now) '],
                        right: ['I was so tired, I ', <u>could have</u>, ' slept for a week.(past)']
                    },
                    {
                        left: 'Я так устал, что мог бы проспать целую неделю. ',
                        right: 'Я так устал, что мог проспать целую неделю.'
                    }
                ]}
            />
            <p>
                {' '}
                Что-то <b>could have</b> happened (могло случиться) = это было возможно, но не случилось:{' '}
            </p>
            <Example
                engExample={[
                    'Why did you stay at a hotel when you were in Ryazan? You ',
                    <u>could</u>,
                    ' have stayed with Tomas.'
                ]}
                rusExample='Почему ты остановилась в гостинице, когда была в Рязани? Ты могла бы остаться у Томаса.'
            />

            <NumberBlock numberBlock='5' />
            <p>
                {' '}
                Мы используем <b>couldn’t</b>, говоря о том, что не было бы возможно сейчас:{' '}
            </p>
            <Example
                engExample={['Everything is fine right now. Things ', <u>couldn’t</u>, ' be better.']}
                rusExample='Сейчас все в порядке. Лучше и быть не может.'
            />
            <p>
                {' '}
                В прошедшем времени мы используем <b>couldn’t have (done)</b>:{' '}
            </p>
            <Example
                engExample={['We had a really good holiday. It ', <u>couldn’t have</u>, ' been better.']}
                rusExample='У нас был действительно хороший отпуск. Лучше и быть не могло.'
            />
            <RuleTestWrapper value={test} rule={props} />
        </div>
    )
}

export default CouldCouldHave
