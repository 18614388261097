import IconAlarm from '../svg_icons/IconAlarm'
import IconFire from '../svg_icons/IconFire'
import IconChecked from '../svg_icons/IconChecked'
import RuleStatus from '../../../const/RuleStatus'

const RuleCard = (props) => {
    const { name = '', type = '', status = null, className = '', image = null } = props

    return (
        <>
            <div className='rule-card-status-badge'>
                {status === RuleStatus.ALMOST_DONE && <IconChecked />}
                {status === RuleStatus.DONE && <IconChecked />}
                {status === RuleStatus.NEW && <IconFire />}
                {status === RuleStatus.TO_REPEAT && <IconAlarm />}
                {status === RuleStatus.WITH_MISTAKES && <IconAlarm />}
            </div>
            <div className={'rule-card ' + className}>
                <span className='rule-card__type'>{type}</span>
                <img className={'rule-card__img'} src={image} alt={name} />
                <h2 className='rule-card__name'>{name}</h2>
            </div>
        </>
    )
}

export default RuleCard
