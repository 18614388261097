/**
 * Project geekenglish
 *
 * @author Alexandr Filatov on 21.04.2020
 * Дублируются в файле entity/nodb/RuleStatus.java
 */
let RuleStatus = {
    NEW: 'NEW',
    DONE: 'DONE',
    WITH_MISTAKES: 'WITH_MISTAKES',
    TO_REPEAT: 'TO_REPEAT',
    ALMOST_DONE: 'ALMOST_DONE'
}

export default RuleStatus
