import {
    executeJsonGetMethod,
    executeJsonPostMethod,
    executePostMethod,
    executePutMethod
} from '../api/rest/restApiUtils'

/**
 * Получение статискики по url
 * @param {string} path - путь до статискики с query переменными
 * @method getStatistic
 * @return {Response} Fetch Response
 */
export function getStatistic(path) {
    return executeJsonGetMethod(path).then((resp) => {
        return Promise.resolve(resp.data)
    })
}

/**
 * Сохранение статистики по тесту
 * @param {object} sendingData - объект статистики
 * @method saveUserTestStatistic
 * @return {Response} Fetch Response
 */
export function saveUserTestStatistic(sendingData) {
    return executeJsonPostMethod('/statistic/test', sendingData).then((resp) => {
        return Promise.resolve(resp.data.content)
    })
}

/**
 * Сохранение статистики по карточкам
 * @param {object} sendingData - объект статистики
 * @method saveUserTestStatistic
 * @return {Response} Fetch Response
 */
export function saveUserCardStatistic(sendingData) {
    return executeJsonPostMethod('/statistic/card', sendingData).then((resp) => {
        return Promise.resolve(resp.data.content)
    })
}

/**
 * Сохранение статистики fill-gaps
 * @param {object} sendingData - объект статистики
 * @method saveUserFillGapsStatistic
 * @return {Response} Fetch Response
 */
export function saveUserFillGapsStatistic(sendingData) {
    return executeJsonPostMethod('/statistic/fill-gaps', sendingData).then((resp) => {
        return Promise.resolve(resp.data.content)
    })
}

/**
 * Сохранение статистики по филвордам
 * @param {object} sendingData - объект статистики
 * @method saveUserFillWordStatistic
 * @return {Response} Fetch Response
 */
export function saveUserFillWordStatistic(sendingData) {
    return executePostMethod('/statistic/fill-word', sendingData)
}

/**
 * Получение статискики по неправильным ответам
 * @method getMistakesStatistic
 * @return {Response} Fetch Response
 */
export function getMistakesStatistic() {
    return executeJsonGetMethod('/rating/user/words/mistakes').then((resp) => {
        return Promise.resolve(resp.data.content)
    })
}

/**
 * Получение статискики по не повторенным спискам слов
 * @method getWordListStatistic
 * @return {Response} Fetch Response
 */
export function getWordListStatistic() {
    return executeJsonGetMethod('/statistic/word-lists').then((resp) => {
        return Promise.resolve(resp.data)
    })
}

/**
 * Получение статистики для отрисовки графика
 * @method getGraphStatistic
 * @return {Response} Fetch Response
 */
export function getGraphStatistic() {
    return executeJsonGetMethod('/statistic/graph').then((resp) => {
        return Promise.resolve(resp.data.content)
    })
}

/**
 * Получение информации по словам для пользователя и пользователя по userId
 * @method getWordsStatisticByUserId
 * @return {Response} Fetch Response
 */
export function getWordsStatisticByUserId(userId) {
    return executeJsonGetMethod('/statistic/words/' + userId).then((resp) => {
        return Promise.resolve(resp.data)
    })
}

/**
 * Обновление статистики нового пользователя
 * @method updateNewCommerceStatistic
 * @return {Response} Fetch Response
 */
export function updateNewCommerceStatistic(page) {
    return executePutMethod('/statistic/pages/' + page)
}
