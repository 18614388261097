import { FC } from 'react'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'

export const GEButton: FC<ButtonProps> = (props) => {
    return (
        <Button  {...props} className={'scale-btn ' + props.className}>
            {props.children}
        </Button>
    )
}