let DateTimeUtils = {
  COUNT_MILLISECONDS_IN_SECONDS: 1000,

  /**
   * Получение количества милисекунд между двумя датами
   */
  getCountMilliSecondsBetweenDates: function (startDate, endDate) {
    return endDate.getTime() - startDate.getTime()
  },

  /**
   * Получение количества милисекунд
   * переданного времени перед текущим
   */
  getCountMilliSecondsBeforeCurrent: function (date) {
    return this.getCountMilliSecondsBetweenDates(date, new Date())
  },

  /**
   * Получение даты в формате: 06.02.22 (15:24)
   */
  getFormattedDate: function (date) {
    let result
    const msDate = new Date(date)
    let dd = msDate.getDate()
    if (dd < 10) {
      dd = '0' + dd
    }
    let mm = msDate.getMonth() + 1
    if (mm < 10) {
      mm = '0' + mm
    }
    let yy = msDate.getFullYear() % 100
    if (yy < 10) {
      yy = '0' + yy
    }
    result = dd + '.' + mm + '.' + yy + ' (' + msDate.getHours() + ':' + msDate.getMinutes() + ')'
    return result
  },

  getAllTimesArray: function () {
    let result = []
    let start = 5
    let end = 23

    for (let i = start; i < end; i++) {
      result.push({value: i, label: i + ':00'})
    }
    return result
  }
}

export default DateTimeUtils
