import { useState } from 'react'
import '../../../../styles/scss/forgotPassword/reset_password.scss'
import MainModel from '../../../../model/MainModel'
import * as API_USER from '../../../../shared/http/user'
import { Helmet } from 'react-helmet'
import IconBtnCancel from '../../../elements/svg_icons/IconBtnCancel'
import IconBtnOk from '../../../elements/svg_icons/IconBtnOk'
import { GEButton } from '../../../../common/buttons'

const ResetPassword = () => {
    const [disabledButton, setDisabledButton] = useState(false)
    const [emailValue, setEmailValue] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [confirmMessage, setConfirmMessage] = useState('')
    const [validEmail, setValidEmail] = useState(false)

    const updateEmail = (event) => {
        setEmailValue(event.target.value)
        checkEmailValidation(event.target.value)
    }

    const resetPassword = (event) => {
        event.preventDefault()
        clearUserMessages()
        if (validEmail) {
            setDisabledButton(true)
            setTimeout(() => {
                setDisabledButton(false)
            }, 60000)

            API_USER.resetPassword(emailValue)
                .then(() => {
                    setConfirmMessage(
                        'Сообщение успешно отправлено на почту. Повторный запрос будет доступен через минуту'
                    )
                })
                .catch((e) => {
                    setErrorMessage(MainModel.getMessageFromGeekException(e))
                    setDisabledButton(false)
                })
        }
    }

    const clearUserMessages = () => {
        setErrorMessage('')
        setConfirmMessage('')
    }

    const checkEmailValidation = (email) => {
        let regExp = /\S+@\S+\.\S+/
        if (regExp.test(String(email).toLowerCase())) {
            setValidEmail(true)
        } else {
            setValidEmail(false)
        }
    }

    return (
        <div id='reset-password'>
            <Helmet title='GE: Сброс пароля' />
            <form className='reset-password__form'>
                <h1 className='reset-password__title'>Geekenglish сброс пароля</h1>
                <input
                    className='reset-password__input-email'
                    type='email'
                    name='email'
                    placeholder='Email'
                    onChange={(event) => updateEmail(event)}
                />
                <GEButton
                    className='margin10top'
                    type='primary'
                    onClick={(event) => resetPassword(event)}
                    disabled={disabledButton ? 'true' : ''}
                >
                    <span className='reset-password__span-reset-password'>Сбросить пароль</span>
                    {validEmail ? (
                        <IconBtnOk class='button__icon-margin' />
                    ) : (
                        <IconBtnCancel class='button__icon-margin' />
                    )}
                </GEButton>
                <span className='reset-password__message'>{confirmMessage}</span>
                <span className='reset-password__error'>{errorMessage}</span>
            </form>
        </div>
    )
}

export default ResetPassword
