import {FC} from "react";
import '../../../../../styles/scss/profile_page/profile_page.scss'
import SuccessBarList from "../../statistic/SuccessBarList";

interface TestStatisticsCardProps {
}

export const TestStatisticsCard: FC<TestStatisticsCardProps> = () => {
    return (
        <div className='test-statistics-card-container grid-6'>
            <div className='test-statistics-card-text'>Статистика по тестам</div>
            <SuccessBarList
                loadPath={'/statistic/test'}
                params={[
                    {title: 'Тестов пройдено', paramName: 'countSolvedTest'},
                    {title: 'Процент верных ответов', paramName: 'percentRightAnswers'}
                ]}/>
        </div>
    )
}
