import '../../../../styles/css/statistic/statistic.css'
import BarChart from './BarChart'
import TopWordsCountStats from './top/TopWordsCountStats'
import { Helmet } from 'react-helmet'

const Statistic = () => {
    return (
        <>
            <Helmet title='GE: Статистика' />
            <section id='statistic' className='statistic'>
                <article className='statistic__chart'>
                    <BarChart />
                </article>
                <article className='statistic__bottom-bar'>
                    <TopWordsCountStats title={'Рейтинг по числу слов'} loadPath={'/rating/user/words'} />
                    <TopWordsCountStats title={'Рейтинг повторенных слов'} loadPath={'/rating/user/repeated/words'} />
                </article>
            </section>
        </>
    )
}

export default Statistic
