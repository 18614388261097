import '../../../styles/css/elements/svg_icons/icon-btn.css'
import { Tooltip } from 'antd'

const IconRefresh = ({ tooltipText }) => {
    return (
        <Tooltip title={tooltipText}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='none'
                stroke='#000'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5'
                className='icon icon-tabler icon-tabler-refresh'
                viewBox='0 0 24 22'
            >
                <path stroke='none' d='M0 0h24v24H0z'></path>
                <path d='M20 11A8.1 8.1 0 004.5 9M4 5v4h4M4 13a8.1 8.1 0 0015.5 2m.5 4v-4h-4'></path>
            </svg>
        </Tooltip>
    )
}
export default IconRefresh
