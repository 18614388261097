import React from 'react'
import { Layout, Button, Row, Col, Card, Typography } from 'antd'
import {
    VideoCameraOutlined,
    FileTextOutlined,
    QuestionCircleOutlined,
    BookOutlined,
    AudioOutlined
} from '@ant-design/icons'
import { goToPageNotPremium } from '../../utils/goToPage'
import { NavigationPages } from '../../../common/navigation/navigation-pages'
import { useNavigate } from 'react-router-dom'

const { Header, Content, Footer } = Layout
const { Title, Paragraph } = Typography

const TeacherLandingPage = () => {
    const navigate = useNavigate()

    return (
        <Layout>
            {/* Верхнее меню */}
            <Header style={{ backgroundColor: '#3c5482' }}>

                <div style={{ color: 'white', fontSize: '24px', float: 'left' }}>
                    <img src='../images/custom/logo.png' alt='Logo' style={{ width: 32, height: 32 }} />
                    GeekEnglish
                </div>
            </Header>

            {/* Основной контент */}
            <Content style={{ padding: '50px' }}>
                <div style={{ textAlign: 'center', marginBottom: '40px' }}>
                    <Title level={1}>Создавайте уникальные курсы и делитесь знаниями с GeekEnglish</Title>
                    <Paragraph>Платформа для преподавателей, которая позволяет создавать курсы с видео, презентациями,
                        тестами, карточками слов и аудиофайлами.</Paragraph>
                    <Button onClick={() => goToPageNotPremium(NavigationPages.CREATE_COURSE, navigate)} type='primary'
                            size='large'>Начать бесплатно</Button>
                </div>

                {/* Преимущества платформы */}
                <Row gutter={[24, 24]} justify='center' style={{ marginTop: '30px' }}>
                    <Col xs={24} sm={12} md={8} lg={4}>
                        <Card bordered={false} style={{ textAlign: 'center' }}>
                            <VideoCameraOutlined style={{ fontSize: '40px', color: '#1890ff' }} />
                            <Title level={4}>Видео</Title>
                            <Paragraph>Добавляйте видеоуроки для лучшего восприятия информации.</Paragraph>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={4}>
                        <Card bordered={false} style={{ textAlign: 'center' }}>
                            <FileTextOutlined style={{ fontSize: '40px', color: '#1890ff' }} />
                            <Title level={4}>Презентации</Title>
                            <Paragraph>Используйте презентации для структурирования материала.</Paragraph>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={4}>
                        <Card bordered={false} style={{ textAlign: 'center' }}>
                            <QuestionCircleOutlined style={{ fontSize: '40px', color: '#1890ff' }} />
                            <Title level={4}>Тесты</Title>
                            <Paragraph>Создавайте тесты для оценки знаний студентов.</Paragraph>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={4}>
                        <Card bordered={false} style={{ textAlign: 'center' }}>
                            <BookOutlined style={{ fontSize: '40px', color: '#1890ff' }} />
                            <Title level={4}>Карточки слов</Title>
                            <Paragraph>Добавляйте карточки слов для запоминания терминов и понятий.</Paragraph>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={4}>
                        <Card bordered={false} style={{ textAlign: 'center' }}>
                            <AudioOutlined style={{ fontSize: '40px', color: '#1890ff' }} />
                            <Title level={4}>Аудио</Title>
                            <Paragraph>Включайте аудиофайлы для улучшения навыков аудирования и восприятия
                                речи.</Paragraph>
                        </Card>
                    </Col>
                </Row>

                {/* Почему стоит выбрать нас */}
                <div style={{ backgroundColor: '#f0f2f5', padding: '50px', marginTop: '50px', textAlign: 'center' }}>
                    <Title level={2}>Почему стоит выбрать GeekEnglish?</Title>
                    <Row gutter={16} style={{ marginTop: '30px' }} justify='center'>
                        <Col xs={24} sm={12} md={8}>
                            <Card bordered={false}>
                                <Title level={3}>Простой и интуитивный интерфейс</Title>
                                <Paragraph>Наша платформа интуитивно понятна, чтобы вы могли быстро создавать и
                                    редактировать курсы, сосредоточившись на обучении, а не на технических
                                    деталях.</Paragraph>
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Card bordered={false}>
                                <Title level={3}>Интерактивные инструменты</Title>
                                <Paragraph>GeekEnglish предлагает мощные интерактивные функции, включая тесты, карточки,
                                    аудио и видео материалы, что делает курсы увлекательными и полезными для
                                    студентов.</Paragraph>
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Card bordered={false}>
                                <Title level={3}>Поддержка 24/7</Title>
                                <Paragraph>Наша команда всегда готова помочь вам на каждом этапе, от создания курса до
                                    его публикации и дальнейшего развития.</Paragraph>
                            </Card>
                        </Col>
                    </Row>
                </div>

                {/* Призыв к действию */}
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <Title level={2}>Готовы начать?</Title>
                    <Paragraph>Присоединяйтесь к GeekEnglish и создавайте свои первые курсы уже сегодня!</Paragraph>
                    <Button onClick={() => goToPageNotPremium(NavigationPages.CREATE_COURSE, navigate)} type='primary'
                            size='large'>Предложить курс</Button>
                </div>
            </Content>

            {/* Футер */}
            <Footer style={{ textAlign: 'center' }}>
                GeekEnglish ©2024
            </Footer>
        </Layout>
    )
}

export default TeacherLandingPage
