import '../../../../styles/scss/pages/premium/premium.scss'
import { Helmet } from 'react-helmet'

import { useEffect } from 'react'
import * as API_TELEGRAM from '../../../../shared/http/telegram-notification'
import { NavigationPages } from '../../../../common/navigation/navigation-pages'
import { useAppSelector } from '../../../../store/hooks'
import { userSelector } from '../../../../store/global'
import { GELink } from '../../../../common/link/GELink'

const PremiumHelp = () => {
    const user = useAppSelector(userSelector)

    useEffect(() => {
        API_TELEGRAM.notifyTelegramBot('Перешел на страницу покупки помощи при покупке')
    }, [])

    return (
        <div id='premium'>
            <Helmet title='Помощь покупки' />
            <h2 className='premium__sub-header'>Шаги активации подписки:</h2>
            <h3 className='premium__sub-header'>При покупке подписи вы получаете промокод для ее активации на почту</h3>
            <h2 className='premium__sub-header marginTop20'>Вот 3 простых шага активации промокода:</h2>
            <h3 className='premium__sub-header'>1. Скопируйте ваш промокод</h3>
            <h3 className='premium__sub-header'>2. Перейдите на страницу <GELink
                to={`${NavigationPages.PROFILE}/${user.id}`}>профиля</GELink>
            </h3>
            <h3 className='premium__sub-header'>3. Нажмите на кнопку "Меню" &gt; "Ввести промокод" и примерите ваш
                промокод</h3>
            <h4 className='premium__sub-header'>В случае ошибки пишите на почту: geekenglish@yandex.ru</h4>
        </div>
    )
}

export default PremiumHelp
