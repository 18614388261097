import { FC, useEffect, useLayoutEffect, useState } from 'react'
import { WordDto } from '../../../../../shared/types/api'
import IconRotate from '../../../../elements/svg_icons/IconRotate'
import Constants from '../../../../../const/Constants'

interface FlippedCardProps {
    word: WordDto
    cardWords: Array<WordDto>
    flipped: boolean
    flipCard: () => void
    side: string
}

const FlippedCard: FC<FlippedCardProps> = ({ word, flipped, flipCard, cardWords, side }) => {

    const [sideOne, setSideOne] = useState<string | undefined>('')
    const [sideTwo, setSideTwo] = useState<string | undefined>('')

    const flip = () => {
        flipCard()
    }

    useLayoutEffect(() => {
        if (flipped) {
            flip()
        }
    }, [word])

    useEffect(() => {
        const sideO = side === Constants.CARD_SIDE_ONE ? word.engWord : word.rusWord
        const sideT = side === Constants.CARD_SIDE_ONE ? word.rusWord : word.engWord
        setTimeout(() => {
            setSideOne(sideO)
            setSideTwo(sideT)
        }, 200)
    }, [word, cardWords, side])

    return (
        <div className='word-card__content'>
            <div className='word-card__content__card'>
                <IconRotate />
                <div className={flipped ? 'card flipped' : 'card'} onClick={flip}>
                    <div className='front'>
                        <div className='card__card-title'>
                            <h3 className='cardTitle' id='currentEngWord'>
                                {sideOne}
                            </h3>
                        </div>
                    </div>
                    <div className='back'>
                        <div className='card__card-title'>
                            <h3 className='cardTitle' id='currentRusWord'>
                                {sideTwo}
                            </h3>
                        </div>
                    </div>
                </div>
                <>
                </>
            </div>
        </div>
    )
}

export default FlippedCard
