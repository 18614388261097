import PropTypes from 'prop-types'

import '../../../styles/css/elements/svg_icons/icon-btn.css'

function IconFire(props) {
    const { width = '60', height = '60' } = props

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            fill='none'
            stroke='#ff4500'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            className='icon icon-tabler icon-tabler-flame'
            viewBox='0 0 24 24'
        >
            <title>Новое правило</title>
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <path d='M12 12c2-2.96 0-7-1-8 0 3.038-1.773 4.741-3 6-1.226 1.26-2 3.24-2 5a6 6 0 1012 0c0-1.532-1.056-3.94-2-5-1.786 3-2.791 3-4 2z'></path>
        </svg>
    )
}
export default IconFire

IconFire.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string
}
