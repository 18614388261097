import '../../../../../styles/scss/admin-btn/admin-btn.scss'
import PrivilegesUtil from "../../../../utils/PrivilegesUtil";
import Privileges from "../../../../../const/Privileges";
import {useNavigate} from "react-router-dom";
import {GEButton} from "../../../../../common/buttons";
import {useAppSelector} from "../../../../../store/hooks";
import {userSelector} from "../../../../../store/global";
import {NavigationPages} from "../../../../../common/navigation/navigation-pages";

const AdminBtnComponent = () => {
    const currentUser = useAppSelector(userSelector)
    const navigate = useNavigate()
    const goToAdmin = () => {
        navigate(NavigationPages.ADMIN_PAGE)
    }

    return (
        <div className='admin-btn__container'>
            {PrivilegesUtil.hasPrivilege(currentUser, Privileges.ADMIN) && (
                <GEButton onClick={goToAdmin}>
                    <span>
                    ADMIN PAGE
                </span>
                </GEButton>
            )}
        </div>
    )
}

export default AdminBtnComponent
