import RulesHeader from '../../blocks/RulesHeader'
import NumberBlock from '../../blocks/NumberBlock'
import TaskExample from '../../blocks/TaskExample'
import Example from '../../blocks/Example'
import FactRule from '../../blocks/FactRule'
import TableTwo from '../../blocks/TableTwo'
import TableThree from '../../blocks/TableThree'
import { test } from './test'
import RuleTestWrapper from '../../RuleTestWrapper'

const PastSimple = (props) => {
    return (
        <div>
            <RulesHeader title='Past simple' subtitle='Простое прошедшее' />

            <NumberBlock numberBlock='1' />
            <TaskExample taskExample='Изучите следующую ситуацию:' />
            <Example
                engExample={['The police ', <u>stopped</u>, ' me on my way home last night.']}
                rusExample={['Прошлой ночью полиция ', <u>остановила</u>, ' меня по пути домой.']}
            />
            <p>
                {' '}
                Мы используем past simple, когда говорим о действие, которое произошло в определенное время в прошлом и
                время совершения которого уже истекло.
            </p>
            <FactRule factRule='Существительное + глагол+ed это the past simple:' />

            <NumberBlock numberBlock='2' />
            <p>
                {' '}
                Но многие глаголы являются неправильными. В past simple, в таком случае, используется 2-я форма глагола.
            </p>
            <Example
                engExample={['We ', <u>saw</u>, ' Vlad in town a few weeks ago.']}
                rusExample={['Мы ', <u>видели</u>, ' Влада в городе несколько недель назад.']}
            />

            <NumberBlock numberBlock='3' />
            <p>
                {' '}
                В вопросительных и отрицательных предложениях мы используем <b>did/didn’t</b> + инфинитив/2-я форма
                неправильного глагола.
            </p>
            <TableThree
                elements={[{ left: 'Did', center: 'I/she/they', right: 'инфинитив/2-я форма неправильного глагола?' }]}
            />
            <TableThree
                elements={[
                    { left: 'I/she/they', center: 'didn’t', right: 'инфинитив/2-я форма неправильного глагола' }
                ]}
            />
            <Example
                engExample={['', <u>Did</u>, ' you ', <u>wash</u>, ' your hands?']}
                rusExample={['Ты ', <u>помыл</u>, ' руки?']}
            />
            <Example
                engExample={['We ', <u>didn’t find</u>, ' our car.']}
                rusExample={['Мы ', <u>не нашли</u>, ' свою машину.']}
            />
            <p>
                {' '}
                В следующих примерах, <b>do</b> - главный глагол предложения (<b>did</b> ... <b>do</b> /{' '}
                <b>didn’t do</b>).
            </p>
            <Example
                engExample={[
                    'What ',
                    <u>did</u>,
                    ' you ',
                    <u>do</u>,
                    ' at the weekend? (',
                    <u>NOT</u>,
                    ' What did you at the weekend?)'
                ]}
                rusExample={['Чем ты ', <u>занимался</u>, ' на выходных?']}
            />
            <Example
                engExample={['I ', <u>didn’t do</u>, ' anything. (', <u>NOT</u>, ' I didn’t anything)']}
                rusExample={['Я ничего ', <u>не делал</u>, '.']}
            />

            <NumberBlock numberBlock='4' />
            <p>
                {' '}
                Прошедшие формы глагола to <b>be</b> (am/is/are) это <b>was/were</b>.
            </p>
            <TableTwo
                elements={[
                    { left: 'I/he/she/it', right: 'was/wasn’t' },
                    { left: 'we/you/they', right: 'were/weren’t' }
                ]}
            />
            <TableTwo
                elements={[
                    { left: 'was', right: 'I/he/she/it?' },
                    { left: 'were', right: 'we/you/they?' }
                ]}
            />
            <p>
                {' '}
                Заметьте, что мы не используем <b>did</b> в отрицаниях и вопросах с <b>was/were</b>.
            </p>
            <Example
                engExample={['', <u>Was</u>, ' the weather good when you ', <u>were</u>, ' on holiday?']}
                rusExample={['Погода ', <u>была</u>, ' хорошей, когда вы ', <u>были</u>, ' в отпуске?']}
            />
            <Example
                engExample={['Did you go out last night or ', <u>were</u>, ' you too tired?']}
                rusExample={[
                    'Ты ходил куда-нибудь вчера вечером или ты слишком устал? (досл.: ',
                    <u>был</u>,
                    ' слишком усталым)'
                ]}
            />

            <RuleTestWrapper value={test} rule={props} />
        </div>
    )
}

export default PastSimple
