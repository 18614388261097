import { Helmet } from 'react-helmet'
import '../../../../../styles/scss/courses/courses-overview.scss'
import { CourseOverviewHeader } from './CourseOverviewHeader'
import { Collapse, Flex } from 'antd'
import { CourseOverviewAuthor } from './CourseOverviewAuthor'
import { CourseOverviewDetails } from './CourseOverviewDetails'
import { useEffect, useState } from 'react'
import { CourseDto, ModulesDto } from '../../../../../shared/types/api'
import * as API_COURSES from '../../../../../shared/http/courses'
import Loading from '../../../../elements/loading/Loading'
import { IconVideo } from '../../../../elements/svg_icons/IconVideo'

export const CourseOverview = () => {

    const [course, setCourse] = useState<CourseDto>()
    const [courseModules, setCourseModules] = useState<any>()

    useEffect(() => {
        API_COURSES.getCourseOverview(1).then((resp) => {

            const modules = resp?.modules?.map((m: ModulesDto) => {
                return {
                    key: m.id,
                    label: <Flex justify={'space-between'}>
                        <p style={{ fontSize: 20 }}>{m.title}</p>
                        <IconVideo />
                    </Flex>,
                    children: <Flex vertical><p> {m.description}</p></Flex>
                }
            })
            setCourseModules(modules)
            setCourse(resp as CourseDto)
        }).catch(() => {

        }).finally(() => {

        })
    }, [])


    const courseHardCode =
        {
            userHas: false,
            id: 1,
            title: 'Profound \"Upper-Intermediate\"',
            description: 'Здравствуйте! Я опытный преподаватель английского языка с 10 лет опыта в обучении. Я специализируюсь на создании курсов для студентов всех уровней и стремлюсь помочь каждому достигнуть их языковых целей. Мой курс Profound предназначен для тех, кто хочет углубить свои знания английского языка и достичь высокого уровня владения. Я предлагаю понятные объяснения и практические задания, чтобы каждый мог легко освоить материал.',
            headImage: '../../images/tmp/course1.jpg',
            price: 6900,
            oldPrice: 9900,
            author: {
                id: 1,
                name: 'Владимир',
                description: 'Я профессиональный преподаватель английского языка с более чем 4,5 лет опыта работы в онлайн-формате. Я провел более 15 000 часов, обучая более 300 индивидуальных студентов, и рад видеть их достижения и успехи.\n' +
                    '\n' +
                    'Я также являюсь профессиональным шахматистом с 13-летним стажем и закончил американский университет по специальности бизнес. Моя профессиональная деятельность включает работу в маркетинге, что помогает мне эффективно разрабатывать учебные материалы и адаптировать подход к каждому студенту.\n' +
                    '\n' +
                    'На моем YouTube-канале, который уже насчитывает более 40 000 подписчиков, я делюсь своими знаниями и опытом, создавая полезный контент для изучающих английский язык. Мое увлечение преподаванием и стремление помочь каждому студенту достичь своих целей – вот что делает мою работу особенно удовлетворительной.\n' +
                    '\n' +
                    'Я люблю преподавать, делиться своими знаниями и радоваться успехам моих учеников. Надеюсь, что мои курсы помогут вам также достичь новых высот в изучении английского языка!\n',
                avatar: '../../images/tmp/dragonenglish.jpg',
                links: {
                    telegram: 'https://t.me/EnglishDragonClubOpen',
                    youTube: 'https://youtube.com/@englishdragonclub?si=Ro0iurXtvxYaMShN'
                }
            },
            lessonsAmount: 10,
            level: ['B2'],
            duration: '3ч 20м.',
            views: 150,
            modules: [
                {
                    id: 1,
                    title: '1. Causatives (Казуативные конструкции)',
                    description: 'Изучите, как использовать казуативные конструкции для выражения действий, которые кто-то делает для вас или для кого-то другого. В этом уроке мы рассмотрим такие фразы, как "have someone do something" и "get someone to do something".',
                    video: true
                },
                {
                    id: 2,
                    title: '2. Future Tenses (Будущее время)',
                    description: 'Овладейте различными способами выражения будущего времени в английском языке. Мы изучим "will," "going to," и другие конструкции для построения будущих планов и прогнозов.',
                    video: true
                },
                {
                    id: 3,
                    title: '3. Past Tenses (Прошедшее время)',
                    description: 'Разберитесь с нюансами прошедшего времени. Мы обсудим использование "past simple," "past continuous," и "past perfect" для точного описания событий, произошедших в прошлом.',
                    video: true
                },
                {
                    id: 4,
                    title: '4. Passive Voice (Пассивный залог)',
                    description: 'Научитесь строить предложения в пассивном залоге и понимаете, как изменяется значение предложений. Урок охватывает как простые, так и сложные структуры пассивного залога.',
                    video: true
                },
                {
                    id: 5,
                    title: '5. Get/Be + Adjectives (Get/Be + прилагательные) ',
                    description: 'Изучите, как использовать "get" и "be" с прилагательными для описания изменений состояния и чувств. В уроке будут рассмотрены такие конструкции, как "get tired" и "be excited."',
                    video: true
                },
                {
                    id: 6,
                    title: '6. Linkers/Conjunctions (Связующие слова/союзы)',
                    description: 'Овладейте различными связующими словами и союзами для улучшения связности ваших текстов и речи. Мы рассмотрим "however," "although," "because," и другие.',
                    video: true
                },
                {
                    id: 7,
                    title: '7. Participles (Причастия)',
                    description: 'Разберитесь с использованием причастий в предложениях. Урок включает в себя как настоящие, так и прошедшие причастия, и их применение в различных контекстах.',
                    video: true
                },
                {
                    id: 8,
                    title: '8. Quantifiers (Квантифайеры)',
                    description: 'Изучите, как использовать квантифайеры для описания количества и степени. Мы рассмотрим такие слова, как "some," "any," "much," "many," и другие.',
                    video: true
                },
                {
                    id: 9,
                    title: '9. Infinitives and Gerunds Часть 1 (Инфинитивы и герундии) ',
                    description: 'Научитесь различать и правильно использовать инфинитивы и герундии. Мы рассмотрим случаи, когда нужно использовать один или другой, и их применение в различных структурах.',
                    video: true
                },
                {
                    id: 10,
                    title: '10. Infinitives and Gerunds Часть 2 (Инфинитивы и герундии)',
                    description: 'Научитесь различать и правильно использовать инфинитивы и герундии. Мы рассмотрим случаи, когда нужно использовать один или другой, и их применение в различных структурах.',
                    video: true
                }
            ]
        }

    return (
        <>
            <Helmet title={course && course.title} />
            {!course && <Loading />}
            {course && <Flex id='courses' vertical>


                <CourseOverviewHeader course={course} />
                <Flex gap='24px' className='courses__content'>
                    <Flex vertical className='courses__description-area' gap='16px'>
                        <div>
                            <h2 style={{ fontSize: 40 }}> Описание курса</h2>
                            <div className='courses__description'>{courseHardCode.description}</div>
                        </div>
                        <div className='courses__details-area'>
                            <h2 style={{ fontSize: 40 }}>Детали курса</h2>
                            <Flex gap='16px' wrap={'wrap'}>
                                <CourseOverviewDetails courseDetail={courseHardCode.lessonsAmount}
                                                       title='Число уроков' />
                                <CourseOverviewDetails courseDetail={courseHardCode.level} title='Уровни' />
                                <CourseOverviewDetails courseDetail={courseHardCode.duration} title='Длительность' />
                                <CourseOverviewDetails courseDetail={courseHardCode.views} title='Просмотры' />
                            </Flex>
                        </div>
                    </Flex>
                    <CourseOverviewAuthor course={courseHardCode} />
                </Flex>
                {courseModules &&
                <Collapse className='courses__collapse' items={courseModules} defaultActiveKey={['1']}
                          onChange={() => {
                          }} size='large' />}
            </Flex>}
        </>
    )
}