import { Tooltip } from 'antd'

export const IconVideo = () => {

    return (
        <Tooltip title={'Модуль содержит видео материалы'}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                fill='none'
                stroke='#fff'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5'
                className='icon icon-tabler icon-tabler-video margin5top'
                viewBox='0 0 24 24'
            >
                <path stroke='none' d='M0 0h24v24H0z'></path>
                <path
                    d='M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14v-4zM3 8a2 2 0 012-2h8a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'></path>
            </svg>
        </Tooltip>
    )
}



