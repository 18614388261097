import { executeJsonGetMethod } from '../api/rest/restApiUtils'

/**
 * Получить слова для формирования филворда
 * @method getFillWords
 * @param  {number}  wordListsId - id списка слов
 * @param  {number}  size - id списка слов
 * @return {Response} Fetch Response
 */
export function getFillWords(wordListsId, size) {
    let url = '/user/game/fillword?' + 'width=' + size + '&' + 'id=' + wordListsId
    return executeJsonGetMethod(url).then((resp) => {
        return resp.data
    })
}
