export const test = [
    {
        id: 1,
        title: '______ you still ______ ______ at six o’clock?',
        variants: [
            { id: 1, title: 'Will; working be', isTrue: false },
            { id: 2, title: 'Working; will be', isTrue: false },
            { id: 3, title: 'Be; will working', isTrue: false },
            { id: 4, title: 'Will; be working', isTrue: true }
        ]
    },
    {
        id: 2,
        title: 'He ______ ______ ______ at midnight.',
        variants: [
            { id: 1, title: 'be will sleep', isTrue: false },
            { id: 2, title: 'be will sleeping', isTrue: false },
            { id: 3, title: 'will be sleeping', isTrue: true },
            { id: 4, title: 'will be sleep', isTrue: false }
        ]
    },
    {
        id: 3,
        title: 'We ______ ______ ______ our flat in May.',
        variants: [
            { id: 1, title: 'will be renovating', isTrue: true },
            { id: 2, title: 'will be renovate', isTrue: false },
            { id: 3, title: 'will being renovate', isTrue: false },
            { id: 4, title: 'be will renovating', isTrue: false }
        ]
    },
    {
        id: 4,
        title: 'I ______ ______ ______ for you at that moment.',
        variants: [
            { id: 1, title: 'will be waiting', isTrue: true },
            { id: 2, title: 'will be wait', isTrue: false },
            { id: 3, title: 'will being wait', isTrue: false },
            { id: 4, title: 'be will waiting', isTrue: false }
        ]
    },
    {
        id: 5,
        title: 'What ______ you ______ ______ when he comes?',
        variants: [
            { id: 1, title: 'will; be do', isTrue: false },
            { id: 2, title: 'will; be doing', isTrue: true },
            { id: 3, title: 'be; will do', isTrue: false },
            { id: 4, title: 'be; will doing', isTrue: false }
        ]
    }
]
