import { FC } from 'react'
import { WordDto } from '../../../../shared/types/api'
import Constants from '../../../../const/Constants'

interface TestAnswersProps {
    side: string,
    answerIndexes: Array<number>,
    words: Array<WordDto>
    answerBtnClick: (id: number) => void
}

export const TestAnswers: FC<TestAnswersProps> = ({ answerIndexes, words, answerBtnClick, side }) => {
    return (
        <div id='flex'>
            {answerIndexes.map((index, n) => (
                <span key={index} onClick={() => answerBtnClick(index)}
                      className='width270px marginAuto testAreaAnswer'>{side === Constants.CARD_SIDE_ONE
                    ? words[index].rusWord : words[index].engWord}</span>
            ))}
        </div>
    )
}
