import '../../../../styles/css/aferta/aferta.css'
import { Helmet } from 'react-helmet'

const AfertaPage = () => {
    return (
        <div id='aferta'>
            <Helmet title='Договор-оферта на оказание услуг' />
            <div style={{ padding: '20px' }}>
                <h1>ЕДИНАЯ ОФЕРТА СЕРВИСА «GEEKENGLISH»</h1>
                <p><strong>Редакция от 11.10.2024</strong></p>
                <p>
                    Настоящая единая оферта сервиса «GeekEnglish» представляет собой предложение Индивидуального
                    предпринимателя Филатова Александра Вадимовича (далее – GeekEnglish)
                    заключить договор на оказание услуг по предоставлению доступа к функционалу сервиса «GeekEnglish» на
                    изложенных ниже условиях.
                </p>

                <h2>1. Термины и определения</h2>
                <p><strong>1.1. Акцепт Оферты</strong> – полное и безоговорочное принятие условий настоящей Оферты путем
                    осуществления Пользователем действий, предусмотренных разделом 3 настоящей Оферты</p>
                <p><strong>1.2. Акцепт Оферты Преподавателя</strong> – полное и безоговорочное согласие Пользователя с
                    условиями Оферты
                    Преподавателя, выраженное в форме приобретения Пользователем Онлайн-
                    курса в порядке, предусмотренном разделом 6 Оферты.</p>
                <p><strong>1.3. Онлайн-курс</strong> – видео- или аудиоматериал либо текстовый материал, размещаемый
                    Преподавателем в Сервисе и приобретаемый Пользователем в целях
                    просмотра или прослушивания.</p>
                <p><strong>1.4. Оферта</strong> – ннастоящий документ «Единая оферта сервиса GeekEnglish»,
                    устанавливающий
                    взаимные права и обязанности между GeekEnglish и Пользователем, а также
                    между GeekEnglish и Преподавателем, размещенный
                    в телекоммуникационной сети «Интернет» по адресу:<a
                        href='https://geekenglish.ru/aferta'>https://geekenglish.ru/aferta</a>.</p>
                <p><strong>1.5. Оферта Преподавателя</strong> – предложение Преподавателя приобрести размещенный им в
                    Сервисе Онлайн-
                    курс по указанной в Сервисе стоимости, адресованное неограниченному кругу
                    Пользователей (публичная оферта по смыслу статьи 437 ГК РФ).</p>
                <p><strong>1.6. Пользователь</strong> – физическое лицо, совершившее Акцепт Оферты в соответствии с
                    разделом
                    3 настоящей Оферты и пользующееся (планирующее использовать) услугами
                    (-и) GeekEnglish по предоставлению доступа к функционалу Сервиса.</p>
                <p><strong>1.7. Преподаватель</strong> – полностью дееспособное физическое лицо или индивидуальный
                    предприниматель, совершившее Акцепт Оферты в соответствии с разделом
                    3 настоящей Оферты, заполнившее анкету преподавателя онлайн-курса
                    в разделе «Профиль» Сайта и размещающее (планирующее размещать)
                    Онлайн-курсы в Сервисе.</p>
                <p><strong>1.8. Сайт</strong> – ссовокупность графических и информационных материалов, а также программ
                    для ЭВМ и баз данных, обеспечивающих их доступность
                    в телекоммуникационной сети «Интернет» по адресу: <a
                        href='https://geekenglish.ru/'>https://geekenglish.ru/</a>.</p>
                <p><strong>1.9. Сервис</strong> – автоматизированная система, предоставляющая Преподавателям
                    и Пользователям возможность осуществлять размещение Онлайн-курсов
                    и их приобретение в целях дальнейшего просмотра или прослушивания,
                    соответственно, а также возможность самостоятельного изучения
                    иностранных слов посредством функционала автоматизированной системы,
                    размещенная в телекоммуникационной сети «Интернет»
                    по адресу: <a href='https://geekenglish.ru/'>https://geekenglish.ru/</a>.</p>
                <p><strong>1.10. Стороны</strong> – совместно именуемые GeekEnglish, Пользователь и Преподаватель.</p>
                <p><strong>1.11. Telegram-бот GeekEnglish</strong> – автоматизированная система по сбору, передаче и
                    обмену электронными
                    сообщениями между GeekEnglish, Пользователем и Преподавателем,
                    предоставляющая возможность отправлять и получать электронные
                    сообщения, имеющие значение для совершения юридически-значимых
                    действий в соответствии с настоящей Офертой, организованная силами
                    GeekEnglish и функционирующая с помощью информационных систем и (или)
                    программ для электронных вычислительных машин, которые предназначены
                    для обмена электронными сообщениями исключительно между
                    пользователями этих информационных систем и (или) программ для
                    электронных вычислительных машин, при котором отправитель электронного
                    сообщения определяет получателя или получателей электронного сообщения,
                    деятельность которой обеспечивает организатор сервиса обмена
                    многовенными сообщениями «Telegram», размещенная
                    в телекоммуникационной сети «Интернет» по адресу:
                    <a href='https://t.me/GeekenglishBot'>https://t.me/GeekenglishBot</a>.</p>

                <h2>2. Предмет Оферты</h2>
                <p><strong>2.1.</strong> Предметом настоящей Оферты является предоставление Пользователю и Преподавателю
                    курса
                    удаленного доступа к функционалу Сервиса «GeekEnglish» в целях размещения, просмотра или
                    прослушивания Онлайн-курсов.</p>
                <p><strong>2.2.</strong>Услуги, оказываемые в соответствии с настоящей Офертой, включают в себя
                    различные
                    действия, условия, сроки и обязанности, а также порядок оплаты в зависимости от стороны, которая
                    осуществляет юридически-значимые действия в соответствии с Офертой. При этом действие
                    отдельных условий Оферты распространяется на сторону только в том случае, если из Оферты
                    прямо и недвусмысленно следует, что определенные условия относятся к конкретной Стороне.</p>
                <p><strong>2.3.</strong> GeekEnglish является агентом Преподавателя по смыслу главы 52 Гражданского
                    кодекса РФ
                    (далее – «ГК РФ») в части совершения определенных действий, предусмотренных настоящей
                    Офертой. Будучи агентом Преподавателя курса, GeekEnglish действует от своего имени, в интересах
                    и за счет Преподавателя курса.</p>

                <h2>3. Акцепт Оферты и заключение Договора</h2>
                <p><strong>3.1.</strong> Лицо осуществляет акцепт настоящей Оферты и регистрацию в Сервисе в качестве
                    Пользователя путем совершения в совокупности следующий действий:</p>
                <ul>
                    <li>3.1.1. заполнения регистрационной формы, содержащей сведения об адресе электронной почты
                        Пользователя, или
                        авторизации в Сервисе посредством сторонних сервисов – «Telegram» или «Google»;
                    </li>
                    <li>3.1.2. подтверждения аккаунта Пользователя посредством нажатия кнопки «Подтвердить аккаунт»
                        в электронном письме, направленном на адрес электронной почты Пользователя, указанный при
                        заполнении регистрационной формы;
                    </li>
                </ul>
                <p><strong>3.2.</strong> После совершения лицом совокупности действий в порядке, предусмотренном
                    разделом 3
                    настоящей Оферты, лицу предоставляется доступ к функционалу Сервиса в качестве Пользователя.
                    С момента предоставления доступа к функционалу Сервиса в качестве Пользователя лицо
                    приобретает права и обязанности Пользователя в соответствии с настоящей Офертой.</p>

                <p><strong>3.3.</strong> Регистрация в Сервисе в качестве Преподавателя осуществляется посредством
                    нажатия
                    Пользователем кнопки «Переключиться на аккаунт преподавателя» в разделе «Профиль» Сайта,
                    последующего заполнения анкеты преподавателя онлайн-курса и нажатия кнопки «Перейти на
                    аккаунт преподавателя».
                    Анкета преподавателя онлайн-курса содержит следующие сведения о Преподавателе:
                    <br /> - фамилия, имя, отчество Преподавателя;
                    <br /> - копия (скан) паспорта (иного удостоверяющего личность документа) Преподавателя, содержащая
                    (-ий) информацию о фамилии, имени, отчестве (при наличии), дате рождения, дате выдачи,
                    выдавшем органе, о месте жительства (при наличии);
                    <br /> - ИНН Преподавателя;
                    <br /> - банковские реквизиты Преподавателя, содержащие информацию о номере лицевого счета, БИК
                    банка, наименование банка, корреспондентский счет банка.</p>

                <p><strong>3.4.</strong> После совершения действий, предусмотренных пунктом 3.3. настоящей Оферты,
                    GeekEnglish
                    осуществляет проверку предоставленных Пользоваталем сведений в анкете преподавателя онлайн-
                    курса и предоставляет Пользователю доступ к Сервису в качестве Преподавателя.
                    С момента предоставления Пользователю доступа к функционалу Сервиса в качестве
                    Преподавателя Пользователь также приобретает права и обязанности Преподавателя в соответствии
                    с настоящей Офертой, не утрачивая при этом прав и обязанностей Пользователя в соответствии с
                    настоящей Офертой</p>

                <p><strong>3.5.</strong> GeekEnglish вправе по своему усмотрению отказать Пользователю в предоставлении
                    доступа к
                    Сервису в качестве Преподавателя. При этом GeekEnglish пользуется правом не раскрывать
                    Пользователю причины, по которым последнему не был предоставлен доступ к Сервису в качестве
                    Преподавателя.</p>

                <h2>4. Порядок использования Сервиса Пользователями</h2>
                <p><strong>4.1.</strong>После предоставления доступа к функционалу Сервиса Пользователь вправе
                    приобретать
                    размещаемые Преподавателями в Сервисе Онлайн-курсы.</p>
                <p><strong>4.2.</strong>Приобретение Пользователем Онлайн-курса признается Акцептом Оферты
                    Преподавателя.
                    Приобретая Онлайн-курс, Пользователь полностью и безоговорочно соглашается с Офертой
                    Преподавателя.</p>
                <p><strong>4.3.</strong>После приобретения Пользователем Онлайн-курса в порядке, предусмотренном
                    разделом 6
                    настоящей Оферты, GeekEnglish в течение 1 (одного) рабочего дня предоставляет Пользователю
                    доступ к просмотру или прослушиванию Онлайн-курса посредством функционала Сервиса.</p>
                <p><strong>4.4.</strong>В течение 14 (четырнадцати) календарных дней с момента оплаты стоимости
                    Онлайн-курса
                    Пользователь вправе осуществить возврат стоимости Онлайн-курса.
                    Пользователь вправе осуществить возврат стоимости Онлайн-курса в случае, если общее время
                    просмотра Пользователем материала Онлайн-курса не превысило 3 (трех) часов.
                    Возврат стоимости Онлайн-курса осуществляется посредством направления Пользователем
                    электронного письма на адрес электронной почты GeekEnglish, указанный в разделе 12 настоящей
                    Оферты, с пометкой «Возврат стоимости онлайн-курса». В течение 3 (трех) рабочих дней после
                    получения электронного письма GeekEnglish осуществляет возврат ранее уплаченной
                    Пользователем стоимости Онлайн-курса.
                    Обязанность GeekEnglish по возврату стоимости Онлайн-курса считается исполненной с момента
                    списания денежных средств с расчетного счета GeekEnglish, указанного в разделе 12 настоящей
                    Оферты.</p>
                <p><strong>4.5.</strong>GeekEnglish имеет право отказать Пользователю в предоставлении доступа к Сервису
                    в любой
                    момент. При этом GeekEnglish пользуется правом не раскрывать Пользователю причины, по
                    которым Пользователю было отказано в предоставлении доступа к Сервису.</p>

                <h2>5. Порядок использования Сервиса Преподавателями</h2>
                <p><strong>5.1.</strong> С момента предоставления Пользователю доступа к Сервису в качестве
                    Преподавателя
                    Преподаватель вправе осуществлять размещение Онлайн-курса в Сервисе посредством
                    функционала Сервиса. Размещенный Преподавателем в Сервисе Онлайн-курс является Офертой
                    Преподавателя. Деятельность Преподавателя по размещению Онлайн-курса в Сервисе и получению оплаты за
                    приобретение Пользователем Онлайн-курса не является предпринимательской деятельностью по
                    смыслу статьи 2 ГК РФ, поскольку деятельность Преподавателя не носит самостоятельный характер
                    и осуществляется исключительно посредством агента – GeekEnglish.</p>
                <p><strong>5.2.</strong> GeekEnglish вправе по своему усмотрению отказать Преподавателю в размещении
                    Онлайн-
                    курса в Сервисе. При этом GeekEnglish пользуется правом не раскрывать Преподавателю причины,
                    по которым последнему было отказано в размещении Онлайн-курса в Сервисе.</p>
                <p><strong>5.3.</strong> Размещая Онлайн-курс в Сервисе, Преподаватель соглашается с тем, что
                    GeekEnglish является
                    его агентом в отношениях с Пользователями, действующим от своего имени, в интересах и за счет
                    Преподавателя.</p>
                <p><strong>5.4.</strong> Стоимость Онлайн-курса определяется Преподавателем самостоятельно при
                    размещении
                    Онлайн-курса посредством функционала Сервиса.</p>
                <p><strong>5.5.</strong> GeekEnglish имеет право отказать Преподавателю в предоставлении доступа к
                    Сервису в любой
                    момент, если Преподаватель не соответствует критериям надежности, устанавливаемым самим
                    GeekEnglish. При этом GeekEnglish пользуется правом не раскрывать Преподавателю причины, по
                    которым последний был отнесен к ненадежным контрагентам.</p>


                <h2>6. Порядок расчетов</h2>
                <p><strong>6.1.</strong> Приобретение Пользователем Онлайн-курса осуществляется посредством безналичной
                    оплаты
                    Пользователем отраженной в Сервисе стоимости Онлайн-курса по реквизитам GeekEnglish,
                    указанным в разделе 12 настоящей Оферты.
                    Обязательство Пользователя по оплате стоимости Онлайн-курса считается исполненным с момента
                    зачисления денежных средств на расчетный счет GeekEnglish, указанный в разделе 12 настоящей
                    Оферты.</p>
                <p><strong>6.2.</strong> Будучи агентом Преподавателя, GeekEnglish от своего имени осуществляет прием
                    оплаты
                    стоимости Онлайн-курса от Пользователя.
                    В течение 28 (двадцати восьми) календарных дней после получения оплаты стоимости Онлайн-
                    курса от Пользователя GeekEnglish обязуется перечислить Преподавателю стоимость Онлайн-курса
                    по реквизитам счета Преподавателя, указанным в анкете преподавателя онлайн-курса в разделе
                    «Профиль», за вычетом агентского вознаграждения GeekEnglish.
                    Размер агентского вознаграждения GeekEnglish согласуется с Преподавателем при размещении
                    Преподавателем Онлайн-курса в Сервисе.
                    Обязанность GeekEnglish по уплате Преподавателю стоимости Онлайн-курса считается
                    исполненной с момента списания денежных средств с расчетного счета GeekEnglish, указанного в
                    разделе 12 настоящей Оферты.</p>


                <h2>7. GeekEnglish Премиум</h2>
                <p><strong>7.1.</strong> Посредством функционала Сервиса (нажатия кнопки «Подключить премиум» в разделе
                    «Профиль» личного кабинета) Пользователь вправе приобрести премиум-доступ к функционалу
                    Сервиса, предоставляющий Пользователю дополнительные возможности по использованию
                    Сервиса.</p>
                <p><strong>7.2.</strong> Приобретение Пользователем премиум-доступа к функционалу Сервиса осуществляется
                    в
                    порядке, предусмотренном разделом 6 настоящей Оферты.</p>
                <p><strong>7.3.</strong> С дополнительными возможностями Пользователя по использованию Сервиса
                    Пользователь
                    может ознакомиться по адресу: <a
                        href='https://geekenglish.ru/premium'>https://geekenglish.ru/premium</a>.</p>
                <p><strong>7.4.</strong> Стоимость тарифов по приобретению премиум-доступа к функционалу Сервиса
                    определяется
                    GeekEnglish. С условиями тарифов на приобретение премиум-доступа к функционалу Сервиса
                    Пользователь может ознакомиться по адресу: <a
                        href='https://geekenglish.ru/premium'>https://geekenglish.ru/premium</a>.</p>
                <p><strong>7.5.</strong> Условия тарифов могут быть изменены GeekEnglish в одностороннем порядке. При
                    этом в
                    случае использования Пользователем премиум-доступа к функционалу Сервиса на условиях тарифа
                    и внесения Пользованием предоплаты в соответствии с ранее действовавшими условиями тарифа
                    Пользователь продолжает использовать премиум-доступа к функционалу Сервиса в соответствии с
                    ранее действовавшими условиями тарифа до истечения срока действия тарифа.</p>
                <p><strong>7.6.</strong> В случае изменения GeekEnglish условий используемого Пользователем тарифа и
                    продолжения
                    использования Пользователем премиум-доступа к функционалу Сервиса используемый
                    Пользователем тариф считается продленным на новый срок на новых условиях.</p>


                <h2>8. Интеллектуальная собственность</h2>
                <p><strong>8.1.</strong> Вся инфраструктура Сайта, а также его программное обеспечение и содержание, за
                    исключением материала, размещенного Преподавателем в рамках Онлайн-курса, являются
                    собственностью GeekEnglish.</p>
                <p><strong>8.2.</strong> Преподаватель сохраняет права на все материалы, размещенные им в рамках
                    Онлайн-курсов.</p>
                <p><strong>8.3.</strong> Любое использование размещенных на Сайте результатов интеллектуальной
                    деятельности без
                    разрешения законного правообладателя является незаконным. Размещение на Сайте
                    противоправного контента, в том числе контента, нарушающего охраняемые законом права третьих
                    лиц, запрещено. GeekEnglish имеет право удалить противоправный контент, а также заблокировать
                    (удалить аккаунт) Преподавателя, разместившего противоправный контент.</p>


                <h2>9. Ответственность Сторон</h2>
                <p><strong>9.1.</strong> GeekEnglish не несет ответственности перед Пользователем за качество материала,
                    размещаемого Преподавателем в рамках Онлайн-курса. GeekEnglish и Преподаватель не
                    осуществляют деятельность по оказанию образовательных услуг по смыслу законодательства
                    Российской Федерации.</p>
                <p><strong>9.2.</strong> Преподаватель несет ответственность за качество материала, размещаемого им в
                    рамках
                    Онлайн-курса.</p>
                <p><strong>9.3.</strong> Преподаватель обязуется возместить GeekEnglish убытки, возникшие в связи с
                    деятельностью
                    Преподавателя по размещению Онлайн-курсов в Сервисе</p>


                <h2>10. Порядок разрешения споров</h2>
                <p><strong>10.1.</strong> Все споры и разногласия, возникающие между Сторонами в рамках настоящей Оферты
                    или в
                    связи с ней подлежат разрешению судом общей юрисдикции по месту ответчика.</p>
                <p><strong>10.2.</strong> До предъявления иска, вытекающего из настоящей Оферты, сторона, которая
                    считает, что ее
                    права нарушены (далее – заинтересованная сторона), обязана направить другой стороне
                    письменную претензию. Претензия должна содержать требования заинтересованной стороны и их
                    обоснование с указанием нарушенных другой стороной норм законодательства и (или) условий
                    Договора.</p>
                <p><strong>10.3.</strong> К претензии должны быть приложены копии документов, подтверждающих изложенные
                    в ней
                    обстоятельства.</p>
                <p><strong>10.4.</strong> Сторона, которая получила претензию, обязана ее рассмотреть и направить
                    письменный
                    мотивированный ответ другой стороне в течение 20 (двадцати) рабочих дней с момента получения
                    претензии. Заинтересованная сторона вправе передать спор на рассмотрение судебного органа по
                    истечении 20 (двадцати) рабочих дней со дня направления претензии.</p>

                <h2>11. Прочие условия</h2>
                <p><strong>11.1.</strong> Заявления, уведомления, извещения, требования или иные юридически значимые
                    сообщения,
                    с которыми закон или настоящая Оферта связывают наступление гражданско-правовых
                    последствий для другой стороны, направляются путем их отправки на адрес электронной почты по
                    данным, указанным Пользователем в регистрационной форме и предусмотренным разделом 12
                    настоящей Оферты, соответственно, либо путем их отправки посредством Telegram-бота
                    GeekEnglish.</p>
                <p><strong>11.2.</strong> Если иное не предусмотрено законом или настоящей Офертой, все юридически
                    значимые
                    сообщения по настоящей Оферте влекут для получающей их стороны наступление гражданско-
                    правовых последствий с момента доставки соответствующего сообщения ей или ее представителю.
                    Все юридически значимые сообщения по настоящей Оферте, направленные посредством их
                    отправки электронной почтой или мессенджером «Telegram», считаются полученными на
                    следующий рабочий день после их отправки адресату. Сообщение считается доставленным и в тех
                    случаях, если оно поступило лицу, которому оно направлено (адресату), но по обстоятельствам,
                    зависящим от него, не было ему вручено или адресат не ознакомился с ним.</p>
                <p><strong>11.3.</strong> Сайт или его элементы периодически могут быть частично или полностью
                    недоступны по
                    причине проведения профилактических или других работ технического характера,
                    обеспечивающих нормальное функционирование Сайта. В таком случае GeekEnglish не несет
                    обязательств по уведомлению Пользователей или Преподавателей или неполучению ими
                    информации.</p>
                <p><strong>11.4.</strong> GeekEnglish вправе в любой момент в одностороннем порядке изменить условия
                    настоящей
                    Оферты. В таком случае GeekEnglish размещает на Сайте новую редакцию оферты с пометкой даты
                    размещения новой редакции оферты. Актуальная редакция Оферты размещена в
                    телекоммуникационной сети «Интернет» по адресу: <a
                        href='https://geekenglish.ru/aferta'>https://geekenglish.ru/aferta</a>.</p>


                <h2>12. Реквизиты GeekEnglish</h2>
                <p>
                    Адрес 390039, Россия, Рязанская обл.,
                    г. Рязань, ул. Интернациональная, д. 22,
                    корп. 2, кв. 69<br />
                    Почта: <a href='mailto:GeekEnglish@yandex.com'>GeekEnglish@yandex.com</a><br />
                    Telegram: <a href='https://t.me/GeekenglishBot'>@GeekenglishBot</a><br />
                    Телефон: +7 (915) 608-81-87<br />
                    ИНН: 622901533825<br />
                    БИК: 044525974 в АО «ТБанк»<br />
                    р/c 40802810600002552409<br />
                    к/c 30101810145250000974
                </p>
            </div>
        </div>
    )
}

export default AfertaPage
