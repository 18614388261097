import { Button, Form, FormProps, Input, Modal, Select } from 'antd'
import { FC, useState } from 'react'
import TextArea from 'antd/es/input/TextArea'
import Constants from '../../const/Constants'
import * as API_AI from '../../shared/http/openai'
import { setWordListsRoutine, wordListsDataSelector } from '../../store/wordLists'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import WordListUtils from '../utils/WordListUtils'
import NotificationsUtils from '../utils/NotificationsUtils'

interface AddAICardListDialogProps {
    isOpen: boolean,
    close: () => void
}

const AddAICardListDialog: FC<AddAICardListDialogProps> = ({ isOpen, close }) => {

    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const wordLists = useAppSelector(wordListsDataSelector)
    const [form] = Form.useForm()

    type FieldType = {
        title?: string;
        description?: string;
        level?: string;
        count?: number;
        sideOneLang?: string;
        sideTwoLang?: string;
    };

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        setLoading(true)
        API_AI.generateAICardList(values).then(w => {
            const parseWorldList = WordListUtils.parseWorldList(w)
            dispatch(setWordListsRoutine([...wordLists, parseWorldList]))
            NotificationsUtils.openSuccessNotification('Список ' + parseWorldList.listName + ' успешно создан!')
            clearAndClose()
        }).finally(() => {
            setLoading(false)
        }).catch(() => {
            NotificationsUtils.openErrorNotification('Не удалось создать список')
        })
    }


    const levels = Constants.CEFR_LEVELS.map(item => {
        return { value: item, label: item }
    })

    const languages = Constants.LANGUAGES.map(item => {
        return { value: item.id, label: item.description }
    })

    const count = [10, 15, 20, 25, 30, 35, 40].map(item => {
        return { value: item, label: item }
    })

    const clearAndClose = () => {
        form.resetFields()
        close()
    }

    return (
        <Modal title='Создание списка карточек'
               onCancel={clearAndClose}
               footer={[]}
               open={isOpen}>
            <Form
                form={form}
                name='basic'
                labelCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete='off'
                onFinish={onFinish}
            >
                <Form.Item<FieldType>
                    name='title'
                    rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
                    label={'Название списка слов'}
                >
                    <Input />
                </Form.Item>
                <Form.Item<FieldType>
                    name='description'
                    label={'Описание'}
                >
                    <TextArea maxLength={600} rows={4}
                              placeholder={'Слова на какую тематику вы бы хотели тут видеть'} />
                </Form.Item>

                <Form.Item<FieldType>
                    name='level'
                    rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
                    label={'Уровень владения языком'}
                >
                    <Select
                        options={levels}
                        defaultValue={'Выбрать уровень'}
                    />
                </Form.Item>

                <Form.Item<FieldType>
                    name='sideOneLang'
                    rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
                    label={'Сторона 1'}
                >
                    <Select
                        defaultValue={'Выбрать язык'}
                        options={languages}
                    />
                </Form.Item>

                <Form.Item<FieldType>
                    name='sideTwoLang'
                    rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
                    label={'Сторона 2'}
                >
                    <Select
                        defaultValue={'Выбрать язык'}
                        options={languages}
                    />
                </Form.Item>

                <Form.Item<FieldType>
                    name='count'
                    rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
                    label={'Число карточек в списке'}
                >
                    <Select
                        defaultValue={'Выбрать число'}
                        options={count}
                    />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 9, span: 8 }}>
                    <Button loading={loading} type='primary' htmlType='submit'>
                        Добавить
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddAICardListDialog
