import { createSlice } from '@reduxjs/toolkit'
import { createNewWord, deleteWord, fetchWords, updateWord } from './extraReducers'
import WordUtils from '../../components/utils/WordUtils'

const slice = createSlice({
    name: 'words',
    isLoading: false,
    isLoaded: false,
    initialState: {
        data: []
    },
    reducers: {
        setWordsRoutine: (state, action) => {
            state.data = action.payload
        },
        clearWordsRoutine: (state) => {
            state.data = []
        },
        clearNotSavedWordsRoutine: (state) => {
            state.data = WordUtils.clearNotSavedWords(state.data)
        },
        setWordsLoadingRoutine: (state, action) => {
            state.isLoading = action.payload
        }
    },
    extraReducers: {
        [fetchWords.fulfilled]: (state, action) => {
            const words = [...state.data, ...WordUtils.parseWorlds(action.payload)]
            state.data = WordUtils.removeDuplicates(words)
            state.isLoading = false
            state.isLoaded = true
        },
        [fetchWords.pending]: (state) => {
            state.isLoading = true
            state.isLoaded = false
        },
        [fetchWords.rejected]: (state) => {
            state.isLoading = false
            state.isLoaded = true
        },

        [createNewWord.fulfilled]: (state, action) => {
            const { word, wordId } = action.payload
            state.data = state.data.map((w) => {
                if (w.id === wordId) {
                    return WordUtils.parseWorld(word)
                }
                return w
            })
            state.isLoading = false
            state.isLoaded = true
        },
        [createNewWord.pending]: (state, { meta }) => {
            const wordId = meta.arg.word.id
            state.data = state.data.map((w) => {
                return w.id === wordId ? WordUtils.loadWord(w, true) : w
            })
            state.isLoaded = false
        },
        [createNewWord.rejected]: (state, { meta }) => {
            const wordId = meta.arg.word.id
            state.data = state.data.map((w) => {
                return w.id === wordId ? WordUtils.loadWord(w, false) : w
            })
            state.isLoaded = true
            WordUtils.wordErrorMsg('Не удалось сохранить карточку')
        },

        [updateWord.fulfilled]: (state, action) => {
            const { word } = action.payload
            state.data = state.data.map((w) => {
                if (w.id === word.id) {
                    return WordUtils.parseWorld(word)
                }
                return w
            })
            state.isLoading = false
            state.isLoaded = true
        },
        [updateWord.pending]: (state, { meta }) => {
            const wordId = meta.arg.word.id
            state.data = state.data.map((w) => {
                return w.id === wordId ? WordUtils.loadWord(w, true) : w
            })
            state.isLoaded = false
        },
        [updateWord.rejected]: (state, { meta }) => {
            const wordId = meta.arg.word.id
            state.data = state.data.map((w) => {
                return w.id === wordId ? WordUtils.loadWord(w, false) : w
            })
            state.isLoaded = true
            WordUtils.wordErrorMsg('Не удалось обновить карточку')
        },

        [deleteWord.fulfilled]: (state, action) => {
            const wordId = action.payload.wordId
            state.data = state.data.filter((w) => w.id !== wordId)
            state.isLoaded = true
        },
        [deleteWord.pending]: (state, { meta }) => {
            const wordId = meta.arg.wordId
            state.data = state.data.map((w) => {
                return w.id === wordId ? WordUtils.loadWord(w, true) : w
            })
        },
        [deleteWord.rejected]: (state, { meta }) => {
            const wordId = meta.arg.wordId
            state.data = state.data.map((w) => {
                return w.id === wordId ? WordUtils.loadWord(w, false) : w
            })
            state.isLoaded = true
            WordUtils.wordErrorMsg('Не удалось удалить карточку')
        }
    }
})

export const { setWordsRoutine, clearWordsRoutine, setWordsLoadingRoutine, clearNotSavedWordsRoutine } = slice.actions
export const wordsDataSelector = state => state.words.data
export const wordsIsisLoadingStatusSelector = (state) => state.words.isLoading
export default slice.reducer
