export const test = [
    {
        id: 1,
        title: 'Alex ____ a good job..',
        variants: [
            { id: 1, title: 'finds', isTrue: false },
            { id: 2, title: 'has found', isTrue: true },
            { id: 3, title: 'founded', isTrue: false },
            { id: 4, title: 'have found', isTrue: false }
        ]
    },
    {
        id: 2,
        title: 'I ____ a new flat.',
        variants: [
            { id: 1, title: 'bought', isTrue: false },
            { id: 2, title: 'have been buying', isTrue: false },
            { id: 3, title: 'have bought', isTrue: true },
            { id: 4, title: 'buy', isTrue: false }
        ]
    },
    {
        id: 3,
        title: ' ____ you ever  ____ to Ryazan?',
        variants: [
            { id: 1, title: 'Has, been', isTrue: false },
            { id: 2, title: 'Have, been', isTrue: true },
            { id: 3, title: 'Have, were', isTrue: false },
            { id: 4, title: 'Have, be', isTrue: false }
        ]
    },
    {
        id: 4,
        title: ' ____ you ____ breakfast today?',
        variants: [
            { id: 1, title: 'Has, eaten', isTrue: false },
            { id: 2, title: 'Have, ate', isTrue: false },
            { id: 3, title: 'Have, eaten', isTrue: true },
            { id: 4, title: 'Have, eat', isTrue: false }
        ]
    },
    {
        id: 5,
        title: 'I ____ well for 3 nights.',
        variants: [
            { id: 1, title: 'hasn’t sleept', isTrue: false },
            { id: 2, title: 'hasn’t slept', isTrue: false },
            { id: 3, title: 'haven’t sleept', isTrue: false },
            { id: 4, title: 'haven’t slept', isTrue: true }
        ]
    },
    {
        id: 6,
        title: 'I ____ not ____ him since June.',
        variants: [
            { id: 1, title: 'did, see', isTrue: false },
            { id: 2, title: 'did, seen', isTrue: false },
            { id: 3, title: 'have, saw', isTrue: false },
            { id: 4, title: 'have, seen', isTrue: true }
        ]
    },
    {
        id: 7,
        title: 'I haven’t done it ____ .',
        variants: [
            { id: 1, title: 'yet', isTrue: true },
            { id: 2, title: 'already', isTrue: false },
            { id: 3, title: 'just', isTrue: false },
            { id: 4, title: 'since, were having', isTrue: false }
        ]
    },
    {
        id: 8,
        title: 'She’s ____ the letter. She ____ it yesterday.',
        variants: [
            { id: 1, title: 'wrote / wrote', isTrue: false },
            { id: 2, title: 'written / written', isTrue: false },
            { id: 3, title: 'wrote / written', isTrue: false },
            { id: 4, title: 'written / wrote', isTrue: true }
        ]
    }
]
