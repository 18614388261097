export const test = [
    {
        id: 1,
        title: 'Listen. I ____ hear something.',
        variants: [
            { id: 1, title: 'could', isTrue: false },
            { id: 2, title: 'can', isTrue: true },
            { id: 3, title: 'is can', isTrue: false },
            { id: 4, title: 'could have', isTrue: false }
        ]
    },
    {
        id: 2,
        title: "Tom didn't make it to my birthday party. He ____ been stuck at work.",
        variants: [
            { id: 1, title: 'can', isTrue: false },
            { id: 2, title: 'is can', isTrue: false },
            { id: 3, title: 'could', isTrue: false },
            { id: 4, title: 'could have', isTrue: true }
        ]
    },
    {
        id: 3,
        title: 'I ran as fast as I ____ , but I was still late.',
        variants: [
            { id: 1, title: 'can', isTrue: false },
            { id: 2, title: 'could', isTrue: true },
            { id: 3, title: 'able to', isTrue: false },
            { id: 4, title: 'could have', isTrue: false }
        ]
    },
    {
        id: 4,
        title: '____ you hold the beer, please?',
        variants: [
            { id: 1, title: 'could have', isTrue: false },
            { id: 2, title: 'can', isTrue: false },
            { id: 3, title: 'could', isTrue: true },
            { id: 4, title: 'couldn’t', isTrue: false }
        ]
    },
    {
        id: 5,
        title: 'You ____ give her a book.',
        variants: [
            { id: 1, title: 'could', isTrue: true },
            { id: 2, title: 'couldn’t', isTrue: false },
            { id: 3, title: 'can', isTrue: false },
            { id: 4, title: 'could have', isTrue: false }
        ]
    },
    {
        id: 6,
        title: 'The situation was bad, but it ____ worse.',
        variants: [
            { id: 1, title: 'could', isTrue: false },
            { id: 2, title: 'could have', isTrue: false },
            { id: 3, title: 'could', isTrue: false },
            { id: 4, title: 'could have been', isTrue: true }
        ]
    },
    {
        id: 7,
        title: 'We ____ go to Russia.',
        variants: [
            { id: 1, title: 'could', isTrue: true },
            { id: 2, title: 'can', isTrue: false },
            { id: 3, title: 'could have', isTrue: false },
            { id: 4, title: 'is could', isTrue: false }
        ]
    }
]
