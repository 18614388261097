import { Component } from 'react'

class Slider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            text: '',
            isDeleting: false,
            loopNum: 0,
            typingSpeed: 50,
            awaitBeforeDeleting: 1500,
            awaitBeforeNextSlide: 1500,
            width: 0,
            left: 0,
            currentImage: '',
            background: '',
            color: ''
        }
    }

    componentDidMount() {
        const slide = this.props.data[0]
        this.setState({
            width: `${this.props.data.length * 100}%`,
            currentImage: slide.img,
            color: slide.color,
            background: slide.background
        })
        this.handleType()
    }

    handleType = () => {
        const { data } = this.props
        const { isDeleting, loopNum, text, typingSpeed } = this.state
        const i = loopNum % data.length
        const fullText = data[i].title

        this.setState({
            text: isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1),
            typingSpeed: isDeleting ? 30 : 50
        })

        if (!isDeleting && text === fullText) {
            setTimeout(() => this.setState({ isDeleting: true }), this.state.awaitBeforeDeleting)
        } else if (isDeleting && text === '') {
            const index = i === data.length - 1 ? 0 : i + 1
            this.setState({
                isDeleting: false,
                loopNum: loopNum + 1,
                left: `-${index * 100}%`,
                currentImage: data[index].img,
                color: data[index].color,
                background: data[index].background
            })
            setTimeout(this.handleType, this.state.awaitBeforeNextSlide)
            return
        }
        setTimeout(this.handleType, typingSpeed)
    }

    render() {
        return (
            <div className={'slider'}>
                <div className={'slider__content'}>
                    <img className={'slider__img'} src={this.state.currentImage} alt={'Slider english'} />
                    <div className={'slider__text-wrap'}>
                        <h2 className={'slider__text'} style={{ color: this.state.color }}>
                            {this.state.text}
                            <span className={'slider__cursor'} style={{ color: this.state.color }}>
                                |
                            </span>
                        </h2>
                        <div className={'slider__button-container'}>
                            <button
                                className={'slider__button'}
                                onClick={this.props.onClick}
                                style={{ background: this.state.color }}
                            >
                                Зарегистрироваться
                            </button>
                        </div>
                    </div>
                </div>
                <div className={'slider__background'} style={{ left: this.state.left, width: this.state.width }}>
                    {this.props.data.map((item, key) => (
                        <div className={'slider__item'} style={{ background: item.background }} key={key} />
                    ))}
                </div>
            </div>
        )
    }
}

export default Slider
