import '../../../styles/css/elements/svg_icons/icon-btn.css'

function IconEyeCross(props) {
    const { onClick = () => null } = props
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='30'
            height='30'
            fill='none'
            stroke='#000'
            onClick={onClick}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            className='icon icon-tabler icon-tabler-eye-off'
            viewBox='0 0 24 24'
        >
            <title>Скрыть перевод</title>
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <path d='M3 3L21 21'></path>
            <path d='M10.584 10.587a2 2 0 002.828 2.83M9.363 5.365A9.466 9.466 0 0112 5c4 0 7.333 2.333 10 7-.778 1.361-1.612 2.524-2.503 3.488m-2.14 1.861C15.726 18.449 13.942 19 12 19c-4 0-7.333-2.333-10-7 1.369-2.395 2.913-4.175 4.632-5.341'></path>
        </svg>
    )
}
export default IconEyeCross
