import { executePostMethod } from '../api/rest/restApiUtils'

/**
 * Создать список слов
 * @method create
 * @return {Response} Fetch Response
 * @param listId - wl id
 */
export function submit(listId) {
    return executePostMethod(`/submit-donewordlist/submit/${listId}`).then((resp) => {
        return Promise.resolve(resp.data)
    })
}
