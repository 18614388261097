import '../../../styles/css/elements/svg_icons/icon-btn.css'

function IconBtnEdit(props) {
    return (
        <svg
            onClick={() => (props.onClick ? props.onClick() : false)}
            className={'icon-btn ' + props.class}
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
        >
            <title>Редактировать</title>
            <path
                fill='#000'
                fillRule='evenodd'
                d='M18.086 1.5a2 2 0 012.828 0L22.5 3.086a2 2 0 010 2.828l-6.5 6.5a2 2 0 01-1.414.586H12a1 1 0 01-1-1V9.414A2 2 0 0111.586 8l6.5-6.5zm3 3L19.5 2.914l-6.5 6.5V11h1.586l6.5-6.5zM4 4a1 1 0 00-1 1v15a1 1 0 001 1h15a1 1 0 001-1v-8a1 1 0 112 0v8a3 3 0 01-3 3H4a3 3 0 01-3-3V5a3 3 0 013-3h8a1 1 0 110 2H4z'
                clipRule='evenodd'
            />
        </svg>
    )
}

export default IconBtnEdit
