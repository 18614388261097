import { FC } from 'react'
import { GEButton } from '../../../../../common/buttons'

interface RememberButtonsProps {
    notRememberState: boolean
    remember: () => void
    notRemember: () => void
    nextAndRotateWord: () => void
}

const RememberButtons: FC<RememberButtonsProps> = ({ remember, notRemember, notRememberState, nextAndRotateWord }) => {
    return (
        <div className='word-card__btn-area'>
            <GEButton
                className={notRememberState ? ' width270px wordButton__disabled' : 'width270px'}
                disabled={notRememberState}
                id='nextWord'
                type='primary'
                size='large'
                onClick={remember}
            >Помню</GEButton>
            <GEButton
                className='width270px wordButton__disabled'
                id='prewWord'
                size='large'
                type='primary'
                danger={!notRememberState}
                onClick={notRememberState ? nextAndRotateWord : notRemember}
            >{notRememberState ? 'Следующее' : 'Не помню'}</GEButton>
        </div>
    )
}

export default RememberButtons
