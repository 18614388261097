import { FC } from 'react'
import IconVolumeUp from '../../../../elements/svg_icons/IconVolumeUp'
import { WordList } from '../../../../../shared/types/api'
import IconVolumeOff from '../../../../elements/svg_icons/IconVolumeOff'

interface SoundButtonProps {
    playWord: () => void,
    wordList: WordList
}

const SoundButton: FC<SoundButtonProps> = ({ playWord, wordList }) => {
    return (
        <>
            <IconVolumeUp
                stroke='#000000'
                className={wordList && wordList.sideOneLang && wordList.sideTwoLang ? 'btn-sound' : 'none'}
                onClick={() => playWord()}
                aria-hidden='true'
                width={'48'}
                height={'48'}
            />
            <IconVolumeOff stroke='#000000'
                           className={wordList && wordList.sideOneLang && wordList.sideTwoLang ? 'none' : 'btn-sound'}
                           aria-hidden='true' />
        </>
    )
}

export default SoundButton
