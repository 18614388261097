import { FC, useState } from 'react'
import { Modal, Tooltip } from 'antd'
import { GEButton } from '../../../../../common/buttons'
import * as API_DONE_WORDLIST from '../../../../../shared/http/done-word-list'
import { handleHttpError } from '../../../../../common/utils/errors'
import { WordList } from '../../../../../shared/types/api'
import { useNavigate } from 'react-router-dom'


interface CopyDoneCardListBtnProps {
    wordList: WordList,
    updateUserWordLists: () => void
}

const CopyDoneCardListBtn: FC<CopyDoneCardListBtnProps> = ({
                                                               wordList,
                                                               updateUserWordLists
                                                           }) => {

    const navigate = useNavigate()

    const [loading, setLoading] = useState<boolean>(false)

    const content = (<>Список успешно добавлен. Теперь доступен в <a
        href={'https://geekenglish.ru/dictionary'}>словаре</a></>)

    const copyDoneWordList = () => {
        setLoading(true)
        API_DONE_WORDLIST.copyDoneWordList(wordList.id)
            .then(() => {
                Modal.success({
                    content: content
                })
                updateUserWordLists()
            })
            .catch((err) => {
                handleHttpError(err, navigate)
            })
            .finally(() => {
                    setLoading(false)
                }
            )
    }

    return (
        <Tooltip title='Список добавится в словарь, и его можно будет редактировать'>
            <div
                className={'pointer_cursor add_button'}
                onClick={copyDoneWordList}
            >
                <GEButton
                    loading={loading}
                    className='width170px margin10top'
                    type='primary'
                >
                    Добавить
                </GEButton>
            </div>
        </Tooltip>
    )
}

export default CopyDoneCardListBtn
