
const IconPen = (props: { onClick?: (() => void) }) => {
    const { onClick = () => null } = props
    return (

        <svg onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.4 18.161L18.796 10.765C17.5517 10.2455 16.4216 9.48642 15.47 8.531C14.5142 7.57921 13.7547 6.44877 13.235 5.204L5.83902 12.6C5.26202 13.177 4.97302 13.466 4.72502 13.784C4.43213 14.1593 4.18098 14.5653 3.97602 14.995C3.80302 15.359 3.67402 15.747 3.41602 16.521L2.05402 20.604C1.99133 20.7909 1.98203 20.9917 2.02716 21.1836C2.07229 21.3756 2.17007 21.5511 2.30949 21.6905C2.44891 21.83 2.62446 21.9277 2.81641 21.9729C3.00835 22.018 3.20907 22.0087 3.39602 21.946L7.47902 20.584C8.25402 20.326 8.64102 20.197 9.00502 20.024C9.43502 19.819 9.84102 19.568 10.216 19.275C10.534 19.027 10.823 18.738 11.4 18.161ZM20.848 8.713C21.5855 7.97557 21.9997 6.97539 21.9997 5.9325C21.9997 4.88961 21.5855 3.88943 20.848 3.152C20.1106 2.41457 19.1104 2.00028 18.0675 2.00028C17.0246 2.00028 16.0245 2.41457 15.287 3.152L14.4 4.039L14.438 4.15C14.8751 5.40072 15.5904 6.5359 16.53 7.47C17.492 8.4377 18.667 9.16711 19.961 9.6L20.848 8.713Z"
                fill="#4174F8"/>
        </svg>

)
}
export default IconPen
