import PresentSimpleRule from './items/presentsimple/PresentSimpleRule'
import ActivePassiveVoice from './items/activepassivevoice/ActivePassiveVoice'
import MustAndCant from './items/mustandcant/MustAndCant'
import CanCouldAndAbleTo from './items/cancouldandableto/CanCouldAndAbleTo'
import CouldCouldHave from './items/couldcouldhave/CouldCouldHave'
import PresentContinuous from './items/presentcontinious/PresentContinuous'
import PastContinuous from './items/pastcontinuous/PastContinuous'
import PresentPerfect from './items/presentperfect/PresentPerfect'
import PresentPerfectContinuous from './items/presentperfectcontinuous/PresentPerfectContinuous'
import PastSimple from './items/pastsimple/PastSimple'
import FutureSimple from './items/futuresimple/FutureSimple'
import FutureContinuous from './items/futurecontinuous/FutureContinuous'
import FuturePerfect from './items/futureperfect/FuturePerfect'
import PastPerfect from './items/pastperfect/PastPerfect'
import PastPerfectContinuous from './items/pastperfectcontinuous/PastPerfectContinuous'
import FuturePerfectContinuous from './items/futureperfectcontinuous/FuturePerfectContinuous'

export const RulesMap = {
    PresentSimpleRule,
    ActivePassiveVoice,
    MustAndCant,
    CanCouldAndAbleTo,
    CouldCouldHave,
    PresentContinuous,
    PastContinuous,
    PresentPerfect,
    PresentPerfectContinuous,
    PastSimple,
    FutureSimple,
    FutureContinuous,
    FuturePerfect,
    PastPerfect,
    PastPerfectContinuous,
    FuturePerfectContinuous
}
