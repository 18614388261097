import { CartesianGrid, BarChart, Tooltip, XAxis, YAxis, Legend, Bar, ResponsiveContainer } from 'recharts'

const ChartBar = () => {
    const getDate = (ts) => {
        const date = new Date(ts)
        return (date.getMonth() || 12) + '/' + date.getFullYear()
    }

    const data = Array(7)
        .fill({})
        .map((item, index, arr) => ({
            name: getDate(Date.now() - (arr.length - index) * 31 * 24 * 60 * 60 * 1000),
            'Выучено слов': Math.round(Math.random() * 500),
            Ошибок: Math.round(Math.random() * 500)
        }))

    return (
        <ResponsiveContainer width='60%' height={400} className={'responsive-statistic-container'}>
            <BarChart data={data} style={{ fontSize: '1rem' }}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='name' />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey='Выучено слов' fill='#0088FE' unit={' слов'} />
                <Bar dataKey='Ошибок' fill='#FF8042' unit={' слов'} />
            </BarChart>
        </ResponsiveContainer>
    )
}

export default ChartBar
