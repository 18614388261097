import { FC, useEffect } from 'react'
import {
    wordListsDataSelector
} from '../../../../store/wordLists'
import '../../../../styles/scss/pages/dictionary/card-list/card-list.scss'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { CommunityDto, WordDto, WordList } from '../../../../shared/types/api'
import { userSelector } from '../../../../store/global'
import { wordsDataSelector } from '../../../../store/words'
import { selectCardList } from '../../../helpers/card-list-helper'
import { Flex, Modal } from 'antd'
import WordLists from './wordlists/WordLists'
import { fetchCommunityWorldListsById } from '../../../../store/wordLists/extraReducers'
import AddCommunityWordListButton from './wordlists/AddCommunityWordListButton'

interface CommunityWordListsProps {
    community: CommunityDto,
}

const DictionaryCommunityLists: FC<CommunityWordListsProps> = ({
                                                                   community
                                                               }) => {
    const user = useAppSelector(userSelector)
    const wordLists = useAppSelector(wordListsDataSelector)
    const cards = useAppSelector(wordsDataSelector)
    const dispatch = useAppDispatch()

    const loadCommunityWordLists = (id: number) => {
        if (!wordLists.some((wl: WordList) => wl.communityId === community.id)) {
            dispatch(fetchCommunityWorldListsById(id)).catch((err) => {
                Modal.error({
                    title: 'Ошибка!',
                    content: 'Не удалось загрузить списки карточек'
                })
            })
        }
    }

    useEffect(() => {
        return cancelAllWorListChanges()
    }, [])


    useEffect(() => {
        loadCommunityWordLists(community.id)
    }, [community?.id])

    const cancelAllWorListChanges = () => {
        selectCardList(dispatch, 'null-list', wordLists as Array<WordList>, cards as Array<WordDto>)
    }

    const isNotInEditMode = () => {
        return !wordLists.some((wl: WordList) => wl.editMode)
    }

    return (
        <div className='dictionary' id='dictionary'>
            <Flex justify='flex-start' className='dictionary_btn'>
                {isNotInEditMode() &&
                <AddCommunityWordListButton
                    community={community}
                    canEdit={community.canEdit} />}
            </Flex>

            <div className='dictionary_container'>
                <WordLists
                    wordLists={wordLists}
                    user={user}
                    isCommunity={true}
                    communityId={community.id}
                    canEditCommunity={community.canEdit}
                    inviteKey={community.inviteKey}
                />
            </div>
        </div>
    )
}

export default DictionaryCommunityLists
