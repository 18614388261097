import { FC } from 'react'
import Confetti from 'react-confetti'

interface ConfettiOnTheEndProps {
    showConfetti: boolean
}

const ConfettiOnTheEnd: FC<ConfettiOnTheEndProps> = ({ showConfetti }) => {
    return (
        <>
            {showConfetti && <Confetti
                recycle={false}
                width={window.screen.width}
                height={window.screen.height}
            />}
        </>

    )
}

export default ConfettiOnTheEnd