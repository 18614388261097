import { useEffect, useState } from 'react'
import '../../../../styles/css/test/testcss.css'
import '../../../../styles/css/main_styles.css'
import SelectCategory from '../../../elements/selectors/SelectCategory'
import CompletedTestDialog from '../../../modalwindows/CompletedTestDialog'
import WordsListTestCombineSelector from '../../../blocks/combine-selectors/WordsListTestCombineSelector'
import NewCommerceUtil from '../../../utils/NewCommerceUtil'
import Pages from '../../../../const/Pages'
import { setUserRoutine, userSelector } from '../../../../store/global'
import { Modal } from 'antd'
import { Helmet } from 'react-helmet'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { CardHeader } from '../../../blocks/ui/CardHeader'
import { TestQuestion } from './TestQuestion'
import { TestAnswers } from './TestAnswers'
import { removeElementFromArray, shuffleArray } from '../../../../common/utils/array'
import useCombineSelector from '../../../../hooks/useCombineSelector'
import { TestWrongAnswersDialog } from '../../../modalwindows/TestWrongAnswersDialog'
import { UserDto, WordDto } from '../../../../shared/types/api'
import ConfettiOnTheEnd from '../../../blocks/confetti-on-the-end/ConfettiOnTheEnd'

export const Test = () => {
    const dispatch = useAppDispatch()

    const user = useAppSelector(userSelector)
    const setReactUser = (uUser: UserDto) => dispatch(setUserRoutine(uUser))


    const [activeWords, setActiveWords] = useState<Array<WordDto>>([])
    const [index, setIndex] = useState<number>(0)
    const [answerIndexes, setAnswerIndexes] = useState<Array<number>>([])
    const [highlight, setHighlight] = useState<string>('')
    const [wrongAnswers, setWrongAnswers] = useState<Array<WordDto>>([])

    //Модалочка
    const [showCompletedTestMW, setShowCompletedTestMW] = useState<boolean>(false)
    const [showWrongAnswersDialog, setShowWrongAnswersDialog] = useState<boolean>(false)
    const [restartCounter, setSetRestartCounter] = useState<number>(0)


    const restartTest = () => {

        setShowCompletedTestMW(false)
        setSetRestartCounter(restartCounter + 1)
        startTest(getActualWordsInMemory(selectedWordListId as number))
    }

    const showWrongWords = () => {
        setShowCompletedTestMW(false)
        setShowWrongAnswersDialog(true)
        //testView.showWrongWords()
    }

    const startTest = (words: Array<WordDto>) => {
        setWrongAnswers([])
        setActiveWords([])
        setIndex(0)
        if (words.length > 4) {
            const array = generateAnswerIndexes(words.length, index)
            const shuffledWords = shuffleArray(words)
            setActiveWords(shuffledWords)
            setAnswerIndexes(array)
        } else {
            setActiveWords([])
            showWarningPrompt()
        }
    }

    const {
        selectedWordListId,
        communities,
        wordListsIsLoading,
        side,
        getActualWordsInMemory,
        changeWordListById,
        changeSideValue
    } = useCombineSelector(startTest)

    const generateAnswerIndexes = (arrayLength: number, correctIndex: number) => {
        const numbers = Array.from(Array(arrayLength).keys())
        const array = shuffleArray(removeElementFromArray(numbers, correctIndex))
        const slicedArray = array.slice(0, 3)
        slicedArray.push(correctIndex)
        return shuffleArray(slicedArray)
    }

    const closeCompletedTestMW = () => {
        setShowCompletedTestMW(false)
        restartTest()
    }

    const updateUserIfNew = () => {
        const newUser = NewCommerceUtil.showNewCommerceModal(user, Pages.TEST)
        if (newUser) {
            setReactUser(newUser)
        }
    }

    const showWarningPrompt = () => {
        const t = {
            title: 'Неверный список карточек',
            content: 'В списке должно быть минимум 5 карточек. Можете добавить их в словаре или выбрать другой список.'
        }
        Modal.warning(t)
    }


    useEffect(() => {
        updateUserIfNew()
        return () => {
        }
    }, [])


    //number
    const answerBtnClick = (id: number) => {
        if (id === index) {
            blinkQuestion('highlightRight')
        } else {
            setWrongAnswers([...wrongAnswers, activeWords[id]])
            blinkQuestion('highlightFalse')
        }
        const nextIndex = index + 1
        //Если прошли все слова  то
        if (nextIndex === activeWords.length) {
            //Покажем статистику
            setShowCompletedTestMW(true)
        } else {
            setIndex(nextIndex)
            const array = generateAnswerIndexes(activeWords.length, nextIndex)
            setAnswerIndexes(array)
        }
    }

    /**
     * Подсветить на пол секунды верно или не верно
     * @param highlightClass - либо красный цвет, либо зеленый
     */
    const blinkQuestion = (highlightClass: string) => {
        setHighlight(highlightClass)
        setTimeout(() => setHighlight(''), 500)
    }

    const closeShowWrongAnswersDialog = () => {
        setShowWrongAnswersDialog(false)
        restartTest()
    }

    return (
        <div id='test'>
            <Helmet title='GE: Тест' />

            {activeWords.length > 4 && <CardHeader current={index + 1} max={activeWords.length} />}
            {activeWords.length > 4 &&
            <TestQuestion highlight={highlight} side={side} index={index} words={activeWords} />}
            {activeWords.length > 4 &&
            <TestAnswers side={side} answerBtnClick={answerBtnClick} answerIndexes={answerIndexes}
                         words={activeWords} />}
            {activeWords.length <= 4 && <SelectCategory message='Выберите список карточек' />}

            <WordsListTestCombineSelector
                changeWordList={changeWordListById}
                restartTest={restartTest}
                communities={communities}
                showWordListLoader={wordListsIsLoading}
                changeSide={changeSideValue}
                selectedWordListId={selectedWordListId}
            />

            {/*  Модальные окна*/}
            {showCompletedTestMW && <CompletedTestDialog
                open={showCompletedTestMW}
                rightAnswers={activeWords.length - wrongAnswers.length}
                amountWordsInTest={activeWords.length}
                wrongAnswers={wrongAnswers}
                repeat={restartTest}
                cancelWindow={closeCompletedTestMW}
                showWongWords={showWrongWords}
                selectedWordListId={selectedWordListId}
            />}
            <TestWrongAnswersDialog open={showWrongAnswersDialog && wrongAnswers.length > 0}
                                    wrongAnswers={wrongAnswers}
                                    close={closeShowWrongAnswersDialog} />
            <ConfettiOnTheEnd showConfetti={showCompletedTestMW} />
        </div>
    )
}

export default Test
