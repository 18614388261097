import Letter from './Letter'
import { FC } from 'react'
import { ID } from '../../../../../shared/types/api'
import { letterValue } from '../utils/GuessWordUtils'

interface WordProps {
    wordArray: Array<{ value: string, realValue: string, id: ID }>,
    updateUserAnswers: (p: letterValue) => void
}

const Word: FC<WordProps> = ({ wordArray, updateUserAnswers }) => {

    return (
        <div className='guess-word__word'>
            {wordArray.map((letter, letterId) => (
                <Letter key={letterId} value={letter} updateUserAnswers={updateUserAnswers} />
            ))}
        </div>
    )
}

export default Word