import { useEffect, useState } from 'react'
import * as API_USER from '../../../../shared/http/user'
import '../../../../styles/css/newconsomer/newconsomer.css'
import NewComersSections from './NewComersSections'
import Loading from '../../../elements/loading/Loading'
import { Helmet } from 'react-helmet'
import { useAppSelector } from '../../../../store/hooks'
import { userSelector } from '../../../../store/global'
import { NavigationPages } from '../../../../common/navigation/navigation-pages'
import { removeToken, saveToken } from '../../../../store/memory/memory'
import { goToPageNotPremium } from '../../../utils/goToPage'
import { useNavigate } from 'react-router-dom'

const NewComers = () => {
    const navigate = useNavigate()
    const [verification, setVerification] = useState(false)
    const user = useAppSelector(userSelector)

    useEffect(() => {
        if (!user) {
            verificationAccountEmail()
        } else {
            goToPageNotPremium(`${NavigationPages.PROFILE}/${user.id}`, navigate)
        }

    }, [])

    const verificationAccountEmail = () => {
        let urlParams = new URLSearchParams(window.location.search)
        let verificationEmailDto = {
            userId: urlParams.get('id'),
            token: urlParams.get('token')
        }
        API_USER.verificate(verificationEmailDto)
            .then((resp) => {
                removeToken()
                saveToken(resp.token)
                setVerification(true)
            })
            .catch(() => setVerification(false))
    }

    return (
        <div id='new-consomer'>
            <Helmet title='GE: Добро пожаловать' />
            {verification ? <NewComersSections /> : <Loading />}
        </div>
    )
}

export default NewComers
