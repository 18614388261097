import '../../../styles/css/elements/svg_icons/icon-btn.css'

function IconPlus(props) {
    const {
        className = '',
        onClick = () => null,
        stroke = '#ffffff',
        width = '35',
        height = '35',
        viewBox = '0 0 24 24'
    } = props

    return (
        <svg
            onClick={onClick}
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            fill='none'
            stroke={stroke}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.7'
            className={'icon icon-tabler icon-tabler-plus ' + className}
            viewBox={viewBox}
        >
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <path d='M12 5L12 19'></path>
            <path d='M5 12L19 12'></path>
        </svg>
    )
}

export default IconPlus
