import '../../styles/css/dictionary/modalwindow/modalw.css'
import {FC} from "react";
import {Button, Modal} from "antd";

interface InfoWordsDictionaryDialogProps {
    cancelWindow: () => void
    open: boolean;
}

const InfoWordsDictionaryDialog: FC<InfoWordsDictionaryDialogProps> = ({cancelWindow, open}) => {

    return (
        <Modal onCancel={cancelWindow}
               footer={[
                   <Button type='primary' onClick={cancelWindow}>
                       ЗАКРЫТЬ
                   </Button>,
               ]}
               open={open}>
            <h2>
                <b>Как составить карточки?</b>
            </h2>
            <div className='modal-dialog__info-words'>
                <div>
                    <span className='modal-dialog__info-words_header'>Слово/фраза – перевод:</span>
                    <span>
                            <span
                                className='modal-dialog__info-words_side-first'>To fight like cat and dog </span>-{' '}
                        <span className='modal-dialog__info-words_side-second'>жить как кошка с собакой</span>
                        </span>
                </div>
                <div>
                    <span className='modal-dialog__info-words_header'>Определение - слово/фраза:</span>
                    <span>
                            <span className='modal-dialog__info-words_side-first'>To argue all the time </span>-{' '}
                        <span className='modal-dialog__info-words_side-second'>to fight like cat and dog</span>
                        </span>
                </div>
                <div>
                    <span className='modal-dialog__info-words_header'>Фраза с пропуском – пропущенное слово:</span>
                    <span>
                            <span
                                className='modal-dialog__info-words_side-first'>To fight like ___ and dog </span>–{' '}
                        <span className='modal-dialog__info-words_side-second'>cat</span>
                        </span>
                </div>
                <div>
                    <span className='modal-dialog__info-words_header'>Ключевое слово - фраза: </span>
                    <span>
                            <span className='modal-dialog__info-words_side-first'>Cat </span>-{' '}
                        <span className='modal-dialog__info-words_side-second'>to fight like cat and dog</span>
                        </span>
                </div>
            </div>
        </Modal>
    )
}

export default InfoWordsDictionaryDialog
