import '../../../../styles/scss/courses/courses-card.scss'
import { GEButton } from '../../../../common/buttons'
import { goToPageNotPremium } from '../../../utils/goToPage'
import { NavigationPages } from '../../../../common/navigation/navigation-pages'
import { useNavigate } from 'react-router-dom'
import { FC } from 'react'
import { Flex } from 'antd'
import { SmallCourseDto } from '../../../../shared/types/api'

interface CoursesCardProps {
    card: SmallCourseDto
    key: number,
}

export const CoursesCard: FC<CoursesCardProps> = ({ card, key }) => {

    const navigate = useNavigate()

    return (
        <div className='courses-card__container'>
            <span className='courses-card__card-name'>{card.title}</span>
            <Flex gap='32px' className='courses-card__card-container'>
                <img src={'../../images/tmp/course1.jpg'} alt={card.title} className='courses-card__img' />
                <span className='courses-card__description'>{card.description}</span>
            </Flex>
            <Flex justify='flex-end'>
                <GEButton onClick={() => goToPageNotPremium(`${NavigationPages.COURSES_OVERVIEW}/1`, navigate)}>
                    Перейти к курсу
                </GEButton>
            </Flex>
        </div>


    )
}