import {FC} from 'react'
import '../../styles/scss/modal-learn-card/modal-learn-card.scss'
import {Button, Divider, Flex, Modal} from 'antd'
import {WordList} from "../../shared/types/api";
import {goToExercisePage} from "../utils/goToPage";
import {NavigationPages} from "../../common/navigation/navigation-pages";
import {useNavigate} from "react-router-dom";

interface LearnCardDialogProps {
    wordList: WordList,
    close: () => void,
    open: boolean;
}

const LearnCardDialog: FC<LearnCardDialogProps> = ({close, open, wordList}) => {

    const navigate = useNavigate()

    return (
        <Modal onCancel={close}
               destroyOnClose={true}
               title={'Как будем учить?'}
               footer={[
                   <Button
                       onClick={close}
                       type='primary'
                   >
                       Закрыть
                   </Button>
               ]}
               open={open}>

            <div className='learn-card__container'>
                <Flex gap='16px'
                      justify='center'
                      align='center'
                      className='learn-card__hover'
                      onClick={() => goToExercisePage(NavigationPages.CARDS, navigate,wordList)}
                >
                    <img src='/images/mainpage/cards/cards.png' alt='Карточки' width='40px' height='40px'/>
                    <span className='learn-card__span-text'>Карточки</span>
                </Flex>
                <Divider/>
                <Flex gap='16px' justify='center' align='center' className='learn-card__hover'
                      onClick={() => goToExercisePage(NavigationPages.TEST, navigate,wordList)}
                >
                    <img src='/images/mainpage/cards/test.png' alt='Тесты' width='40px' height='40px'/>
                    <span className='learn-card__span-text'>Тесты</span>
                </Flex>
                <Divider/>
                <Flex gap='16px' justify='center' align='center' className='learn-card__hover'
                      onClick={() => goToExercisePage(NavigationPages.GUESS_WORD, navigate,wordList)}
                >
                    <img src='/images/mainpage/cards/fill-gaps.png' alt='Заполни пропуки' width='40px' height='40px'/>
                    <span className='learn-card__span-text'>Заполни пропуки</span>
                </Flex>
                <Divider/>
                <Flex gap='16px' justify='center' align='center' className='learn-card__hover'
                      onClick={() => goToExercisePage(NavigationPages.FILL_WORDS, navigate,wordList)}
                >
                    <img src='/images/mainpage/cards/scrabble.png' alt='Филворды' width='40px' height='40px'/>
                    <span className='learn-card__span-text'>Филворды</span>
                </Flex>
            </div>

        </Modal>
    )
}

export default LearnCardDialog
