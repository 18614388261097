const IconTelegramPremium = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='100'
            height='100'
            fill='none'
            viewBox='0 0 100 100'
        >
            <g clipPath='url(#clip0_19_39)'>
                <path
                    fill='url(#paint0_linear_19_39)'
                    d='M50 0a49.994 49.994 0 00-35.352 14.645A50.015 50.015 0 000 50a50.015 50.015 0 0014.648 35.356 49.994 49.994 0 0070.704 0A50.015 50.015 0 00100 50a50.015 50.015 0 00-14.648-35.355A49.994 49.994 0 0050 0z'
                ></path>
                <path
                    fill='#fff'
                    d='M22.633 49.472c14.578-6.35 24.297-10.537 29.156-12.56 13.89-5.775 16.773-6.778 18.656-6.812.414-.007 1.336.095 1.938.582.5.41.64.965.71 1.354.063.39.15 1.276.079 1.968-.75 7.906-4.008 27.092-5.664 35.948-.695 3.746-2.078 5.003-3.414 5.125-2.906.268-5.11-1.918-7.922-3.761-4.399-2.886-6.883-4.68-11.156-7.496-4.938-3.253-1.735-5.041 1.078-7.963.734-.765 13.531-12.401 13.773-13.457.031-.132.063-.624-.234-.883-.29-.26-.719-.172-1.032-.101-.445.1-7.468 4.747-21.093 13.94-1.992 1.37-3.797 2.038-5.422 2.003-1.781-.039-5.219-1.01-7.773-1.84-3.125-1.017-5.618-1.556-5.399-3.285.11-.9 1.352-1.82 3.719-2.762z'
                ></path>
            </g>
            <defs>
                <linearGradient
                    id='paint0_linear_19_39'
                    x1='5000'
                    x2='5000'
                    y1='0'
                    y2='10000'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#2F88FF'></stop>
                    <stop offset='1' stopColor='#229ED9'></stop>
                </linearGradient>
                <clipPath id='clip0_19_39'>
                    <path fill='#fff' d='M0 0H100V100H0z'></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconTelegramPremium