import WordListType from '../../const/WordListType'
import { v4 as uuidv4 } from 'uuid'
import PrivilegesUtil from './PrivilegesUtil'
import Privileges from '../../const/Privileges'
import { UserDto, WordList } from '../../shared/types/api'

let WordListUtils = {
    isDoneType(type: string) {
        return type === WordListType.DONE
    },

    isUserType(type: string) {
        return type === WordListType.USER
    },

    isCommunityType(type: string) {
        return type === WordListType.COMMUNITY
    },

    isNewWordList(id: number | string) {
        return !Number.isInteger(id)
    },

    createNewUserWordList() {
        return {
            id: uuidv4(),
            listName: '',
            prevListName: '',
            checked: false,
            editMode: true,
            type: WordListType.USER,
            isLoading: false
        }
    },

    createUserCardList(cardName: string): WordList {
        return {
            id: uuidv4(),
            listName: cardName,
            prevListName: cardName,
            checked: false,
            editMode: true,
            type: WordListType.USER,
            isLoading: false
        }
    },

    createNewCommunityCardList(communityId: number, cardName: string): WordList {
        return {
            id: uuidv4(),
            listName: cardName,
            prevListName: cardName,
            checked: false,
            editMode: true,
            type: WordListType.COMMUNITY,
            communityId: communityId,
            isLoading: false
        }
    },

    parseNewWordList(data: WordList): WordList {
        return {
            id: data.id,
            type: data.communityId ? WordListType.COMMUNITY : WordListType.USER,
            listName: data.listName,
            prevListName: data.listName,
            editMode: false,
            countWords: data.countWords,
            checked: false,
            isLoading: false,
            communityId: data.communityId
        }
    },

    createNewCommunityUserWordList(communityId: number | string) {
        return {
            id: uuidv4(),
            listName: '',
            prevListName: '',
            checked: false,
            editMode: true,
            type: WordListType.COMMUNITY,
            communityId: communityId,
            isLoading: false
        }
    },

    parseWorldLists: (data: Array<WordList>): Array<WordList> =>
        data?.map((wl) => WordListUtils.parseWorldList(wl)) || [],

    parseWorldList: (wl: WordList) => ({
        id: wl.id,
        listName: wl.listName,
        prevListName: wl.listName,
        countWords: wl.countWords,
        communityId: wl.communityId,
        sideOneLang: wl.sideOneLang,
        sideTwoLang: wl.sideTwoLang,
        type: wl.type,
        level: wl.level,
        repeatedDate: wl.repeatedDate,
        checked: false,
        editMode: false,
        isLoading: false,
        imageId: wl.imageId
    }),

    calculateDaysToRepeat(repeatedDate: string) {
        if (!repeatedDate) {
            return 0
        }
        let date = new Date(repeatedDate.replace(/([+\-]\d\d)(\d\d)$/, '$1:$2'))
        const today = new Date()

        // To calculate the time difference of two dates
        const timeDiff = date.getTime() - today.getTime()
        let dayDiff = Math.trunc(timeDiff / (1000 * 3600 * 24))
        if (dayDiff < 0) {
            dayDiff = 0
        }
        return Math.abs(dayDiff)
    },

    /**
     * Новая сущность, созданная на фронте имеет id != number. Сущность из бд id = number
     * @param id
     * @returns {boolean}
     */
    isNewEntity(id?: number | string) {
        return typeof id !== 'number'
    },
    /**
     * Может редактировать пользовательский список
     * @param wl - список слов
     * @param user - пользователь
     * @returns {boolean|*}
     */
    canEditUserWordList(wl: WordList, user: UserDto) {
        return (
            wl && (wl.type === WordListType.USER || PrivilegesUtil.hasPrivilege(user, Privileges.EDIT_DONE_WORD_LIST))
        )
    },
    /**
     * Может редактировать список в сообществе
     * @param wl - список слов
     * @param canEditCommunity - флаг сообщества
     * @returns {false}
     */
    canEditCommunityWordList(wl: WordList, canEditCommunity: boolean) {
        return wl && wl.type === WordListType.COMMUNITY && canEditCommunity
    },
    /**
     * Может редактировать сяписок слов
     * @param wl - список слов
     * @param user - пользователь
     * @param canEditCommunity - флаг сообщества
     * @returns {boolean|*|false}
     */
    canEditWordList: (wl: WordList, user: UserDto, canEditCommunity: boolean) => {
        return (
            WordListUtils.canEditUserWordList(wl, user) || WordListUtils.canEditCommunityWordList(wl, canEditCommunity)
        )
    },

    mergeWordLists: (memory: Array<WordList>, updated: Array<WordList>) => {
        //Возможно можно сделать более оптимально
        const arr = [...memory, ...updated]
        return Object.values(
            arr.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
        )
    }
}

export default WordListUtils
