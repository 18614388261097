import { useEffect, useState } from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { reviewsData } from '../data'
import ReviewCard from './ReviewCard'
import { GEButton } from '../../../../common/buttons'

const Reviews = (props) => {
    const { count = 1 } = props

    const [data, setData] = useState([])
    const [currentSlice, setCurrentSlice] = useState({ start: 0, end: 0 })

    const hasNextSlice = currentSlice.end < reviewsData.length
    const hasPrevSlice = currentSlice.start - 1 >= 0

    const handleClickNextSlide = () => {
        setData(reviewsData.slice(currentSlice.start + count, currentSlice.end + count))
        setCurrentSlice({
            start: currentSlice.start + count,
            end: currentSlice.end + count
        })
    }

    const handleClickPrevSlide = () => {
        setData(reviewsData.slice(currentSlice.start - count, currentSlice.end - count))
        setCurrentSlice({
            start: currentSlice.start - count,
            end: currentSlice.end - count
        })
    }

    useEffect(() => {
        setData(reviewsData.slice(0, count))
        setCurrentSlice({ start: 0, end: count })
    }, [])

    return (
        <>
            {data.map((item, key) => (
                <ReviewCard data={item} key={key} />
            ))}

            {hasPrevSlice && (
                <GEButton type={'text'} className={'review-btn review-btn-left'} onClick={handleClickPrevSlide}>
                    <LeftOutlined style={{ fontSize: 24, color: '#32407A' }} />
                </GEButton>
            )}

            {hasNextSlice && (
                <GEButton type={'text'} className={'review-btn review-btn-right'} onClick={handleClickNextSlide}>
                    <RightOutlined style={{ fontSize: 24, color: '#32407A' }} />
                </GEButton>
            )}
        </>
    )
}

export default Reviews
