import { notification } from 'antd'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { GEButton } from '../../../../common/buttons'
import { getEmailByUser, getFirstName, getLastName, getLoginByUser, isPremium } from '../../../../common/utils/user'
import * as API_USER from '../../../../shared/http/user'
import Constants from '../../../../const/Constants'
import '../../../../styles/css/profile_page/profile_page.css'
import Loading from '../../../elements/loading/Loading'
import IconCrown from '../../../elements/svg_icons/IconCrown'
import UserUtil from '../../../utils/UserUtil'
import InfoPart from './InfoPart'

const UserProfilePage = (props) => {
    const [user, setUser] = useState(null)
    const [isFriend, setIsFriend] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        getUserById(props.userId)
        checkFriend()
    }, [])

    const getUserById = (id) => {
        API_USER.getUserById(id)
            .then((resp) => {
                setUser(resp)
                setIsLoaded(true)
            })
            .catch((err) => {
                //Todo
            })
    }

    const checkFriend = () => {
        API_USER.checkFriend(props.userId).then((resp) => {
            setIsFriend(!resp)
        })
    }

    const goBack = () => {
        window.history.back()
    }

    const sendFriendRequest = () => {
        API_USER.sendFriendRequest(props.userId).then(() => {
            notification.open({
                message: 'Запрос успешно отправлен'
            })
            setIsFriend(false)
        })
    }

    return (
        <div className='profile_page'>
            {isLoaded ? (
                <>
                    <div className='profile_page_profile'>
                        <label htmlFor='user_photo' className='profile_photo'>
                            <img
                                src={`${Constants.queryConst}/file/profile/photo?id=` + UserUtil.getPhotoId(user)}
                                alt='Profile img'
                                title='Edit photo'
                                className={isPremium(user) && 'premium-profile'}
                            />
                        </label>
                        {isPremium(user) && (
                            <div className='profile_page_profile__status'>
                                <IconCrown />
                                <span>Premium</span>
                            </div>
                        )}
                        <span className='profile_title'>Профиль:</span>

                        <ul className='profile_info'>
                            <li>
                                <span className='profile_info_title'>Логин:</span>{' '}
                                <span className='data'>{getLoginByUser(user)}</span>
                            </li>
                            <li>
                                <span className='profile_info_title'>E-mail:</span>{' '}
                                <span className='data'>{getEmailByUser(user)}</span>
                            </li>
                            <li>
                                <InfoPart
                                    name={'Имя:'}
                                    value={getFirstName(user)}
                                    attribute={'firstName'}
                                    user={user}
                                    isSessionUser={false}
                                />
                            </li>
                            <li>
                                <InfoPart
                                    name={'Фамилия:'}
                                    value={getLastName(user)}
                                    attribute={'lastName'}
                                    user={user}
                                    isSessionUser={false}
                                />
                            </li>
                        </ul>
                        <div className='profile_page-profile_add-to-profile'>
                            <GEButton type='primary' disabled={!isFriend} onClick={sendFriendRequest}>
                                Добавить в друзья
                            </GEButton>
                        </div>
                        <div className='profile_page-profile_go-back'>
                            <GEButton danger onClick={goBack}>
                                Назад
                            </GEButton>
                        </div>
                    </div>
                </>
            ) : (
                <Loading />
            )}
        </div>
    )
}

UserProfilePage.propTypes = {
    userId: PropTypes.number.isRequired
}

export default UserProfilePage
