import { createSlice } from '@reduxjs/toolkit'
import {
    fetchAuthByCode,
    fetchCheckSession,
    fetchLogin,
    fetchUpdateNotificationFlag,
    fetchUpdateNotificationTime
} from './extraReducers'
import { convertHoursToTime } from '../../common/utils/user'

const slice = createSlice({
    name: 'global',
    initialState: {
        user: null,
        isLoadingLogin: true,
        error: null
    },
    reducers: {
        setUserRoutine: (state, action) => {
            state.user = action.payload
        },
        setLoadingRoutine: (state, action) => {
            state.isLoadingLogin = action.payload
        },
        hideErrorRoutine: (state) => {
            state.error = null
        }
    },
    extraReducers: {
        [fetchCheckSession.pending]: (state) => {
            state.isLoadingLogin = true
            state.error = null
        },
        [fetchCheckSession.fulfilled]: (state, action) => {
            state.user = action.payload
            state.isLoadingLogin = false
            state.error = null
        },
        [fetchCheckSession.rejected]: (state, action) => {
            state.user = null
            state.isLoadingLogin = false
            // TODO: разобраться, как получать ошибку (сейчас она тут не возвращается)
            state.error = action.payload?.error
        },
        [fetchLogin.pending]: (state) => {
            state.user = null
            state.isLoadingLogin = true
            state.error = null
        },
        [fetchLogin.fulfilled]: (state, action) => {
            state.user = action.payload.user
            state.isLoadingLogin = false
            state.error = null
        },
        [fetchLogin.rejected]: (state) => {
            state.user = null
            state.isLoadingLogin = false
            state.error = 'Неверный логин или пароль'
        },
        [fetchAuthByCode.pending]: (state) => {
            state.user = null
            state.isLoadingLogin = true
            state.error = null
        },
        [fetchAuthByCode.fulfilled]: (state, action) => {
            state.user = action.payload.user
            state.isLoadingLogin = false
            state.error = null
        },
        [fetchAuthByCode.rejected]: (state) => {
            state.user = null
            state.isLoadingLogin = false
            state.error = 'Неверный логин или пароль'
        },
        [fetchUpdateNotificationFlag.fulfilled]: (state) => {
            state.user.notificationFlag = !state.user.notificationFlag
        },
        [fetchUpdateNotificationTime.fulfilled]: (state, action) => {
            state.user.notificationTime = convertHoursToTime(action.payload.time)
        }
    }
})

export const { setUserRoutine, setLoadingRoutine, hideErrorRoutine } = slice.actions
export const userSelector = (state) => state.global.user
export const errorSelector = (state) => state.global.error
export const premiumStatusSelector = state => state.global.user?.isPremium
export const loginLoadingSelector = state => state.global.isLoadingLogin
export default slice.reducer
