import { Button, DatePicker, DatePickerProps, Input, Modal } from 'antd'
import { FC, useState } from 'react'
import * as API_GOAL from '../../shared/http/goal'
import NotificationsUtils from '../utils/NotificationsUtils'
import { GoalDto } from '../../shared/types/api'
import moment from 'moment'
import dayjs from 'dayjs'
import { RangePickerProps } from 'antd/es/date-picker'

interface CreateGoalProps {
    isOpen: boolean,
    close: () => void
    setGoalFromResponce: (goal: GoalDto) => void
}

const CreateGoal: FC<CreateGoalProps> = ({ isOpen, close, setGoalFromResponce }) => {

    const [count, setCount] = useState<number | undefined>(undefined)
    const [date, setDate] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        const format = moment(dateString).format()
        setDate(format)
    }

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        return current && current < dayjs().endOf('day')
    }

    const handleChangeCount = (event: any) => {
        setCount(event.target.value)
    }


    const createGoal = () => {
        setLoading(true)
        if (count) {
            API_GOAL.createGoal({ count: count, deadlineDate: date })
                .then((responce) => {
                    setGoalFromResponce(responce.data)
                })
                .catch(() => {
                    NotificationsUtils.openErrorNotification('Не удалось создать цель')
                })
                .finally(() => {
                    close()
                    setLoading(false)
                })
        }
    }

    return (
        <Modal title='Вы редактируете цель'
               onCancel={close}
               footer={[
                   <Button key='submit' onClick={close}>
                       Отмена
                   </Button>,
                   <Button
                       onClick={createGoal}
                       type='primary'
                       loading={loading}
                       disabled={!date || !count}
                   >
                       Сохранить
                   </Button>
               ]}
               open={isOpen}>
            <div className='modal-input-container'>
                <Input
                    value={count}
                    size='large'
                    onChange={handleChangeCount}
                    placeholder='Введите количество слов'
                    maxLength={4}
                />
                <DatePicker onChange={onChange} placeholder='Введите дату окончания цели' disabledDate={disabledDate} />
            </div>
        </Modal>
    )
}
export default CreateGoal
