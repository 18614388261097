import { FC } from 'react'
import { WordDto } from '../../../../shared/types/api'
import Constants from '../../../../const/Constants'

interface TestQuestionProps {
    highlight: string
    index: number,
    side: string
    words: Array<WordDto>
}

export const TestQuestion: FC<TestQuestionProps> = ({ index, words, highlight, side }) => {
    return (
        <div className={highlight}
             id='eng'>{side === Constants.CARD_SIDE_ONE ? words[index].engWord : words[index].rusWord}</div>
    )
}
