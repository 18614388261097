import '../../../styles/scss/google-auth-btn/style.scss'
import { GEButton } from '../../../common/buttons'
import IconGoogle from '../svg_icons/IconGoogle'


const GoogleAuthButton = () => {
    const login = () => {
        window.location.pathname = '/api/geekenglish-main/oauth2/authorization/google'
    }

    return (
        <GEButton onClick={login}>
            <IconGoogle />
            <span className={'google-auth-btn-text width124px'}> Войти с Google</span>
        </GEButton>
    )
}

export default GoogleAuthButton