import PropTypes from 'prop-types'

const FactRule = (props) => {
    return (
        <div className='fact-rule'>
            <p> {props.factRule}</p>
        </div>
    )
}

export default FactRule

FactRule.propTypes = {
    factRule: PropTypes.string.isRequired
}
