import { notification } from 'antd'

let NotificationsUtils = {
    openErrorNotification: function (description) {
        notification['error']({
            message: 'Ошибка',
            description: description
        })
    },
    openSuccessNotification: function (description) {
        notification['success']({
            message: 'Успешно',
            description: description
        })
    }
}

export default NotificationsUtils
