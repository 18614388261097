import PropTypes from 'prop-types'

import '../../../styles/css/elements/svg_icons/icon-btn.css'

function IconQuestion(props) {
    const { className = '', onClick = () => null } = props

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='30'
            height='30'
            fill='none'
            stroke='#000'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            className={'icon icon-tabler icon-tabler-help ' + className}
            onClick={onClick}
            viewBox='0 0 24 24'
        >
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <circle cx='12' cy='12' r='9'></circle>
            <path d='M12 17L12 17.01'></path>
            <path d='M12 13.5a1.5 1.5 0 011-1.5 2.6 2.6 0 10-3-4'></path>
        </svg>
    )
}
export default IconQuestion

IconQuestion.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func
}
