import '../../../../styles/scss/course/course-item.scss'
import { Flex } from 'antd'
import { PlayIcon } from '../../../elements/svg_icons/PlayIcon'
import { PauseIcon } from '../../../elements/svg_icons/PauseIcon'
import { FC } from 'react'
import { ModulesDto } from '../../../../shared/types/api'
import IconChecked from '../../../elements/svg_icons/IconChecked'

interface CourseItemProps {
    selectModule: (module: ModulesDto) => void,
    module: ModulesDto,
    activeModule: ModulesDto | undefined,
    completedModules: Array<number> | undefined
}

export const CourseItem: FC<CourseItemProps> = ({ module, selectModule, activeModule, completedModules }) => {

    return (
        <Flex className='course-item__container' gap='8px' onClick={() => selectModule(module)}>
            <div className='course-item__icon-container '>
                {activeModule?.id === module.id ? <PauseIcon /> : <PlayIcon />}
            </div>
            <Flex vertical justify='space-around'>

                <span className='course-item__text'>{module.title}</span>
                {/*  //Todo*/}
                {/*   {completedModules && completedModules.includes(module.id) &&
                <span className='course-item__text-time'>10:44</span>}*/}

            </Flex>
            {completedModules && completedModules.includes(module.id) &&
            <div className='course-item__checked'>
                <IconChecked />
            </div>
            }

        </Flex>
    )
}