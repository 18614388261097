import {FC} from 'react'

interface PageNotFoundIconProps {
    className?: string,
    onClick?: () => void
}

export const PageNotFoundIcon: FC<PageNotFoundIconProps> = ({className = '', onClick}) => {

    return (
        <svg width="694" height="718" viewBox="0 0 694 718" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_216_771)">
                <path
                    d="M232.147 323.949L290.33 590.477C292.772 601.688 298.702 611.839 307.269 619.472C315.836 627.105 326.599 631.827 338.013 632.962C367.379 635.865 411.991 632.682 469.657 606.407C530.782 578.556 562.307 541.496 578.249 514.106C583.88 504.421 586.377 493.229 585.397 482.065C584.417 470.901 580.008 460.313 572.774 451.753L396.45 242.938"
                    fill="#F4F4F4"/>
                <path
                    d="M319.737 294.474C397.841 255.964 450.624 203.383 437.63 177.03C424.637 150.677 350.787 160.532 272.683 199.042C194.579 237.552 141.796 290.133 154.789 316.486C167.783 342.839 241.632 332.984 319.737 294.474Z"
                    fill="#262626"/>
                <path
                    d="M320.127 295.266C282.268 313.933 244.21 326.51 212.962 330.677C181.402 334.888 160.461 329.987 153.996 316.875C147.531 303.763 156.392 284.167 178.95 261.695C201.284 239.448 234.431 216.912 272.291 198.246C310.15 179.579 348.208 167.003 379.456 162.835C411.015 158.624 431.956 163.525 438.421 176.637C444.886 189.749 436.025 209.345 413.467 231.817C391.135 254.067 357.986 276.6 320.127 295.266ZM273.074 199.834C235.382 218.418 202.398 240.832 180.196 262.949C158.219 284.843 149.477 303.717 155.58 316.096C161.684 328.475 181.98 333.033 212.729 328.929C243.791 324.784 281.655 312.266 319.346 293.682C357.037 275.098 390.021 252.684 412.223 230.568C434.2 208.674 442.942 189.799 436.839 177.42C430.736 165.041 410.44 160.484 379.691 164.587C348.628 168.732 310.765 181.25 273.074 199.834Z"
                    fill="#262626"/>
                <path
                    d="M310.774 276.297C413.393 225.7 485.292 161.785 471.366 133.539C457.439 105.293 362.96 123.412 260.341 174.009C157.722 224.606 85.8225 288.521 99.7494 316.767C113.676 345.013 208.155 326.894 310.774 276.297Z"
                    fill="#4174F8"/>
                <path
                    d="M311.165 277.089C261.464 301.594 212.101 319.307 172.168 326.965C131.885 334.69 105.885 331.208 98.9561 317.155C92.0275 303.103 105.097 280.359 135.752 253.106C166.14 226.091 210.248 197.719 259.948 173.214C309.649 148.709 359.011 130.995 398.944 123.337C439.227 115.612 465.228 119.095 472.156 133.147C479.084 147.198 466.015 169.942 435.361 197.196C404.972 224.211 360.864 252.584 311.165 277.089ZM260.731 174.801C211.169 199.238 167.201 227.518 136.926 254.43C122.245 267.48 111.603 279.485 105.294 290.111C98.8757 300.921 97.2764 309.758 100.54 316.377C103.804 322.996 111.788 327.109 124.27 328.598C136.541 330.063 152.544 328.931 171.835 325.231C211.618 317.603 260.819 299.943 310.384 275.505C359.949 251.066 403.914 222.789 434.188 195.877C448.869 182.826 459.511 170.821 465.821 160.195C472.239 149.386 473.838 140.549 470.574 133.929C467.31 127.31 459.327 123.198 446.844 121.708C434.573 120.243 418.57 121.375 399.279 125.075C359.497 132.705 310.294 150.367 260.732 174.804L260.731 174.801Z"
                    fill="#262626"/>
                <path
                    d="M356.353 153.192C367.501 175.802 330.962 200.438 290.597 221.543C246.619 244.537 196.347 259.76 185.37 237.496C174.269 214.82 172.607 188.67 180.75 164.779C188.892 140.888 206.174 121.205 228.807 110.046C251.439 98.8874 277.575 97.1626 301.485 105.25C325.394 113.338 345.127 130.578 356.356 153.191L356.353 153.192Z"
                    fill="white"/>
                <path
                    d="M343.05 154.187C342.844 154.288 342.607 154.305 342.389 154.235C342.171 154.165 341.989 154.012 341.881 153.81C337.639 145.812 332.083 138.585 325.445 132.43C325.273 132.271 325.172 132.05 325.163 131.816C325.154 131.582 325.238 131.354 325.397 131.182C325.556 131.01 325.777 130.909 326.011 130.9C326.245 130.891 326.473 130.975 326.644 131.135C333.427 137.424 339.104 144.809 343.439 152.981C343.549 153.187 343.573 153.429 343.504 153.653C343.436 153.877 343.282 154.064 343.075 154.174L343.05 154.187Z"
                    fill="#262626"/>
                <path
                    d="M312.61 122.256C312.478 122.321 312.332 122.352 312.185 122.346C312.038 122.341 311.895 122.298 311.769 122.223C293.459 111.421 271.66 108.194 251.013 113.229C250.789 113.278 250.554 113.238 250.359 113.117C250.164 112.995 250.023 112.803 249.968 112.58C249.912 112.356 249.946 112.121 250.062 111.922C250.178 111.723 250.366 111.578 250.588 111.516C271.684 106.37 293.958 109.667 312.668 120.705C312.804 120.786 312.917 120.902 312.993 121.042C313.069 121.181 313.106 121.339 313.1 121.497C313.094 121.656 313.046 121.811 312.959 121.944C312.873 122.077 312.752 122.185 312.61 122.256Z"
                    fill="#262626"/>
                <path
                    d="M282.745 226.52C244.947 245.157 213.035 252.985 196.332 247.533C190.795 245.725 186.84 242.479 184.576 237.887C178.944 226.545 175.604 214.205 174.748 201.572C173.892 188.939 175.536 176.262 179.586 164.267C183.636 152.273 190.013 141.197 198.35 131.674C206.688 122.151 216.823 114.368 228.174 108.771C239.526 103.174 251.871 99.8735 264.502 99.0579C277.132 98.2422 289.801 99.9278 301.782 104.018C313.763 108.108 324.82 114.523 334.32 122.894C343.82 131.265 351.576 141.428 357.144 152.801C362.402 163.467 357.773 175.506 343.385 188.585C332.314 198.649 315.181 209.685 291.005 222.325C288.224 223.778 285.471 225.176 282.745 226.52ZM229.073 110.598C206.629 121.695 189.506 141.247 181.46 164.964C173.414 188.681 175.103 214.626 186.155 237.107C188.202 241.256 191.808 244.2 196.876 245.855C214.339 251.56 249.217 242.178 290.185 220.762C323.804 203.185 367.159 177.095 355.565 153.581C344.461 131.124 324.908 113.987 301.194 105.929C277.481 97.8712 251.545 99.5505 229.076 110.599L229.073 110.598Z"
                    fill="#262626"/>
                <path
                    d="M150.584 300.212C156.716 297.189 159.234 289.765 156.21 283.631C153.185 277.496 145.762 274.974 139.631 277.997C133.5 281.02 130.982 288.444 134.006 294.578C137.031 300.713 144.453 303.235 150.584 300.212Z"
                    fill="white"/>
                <path
                    d="M150.975 301.004C148.623 302.164 145.979 302.599 143.379 302.256C140.778 301.913 138.337 300.806 136.365 299.076C134.393 297.345 132.978 295.069 132.299 292.534C131.619 290 131.707 287.321 132.549 284.837C133.392 282.352 134.953 280.174 137.033 278.578C139.114 276.981 141.621 276.037 144.239 275.867C146.856 275.696 149.465 276.305 151.737 277.618C154.009 278.93 155.841 280.887 157.001 283.24C158.553 286.397 158.79 290.04 157.66 293.37C156.531 296.7 154.126 299.446 150.975 301.004ZM140.021 278.788C137.982 279.794 136.287 281.381 135.15 283.351C134.013 285.321 133.486 287.583 133.635 289.853C133.784 292.123 134.603 294.298 135.987 296.103C137.372 297.908 139.26 299.262 141.414 299.994C143.567 300.726 145.889 300.802 148.085 300.214C150.281 299.626 152.254 298.4 153.753 296.69C155.252 294.98 156.21 292.864 156.507 290.609C156.803 288.354 156.425 286.061 155.419 284.021C154.067 281.287 151.687 279.2 148.8 278.219C145.914 277.238 142.756 277.443 140.021 278.788Z"
                    fill="#262626"/>
                <path
                    d="M304.617 263.809C310.748 260.786 313.267 253.362 310.242 247.228C307.217 241.093 299.795 238.571 293.664 241.594C287.533 244.617 285.014 252.041 288.039 258.175C291.063 264.31 298.486 266.832 304.617 263.809Z"
                    fill="white"/>
                <path
                    d="M305.008 264.601C302.655 265.761 300.012 266.197 297.411 265.853C294.811 265.51 292.37 264.403 290.398 262.673C288.426 260.942 287.01 258.666 286.331 256.131C285.652 253.597 285.739 250.918 286.582 248.434C287.425 245.95 288.985 243.771 291.066 242.175C293.146 240.578 295.654 239.635 298.271 239.464C300.888 239.293 303.498 239.902 305.77 241.215C308.041 242.527 309.873 244.484 311.034 246.838C312.585 249.994 312.823 253.637 311.693 256.967C310.563 260.297 308.159 263.043 305.008 264.601ZM294.054 242.386C292.015 243.391 290.32 244.979 289.183 246.949C288.047 248.918 287.52 251.181 287.669 253.45C287.818 255.72 288.636 257.895 290.021 259.7C291.405 261.505 293.293 262.859 295.447 263.59C297.6 264.322 299.921 264.399 302.117 263.811C304.314 263.223 306.286 261.996 307.785 260.287C309.284 258.577 310.242 256.461 310.539 254.206C310.835 251.951 310.457 249.658 309.451 247.618C308.099 244.884 305.719 242.798 302.833 241.817C299.946 240.837 296.789 241.041 294.054 242.386Z"
                    fill="#262626"/>
                <path
                    d="M427.256 163.797C433.388 160.774 435.906 153.351 432.881 147.216C429.857 141.081 422.434 138.559 416.303 141.582C410.172 144.605 407.653 152.029 410.678 158.163C413.703 164.298 421.125 166.82 427.256 163.797Z"
                    fill="white"/>
                <path
                    d="M427.647 164.589C425.294 165.749 422.651 166.185 420.05 165.842C417.45 165.498 415.009 164.392 413.037 162.661C411.065 160.931 409.65 158.654 408.97 156.12C408.291 153.585 408.378 150.906 409.221 148.422C410.064 145.938 411.624 143.759 413.705 142.163C415.786 140.566 418.293 139.623 420.91 139.452C423.528 139.281 426.137 139.89 428.409 141.203C430.68 142.515 432.512 144.472 433.673 146.826C435.225 149.982 435.462 153.625 434.332 156.955C433.202 160.285 430.798 163.031 427.647 164.589ZM416.693 142.373C414.654 143.379 412.959 144.967 411.822 146.936C410.685 148.906 410.158 151.169 410.307 153.439C410.456 155.708 411.275 157.883 412.659 159.688C414.044 161.493 415.932 162.847 418.085 163.579C420.239 164.311 422.56 164.388 424.757 163.8C426.953 163.211 428.926 161.985 430.425 160.275C431.924 158.565 432.882 156.449 433.179 154.194C433.475 151.939 433.096 149.646 432.09 147.606C430.739 144.872 428.359 142.786 425.472 141.805C422.586 140.824 419.428 141.028 416.693 142.373Z"
                    fill="#262626"/>
                <path
                    d="M378.465 213.59C384.596 210.567 387.115 203.143 384.09 197.009C381.065 190.874 373.643 188.352 367.512 191.375C361.381 194.398 358.862 201.822 361.887 207.956C364.911 214.091 372.334 216.613 378.465 213.59Z"
                    fill="white"/>
                <path
                    d="M378.856 214.382C376.503 215.542 373.86 215.978 371.259 215.634C368.659 215.291 366.218 214.184 364.246 212.454C362.274 210.723 360.858 208.447 360.179 205.912C359.5 203.378 359.587 200.699 360.43 198.215C361.273 195.73 362.833 193.552 364.914 191.956C366.994 190.359 369.502 189.416 372.119 189.245C374.737 189.074 377.346 189.683 379.618 190.996C381.889 192.308 383.721 194.265 384.882 196.618C386.433 199.775 386.671 203.418 385.541 206.748C384.411 210.078 382.007 212.824 378.856 214.382ZM367.902 192.166C365.863 193.171 364.167 194.759 363.03 196.729C361.894 198.698 361.366 200.961 361.515 203.231C361.664 205.501 362.483 207.676 363.867 209.481C365.252 211.286 367.14 212.64 369.293 213.372C371.447 214.104 373.768 214.181 375.965 213.593C378.161 213.005 380.134 211.778 381.633 210.068C383.132 208.359 384.09 206.242 384.387 203.987C384.683 201.732 384.305 199.439 383.299 197.399C381.947 194.665 379.567 192.579 376.681 191.598C373.794 190.617 370.637 190.821 367.902 192.166Z"
                    fill="#262626"/>
                <path
                    d="M220.561 293.325C226.692 290.302 229.21 282.879 226.186 276.744C223.161 270.61 215.739 268.087 209.608 271.11C203.476 274.133 200.958 281.557 203.983 287.692C207.007 293.826 214.43 296.349 220.561 293.325Z"
                    fill="white"/>
                <path
                    d="M220.952 294.118C218.599 295.278 215.956 295.714 213.355 295.371C210.755 295.027 208.314 293.92 206.342 292.19C204.37 290.459 202.954 288.183 202.275 285.648C201.596 283.114 201.683 280.435 202.526 277.951C203.369 275.467 204.929 273.288 207.01 271.692C209.09 270.095 211.598 269.152 214.215 268.981C216.832 268.81 219.442 269.419 221.714 270.732C223.985 272.044 225.817 274.001 226.978 276.355C228.529 279.511 228.767 283.154 227.637 286.484C226.507 289.814 224.103 292.56 220.952 294.118ZM209.998 271.902C207.958 272.907 206.262 274.495 205.125 276.464C203.988 278.434 203.46 280.697 203.608 282.967C203.757 285.237 204.575 287.412 205.959 289.217C207.344 291.023 209.232 292.377 211.385 293.109C213.539 293.841 215.861 293.918 218.057 293.33C220.254 292.742 222.227 291.516 223.726 289.806C225.225 288.096 226.184 285.98 226.48 283.724C226.777 281.469 226.398 279.176 225.391 277.136C224.04 274.403 221.661 272.317 218.775 271.336C215.889 270.355 212.733 270.558 209.998 271.902Z"
                    fill="#262626"/>
                <path
                    d="M383.765 127.54C384.174 130.455 383.531 133.42 381.953 135.904C380.375 138.387 377.963 140.227 375.151 141.093C372.339 141.959 369.31 141.795 366.608 140.629C363.905 139.464 361.705 137.374 360.402 134.735C360.28 134.486 360.17 134.238 360.066 133.988C368.273 131.517 376.189 129.359 383.765 127.54Z"
                    fill="white"/>
                <path
                    d="M377.37 141.159C374.215 142.711 370.573 142.947 367.243 141.815C363.913 140.684 361.168 138.277 359.609 135.124C359.478 134.858 359.361 134.592 359.25 134.324L358.877 133.422L359.81 133.141C368.063 130.657 376.053 128.483 383.559 126.679L384.504 126.453L384.639 127.416C385.031 130.181 384.539 133 383.233 135.468C381.928 137.936 379.875 139.928 377.37 141.159ZM361.293 134.541C362.502 136.878 364.476 138.73 366.884 139.788C369.293 140.847 371.991 141.047 374.529 140.356C377.066 139.665 379.29 138.125 380.829 135.992C382.368 133.859 383.13 131.263 382.986 128.635C376.098 130.31 368.81 132.294 361.293 134.541Z"
                    fill="#262626"/>
                <path
                    d="M167.067 230.059C168.357 232.678 168.682 235.667 167.983 238.501C167.285 241.334 165.608 243.83 163.25 245.547C160.891 247.264 158.002 248.092 155.091 247.886C152.181 247.679 149.436 246.451 147.342 244.418C153.366 239.518 159.86 234.544 166.788 229.534C166.884 229.703 166.978 229.88 167.067 230.059Z"
                    fill="white"/>
                <path
                    d="M161.832 247.431C159.376 248.638 156.608 249.057 153.904 248.631C151.2 248.206 148.693 246.956 146.725 245.053L146.012 244.361L146.783 243.734C152.837 238.81 159.393 233.792 166.269 228.82L167.066 228.243L167.553 229.097C167.658 229.282 167.76 229.475 167.856 229.67C169.408 232.826 169.645 236.468 168.516 239.798C167.387 243.128 164.983 245.873 161.832 247.431ZM148.704 244.45C150.709 246.083 153.206 246.993 155.791 247.033C158.376 247.072 160.899 246.24 162.952 244.669C165.005 243.098 166.468 240.88 167.106 238.374C167.744 235.868 167.519 233.22 166.467 230.857C160.226 235.391 154.259 239.957 148.704 244.45Z"
                    fill="#262626"/>
                <path
                    d="M472.789 561.346L342.783 553.542L353.307 376.88L443.203 382.276L480.922 424.806L472.789 561.346Z"
                    fill="white"/>
                <path opacity="0.3"
                      d="M455.374 484.522C458.713 482.875 460.084 478.833 458.437 475.493C456.79 472.152 452.748 470.779 449.41 472.425C446.071 474.071 444.7 478.114 446.347 481.454C447.994 484.794 452.036 486.168 455.374 484.522Z"
                      fill="#367CFF"/>
                <path opacity="0.3"
                      d="M375.873 484.12C379.212 482.474 380.583 478.432 378.936 475.091C377.289 471.751 373.247 470.378 369.909 472.024C366.57 473.67 365.199 477.712 366.846 481.052C368.493 484.393 372.534 485.766 375.873 484.12Z"
                      fill="#367CFF"/>
                <path
                    d="M473.616 562.281L341.85 554.37L352.479 375.946L443.621 381.42L481.826 424.497L473.616 562.281ZM343.717 552.714L471.959 560.413L480.023 425.117L442.788 383.134L354.137 377.814L343.717 552.714Z"
                    fill="#262626"/>
                <path d="M443.203 382.276L440.813 422.398L480.923 424.806L443.203 382.276Z" fill="#4174F8"/>
                <path
                    d="M481.313 425.598C481.175 425.665 481.023 425.696 480.87 425.687L440.76 423.28C440.526 423.265 440.308 423.159 440.153 422.984C439.997 422.808 439.918 422.579 439.932 422.345L442.321 382.221C442.331 382.047 442.393 381.88 442.498 381.741C442.604 381.601 442.748 381.497 442.913 381.44C443.078 381.383 443.256 381.376 443.425 381.42C443.594 381.465 443.746 381.558 443.862 381.689L481.58 424.216C481.67 424.317 481.736 424.439 481.771 424.57C481.807 424.701 481.812 424.838 481.786 424.971C481.76 425.104 481.703 425.23 481.621 425.338C481.538 425.445 481.432 425.533 481.31 425.593L481.313 425.598ZM441.746 421.57L478.848 423.797L443.957 384.457L441.746 421.57Z"
                    fill="#292A2E"/>
                <path
                    d="M389.285 460.097C391.92 458.798 393.002 455.607 391.702 452.971C390.402 450.335 387.212 449.251 384.578 450.55C381.943 451.849 380.86 455.039 382.16 457.676C383.46 460.312 386.65 461.396 389.285 460.097Z"
                    fill="#292A2E"/>
                <path
                    d="M444.073 458.391C446.708 457.091 447.79 453.901 446.49 451.265C445.19 448.629 442.001 447.545 439.366 448.844C436.731 450.143 435.649 453.333 436.948 455.97C438.248 458.606 441.438 459.69 444.073 458.391Z"
                    fill="#292A2E"/>
                <path
                    d="M438.231 497.666C438.025 497.767 437.788 497.784 437.569 497.713C437.35 497.642 437.168 497.489 437.061 497.286C435.079 493.518 432.169 490.317 428.605 487.988C425.042 485.66 420.944 484.279 416.699 483.977C412.454 483.675 408.202 484.463 404.347 486.265C400.492 488.066 397.162 490.823 394.67 494.273C394.603 494.37 394.518 494.453 394.419 494.516C394.32 494.58 394.209 494.623 394.093 494.642C393.977 494.662 393.859 494.659 393.744 494.632C393.629 494.606 393.521 494.557 393.426 494.488C393.331 494.419 393.25 494.331 393.189 494.231C393.128 494.13 393.087 494.018 393.07 493.902C393.053 493.785 393.059 493.667 393.088 493.553C393.118 493.439 393.169 493.332 393.24 493.238C395.908 489.546 399.474 486.596 403.601 484.668C407.728 482.739 412.279 481.897 416.823 482.22C421.367 482.543 425.754 484.02 429.568 486.513C433.383 489.005 436.499 492.43 438.622 496.462C438.731 496.67 438.753 496.912 438.684 497.135C438.615 497.359 438.46 497.546 438.253 497.655L438.231 497.666Z"
                    fill="#292A2E"/>
                <path
                    d="M388.674 448.392C386.088 449.627 383.204 450.099 380.359 449.753C380.129 449.715 379.924 449.587 379.788 449.398C379.652 449.209 379.596 448.974 379.633 448.744C379.669 448.514 379.795 448.308 379.983 448.17C380.17 448.033 380.405 447.975 380.635 448.009C380.719 448.022 386.935 448.922 391.145 444.381C391.306 444.216 391.525 444.12 391.755 444.114C391.985 444.108 392.209 444.193 392.378 444.35C392.547 444.507 392.648 444.723 392.659 444.954C392.671 445.184 392.592 445.409 392.439 445.582C391.367 446.742 390.091 447.695 388.674 448.392Z"
                    fill="#292A2E"/>
                <path
                    d="M448.763 447.056C448.674 447.099 448.579 447.128 448.481 447.14C440.662 448.105 437.365 443.33 437.228 443.126C437.097 442.932 437.049 442.693 437.094 442.463C437.139 442.232 437.274 442.029 437.468 441.899C437.663 441.768 437.902 441.719 438.132 441.765C438.362 441.81 438.565 441.944 438.696 442.139C438.816 442.313 441.576 446.21 448.268 445.386C448.474 445.361 448.684 445.41 448.858 445.524C449.032 445.638 449.161 445.81 449.22 446.01C449.28 446.21 449.267 446.424 449.184 446.615C449.101 446.806 448.953 446.962 448.766 447.054L448.763 447.056Z"
                    fill="#292A2E"/>
                <path
                    d="M95.104 392.503C95.0001 392.555 94.8871 392.585 94.7715 392.592C94.6559 392.6 94.5399 392.585 94.4302 392.547C94.3205 392.51 94.2192 392.451 94.1321 392.375C94.045 392.298 93.9739 392.205 93.9227 392.101L82.907 369.76C82.8517 369.655 82.8179 369.541 82.8075 369.423C82.7972 369.306 82.8106 369.187 82.8469 369.075C82.8832 368.962 82.9417 368.858 83.0188 368.769C83.096 368.68 83.1904 368.607 83.2963 368.554C83.4022 368.502 83.5175 368.472 83.6354 368.465C83.7533 368.458 83.8713 368.475 83.9826 368.515C84.0938 368.554 84.196 368.616 84.2831 368.696C84.3701 368.776 84.4403 368.872 84.4894 368.98L95.5051 391.321C95.5564 391.425 95.5868 391.538 95.5945 391.654C95.6021 391.77 95.5869 391.886 95.5496 391.996C95.5124 392.105 95.4538 392.207 95.3774 392.294C95.3009 392.381 95.208 392.452 95.104 392.503Z"
                    fill="#262626"/>
                <path
                    d="M96.8822 377.74L82.3103 384.924C82.1004 385.028 81.8579 385.044 81.6363 384.968C81.4147 384.893 81.232 384.733 81.1285 384.523C81.025 384.313 81.0092 384.071 81.0844 383.849C81.1597 383.627 81.3199 383.445 81.5298 383.341L96.1016 376.157C96.3116 376.053 96.554 376.037 96.7756 376.112C96.9973 376.188 97.1799 376.348 97.2834 376.558C97.3869 376.768 97.4028 377.01 97.3275 377.232C97.2523 377.453 97.0921 377.636 96.8822 377.74Z"
                    fill="#262626"/>
                <path
                    d="M536.548 329.531C536.338 329.634 536.095 329.65 535.874 329.575C535.652 329.499 535.469 329.339 535.366 329.129L518.609 295.144C518.506 294.934 518.49 294.692 518.565 294.47C518.64 294.248 518.801 294.066 519.011 293.962C519.22 293.859 519.463 293.843 519.685 293.918C519.906 293.994 520.089 294.154 520.192 294.364L536.949 328.349C537.052 328.559 537.068 328.801 536.993 329.023C536.918 329.244 536.758 329.427 536.548 329.531Z"
                    fill="#262626"/>
                <path
                    d="M539.255 307.072L517.088 318.001C516.878 318.105 516.636 318.121 516.414 318.045C516.192 317.97 516.01 317.81 515.906 317.6C515.803 317.39 515.787 317.148 515.862 316.926C515.937 316.704 516.098 316.522 516.308 316.418L538.474 305.489C538.684 305.385 538.926 305.37 539.148 305.445C539.37 305.52 539.552 305.68 539.656 305.89C539.759 306.1 539.775 306.343 539.7 306.564C539.625 306.786 539.464 306.968 539.255 307.072Z"
                    fill="#262626"/>
                <path
                    d="M537.524 195.489C537.42 195.54 537.307 195.57 537.192 195.578C537.076 195.585 536.96 195.57 536.85 195.533C536.74 195.496 536.639 195.437 536.552 195.36C536.465 195.284 536.393 195.191 536.342 195.087L527.964 178.095C527.861 177.885 527.845 177.642 527.92 177.42C527.995 177.199 528.155 177.016 528.365 176.912C528.575 176.809 528.817 176.793 529.039 176.868C529.261 176.944 529.444 177.104 529.547 177.314L537.925 194.306C537.977 194.41 538.007 194.523 538.015 194.639C538.022 194.755 538.007 194.871 537.97 194.981C537.933 195.091 537.874 195.192 537.798 195.279C537.721 195.366 537.628 195.437 537.524 195.489Z"
                    fill="#262626"/>
                <path
                    d="M538.877 184.259L527.794 189.724C527.69 189.775 527.577 189.805 527.461 189.813C527.345 189.821 527.229 189.805 527.12 189.768C527.01 189.731 526.909 189.672 526.822 189.596C526.734 189.519 526.663 189.427 526.612 189.323C526.561 189.219 526.53 189.106 526.523 188.99C526.515 188.874 526.531 188.758 526.568 188.649C526.605 188.539 526.664 188.438 526.74 188.35C526.816 188.263 526.909 188.192 527.013 188.141L538.096 182.676C538.306 182.573 538.549 182.557 538.77 182.632C538.992 182.707 539.175 182.868 539.278 183.077C539.382 183.287 539.398 183.53 539.322 183.751C539.247 183.973 539.087 184.156 538.877 184.259Z"
                    fill="#262626"/>
            </g>
            <defs>
                <clipPath id="clip0_216_771">
                    <rect width="500" height="553" fill="white"
                          transform="translate(0.632935 221.265) rotate(-26.2459)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
