import RootComponent from '../../../../rootcomponent/RootComponent'

import { connect } from 'react-redux'
import '../../../../../styles/css/statistic/top_stats.css'
import * as API_STATISTIC from '../../../../../shared/http/statistic'
import Loading from '../../../../elements/loading/Loading'
import PositionTopStats from './PositionTopStats'
import PropTypes from 'prop-types'

class TopWordsCountStats extends RootComponent {
    state = {
        userIndex: null,
        loading: true,
        resultArray: []
    }

    getUserPosition(usersPosition) {
        let result = null
        usersPosition.forEach((item) => {
            const founded = item.users.some((value) => value.login === this.props.user.login)
            if (founded) {
                result = item.position
            }
        })
        return result
    }

    generateUserArray(usersPosition) {
        const generatePositionArray = this.generatePositionArray(usersPosition)
        const userPosition = this.getUserPosition(generatePositionArray)
        this.setState({ userIndex: userPosition })
        let resultArray = []

        if (userPosition && parseInt(userPosition) > 10) {
            resultArray = generatePositionArray.slice(0, 9)
            resultArray.push(generatePositionArray.find((ga) => ga.position === userPosition))
        } else {
            if (generatePositionArray.length > 10) {
                generatePositionArray.slice(0, 10)
            }
            resultArray = generatePositionArray
        }
        this.setState({ resultArray: resultArray })
    }

    generatePositionArray(usersPosition) {
        return Object.keys(usersPosition).map((position) => {
            const usersPositionElement = usersPosition[position]
            return {
                position: position,
                users: usersPositionElement
            }
        })
    }

    render() {
        return (
            <div className='top-stats'>
                {this.props.title && <h2 className='top-stats__heading'>{this.props.title}</h2>}
                {this.state.resultArray === null ? (
                    this.state.loading && (
                        <div className='list-loading'>
                            <Loading />
                        </div>
                    )
                ) : (
                    <ul className='top-stats__list'>
                        {this.state.resultArray &&
                        this.state.resultArray.map((item, i, items) => {
                            return <PositionTopStats key={i} position={Number(item.position)} usersList={item.users} />
                        })}
                    </ul>
                )}
            </div>
        )
    }

    componentDidMount() {
        API_STATISTIC.getStatistic(this.props.loadPath)
            .then((resp) => {
                this.generateUserArray(resp)
            })
            .finally(() => this.setState({ loading: false }))
    }
}

const mapStateToProps = (state) => ({
    user: state.global.user
})

export default connect(mapStateToProps)(TopWordsCountStats)

TopWordsCountStats.propTypes = {
    title: PropTypes.string.isRequired,
    loadPath: PropTypes.string.isRequired
}
