import { GEButton } from '../../../../../common/buttons'
import { FC } from 'react'
import { useAppSelector } from '../../../../../store/hooks'
import { premiumStatusSelector } from '../../../../../store/global'

interface GenerateWordsButtonProps {
    selectedCategory: any,
    wordsAmount: number,
    showAddWordsModal: () => void
}

const GenerateWordsButton: FC<GenerateWordsButtonProps> = ({ selectedCategory, wordsAmount, showAddWordsModal }) => {

    const isPremium = useAppSelector(premiumStatusSelector)

    return (
        <div
            className={selectedCategory !== null && wordsAmount === 0 && isPremium ? '' : 'ge-invisible'}
            onClick={showAddWordsModal}
        >
            <GEButton className={'yellowBackGround'}>
                Сгенерировать карточки из текста
            </GEButton>
        </div>
    )
}

export default GenerateWordsButton
