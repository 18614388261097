import PropTypes from 'prop-types'

const IconArrowBack = (props) => {
    const { className = '', onClick = () => null } = props

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center'}}>
             <svg
            onClick={onClick}
            className={'icon-btn ' + className}
            width='50'
            height='50'
            viewBox='0 0 24 24'
            strokeWidth='2'
            stroke='#ffffff'
            fill='none'
            strokeLinecap='round'
            strokeLinejoin='round'
        >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1' />
        </svg>
        </div>
       
    )
}

IconArrowBack.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func
}

export default IconArrowBack
