import PropTypes from 'prop-types'

const TableTwoVS = (props) => {
    const { elements, notPaint = false } = props
    return (
        <ul className={notPaint ? 'table-two-vs-not-paint' : 'table-two-vs'}>
            {elements.map((item, i) => (
                <li key={i}>
                    <span> {item.left} </span>
                    <span> {item.right} </span>
                </li>
            ))}
        </ul>
    )
}

export default TableTwoVS

TableTwoVS.propTypes = {
    elements: PropTypes.array.isRequired
}
