import { Button, Input, Modal } from 'antd'
import { FC, useRef } from 'react'
import * as API_PROMO_CODE from '../../shared/http/purchases'
import GoogleAnalyticsUtils from '../utils/GoogleAnalyticsUtils'
import GoogleAnalyticsConsts from '../../const/GoogleAnalyticsConsts'
import { goToPageNotPremium } from '../utils/goToPage'
import { NavigationPages } from '../../common/navigation/navigation-pages'
import { useNavigate } from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import { setUserRoutine, userSelector } from '../../store/global'

interface InterPromoCodeProps {
    isOpen: boolean,
    close: () => void
}

const InterPromoCode: FC<InterPromoCodeProps> = ({isOpen, close}) => {

    const input = useRef()
    const navigate = useNavigate()
    const currentUser = useAppSelector(userSelector)
    const dispatch = useAppDispatch()


    const clearAndClose = () => {
        close()
    }

    const openThanksPage = () => {
        goToPageNotPremium(NavigationPages.THANKS_PAGE, navigate)
    }

    const checkPromoCode = () => {
        // @ts-ignore
        API_PROMO_CODE.updateToPremiumByCode(input.current?.input?.value)
            // @ts-ignore
            .then(() => {
                const updatedUser = { ...currentUser, isPremium: true }
                dispatch(setUserRoutine(updatedUser))
                openThanksPage()
            })
            .catch(() => {
                Modal.error({
                    title: 'Ошибка!',
                    content: 'Не удалось применить промокод'
                })
            })
        GoogleAnalyticsUtils.createEvent(GoogleAnalyticsConsts.PREMIUM_ENTER_PROMO_CODE)
    }


    return (
        <Modal title='Введите промокод'
               onCancel={clearAndClose}
               footer={[
                   <Button key='submit' onClick={clearAndClose}>
                       Отмена
                   </Button>,
                   <Button
                       onClick={checkPromoCode}
                       type='primary'
                   >
                       Применить
                   </Button>
               ]}
               open={isOpen}>
            {/*// @ts-ignore*/}
            <Input ref={input}/>
        </Modal>
    )
}
export default InterPromoCode
