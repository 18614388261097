import { Helmet } from 'react-helmet'
import '../../../../styles/scss/course/course.scss'
import ReactPlayer from 'react-player'
import { GEButton } from '../../../../common/buttons'
import { Flex, Progress } from 'antd'
import { CourseItem } from './CourseItem'
import { useEffect, useState } from 'react'
import * as API_COURSES from '../../../../shared/http/courses'
import { CourseDto, ModulesDto } from '../../../../shared/types/api'
import { goToPageNotPremium } from '../../../utils/goToPage'
import { NavigationPages } from '../../../../common/navigation/navigation-pages'
import { useNavigate } from 'react-router-dom'

export const Course = () => {

    const [course, setCourse] = useState<CourseDto | undefined>(undefined)
    const [activeModule, setActiveModule] = useState<ModulesDto | undefined>(undefined)
    const [completedModules, setCompletedModules] = useState<Array<number> | undefined>(undefined)

    const navigate = useNavigate()

    const selectModule = (module: ModulesDto) => {
        setActiveModule(module)
    }

    const courseHardCode =
        {
            userHas: false,
            id: 1,
            title: 'Profound \"Upper-Intermediate\"',
            description: 'Здравствуйте! Я опытный преподаватель английского языка с 10 лет опыта в обучении. Я специализируюсь на создании курсов для студентов всех уровней и стремлюсь помочь каждому достигнуть их языковых целей. Мой курс Profound предназначен для тех, кто хочет углубить свои знания английского языка и достичь высокого уровня владения. Я предлагаю понятные объяснения и практические задания, чтобы каждый мог легко освоить материал.',
            headImage: '../../images/tmp/course1.jpg',
            price: 6900,
            oldPrice: 9900,
            author: {
                id: 1,
                name: 'Владимир',
                description: 'Я профессиональный преподаватель английского языка с более чем 4,5 лет опыта работы в онлайн-формате. Я провел более 15 000 часов, обучая более 300 индивидуальных студентов, и рад видеть их достижения и успехи.\n' +
                    '\n' +
                    'Я также являюсь профессиональным шахматистом с 13-летним стажем и закончил американский университет по специальности бизнес. Моя профессиональная деятельность включает работу в маркетинге, что помогает мне эффективно разрабатывать учебные материалы и адаптировать подход к каждому студенту.\n' +
                    '\n' +
                    'На моем YouTube-канале, который уже насчитывает более 40 000 подписчиков, я делюсь своими знаниями и опытом, создавая полезный контент для изучающих английский язык. Мое увлечение преподаванием и стремление помочь каждому студенту достичь своих целей – вот что делает мою работу особенно удовлетворительной.\n' +
                    '\n' +
                    'Я люблю преподавать, делиться своими знаниями и радоваться успехам моих учеников. Надеюсь, что мои курсы помогут вам также достичь новых высот в изучении английского языка!\n',
                avatar: '../../images/tmp/dragonenglish.jpg',
                links: {
                    telegram: 'https://t.me/EnglishDragonClubOpen',
                    youTube: 'https://youtube.com/@englishdragonclub?si=Ro0iurXtvxYaMShN'
                }
            },
            lessonsAmount: 10,
            level: ['B2'],
            duration: '3ч 20м.',
            views: 150,
            modules: [
                {
                    id: 1,
                    title: '1. Causatives (Казуативные конструкции)',
                    description: 'Изучите, как использовать казуативные конструкции для выражения действий, которые кто-то делает для вас или для кого-то другого. В этом уроке мы рассмотрим такие фразы, как "have someone do something" и "get someone to do something".',
                    video: true,
                    videoName: 'toystory'
                },
                {
                    id: 2,
                    title: '2. Future Tenses (Будущее время)',
                    description: 'Овладейте различными способами выражения будущего времени в английском языке. Мы изучим "will," "going to," и другие конструкции для построения будущих планов и прогнозов.',
                    video: true,
                    videoName: 'Quicksilver Saves Everyone'
                },
                {
                    id: 3,
                    title: '3. Past Tenses (Прошедшее время)',
                    description: 'Разберитесь с нюансами прошедшего времени. Мы обсудим использование "past simple," "past continuous," и "past perfect" для точного описания событий, произошедших в прошлом.',
                    video: true,
                    videoName: 'toystory'
                },
                {
                    id: 4,
                    title: '4. Passive Voice (Пассивный залог)',
                    description: 'Научитесь строить предложения в пассивном залоге и понимаете, как изменяется значение предложений. Урок охватывает как простые, так и сложные структуры пассивного залога.',
                    video: true,
                    videoName: 'toystory'
                },
                {
                    id: 5,
                    title: '5. Get/Be + Adjectives (Get/Be + прилагательные) ',
                    description: 'Изучите, как использовать "get" и "be" с прилагательными для описания изменений состояния и чувств. В уроке будут рассмотрены такие конструкции, как "get tired" и "be excited."',
                    video: true,
                    videoName: 'toystory'
                },
                {
                    id: 6,
                    title: '6. Linkers/Conjunctions (Связующие слова/союзы)',
                    description: 'Овладейте различными связующими словами и союзами для улучшения связности ваших текстов и речи. Мы рассмотрим "however," "although," "because," и другие.',
                    video: true,
                    videoName: 'toystory'
                },
                {
                    id: 7,
                    title: '7. Participles (Причастия)',
                    description: 'Разберитесь с использованием причастий в предложениях. Урок включает в себя как настоящие, так и прошедшие причастия, и их применение в различных контекстах.',
                    video: true,
                    videoName: 'toystory'
                },
                {
                    id: 8,
                    title: '8. Quantifiers (Квантифайеры)',
                    description: 'Изучите, как использовать квантифайеры для описания количества и степени. Мы рассмотрим такие слова, как "some," "any," "much," "many," и другие.',
                    video: true,
                    videoName: 'toystory'
                },
                {
                    id: 9,
                    title: '9. Infinitives and Gerunds Часть 1 (Инфинитивы и герундии) ',
                    description: 'Научитесь различать и правильно использовать инфинитивы и герундии. Мы рассмотрим случаи, когда нужно использовать один или другой, и их применение в различных структурах.',
                    video: true,
                    videoName: 'toystory'
                },
                {
                    id: 10,
                    title: '10. Infinitives and Gerunds Часть 2 (Инфинитивы и герундии)',
                    description: 'Научитесь различать и правильно использовать инфинитивы и герундии. Мы рассмотрим случаи, когда нужно использовать один или другой, и их применение в различных структурах.',
                    video: true,
                    videoName: 'toystory'
                }
            ]
        }

    /*    const PREFIX = 'http://localhost:8088/api/geekenglish-stream/video/stream/mp4/'*/
    const PREFIX = 'https://geekenglish.ru/api/geekenglish-stream/video/stream/mp4/'


    useEffect(() => {
        API_COURSES.getCourse(1).then((resp) => {
            setCourse(resp as CourseDto)
            setCompletedModules(resp.completedModules)
        }).catch(() => {
            goToPageNotPremium(`${NavigationPages.COURSES_OVERVIEW}/1`, navigate)
        }).finally(() => {

        })
    }, [])

    const passModule = () => {
        if (activeModule) {
            API_COURSES.passCourseModule(activeModule.id).then(c => {
                if (completedModules) {
                    setCompletedModules([...completedModules, activeModule.id])
                } else {
                    setCompletedModules([activeModule.id])
                }
            })
        }
    }


    const divWidth = document.getElementById('course__video_container')?.offsetWidth
    const videoWidth = divWidth ? divWidth * 0.98 : 300

    const progress = completedModules && course ? (completedModules.length / course.modules.length) * 100 : 0

    return (
        <div id='course'>
            <Helmet title='GE: Курс' />
            {course &&
            <Flex vertical gap='16px'>
                {activeModule ?
                    <span className='course__module-title'>{activeModule.title}</span>
                    : <span className='course__module-title'>Выберите модуль</span>}
                <Flex className='course__module-container'>
                    <Flex vertical gap='32px' className='course__video-container'>
                        <div className='course__module-video-container' id='course__video_container'>
                            <ReactPlayer className='course__module-video' width={videoWidth}
                                         height={videoWidth * 9 / 16}
                                         controls={true}
                                         url={activeModule && PREFIX + activeModule.videoName} config={{
                                file: {
                                    attributes: {
                                        controlsList: 'nodownload'
                                    }
                                }
                            }} />
                        </div>
                        {activeModule &&
                        <>
                            <Flex justify='flex-end' className='course__btn-area'>
                                <GEButton onClick={passModule}>Отметить как изучено</GEButton>
                            </Flex>
                            <div className='course__module-description'>{activeModule.description}</div>
                        </>}
                    </Flex>

                    <div className='course__list-container'>
                        <Flex vertical className='course__progress-container'>
                            <span className='course__progress-title'>{`Прогресс ${progress}%`}</span>
                            <Progress
                                percent={progress} />
                            <span className='course__progress-quote'>"Обучение - это двигатель профессионального успеха." - Джон Коттер</span>
                        </Flex>
                        <Flex vertical gap='8px'>
                            <Flex justify='space-between'>
                                <span className='course__module-title-list'>Все видео курса</span>
                                <span className='course__module-title-list'>1/10</span>
                            </Flex>
                            <Flex vertical gap='8px'>
                                {course.modules.map((module) => (
                                    <CourseItem selectModule={selectModule} module={module}
                                                activeModule={activeModule} completedModules={completedModules} />
                                ))}
                            </Flex>
                        </Flex>
                    </div>
                </Flex>
            </Flex>
            }
        </div>
    )
}