import {
    executeDeleteMethod,
    executeJsonGetMethod,
    executeJsonPostMethod,
    executeJsonPutMethod
} from '../api/rest/restApiUtils'
import { ID, WordDto } from '../types/api'

/**
 * Сохранение слова
 * @method create
 * @param {object} data
 * @param {number} wordListId
 * @return {Response} Fetch Response
 */
export function create(data: WordDto, wordListId: number) {
    let sendingData = {
        id: 0,
        engWord: data.engWord,
        rusWord: data.rusWord
    }
    let url = '/user/wordlist/' + wordListId
    return executeJsonPostMethod(url, sendingData).then((resp) => {
        return Promise.resolve(resp.data)
    })
}

/**
 * Сохранение нескольких слов
 * @method create
 * @param {array} wordsToSave
 * @param {array} wordsToUpdate
 * @param {number} wordListId
 * @return {Response} Fetch Response
 */
export function saveWords(wordListId: number, wordsToSave: Array<WordDto>, wordsToUpdate: Array<WordDto>) {
    let toSave = wordsToSave.map((w) => {
        return {
            ...w,
            id: null
        }
    })
    let sendingData = {
        wordListId: wordListId,
        save: toSave,
        update: wordsToUpdate
    }
    let url = '/user/wordlist/words'
    return executeJsonPostMethod(url, sendingData).then((resp) => {
        return Promise.resolve(resp.data)
    })
}

/**
 * Получение списка слов
 * @method getWords
 * @param {number} wordListId - идентификатор списка слов
 * @return {Response} Fetch Response
 */
export function getWords(wordListId: ID) {
    return executeJsonGetMethod('/user/wordlist/' + wordListId).then((resp) => {
        return Promise.resolve(resp.data.content)
    })
}

/**
 * Удаление слова
 * @method deleteWord
 * @param {number} wordListId - id списка
 * @param {object} word - слово
 * @return {Response} Fetch Response
 */
export function deleteWord(wordListId: number, word: WordDto) {
    let url = '/user/wordlist/' + wordListId
    let sendingData = {
        wordId: word.id
    }
    return executeDeleteMethod(url, sendingData)
}

/**
 * Обновление слова
 * @method update
 * @param {object} word
 * @param {number} wordListId
 * @return {Response} Fetch Response
 */
export function update(wordListId: number, word: WordDto) {
    let sendingData = {
        id: word.id,
        engWord: word.engWord,
        rusWord: word.rusWord
    }
    let url = '/user/wordlist/' + wordListId
    return executeJsonPutMethod(url, sendingData).then((resp) => {
        return Promise.resolve(resp.data)
    })
}
