import PropTypes from 'prop-types'
import '../../../../../styles/css/dictionary/words_lang_header.css'
import { Select } from 'antd'
import { updateWordListLang } from '../../../../../store/wordLists/extraReducers'
import Constants from '../../../../../const/Constants'
import { useAppDispatch } from '../../../../../store/hooks'

const WordsLangHeader = (props) => {
    const { activeWordList, wordCount, canEdit } = props
    const dispatch = useAppDispatch()

    const update = (data, id) => dispatch(updateWordListLang({ data, id }))

    const onChangeFirst = (value) => {
        let copy = Object.assign({}, activeWordList)
        copy.sideOneLang = value
        update(copy, copy.id)
    }

    const onChangeSecond = (value) => {
        let copy = Object.assign({}, activeWordList)
        copy.sideTwoLang = value
        update(copy, copy.id)
    }

    const mapLangToOptions = () => {
        return Constants.LANGUAGES.map((lang) => {
            return { value: lang.id, label: lang.description }
        })
    }

    return (
        <div className='words-lang-header'>
            {activeWordList?.id && wordCount > 0 && (
                <Select
                    style={{ width: '50%' }}
                    disabled={!canEdit}
                    showSearch
                    placeholder='Выберите язык (сторона 1)'
                    optionFilterProp='children'
                    onChange={onChangeFirst}
                    value={activeWordList.sideOneLang}
                    options={mapLangToOptions()}
                />
            )}
            {activeWordList?.id && wordCount > 0 && (
                <Select
                    style={{ width: '50%' }}
                    disabled={!canEdit}
                    showSearch
                    placeholder='Выберите язык (сторона 2)'
                    optionFilterProp='children'
                    onChange={onChangeSecond}
                    value={activeWordList.sideTwoLang}
                    options={mapLangToOptions()}
                />
            )}
        </div>
    )
}

export default WordsLangHeader

WordsLangHeader.propTypes = {
    activeWordList: PropTypes.object,
    wordCount: PropTypes.number,
    canEdit: PropTypes.bool
}
