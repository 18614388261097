export const test = [
    {
        id: 1,
        title: 'She has been _____ all night long.',
        variants: [
            { id: 1, title: 'danced', isTrue: false },
            { id: 2, title: 'dance', isTrue: false },
            { id: 3, title: 'dancing', isTrue: true },
            { id: 4, title: 'to dance', isTrue: false }
        ]
    },
    {
        id: 2,
        title: 'Why is it so chilly here? Have you _____ airing the room again?',
        variants: [
            { id: 1, title: 'was', isTrue: false },
            { id: 2, title: 'be', isTrue: false },
            { id: 3, title: 'been', isTrue: true },
            { id: 4, title: 'to', isTrue: false }
        ]
    },
    {
        id: 3,
        title: 'He’s so persistent, he _____ doing sit-ups for 10 minutes already!',
        variants: [
            { id: 1, title: 'has been', isTrue: true },
            { id: 2, title: 'have been', isTrue: false },
            { id: 3, title: 'was ', isTrue: false },
            { id: 4, title: 'are', isTrue: false }
        ]
    },
    {
        id: 4,
        title: 'They have been ______ in Africa for twenty years. And now they are happy to return to their homeland.',
        variants: [
            { id: 1, title: 'lives', isTrue: false },
            { id: 2, title: 'live', isTrue: false },
            { id: 3, title: 'to live', isTrue: false },
            { id: 4, title: 'living', isTrue: true }
        ]
    },
    {
        id: 5,
        title: 'We have ______ all our life. It’s time for us to have a rest.',
        variants: [
            { id: 1, title: 'to be working', isTrue: false },
            { id: 2, title: 'working', isTrue: false },
            { id: 3, title: 'been working', isTrue: true },
            { id: 4, title: 'work', isTrue: false }
        ]
    },
    {
        id: 6,
        title: 'The house is in a mess. What have you been ____ all this month without me?',
        variants: [
            { id: 1, title: 'do', isTrue: false },
            { id: 2, title: 'doing', isTrue: true },
            { id: 3, title: 'to do', isTrue: false },
            { id: 4, title: 'done', isTrue: false }
        ]
    },
    {
        id: 7,
        title: 'He _____ for me since seven o’clock to give me something costly.',
        variants: [
            { id: 1, title: 'has to wait', isTrue: false },
            { id: 2, title: 'have been waiting', isTrue: false },
            { id: 3, title: 'have been waited', isTrue: false },
            { id: 4, title: 'has been waiting', isTrue: true }
        ]
    },
    {
        id: 8,
        title: 'They _____ on the phone for the last two hours! That’s incredible!',
        variants: [
            { id: 1, title: 'has been talked', isTrue: false },
            { id: 2, title: 'has talked', isTrue: false },
            { id: 3, title: 'have been talking', isTrue: true },
            { id: 4, title: 'have talk', isTrue: false }
        ]
    }
]
