import { UserDto } from '../../shared/types/api'
import moment from 'moment'

export const getLoginByUser = (user: UserDto | null): string => {
    return user ? user.login : 'Login'
}

export const getEmailByUser = (user: UserDto | null): string => {
    return user && user.email ? user.email : ' '
}

export const getFirstName = (user: UserDto | null): string => {
    return user && user.firstName ? user.firstName : 'Имя'
}

export const getLastName = (user: UserDto | null): string => {
    return user && user.lastName ? user.lastName : 'Фамилия'
}

export const isPremium = (user: UserDto | null): boolean => {
    return !!user?.isPremium
}

export const isNotPremium = (user: UserDto | null): boolean => {
    return !isPremium(user)
}

export const convertHoursToTime = (hours: number): string => {
    const m = moment().utcOffset(0)
    m.set({ hour: hours, minute: 0, second: 0, millisecond: 0 })
    return m.format('hh:mm:ss')
}