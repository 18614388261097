import { v4 as uuidv4 } from 'uuid'
import pL from 'js-regex-pl'
import { notification } from 'antd'
import moment from 'moment'

let WordUtils = {
    /**
     * Создание нового слова
     * @param
     * @returns {{prewEngWord: string, rusWord: string, prewRusWord: string, engWord: string, editMode: boolean, id}}
     */
    createNewWord(listId) {
        return {
            id: uuidv4(),
            engWord: '',
            prevEngWord: '',
            rusWord: '',
            prevRusWord: '',
            editMode: true,
            listId: listId,
            loading: false,
            created: moment(new Date()).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        }
    },

    /**
     * Создание нового слова
     * @param
     * @returns {{prewEngWord: string, rusWord: string, prewRusWord: string, engWord: string, editMode: boolean, id}}
     */
    createNewAiWord(listId, sideOne, sideTwo) {
        return {
            id: uuidv4(),
            engWord: sideOne,
            prevEngWord: sideOne,
            rusWord: sideTwo,
            prevRusWord: sideTwo,
            editMode: true,
            listId: listId,
            loading: false,
            created: moment(new Date()).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        }
    },

    /**
     * Новая сущность, созданная на фронте имеет id != number. Сущность из бд id = number
     * @param id
     * @returns {boolean}
     */
    isNewEntity(id) {
        return typeof id !== 'number'
    },

    /**
     * Преобразуем слова в объект на фронте
     * @param data
     * @returns {*|*[]}
     */
    parseWorlds: (data) => data?.map((word) => WordUtils.parseWorld(word)) || [],

    /**
     * Преобразуем слово в объект на фронте
     * @param word
     * @returns {*|*[]}
     */
    parseWorld: (word) => {
        return {
            id: word.id,
            engWord: word.engWord,
            prevEngWord: word.engWord,
            rusWord: word.rusWord,
            prevRusWord: word.rusWord,
            editMode: false,
            loading: false,
            listId: word.listId,
            created: word.created
        }
    },

    loadWord: (word, value) => {
        return {
            ...word,
            loading: value
        }
    },

    clearNotSavedWords: (words) => {
        return words.map(w => {
            if (w.editMode) {
                return {
                    ...w,
                    engWord: w.prevEngWord ? w.prevEngWord : '',
                    rusWord: w.prevRusWord ? w.prevRusWord : '',
                    editMode: false
                }
            }
            return w
        }).filter((w) => !WordUtils.isNewEntity(w.id))
    },

    removeDuplicates: (words) => {
        return words.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))
    },

    /**
     * Валидация слов по паттерну
     * @param word - слово (строка)
     * @returns {boolean}
     */
    validateWordString: (word) => {
        const myRegexp = new RegExp(WordUtils.getWordPattern(), 'g')
        return !!word.match(myRegexp)
    },

    getWordPattern: () => {
        return `^[${pL}0-9\\s()-.?¿'!¡,:：！？。_\\/]{1,250}$`
    },

    getWordsPattern: () => {
        return `^(([${pL}0-9\\s()-.?¿'!¡,:：！？。_\\/]{1,250}) - ([${pL}0-9\\s()-.?¿'!¡,:：！？。_\\/]{1,250}))$`
    },

    /**
     * Валидация слов по паттерну
     * @param word - слово
     * @returns {boolean}
     */
    validateWord: (word) => {
        return WordUtils.validateWordString(word.engWord) && WordUtils.validateWordString(word.rusWord)
    },

    wordErrorMsg: (msg) => {
        notification.open({
            type: 'error',
            message: 'Ошибка',
            description: msg
        })
    }
}

export default WordUtils
