let GoogleAnalyticsConsts = {
    // 3 - минимальная длина ника
    DICTIONARY_ADD_CARD_LIST: { category: 'DICTIONARY', action: 'ADD_CARD_LIST' },
    PREMIUM_OPEN_BUY_PREMIUM_30: { category: 'PREMIUM', action: 'OPEN_BUY_PREMIUM_30' },
    PREMIUM_OPEN_BUY_PREMIUM_365: { category: 'PREMIUM', action: 'OPEN_BUY_PREMIUM_365' },
    PREMIUM_ENTER_PROMO_CODE: { category: 'PREMIUM', action: 'PREMIUM_ENTER_PROMO_CODE' },
    OPEN_RULES_FROM_LP: { category: 'RULES', action: 'OPEN_RULES_FROM_LP' }
}

export default GoogleAnalyticsConsts
