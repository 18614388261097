import { useEffect, useState } from 'react'
import '../../../../styles/css/main_page/wordCards.css'
import CardWordsListCombineSelector from '../../../blocks/combine-selectors/CardWordsListCombineSelector'
import SelectCategory from '../../../elements/selectors/SelectCategory'
import NewCommerceUtil from '../../../utils/NewCommerceUtil'
import Pages from '../../../../const/Pages'
import { Modal } from 'antd'
import { setUserRoutine, userSelector } from '../../../../store/global'
import { Helmet } from 'react-helmet'
import { isNotPremium } from '../../../../common/utils/user'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { ID, UserDto, WordDto } from '../../../../shared/types/api'
import YandexFloorAd from '../../../blocks/banner/YandexFloorAd'
import useCombineSelector from '../../../../hooks/useCombineSelector'
import CardHeader from './ui/CardHeader'
import CardArrows from './ui/CardArrows'
import RememberButtons from './ui/RememberButtons'
import Constants from '../../../../const/Constants'
import FlippedCard from './ui/FlippedCard'
import SoundButton from './ui/SoundButton'
import * as API_SOUND from '../../../../shared/http/sound'
import CompletedExerciseDialog from '../../../modalwindows/CompletedExerciseDialog'
import ConfettiOnTheEnd from '../../../blocks/confetti-on-the-end/ConfettiOnTheEnd'

const WordsCards = () => {
    const dispatch = useAppDispatch()

    const user = useAppSelector(userSelector)
    const setReactUser = (uUser: UserDto) => dispatch(setUserRoutine(uUser))

    const [cardWords, setCardWords] = useState<Array<WordDto>>([])
    const [notRememberState, setNotRememberState] = useState(false)
    const [showCompleteDialog, setShowCompleteDialog] = useState<boolean>(false)

    const [wordIndex, setWordIndex] = useState<number>(0)
    const [isFlipped, setIsFlipped] = useState<boolean>(false)


    useEffect(() => {
        beforeMount()
        return () => {
        }
    }, [])

    const startCards = (words: Array<WordDto>) => {
        if (words.length <= 2) {
            showWarningPrompt()
        } else {
            setWordIndex(0)
            setCardWords(words)
        }
    }

    const {
        wordLists,
        selectedWordListId,
        communities,
        wordListsIsLoading,
        side,
        changeWordListById,
        changeSideValue
    } = useCombineSelector(startCards)

    //Todo
    const playWord = (): void => {
        //@ts-ignore
        const selectedWl = wordLists.find(wl => wl.id === parseInt(selectedWordListId))
        const secondSide = side === Constants.CARD_SIDE_ONE ? Constants.CARD_SIDE_TWO : Constants.CARD_SIDE_ONE
        const actualSide = isFlipped || notRememberState ? secondSide : side
        API_SOUND.playCard(
            actualSide === Constants.CARD_SIDE_ONE ? selectedWl.sideOneLang : selectedWl.sideTwoLang,
            String(cardWords[wordIndex].id),
            String(actualSide === Constants.CARD_SIDE_ONE ? 1 : 0))

    }

    const nextWordCard = () => {
        if (wordIndex + 1 === cardWords.length) {
            setShowCompleteDialog(true)
        }
        const index = (wordIndex + 1) % cardWords.length
        setWordIndex(index)
        if (notRememberState) {
            flipCard()
        }
        setNotRememberState(false)
    }

    const prevWordCard = () => {
        const index = wordIndex == 0 ? cardWords.length - 1 : wordIndex - 1
        setWordIndex(index)
        if (notRememberState) {
            flipCard()
        }
        setNotRememberState(false)
    }

    const rememberWord = () => {
        nextWordCard()
    }

    const notRemember = () => {
        setNotRememberState(true)
        setCardWords([...cardWords, cardWords[wordIndex]])
        setIsFlipped(true)
    }
    const nextAndRotateWord = () => {
        setIsFlipped(true)
        nextWordCard()
    }

    const flipCard = () => {
        setIsFlipped(!isFlipped)
    }

    /**
     * Показываем подсказку если пользователь зашел на страницу в первый раз
     */
    const updateUserIfNew = () => {
        const newUser = NewCommerceUtil.showNewCommerceModal(user, Pages.CARD)
        if (newUser) {
            setReactUser(newUser)
        }
    }

    /**
     * Срабатывает перед монтированием
     */
    const beforeMount = () => {
        updateUserIfNew()
    }


    const showWarningPrompt = () => {
        const t = {
            title: 'Неверный список карточек',
            content: 'В списке должно быть минимум 3 карточки. Можете добавить их в словаре или выбрать другой список.'
        }
        Modal.warning(t)
    }

    return (
        <div id='word-card'>
            <Helmet title='GE: Карточки' />
            {cardWords.length <= 2 && <SelectCategory message={'Выберите список карточек'} />}
            {cardWords.length > 2 && <CardHeader current={wordIndex + 1} max={cardWords.length} />}
            {cardWords.length > 2 &&
            <FlippedCard flipped={isFlipped} flipCard={flipCard} word={cardWords[wordIndex]} cardWords={cardWords}
                         side={side} />}
            <div className='word-card-btn-area'>
                {cardWords.length > 2 &&
                /*  @ts-ignore*/
                <SoundButton wordList={wordLists.find(wl => wl.id === parseInt(selectedWordListId as string))}
                             playWord={playWord} />}
                {cardWords.length > 2 && <CardArrows prevWordCard={prevWordCard} nextWordCard={nextWordCard} />}
            </div>
            <div className='word-card-btn-area__remember-btn'>
                {cardWords.length > 2 && <RememberButtons notRememberState={notRememberState}
                                                          remember={rememberWord}
                                                          notRemember={notRemember}
                                                          nextAndRotateWord={nextAndRotateWord} />}
            </div>
            <CardWordsListCombineSelector
                selectedWordListId={selectedWordListId}
                communities={communities}
                changeWordList={changeWordListById}
                showWordListLoader={wordListsIsLoading}
                changeSide={changeSideValue}
            />
            {isNotPremium(user) &&
            <YandexFloorAd blockId='R-A-2733375-2' type='floorAd' />}
            <CompletedExerciseDialog
                selectedWordListId={selectedWordListId}
                close={() => setShowCompleteDialog(false)}
                open={showCompleteDialog} />
            <ConfettiOnTheEnd showConfetti={showCompleteDialog} />
        </div>
    )
}

export default WordsCards
