import { useEffect, useState } from 'react'
import * as API_USER from '../../../../../shared/http/user'

const Summary = () => {
    const [userCounter, setUserCounter] = useState([])

    /**
     * Загрузить пользователей с их привилегиями
     */
    const loadUsersActivity = () => {
        API_USER.getCountAllUsers().then((data) => {
            setUserCounter(data)
        })
    }

    useEffect(() => {
        loadUsersActivity()
    }, [])

    return <>Общее число пользователей : {userCounter}</>
}

export default Summary
