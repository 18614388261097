import PropTypes from 'prop-types'
import { useEffect } from 'react'
import {
    wordsListsLoadingStatusSelector,
    setWordListsRoutine
} from '../../../../../store/wordLists'
import '../../../../../styles/scss/pages/dictionary/card-list/card-list.scss'
import Loading from '../../../../elements/loading/Loading'
import NotificationsUtils from '../../../../utils/NotificationsUtils'
import UrlUtils from '../../../../utils/UrlUtils'
import WordListUtils from '../../../../utils/WordListUtils'
import WordListItem from './WordListItem'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'

const WordLists = (props) => {
    const dispatch = useAppDispatch()
    const isWordListsLoading = useAppSelector(wordsListsLoadingStatusSelector)
    const { wordLists, canEditCommunity } = props


    /**
     * Изменить название списка
     * @param e
     * @param id
     */
    const onChangeListName = (e, id) => {
        const listName = e.target.value
        const newWordList = wordLists.map((wordList) => {
            if (wordList.id === id) {
                return { ...wordList, listName }
            }
            return wordList
        })
        dispatch(setWordListsRoutine(newWordList))
    }

    /**
     * Изменить уровень списка слов
     * @param e
     * @param id
     */
    const onChangeListLevel = (e, id) => {
        const level = e.target.value
        const newWordList = wordLists.map((wordList) => {
            if (wordList.id === id) {
                return { ...wordList, level }
            }
            return wordList
        })
        dispatch(setWordListsRoutine(newWordList))
    }

    /**
     * Отменить редактирование
     * @param id
     */
    const cancelEdit = (id) => {
        let newWordList
        if (WordListUtils.isNewEntity(id)) {
            newWordList = wordLists.filter((wordList) => wordList.id !== id)
        } else {
            newWordList = wordLists.map((wordList) => {
                if (wordList.id === id) {
                    return {
                        ...wordList,
                        listName: wordList.prevListName,
                        editMode: false
                    }
                }
                return wordList
            })
        }
        dispatch(setWordListsRoutine(newWordList))
    }

    /**
     * Поделиться сообществом
     */
    const shareWordList = (wlId) => {
        navigator.clipboard
            .writeText(UrlUtils.createShareListUrl(props.inviteKey, wlId))
            .then(() => {
                NotificationsUtils.openSuccessNotification('Ссылка скопирована!')
            })
            .catch(() => {
                NotificationsUtils.openErrorNotification('Не удалось скопировать ссылку')
            })
    }

    return (
        <div className='dictionary_right_bar'>
            <div
                className={'dictionary_right_bar--wls ' + (props.isCommunity && 'dictionary_right_bar--wls-community')}
            >
                {isWordListsLoading ? (
                    <Loading />
                ) : (
                    wordLists
                        .filter(
                            (wl) =>
                                (props.isCommunity && wl.communityId === props.communityId) ||
                                (!props.isCommunity) && !wl.communityId
                        )
                        .map((wordList) => (
                            <WordListItem
                                key={wordList.id}
                                wordList={wordList}
                                onChangeListName={onChangeListName}
                                onChangeListLevel={onChangeListLevel}
                                shareWordList={shareWordList}
                                cancelEdit={cancelEdit}
                                canEditCommunity={canEditCommunity}
                            />
                        ))
                )}
            </div>
        </div>
    )
}

export default WordLists

WordLists.propTypes = {
    wordLists: PropTypes.array,
    user: PropTypes.object,
    activeCommunity: PropTypes.object,
    canEditCommunity: PropTypes.bool,
    isCommunity: PropTypes.bool.isRequired,
    inviteKey: PropTypes.string,
    communityId: PropTypes.number
}