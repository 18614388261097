import '../../../styles/css/elements/svg_icons/icon-btn.css'

function IconRocket(props) {
    const { className = '' } = props
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='44'
            height='44'
            fill='none'
            strokeLinecap='round'
            strokeLinejoin='round'
            className={'icon icon-tabler icon-tabler-rocket ' + className}
            viewBox='0 0 24 24'
        >
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <path d='M4 13a8 8 0 017 7 6 6 0 003-5 9 9 0 006-8 3 3 0 00-3-3 9 9 0 00-8 6 6 6 0 00-5 3'></path>
            <path d='M7 14a6 6 0 00-3 6 6 6 0 006-3'></path>
            <circle cx='15' cy='9' r='1'></circle>
        </svg>
    )
}

export default IconRocket
