import { useParams } from 'react-router-dom'
import UserProfilePage from './UserProfilePage'
import { Helmet } from 'react-helmet'
import { useAppSelector } from '../../../../store/hooks'
import Banner from '../../../blocks/banner/Banner'
import { userSelector } from '../../../../store/global'
import { ProfileMainComponent } from './newProfile/ProfileMainComponent'
import AdminBtnComponent from './newProfile/AdminBtnComponent'

const ProfilePage = () => {
    const currentUser = useAppSelector(userSelector)
    const { id } = useParams()

    return (
        <>
            <Helmet title='GE: Профиль' />
            {currentUser?.id === parseInt(id) ?
                <>
                    <Banner />
                    <AdminBtnComponent currentUser={currentUser} />
                    <ProfileMainComponent />
                </>
                : <UserProfilePage userId={id} />}
        </>
    )
}

export default ProfilePage
