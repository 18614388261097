import { Row } from 'antd'
import PropTypes from 'prop-types'
import '../../../styles/scss/side-bar/mobile-side-bar.scss'
import Burger from '../../elements/burger/Burger'
import IconArrowBack from '../../elements/svg_icons/IconArrowBack'
import { useNavigate } from 'react-router-dom'

const MobileSideBar = (props) => {
    const navigate = useNavigate()

    const { className } = props
    const goBack = () => {
        props.visible ? props.resetVisibility() : navigate(-1)
    }

    return (
        <div id='navbar' className={className}>
            <nav className='navbar'>
                <Row className='navbar__bottom' justify={'space-around'} style={{ height: 70 }}>
                    <Burger className='navbar__burger' onClick={props.changeVisibility} />
                    <IconArrowBack className='navbar__icon-back' onClick={goBack} />
                </Row>
            </nav>
        </div>
    )
}

MobileSideBar.propTypes = {
    className: PropTypes.string.isRequired
}

export default MobileSideBar
