/**
 * Project geekenglish
 *
 * @author Alexandr Filatov on 21.04.2020
 * Дублируются в файле constants/Pages.java
 */
let Pages = {
    TEST: 'TEST',
    CARD: 'CARD',
    DICTIONARY: 'DICTIONARY',
    FILL_GAPS: 'FILL_GAPS',
    DONE_WORDLIST: 'DONE_WORDLIST',
    FILL_WORD: 'FILL_WORD'
}

export default Pages
