export const test = [
    {
        id: 1,
        title: 'Tomas ____ my mobile phone!',
        variants: [
            { id: 1, title: 'use', isTrue: false },
            { id: 2, title: 'used', isTrue: false },
            { id: 3, title: 'are using', isTrue: false },
            { id: 4, title: 'is using', isTrue: true }
        ]
    },
    {
        id: 2,
        title: 'What ____ at the moment?',
        variants: [
            { id: 1, title: 'do you do', isTrue: false },
            { id: 2, title: 'is you doing', isTrue: false },
            { id: 3, title: 'are you doing', isTrue: true },
            { id: 4, title: 'are you do', isTrue: false }
        ]
    },
    {
        id: 3,
        title: 'Nikita ___ tennis now',
        variants: [
            { id: 1, title: 'is not playing', isTrue: true },
            { id: 2, title: 'are play', isTrue: false },
            { id: 3, title: 'doesn’t play', isTrue: false },
            { id: 4, title: 'is not play', isTrue: false }
        ]
    },
    {
        id: 4,
        title: 'We ___ the guitar now',
        variants: [
            { id: 1, title: 'are playing', isTrue: true },
            { id: 2, title: 'is not playing', isTrue: false },
            { id: 3, title: "don't playing", isTrue: false },
            { id: 4, title: 'are play', isTrue: false }
        ]
    },
    {
        id: 5,
        title: '___ you still ___ in London?',
        variants: [
            { id: 1, title: 'Do, working', isTrue: false },
            { id: 2, title: 'Are, working', isTrue: true },
            { id: 3, title: 'Does, work', isTrue: false },
            { id: 4, title: 'Do, works', isTrue: false }
        ]
    },
    {
        id: 6,
        title: 'We ___ in Moscow in 20 minutes.',
        variants: [
            { id: 1, title: 'is land', isTrue: false },
            { id: 2, title: 'lands', isTrue: false },
            { id: 3, title: 'are landing', isTrue: true },
            { id: 4, title: 'is landing', isTrue: false }
        ]
    },
    {
        id: 7,
        title: 'She ___ her dog now',
        variants: [
            { id: 1, title: 'petting', isTrue: false },
            { id: 2, title: 'pets', isTrue: false },
            { id: 3, title: 'are petting', isTrue: false },
            { id: 4, title: 'is petting', isTrue: true }
        ]
    },
    {
        id: 8,
        title: 'Tomas ___ TV at the moment',
        variants: [
            { id: 1, title: 'is watching', isTrue: true },
            { id: 2, title: 'is watch', isTrue: false },
            { id: 3, title: 'watch', isTrue: false },
            { id: 4, title: 'are watching', isTrue: false }
        ]
    }
]
