import { useEffect, useState } from 'react'
import '../../../../styles/css/pages/guess-word/guess-word.css'
import GuessWordWordsListCombineSelector from '../../../blocks/combine-selectors/GuessWordWordsListCombineSelector'
import GuessWordUtils, { letterValue, valueRealValue } from './utils/GuessWordUtils'
import Word from './ui/Word'
import IconEye from '../../../elements/svg_icons/IconEye'
import IconEyeCross from '../../../elements/svg_icons/IconEyeCross'
import NewCommerceUtil from '../../../utils/NewCommerceUtil'
import Pages from '../../../../const/Pages'
import { setUserRoutine, userSelector } from '../../../../store/global'
import Constants from '../../../../const/Constants'
import { Helmet } from 'react-helmet'
import { GEButton } from '../../../../common/buttons'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { Modal } from 'antd'
import SelectCategory from '../../../elements/selectors/SelectCategory'
import useCombineSelector from '../../../../hooks/useCombineSelector'
import { UserDto, WordDto } from '../../../../shared/types/api'
import ConfettiOnTheEnd from '../../../blocks/confetti-on-the-end/ConfettiOnTheEnd'
import FillGapsCompletedExerciseDialog from '../../../modalwindows/FillGapsCompletedExerciseDialog'

const FillGaps = () => {

    const startFillGaps = (words: Array<WordDto>) => {
        setActiveWords(words)
        loadGuessWord(words, 0, percent, side)
    }

    const {
        selectedWordListId,
        communities,
        wordListsIsLoading,
        side,
        changeWordListById,
        changeSideValue
    } = useCombineSelector(startFillGaps)

    const dispatch = useAppDispatch()
    const user = useAppSelector(userSelector)
    const setReactUser = (uUser: UserDto) => dispatch(setUserRoutine(uUser))

    const [showAnswer, setShowAnswer] = useState(false)
    const [activeWords, setActiveWords] = useState<Array<WordDto>>([])
    const [phrase, setPhrase] = useState<Array<{ letters: Array<valueRealValue>, id: string }>>([])
    const [currentWordNumber, setCurrentWordNumber] = useState(0)
    const [percent, setPercent] = useState<number>(80)
    const [userAnswers, setUserAnswers] = useState<Array<letterValue>>([])
    const [availableNext, setAvailableNext] = useState(false)
    const [showCompleteDialog, setShowCompleteDialog] = useState<boolean>(false)

    const skippedLetters = phrase.flatMap(v => v.letters).filter(v => v.value !== v.realValue)

    const updateUserAnswers = (value: letterValue) => {
        let newUserAnswers
        if (!userAnswers.some(ua => ua.letterId === value.letterId)) {
            newUserAnswers = [...userAnswers, value]
            setUserAnswers(newUserAnswers)
        } else {
            newUserAnswers = userAnswers.map((ua) => {
                return ua.letterId === value.letterId ? { ...ua, value: value.value } : ua
            })
            setUserAnswers(newUserAnswers)
        }

        if (skippedLetters.length !== newUserAnswers.length) {
            setAvailableNext(false)
        } else {
            const b = GuessWordUtils.allAnswersIsTrue(newUserAnswers, skippedLetters)
            setAvailableNext(b)
        }
    }

    const getSideTwo = () => {
        return Constants.CARD_SIDE_TWO === side ? Constants.CARD_SIDE_ONE : Constants.CARD_SIDE_TWO
    }

    /**
     * Сгенерировать массив с буквами
     * @param words - массив слов
     * @param count - индекс слова
     * @param tPercent - процент открытых букв
     * @param sideOne - сторона карточки
     */
    const loadGuessWord = (words: Array<WordDto>, count: number, tPercent: number, sideOne: string) => {
        setAvailableNext(false)
        setUserAnswers([])
        if (words.length > 0) {
            //@ts-ignore
            const sideOneWord = words[count][sideOne]
            const newPhrase = GuessWordUtils.generateArray(sideOneWord, tPercent)
            setCurrentWordNumber(count)
            setPhrase(newPhrase)
        } else {
            const t = {
                title: 'Неверный список карточек',
                content: 'В списке должно быть минимум 1 карточка. Можете добавить их в словаре или выбрать другой список.'
            }
            Modal.warning(t)
        }
    }

    /**
     * Следующее слово
     */
    const nextWord = () => {
        hideAnswerSwitch()
        const length = activeWords.length
        if (currentWordNumber + 1 === length) {
            setShowCompleteDialog(true)
        }
        const count = (currentWordNumber + 1) % length
        loadGuessWord(activeWords, count, percent, side)
    }

    /**
     * Изменить уровень сложности
     * @param tPercent процент
     */
    const changePercent = (tPercent: number) => {
        setPercent(tPercent)
        loadGuessWord(activeWords, currentWordNumber, tPercent, side)
    }

    const restart = () => {
        startFillGaps(activeWords)
    }

    const showAnswerSwitch = () => {
        setShowAnswer(!showAnswer)
    }

    const hideAnswerSwitch = () => {
        setShowAnswer(false)
    }

    const updateUserIfNew = () => {
        const newUser = NewCommerceUtil.showNewCommerceModal(user, Pages.FILL_GAPS)
        if (newUser) {
            setReactUser(newUser as UserDto)
        }
    }

    /**
     * Срабатывает перед монтированием
     */
    const beforeMount = () => {
        updateUserIfNew()
    }


    useEffect(() => {
        beforeMount()
    }, [])

    useEffect(() => {
        if (activeWords.length > 0) {
            loadGuessWord(activeWords, currentWordNumber, percent, side)
        }

    }, [side])

    const currentWord = activeWords[currentWordNumber]
    return (
        <div id='guess-word'>
            <Helmet title='GE: Заполни пропуски' />
            {activeWords.length === 0 && <SelectCategory message='Выберите список карточек' />}
            {activeWords.length > 0 && <h1>{currentWordNumber + 1 + '/' + activeWords.length}</h1>}
            <div className={activeWords.length > 0 ? 'fill-word__content_grid' : ''}>
                {phrase && phrase.length > 0 && (
                    <div className='guess-word-area'>
                        {/*@ts-ignore*/}
                        <div className='guess-word-area__rus'>{currentWord && currentWord[getSideTwo()]}</div>
                        <div className='guess-word-area__words'>
                            {phrase.map((word) => (
                                <Word key={word.id} wordArray={word.letters} updateUserAnswers={updateUserAnswers} />
                            ))}
                        </div>
                        <div className='guess-word-area__buttons'>
                            <div className='guess-word-area__help'>
                                {!showAnswer ? (
                                    <IconEye onClick={showAnswerSwitch} />
                                ) : (
                                    <>
                                        <IconEyeCross onClick={showAnswerSwitch} />
                                        {/*@ts-ignore*/}
                                        <span>{currentWord && currentWord[side]}</span>
                                    </>
                                )}
                            </div>
                            <div className='guess-word-area__btns'>
                                <GEButton
                                    className='width270px'
                                    size='large'
                                    type='primary'
                                    onClick={nextWord}
                                    disabled={!availableNext && skippedLetters.length !== 0}
                                >
                                    Следующее слово
                                </GEButton>
                            </div>
                        </div>
                    </div>
                )}
                <GuessWordWordsListCombineSelector
                    selectedWordListId={selectedWordListId}
                    changePercent={changePercent}
                    communities={communities}
                    changeWordList={changeWordListById}
                    showWordListLoader={wordListsIsLoading}
                    restart={restart}
                    changeSide={changeSideValue}
                />
                <FillGapsCompletedExerciseDialog
                    close={() => setShowCompleteDialog(false)} open={showCompleteDialog}
                    cardListId={selectedWordListId} />
                <ConfettiOnTheEnd showConfetti={showCompleteDialog} />
            </div>
        </div>
    )
}

export default FillGaps
