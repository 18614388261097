import { executeGetMethod } from '../api/rest/restApiUtils'

/**
 * Воспроизвести текст с карточки
 * @method playCard
 * @param  {string}  lang - язык
 * @param  {number}  cardId - id карточки
 * @param  {number}  cardSide - сторона карточки
 * @return {Response} Fetch Response
 */
export function playCard(lang: string, cardId: string, cardSide: string) {
    return executeGetMethod(
        '/sound/play?' +
        new URLSearchParams({
            lang: lang,
            cardId: cardId,
            cardSide: cardSide
        })
    )
        .then((response) => response.blob())
        .then((blob) => {
            const url = URL.createObjectURL(blob)
            new Audio(url).play()
        })
        .catch((err) => console.error(err))
}
