const Tip = (props) => {
    const { className = '', title = '', text = '' } = props

    return (
        <div className={'tips__tip ' + className}>
            <h3 className={'tips__title'}>{title}</h3>
            <p className={'tips__text'}>{text}</p>
        </div>
    )
}

export default Tip
