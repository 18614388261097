import { useState } from 'react'
import '../../../../styles/scss/communities/communities.scss'
import { Tabs } from 'antd'
import { GEButton } from '../../../../common/buttons'
import { Helmet } from 'react-helmet'
import { MyCommunities } from './MyCommunities'
import CreateCommunityDialog from '../../../modalwindows/CreateCommunityDialog'
import FindCommunityDialog from '../../../modalwindows/FindCommunityDialog'
import TabPane from 'antd/es/tabs/TabPane'
import { PublicCommunities } from './PublicCommunities'

export const Communities = () => {

    const [showCreateCommunity, setShowCreateCommunity] = useState<boolean>(false)
    const [showFindCommunity, setShowFindCommunity] = useState<boolean>(false)

    return (
        <div id='communities'>
            <Helmet title='GE: Сообщества' />
            <div className='communities__header'>
                <div className='communities__header__btn'>
                    <GEButton onClick={() => setShowFindCommunity(true)}>Найти сообщество</GEButton>
                    <GEButton type='primary' onClick={() => setShowCreateCommunity(true)}>Создать сообщество</GEButton>
                </div>
                <div>
                    <Tabs type='card' defaultActiveKey='1' destroyInactiveTabPane={true}>
                        <TabPane tab='Публичные сообщества' key='1'>
                            <PublicCommunities />
                        </TabPane>
                        <TabPane tab='Мои сообщества' key='2'>
                            <MyCommunities update={showCreateCommunity || showFindCommunity} />
                        </TabPane>
                    </Tabs>
                </div>
                <CreateCommunityDialog
                    showWindow={showCreateCommunity}
                    cancelWindow={() => setShowCreateCommunity(false)}
                />
                <FindCommunityDialog showWindow={showFindCommunity}
                                     cancelWindow={() => setShowFindCommunity(false)} />
            </div>
        </div>
    )
}