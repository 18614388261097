import '../../../styles/css/elements/svg_icons/icon-btn.css'

const IconNumbers = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_271_40)">
                <path
                    d="M0.0439453 0.0437012H10.0002V9.99995H0.0439453V0.0437012ZM10.0002 9.99995H19.9564V19.9562H10.0002V9.99995ZM0.0455078 9.99995H10.0002V19.9562H0.0455078V9.99995ZM10.0002 0.0437012H19.9564V9.99995H10.0002V0.0437012Z"
                    fill="white"/>
                <path
                    d="M0.0439453 0.0437012V9.99995H10.0002V0.0437012H0.0439453ZM6.7752 8.89995C6.7752 8.96401 6.72363 9.01401 6.66113 9.01401H3.58926C3.5252 9.01401 3.4752 8.96401 3.4752 8.89995V7.45308C3.4752 7.39058 3.5252 7.33901 3.58926 7.33901H4.18301V3.10933L3.4127 3.32964L3.3127 3.31089L3.26738 3.22026V1.69839C3.26738 1.64526 3.30488 1.59839 3.35645 1.58745L5.92832 1.03589L6.02363 1.05933L6.06582 1.14683V7.34214H6.66113C6.72363 7.34214 6.7752 7.3937 6.7752 7.4562V8.89995ZM10.0002 9.99995V19.9562H19.9564V9.99995H10.0002ZM18.2674 17.5625C18.2674 17.625 18.2158 17.675 18.1533 17.675H16.8064V19.0578C16.8064 19.1203 16.7564 19.1703 16.6924 19.1703H14.9768C14.9143 19.1703 14.8643 19.1203 14.8643 19.0578V17.675H11.7049C11.6424 17.675 11.5924 17.625 11.5924 17.5625V16.1812C11.5924 16.164 11.6033 16.1531 11.6096 16.139V16.1203L14.8814 11.0718C14.8846 11.0671 14.8893 11.0656 14.8939 11.0609C14.8986 11.0562 14.9018 11.0531 14.9064 11.05C14.9268 11.0328 14.9502 11.0203 14.9768 11.0203H16.6939C16.7221 11.0203 16.7455 11.0328 16.7658 11.05C16.7721 11.0546 16.7752 11.0593 16.7783 11.064C16.783 11.0703 16.7908 11.0734 16.7939 11.0796L16.7924 11.0984C16.7971 11.1109 16.8064 11.1187 16.8064 11.1328V16.0687H18.1533C18.2158 16.0687 18.2674 16.1187 18.2674 16.1812V17.5625Z"
                    fill="#0568FC"/>
                <path d="M14.8626 16.0672V14.1672L13.6313 16.0672H14.8626Z" fill="#0568FC"/>
                <path
                    d="M12.6783 3.85479L12.7674 3.9126L12.8689 3.87979C12.8783 3.87197 13.8033 3.01572 14.6127 3.01572C14.9377 3.01572 15.1939 3.15322 15.3924 3.43447C15.5549 3.66416 15.6033 3.91885 15.5377 4.20791C15.2283 5.59229 12.5002 7.15635 12.472 7.17354C12.433 7.19697 12.4095 7.23604 12.4095 7.28135V8.91885C12.4095 8.9876 12.4642 9.04385 12.5345 9.04385H17.8408C17.9095 9.04385 17.9658 8.9876 17.9658 8.91885V7.35166C17.9658 7.28291 17.9111 7.22822 17.8408 7.22822H14.7986C15.2767 6.93447 16.1158 6.35479 17.0674 5.40166C17.5236 4.94697 17.8127 4.31416 17.8611 3.66572C17.9142 2.95947 17.6767 2.30322 17.1924 1.81885C16.5752 1.20166 15.872 0.88916 15.1017 0.88916C13.4033 0.88916 11.9877 2.39072 11.9283 2.45479C11.8908 2.49541 11.883 2.55635 11.9127 2.60479L12.6783 3.85479ZM6.8283 15.0204C7.4658 14.5407 7.84861 13.9438 7.9158 13.3204C7.97986 12.7173 7.74393 12.1142 7.23299 11.5782C6.72049 11.0407 6.02674 10.7548 5.2283 10.7548C4.00174 10.7548 2.87518 11.4048 2.53143 11.872C2.50174 11.9126 2.50174 11.9673 2.52986 12.0079L3.2908 13.111L3.37049 13.1595L3.46111 13.1345C3.46892 13.1267 4.29861 12.4501 5.0033 12.4501C5.32674 12.4501 5.5658 12.5923 5.73299 12.8829C5.87361 13.1267 5.90017 13.3485 5.81424 13.5579C5.58142 14.1345 4.59861 14.4235 4.58768 14.4267C4.53924 14.4407 4.50486 14.4876 4.50486 14.5392V15.5095C4.50486 15.5626 4.5408 15.611 4.59393 15.622C4.6033 15.6251 5.5408 15.8579 5.7533 16.3938C5.83768 16.6048 5.79392 16.8423 5.62674 17.1017C5.45017 17.3767 5.18143 17.5157 4.8283 17.5157C4.14549 17.5157 3.30955 16.9985 2.94236 16.6704L2.85174 16.6423L2.76892 16.6923L2.00955 17.7938C1.97986 17.8376 1.98299 17.8954 2.01736 17.936C2.06892 17.997 3.3158 19.4345 5.08611 19.4345L5.12986 19.4329C5.62518 19.4267 6.56424 19.3173 7.27986 18.6735C7.86736 18.1454 8.15017 17.3923 8.03767 16.661C7.92674 15.972 7.49236 15.3829 6.8283 15.0204Z"
                    fill="#2F2F2F"/>
            </g>
            <defs>
                <clipPath id="clip0_271_40">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
export default IconNumbers
