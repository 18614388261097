export const sliderData = [
    {
        title: 'Начните изучать английский язык с простых шагов',
        background: '#015091',
        color: '#C38611',
        img: './images/landing_page/scene_1.png'
    },
    {
        title: 'Развивайте словарный запас с помощью готовых подборок слов',
        background: '#5b6ec6',
        color: '#75C047',
        img: './images/landing_page/scene_2.png'
    },
    {
        title: 'Составляйте собственные карточки с набором слов и обменивайтесь ими с друзьями',
        background: '#9cc29a',
        color: '#846DB2',
        img: './images/landing_page/scene_3.png'
    },
    {
        title: 'Проходите тесты, следите за своей статистикой и делитесь ей с друзьями',
        background: '#12333e',
        color: '#CFB882',
        img: './images/landing_page/scene_4.png'
    }
]

export const tipsData = [
    {
        title: '🤷‍♂️ Не знаете с чего начать обучение? ',
        text: 'Мы собрали самые полезные правила и записали их в простой и интересной форме. Категории охватывают базовые и продвинутые разделы грамматики и лингвистики.'
    },
    {
        title: '✍ Надоело записывать слова ручкой?',
        text: 'Создавайте категории и добавляйте новые слова из фильмов и книг. А потом повторяйте их в любой момент времени.'
    },
    {
        title: '🤝 Уже есть команда единомышленников?',
        text: 'Приглашайте друзей, делитесь успехами, правилами и новыми словами. Выходите на новый уровень вместе.'
    }
]

export const cardsData = [
    {
        title: 'Карточки',
        text:
            'Учите слова с помощью эффективной методики карточек. Читаете английское слово, вспоминаете' +
            ' перевод и проверяете себя, переворачивая карточку',
        image: './images/mainpage/cards/cards.png'
    },
    {
        title: 'Тесты',
        text: 'Решайте тесты и закрепляйте результат',
        image: './images/mainpage/cards/test.png'
    },
    {
        title: 'Правила',
        text: 'Изучайте грамматику английского: начиная с базовых понятий и заканчивая правилами уровня Advanced',
        image: './images/mainpage/cards/grammar.png'
    },
    {
        title: 'Подборки слов',
        text:
            'Нет времени выписывать слова самому? У нас есть лучшие тематические подборки слов: базовые, продвинутые, ' +
            'разговорные, из лучших фильмов, сериалов и книг',
        image: './images/mainpage/cards/wordlist.png'
    },
    {
        title: 'Филворды',
        text:
            'Играйте и изучайте английский язык в игровой форме: мы подготовили игру, где необходимо искать слова на' +
            ' поле так, чтобы полностью заполнить квадрат',
        image: './images/mainpage/cards/scrabble.png'
    },
    {
        title: 'Статистика',
        text: 'Отслеживайте свои результаты: оцените прогресс и скорректируйте стратегию обучения',
        image: './images/mainpage/cards/statistic.png'
    }
]

export const wordCardsData = [
    {
        title: 'Местоимения',
        text: 'Не - Он, She - Она, They - Они ...',
        image: './images/landing_page/pronounts.png'
    },
    {
        title: 'Одежда',
        text: 'Blouse - Блузка, Boot - Ботинок, Coat - Пальто ...',
        image: './images/landing_page/clothes.png'
    },
    {
        title: 'Погода',
        text: 'Drizzle - Моросящий дождь, Dew - Роса ...',
        image: './images/landing_page/weather.png'
    },
    {
        title: 'Спорт',
        text: 'Badminton - Бадминтон, Beach volleyball - Пляжный волейбол ...',
        image: './images/landing_page/sport.png'
    },
    {
        title: 'Цвет',
        text: 'Green - Цвета, Orange - Оранжевый, Purple - Фиолетовый ...',
        image: './images/landing_page/colors.png'
    }
]

export const reviewsData = [
    {
        author: 'Никита',
        login: '_cyb3r_',
        text: 'Изучал правила английского и обнаружил этот сайт. Зарегистрировался и открыл для себя огромное множество способов учить английский! Особенно был впечатлен филвордами. Всем советую данный сайт!',
        img: '../images/review/nikita.jpg'
    },
    {
        author: 'Илья',
        login: 'e-darya',
        text:
            'Наткнулся на сайт в youtube, заинтересовали тематические списки со словами из песен! ' +
            'Всегда любил слушать иностранные песни и частенько искал перевод в интернете. На данном сайте открыл для себя возможность не только понять текст песни, но и выучить новые слова.',
        img: '../images/review/ilia.jpg'
    },
    {
        author: 'Влад',
        login: 'kost.ya62',
        text:
            'У меня давно есть мечта - читать книги в оригинале без словаря. Я постоянно выписывал незнакомые слова в блокнот,' +
            ' благодаря данному сайту мне удалось избавиться от тетрадок-словарей которые я носил с собой постоянно, все свои слова ' +
            'я сохраняю на этом сайте и ежедневно повторяю их в пути на работу со смартфона. Теперь мой словарный запас значительно вырос и я свободно читаю многие книги в оригинале!',
        img: '../images/review/vlad.jpeg'
    }
]
