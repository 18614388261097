import '../../../styles/css/elements/svg_icons/icon-btn.css'
import { Tooltip } from 'antd'

function IconVolumeOff(props) {
    const { className = '', onClick = () => null, stroke = '#ffffff' } = props

    return (
        <Tooltip placement='topLeft' title='Выставите язык карточки в словаре'>
            <svg
                onClick={onClick}
                className={'icon icon-tabler icon-tabler-volume-3 ' + className}
                xmlns='http://www.w3.org/2000/svg'
                width='48'
                height='48'
                fill='none'
                stroke={stroke}
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.7'
                viewBox='0 0 24 24'
            >
                <path stroke='none' d='M0 0h24v24H0z'></path>
                <path
                    d='M6 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h2l3.5-4.5A.8.8 0 0111 5v14a.8.8 0 01-1.5.5L6 15M16 10l4 4m0-4l-4 4'></path>
            </svg>
        </Tooltip>
    )
}

export default IconVolumeOff
