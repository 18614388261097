import { useState } from 'react'
import { Radio, Space, Form } from 'antd'
import PropTypes from 'prop-types'
import * as API_RULES from '../../../../shared/http/rule'
import RuleStatus from '../../../../const/RuleStatus'
import { GEButton } from '../../../../common/buttons'
import CompletedTestRuleDialog from "../../../modalwindows/CompletedTestRuleDialog";

const RuleTest = (props) => {
    const { value = [], rule } = props
    const RADIO_ITEM = 'radio_item_id_'
    const RAD_TEXT_COLOR = 'red-text-color'

    const [disableButton, setDisableButton] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const [rightAnswers, setRightAnswers] = useState(0)

    const closeDialogHandler = () => {
        setShowDialog(false)
    }

    const onFinish = (values) => {
        setDisableButton(true)
        let mistakes = 0
        value.forEach((v) => {
            if (v.variants.find((variant) => variant.isTrue).id !== values[v.id]) {
                let element = document.querySelector('.rule-test .' + RADIO_ITEM + v.id)
                if (values[v.id]) {
                    let selectedValue = 'input[value=\'' + values[v.id] + '\']'
                    let input = element.querySelector(selectedValue)
                    let wrongInput = input.closest('label')
                    wrongInput.className = wrongInput.className + ' ' + RAD_TEXT_COLOR
                }
                mistakes++
            }
        })
        let ruleTestStatisticDto = { ruleId: rule.id, mistakes: mistakes }
        API_RULES.saveRuleTestStatistic(ruleTestStatisticDto)
        setRightAnswers(value.length - mistakes)
        setShowDialog(true)
    }

    return (
        <div className='rule-test'>
            {showDialog && (
                <CompletedTestRuleDialog
                    close={closeDialogHandler}
                    rightAnswers={rightAnswers}
                    amountWordsInTest={value.length}
                    open={showDialog}
                />
            )}

            <h3>Пройдите тест, чтобы отметить это правило как изученное:</h3>
            <Form labelAlign={'left'} onFinish={onFinish}>
                {value.map((item, i) => (
                    <Form.Item className={'next-div-block'} key={i} name={item.id} label={item.title}>
                        <Radio.Group className={'radio ' + RADIO_ITEM + item.id + ' block'}>
                            <Space direction='vertical'>
                                {item.variants.map((variant, number) => (
                                    <Radio className={'block'} key={number} value={variant.id}>
                                        {variant.title}
                                    </Radio>
                                ))}
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                ))}
                <Form.Item>
                    <GEButton
                        type='primary'
                        htmlType='submit'
                        disabled={
                            rule.status === RuleStatus.DONE || rule.status === RuleStatus.ALMOST_DONE || disableButton
                        }
                    >
                        Завершить
                    </GEButton>
                </Form.Item>
            </Form>
        </div>
    )
}

export default RuleTest

RuleTest.propTypes = {
    value: PropTypes.array.isRequired,
    rule: PropTypes.object.isRequired
}
