import { useEffect, useState } from 'react'
import { Table } from 'antd'
import * as API_USER from '../../../../../shared/http/user'

const UserActivity = () => {
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)

    /**
     * Загрузить пользователей с их привилегиями
     */
    const loadUsersActivity = () => {
        setLoading(true)
        API_USER.getAllUsers()
            .then((data) => {
                setUsers(data.content)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        loadUsersActivity()
    }, [])

    const columns = [
        {
            title: 'Логин пользователя',
            dataIndex: 'login',
            key: 'login',
            render: (text) => <a>{text}</a>
        },
        {
            title: 'Почта',
            dataIndex: 'email',
            key: 'email',
            render: (text) => <a>{text}</a>
        },
        {
            title: 'Last date',
            dataIndex: 'lastDate',
            key: 'lastDate',
            render: (text) => <a>{text}</a>
        },
        {
            title: 'Telegram channel',
            dataIndex: 'telegramChannel',
            key: 'telegramChannel',
            render: (text) => <a>{text}</a>
        },
        {
            title: 'Активность',
            dataIndex: 'count',
            key: 'count',
            render: (text) => <a>{text}</a>
        }
    ]

    return (
        <>
            <Table columns={columns} dataSource={users} loading={loading} />
        </>
    )
}

export default UserActivity
