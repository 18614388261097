/**
 * Project geekenglish
 *
 * @author Alexandr Filatov on 21.04.2020
 * Дублируются в файле entity/nodb/MessageType.java
 */
let MessageType = {
    NEW_DIALOG_REQUEST: 'NEW_DIALOG_REQUEST',
    CREATED_DIALOG_RESPONSE: 'CREATED_DIALOG_RESPONSE',
    FRIEND_REQUEST: 'FRIEND_REQUEST',
    FRIEND_ACCEPT_REQUEST: 'FRIEND_ACCEPT_REQUEST',
    TEXT_MESSAGE: 'TEXT_MESSAGE'
}

export default MessageType
