import { executeJsonGetMethod } from '../api/rest/restApiUtils'

/**
 * Получить код для привязки пользователя к телеграмму
 * @method createUserBindToken
 * @return {Response} Fetch Response
 */
export function createUserBindToken() {
    return executeJsonGetMethod('/telegram/create-bind-token')
}
