import { FC, useEffect, useState } from 'react'
import '../../../../../styles/scss/profile_page/profile_page.scss'
import { Progress } from 'antd'
import { GEButton } from '../../../../../common/buttons'
import * as API_GOAL from '../../../../../shared/http/goal'
import CreateGoal from '../../../../modalwindows/CreateGoal'
import { GoalDto } from '../../../../../shared/types/api'
import moment from 'moment'

interface GoalCardProps {
}

export const GoalCard: FC<GoalCardProps> = () => {

    const [showConfirmModalGoal, setShowConfirmModalGoal] = useState(false)
    const [goal, setGoal] = useState<GoalDto | undefined>(undefined)

    const openGoalDialog = () => {
        setShowConfirmModalGoal(true)
    }
    const closeGoalDialog = () => {
        setShowConfirmModalGoal(false)
    }
    const setGoalFromResponce = (goal: GoalDto) => {
        setGoal(goal)
    }

    const deadline = moment(goal?.deadlineDate).format('DD.MM.YY')
    const remainder = goal ? goal.count - goal.completed : 0
    const percent = goal ? (goal.completed / goal.count) * 100 : 0


    useEffect(() => {
        API_GOAL.getGoal().then((responce) => {
            setGoal(responce.data)
        })
            .catch(() => {
                setGoal(undefined)
            })
    }, [])


    const btnText = goal ? (goal?.completed >= goal?.count ? 'Создать новую цель' : 'Редактировать цель') : ''
    const cardText = goal ? (goal?.completed >= goal?.count
        ? 'Молодец! Поставленная цель достигнута! Так держать' : `До ${deadline} осталось повторить ${remainder} слов`) : ''

    return (
        <>
            {goal ?
                (
                    <div className='target-card-container'>
                        <div>
                            <Progress className='target-card__progress' type='circle' percent={percent}
                                      format={() => `${goal?.completed}/${goal?.count}`} />
                        </div>
                        <div className='target-card__text-container'>
                            <div className='target-card__text'>{cardText}</div>
                            <GEButton type='primary' onClick={openGoalDialog}>{btnText}</GEButton>
                        </div>
                    </div>)
                :
                <div className='target-card-container'>
                    <div className='target-card__text-container'>
                        <GEButton type='primary' onClick={openGoalDialog}>Создать цель</GEButton>
                    </div>
                </div>
            }
            <CreateGoal
                isOpen={showConfirmModalGoal}
                close={closeGoalDialog}
                setGoalFromResponce={setGoalFromResponce}
            />
        </>
    )
}
