import { createSlice } from '@reduxjs/toolkit'

import { createCommunity, fetchCommunities } from './extraReducers'
import CommunityUtils from '../../components/utils/CommunityUtils'
import { createWordList } from '../wordLists/extraReducers'

const slice = createSlice({
    name: 'communities',
    isLoading: false,
    isLoaded: false,
    initialState: {
        data: []
    },
    reducers: {
        setCommunitiesRoutine: (state, action) => {
            state.data = action.payload
        },
        clearCommunities: (state) => {
            state.data = []
        }
    },
    extraReducers: {
        [fetchCommunities.fulfilled]: (state, action) => {
            state.data = CommunityUtils.parseCommunities(action.payload)
            state.isCommunitiesLoading = false
            state.isLoaded = true
        },
        [fetchCommunities.pending]: (state) => {
            state.isCommunitiesLoading = true
            state.isLoaded = false
        },
        [fetchCommunities.rejected]: (state) => {
            state.isCommunitiesLoading = false
            state.isLoaded = true
        },

        [createCommunity.fulfilled]: (state, action) => {
            const community = CommunityUtils.parseCommunity(action.payload)
            state.isCommunitiesLoading = false
            state.data = [...state.data, community]
        },
        [createWordList.pending]: (state) => {
            state.isCommunitiesLoading = true
        },
        [createWordList.rejected]: (state) => {
            state.isCommunitiesLoading = false
        }
    }
})

export const { setCommunitiesRoutine } = slice.actions
export const communitiesLoadingStatusSelector = (state) => state.communities.isLoading
export const communitiesLoadedStatusSelector = (state) => state.communities.isLoaded
export const communitiesDataSelector = (state) => state.communities.data
export default slice.reducer
