import {useEffect, useState} from 'react'
import * as API_STATISTIC from '../../../../shared/http/statistic'
import Loading from '../../../elements/loading/Loading'
import {Table} from 'antd'
import '../../../../styles/css/statistic/month_wordlists.css'

const MonthWordMistakes = () => {
  const [notRepeatedListNames, setNotRepeatedListNames] = useState([])

  useEffect(() => {
    API_STATISTIC.getWordListStatistic().then((resp) => {
      if (resp.notRepeatedListNames.length > 0) {
        const repeatList = resp.notRepeatedListNames.map(value => {
            return {
              notRepeatedListNames: value
            }
          }
        )
        setNotRepeatedListNames(repeatList)
      } else {
        setNotRepeatedListNames([])
      }
    })
  }, [])

  const columns = [
    {
      title: 'Название списка',
      dataIndex: 'notRepeatedListNames',
      key: 'notRepeatedListNames',
    },

  ]

  return (
    <>
      {!notRepeatedListNames ? (
        <Loading/>
      ) : !notRepeatedListNames.length ? (
          <div className={'month-wl_warning'}>
        <span className={'month-wl__warning'}>У вас пока нет повторенных списков слов</span>
          </div>
      ) : (
        <Table
          columns={columns}
          dataSource={notRepeatedListNames}
          pagination={false}
          locale={{emptyText: 'Не удалось загрузить слова'}}
          className='month-stats__table'
          scroll={{ y: 400}}
        />
      )}
    </>
  )
}

export default MonthWordMistakes
