import { Component } from 'react'

/**
 * Основной компонент с дефолтной логикой обработки ошибок
 */
class RootComponent extends Component {
    catchGeekError = (err) => {
        if (err.text) {
            err.json().then((errorMessage) => {
                if (errorMessage.type) {
                    if (errorMessage.type === 'GENERAL') {
                        this.props.showErrorDialog(errorMessage.description)
                    } else if (errorMessage.type === 'SPECIFIC') {
                        this.catchSpecialGeekError(errorMessage)
                    } else if (errorMessage.type === 'VALID') {
                        this.catchValidGeekError(errorMessage)
                    }
                } else if (errorMessage.message) {
                    this.props.showErrorDialog(errorMessage.message)
                }
            })
        }
    }

    catchSpecialGeekError = (errorMessage) => {}
    catchValidGeekError = (errorMessage) => {}
}

export default RootComponent
