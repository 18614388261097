import '../../styles/css/main_styles.css'
import { FC } from 'react'
import {Link, LinkProps} from "react-router-dom";




export const GELink: FC<LinkProps> = (props) => {
    return (
        <Link {...props} className={'GELink ' + props.className}>
            {props.children}
        </Link>
    );
}