import {FC} from "react";
import '../../../../../styles/scss/profile_page/profile_page.scss'
import {UserCard} from "./UserCardComponent";
import {ErrorsInWordsCard} from "./ErrorsInWordsCardComponent";
import {RepeatListCard} from "./RepeatListCardComponent";
import {GoalCard} from "./GoalCardComponent";
import {TestStatisticsCard} from "./TestStatisticsCardComponent";
import {WordsStatisticsCard} from "./WordsStatisticsCardComponent";

interface ProfileMainComponentProps {
}

export const ProfileMainComponent: FC<ProfileMainComponentProps> = () => {

    return (
        <div className='profile-main-container'>
            <UserCard/>
            <ErrorsInWordsCard/>
            <RepeatListCard/>
            <GoalCard/>
            <TestStatisticsCard/>
            <WordsStatisticsCard/>
        </div>
    )
}
