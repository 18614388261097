import RulesHeader from '../../blocks/RulesHeader'
import NumberBlock from '../../blocks/NumberBlock'
import TaskExample from '../../blocks/TaskExample'
import Example from '../../blocks/Example'
import FactRule from '../../blocks/FactRule'
import TableThree from '../../blocks/TableThree'
import TableTwoVS from '../../blocks/TableTwoVS'
import { test } from './test'
import RuleTestWrapper from '../../RuleTestWrapper'

const PastContinuous = (props) => {
    return (
        <div>
            <RulesHeader title='Past continuous' subtitle='Прошедшее длительное' />

            <NumberBlock numberBlock='1' />
            <TaskExample taskExample='Изучите следующую ситуацию:' />
            <Example
                engExample={[
                    'Yesterday Tomas and Alex played tennis. They began at 10 o’clock and finished at 11.30. So, at 10.30 they ',
                    <u>were playing</u>,
                    ' tennis.'
                ]}
                rusExample='Вчера Томас и Алекс играли в теннис. Они начали в 10 часов, а закончили в 11:30. Так, в 10:30 они играли в теннис.'
            />
            <p>
                {' '}
                They were playing = они играли = они были в процессе игры. Они не закончили играть. Present continuous
                указывает на процесс, действие, длящееся в определенный момент прошлом.
            </p>
            <FactRule factRule='Существительное + Was/were + -ing это the past continuous:' />
            <TableThree
                elements={[
                    { left: 'I/he/she/it', center: 'was', right: 'Глагол+ing' },
                    { left: 'We/you/they', center: 'were', right: 'Глагол+ing' }
                ]}
            />

            <NumberBlock numberBlock='2' />
            <p>
                {' '}
                I was doing something = я делал что-то = я был в процессе работы над чем-либо в определенное время.
                Действие началось до этого времени, но не закончилось:
            </p>
            <Example
                engExample={['This time last year ', <u>I was living</u>, ' in Ryazan.']}
                rusExample='В это время в прошлом году я жил в Рязани.'
            />

            <NumberBlock numberBlock='3' />
            <p>
                {' '}
                Сравните past continuous (<b>I was doing</b>) и past simple (<b>I did</b>):
            </p>
            <TableTwoVS
                notPaint={true}
                elements={[{ left: 'Past continuous (во время действия) ', right: 'Past simple (действие завершено)' }]}
            />
            <TableTwoVS
                elements={[
                    {
                        left: ['I ', <u>was walking</u>, ' home when I met Tomas. (во время действия-прогулки) '],
                        right: ['I ', <u>walked</u>, ' home after the party last night (прогулка завершена)']
                    },
                    {
                        left: 'Я шел домой, когда встретил Томаса. ',
                        right: 'Я пошел домой после вечеринки вчера вечером.'
                    }
                ]}
            />
            <TableTwoVS
                elements={[
                    {
                        left: ['Kate ', <u>was watching</u>, ' television when we arrived. '],
                        right: ['Kate ', <u>watched</u>, ' television a lot when she was ill last year.']
                    },
                    {
                        left: 'Кейт смотрела телевизор, когда мы прибыли. ',
                        right: 'Катя много смотрела телевизор, когда болела в прошлом году.'
                    }
                ]}
            />

            <NumberBlock numberBlock='4' />
            <p>
                Мы часто используем past simple и past continuous вместе, чтобы сказать, что что-то случилось во время
                чего-то еще:
            </p>
            <Example
                engExample={['Vlad ', <u>phoned</u>, ' while we ', <u>were having</u>, ' dinner.']}
                rusExample='Влад сделал звонок, пока мы обедали.'
            />
            <Example
                engExample={['It ', <u>was raining</u>, ' when I ', <u>got</u>, ' up.']}
                rusExample='Когда я проснулся, шёл дождь.'
            />
            <Example
                engExample={[
                    'I ',
                    <u>saw</u>,
                    ' you in the park yesterday. You ',
                    <u>were sitting</u>,
                    ' on the grass and reading a book.'
                ]}
                rusExample='Я видел тебя вчера в парке. Ты сидела на траве и читала книгу.'
            />
            <p>Но мы используем past simple, чтобы сказать, что одно событие сменяется другим:</p>
            <Example
                engExample={[
                    'I ',
                    <u>was walking</u>,
                    ' along the road when I saw Alex. So I stopped, and we had a chat. (увидел → остановился → начал разговор)'
                ]}
                rusExample='Я шел по дороге, когда увидел Алекса. Поэтому я остановился, и мы немного поболтали.'
            />
            <TaskExample taskExample='Сравните:' />
            <TableTwoVS
                elements={[
                    {
                        left: [
                            'When Kate arrived, we ',
                            <u>were having</u>,
                            ' dinner. (мы начали обедать раньше, чем она приехала)'
                        ],
                        right: [
                            'When Kate arrived, we ',
                            <u>had</u>,
                            ' dinner. (приехала Кейт, и затем мы начали ужинать)'
                        ]
                    },
                    { left: 'Когда Кейт приехала, мы ужинали. ', right: 'Когда Кейт приехала, мы поужинали.' }
                ]}
            />

            <NumberBlock numberBlock='5' />
            <p>
                Некоторые глаголы (например, <b>know</b> и <b>want</b>) обычно не используются в форме continuous:
            </p>
            <Example
                engExample={['We were good friends. We ', <u>knew</u>, ' each other well. (NOT We were knowing)']}
                rusExample='Мы были хорошими друзьями. Мы хорошо знали друг друга.'
            />
            <Example
                engExample={['I was enjoying the party, but Dmitry ', <u>wanted</u>, ' to go home. (NOT was wanting)']}
                rusExample='Я наслаждалась вечеринкой, но Дмитрий захотел пойти домой.'
            />
            <RuleTestWrapper value={test} rule={props} />
        </div>
    )
}

export default PastContinuous
