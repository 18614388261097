import { executeJsonPostMethod } from '../api/rest/restApiUtils'

/**
 * Генерация списка слов
 * @method generateTextToWord
 * @param {string} text
 * @param {number} listName
 * @param {boolean} isFilteredUserWords
 * @param {boolean} isFilteredLevelWords
 * @param {boolean} splitByEnter
 * @return {Response} Fetch Response
 */
export function generateTextToWord(
    text,
    listId,
    isFilteredUserWords,
    isFilteredLevelWords,
    level,
    splitByEnter
) {
    let sendingData = {
        text: text,
        listId: listId,
        isFilteredUserWords: isFilteredUserWords,
        isFilteredLevelWords: isFilteredLevelWords,
        level: level,
        splitByEnter: splitByEnter,
        textLang: 'ENGLISH',
        resultTextLang: 'RUSSIAN'
    }
    return executeJsonPostMethod('/text-translation', sendingData)
}

/**
 * Переводит текст карточки
 * @param text - текст для перевода
 * @param current - язык, на котором написан текст
 * @param target - язык, на который нужно перевести
 * @returns {Promise<any>}
 */
export function translateCardText(
    text,
    current,
    target
) {
    let sendingData = {
        text,
        current,
        target
    }
    return executeJsonPostMethod('/text-translation/card', sendingData)
}
