import { GEButton } from '../../../../../common/buttons'
import * as API_AI from '../../../../../shared/http/openai'
import { FC, useState } from 'react'
import WordUtils from '../../../../utils/WordUtils'
import { WordDto } from '../../../../../shared/types/api'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { setWordsRoutine, wordsDataSelector } from '../../../../../store/words'
import Constants from '../../../../../const/Constants'
import { premiumStatusSelector } from '../../../../../store/global'
import { MagicIcon } from '../../../../elements/svg_icons/MagicIcon'

interface AddAIWordsButtonProps {
    selectedCategory: any,
    wordsAmount: number,
}

const AddAIWordsButton: FC<AddAIWordsButtonProps> = ({ selectedCategory, wordsAmount }) => {

    const dispatch = useAppDispatch()
    const isPremium = useAppSelector(premiumStatusSelector)
    const words = useAppSelector(wordsDataSelector)
    const [loading, setLoading] = useState<boolean>(false)
    const [canceledCards, setCanceledCards] = useState<Array<WordDto>>([])
    const setWordsRedux = (w: Array<WordDto>) => dispatch(setWordsRoutine(w))
    const onclickAI = () => {
        setLoading(true)
        API_AI.generateAIWordListCard(selectedCategory?.id, canceledCards).then(w => {
            addWord(w.engWord, w.rusWord)
        }).finally(() => {
            setLoading(false)
        })
    }

    const addWord = (sideOne: string, sideTwo: string) => {
        const newWord = WordUtils.createNewAiWord(selectedCategory?.id, sideOne, sideTwo)
        //@ts-ignore
        setCanceledCards([...canceledCards, newWord])
        setWordsRedux([...words, newWord])
    }

    return (
        <>
            {isPremium && wordsAmount >= 1 && selectedCategory.sideOneLang &&
            selectedCategory.sideTwoLang
            && wordsAmount < Constants.MAX_COUNT_WORDS_IN_WORD_LIST &&
            <GEButton icon={<MagicIcon />} loading={loading} onClick={onclickAI}>
                Добавить слово с ИИ
            </GEButton>
            }
        </>
    )
}

export default AddAIWordsButton