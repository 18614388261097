import PropTypes from 'prop-types'

import '../../../styles/css/elements/svg_icons/icon-btn.css'

function IconClose(props) {
    const { width = '32', height = '32', onClick = () => null } = props
    return (
        <svg
            onClick={onClick}
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            fill='none'
            stroke='#ff2825'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            className={'icon icon-tabler icon-tabler-circle-x ' + props.className}
            viewBox='0 0 24 24'
        >
            <title>Закрыть</title>
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <circle cx='12' cy='12' r='9'></circle>
            <path d='M10 10l4 4m0-4l-4 4'></path>
        </svg>
    )
}

export default IconClose

IconClose.propTypes = {
    height: PropTypes.string,
    onClick: PropTypes.func,
    width: PropTypes.string
}
