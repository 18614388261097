export const shuffleArray = (words: Array<any>) => {
    return words.sort(() => Math.random() - 0.5)
}

export const removeElementFromArray = (array: Array<any>, element: any) => {
    const index = array.indexOf(element)
    if (index > -1) { // only splice array when item is found
        array.splice(index, 1) // 2nd parameter means remove one item only
    }
    return array
}