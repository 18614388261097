import {
    executeFilePostMethod,
    executeJsonGetMethod,
    executePostMethod,
    executePutMethod
} from '../api/rest/restApiUtils'

/**
 * Получить правила
 * @method getRules
 * @return {Response} Fetch Response
 */
export function getRules() {
    return executeJsonGetMethod('/rules/list').then((resp) => {
        return resp.data.content
    })
}

/**
 * Получить ВСЕ правила
 * @method getAllRules
 * @return {Response} Fetch Response
 */
export function getAllRules() {
    return executeJsonGetMethod('/rules/list/all').then((resp) => {
        return resp.data.content
    })
}

/**
 * Сохранение статистики по тесту
 * @param {object} dto - объект статистики
 * @method updatePhoto
 * @return {Response} Fetch Response
 */
export function saveRuleTestStatistic(dto) {
    return executePostMethod('/rules/save/test', dto)
}

/**
 * Смена изображения правила
 * @param {object} photo
 * @method updatePhoto
 * @return {Response} Fetch Response
 */
export function updatePhoto(id, photo) {
    let formData = new FormData()
    formData.append('photo', photo)
    return executeFilePostMethod('/rules/' + id + '/photo', formData)
}

/**
 * Изменение активности правила
 * @param {id} id правила
 * @method updatePhoto
 * @return {Response} Fetch Response
 */
export function updateActive(id) {
    return executePutMethod('/rules/' + id + '/active')
}
