import { PieChart, Pie, Tooltip, Cell, Legend, ResponsiveContainer } from 'recharts'

const ChartRound = () => {
    const data = [
        {
            name: 'Повторенных списков',
            value: 400
        },
        {
            name: 'Оставшихся',
            value: 100
        }
    ]
    const COLORS = ['#3ed468', '#FF8042']

    return (
        <ResponsiveContainer width={'30%'} height={400} className={'responsive-statistic-container'}>
            <PieChart style={{ fontSize: '1rem' }}>
                <Tooltip />
                <Legend />
                <Pie data={data} dataKey='value' nameKey='name' cx='50%' cy='50%' outerRadius={120} fill='#92e4a4'>
                    {data.map((entry, index) => (
                        <Cell key={'cell' + index} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    )
}

export default ChartRound
