import { GEButton } from '../../../common/buttons'
import { openPremiumPage } from '../../../common/utils/promocode'
import { useNavigate } from 'react-router-dom'

const AddPremiumButton = () => {
    const navigate = useNavigate()

    const openPremium = () => {
        openPremiumPage(navigate)
    }

    return (
        <div>
            <GEButton className='grid-list_grid-item_premium_button' onClick={openPremium}>
                Подключить премиум
            </GEButton>
        </div>
    )
}

export default AddPremiumButton
