import {FC} from 'react'
import '../../styles/css/dictionary/modalwindow/main_dialog.css'
import {Button, Modal} from "antd";
import {AttentionIcon} from "../elements/svg_icons/AttentionIcon";

interface ConfirmModalDialogProps {
    message: string,
    decline: () => void,
    confirm: () => void
    open: boolean;

}

const ConfirmModalDialog: FC<ConfirmModalDialogProps> = ({message, decline, confirm, open}) => {

    const confirmFn = () => {
        confirm()
        decline()
    }

    return (
        <Modal onCancel={decline}
               footer={[
                   <Button key='submit' danger onClick={decline}>
                       Отмена
                   </Button>,
                   <Button
                       onClick={confirmFn}
                       type='primary'
                   >
                       Да
                   </Button>
               ]}
               open={open}>
            <div id='main-dialog_message'>
                <AttentionIcon/>
                <span>{message}</span>
            </div>

        </Modal>
    )
}

export default ConfirmModalDialog
