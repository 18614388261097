import '../../../styles/css/elements/svg_icons/icon-btn.css'

function IconLetterCase(props) {
    return (
        <svg
            onClick={() => (props.onClick ? props.onClick() : false)}
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            fill='none'
            stroke='#000'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            className={'icon icon-tabler icon-tabler-letter-case icon-btn ' + props.class}
            viewBox='0 0 24 24'
        >
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <circle cx='17.5' cy='15.5' r='3.5'></circle>
            <path d='M3 19V8.5a3.5 3.5 0 017 0V19M3 13h7M21 12v7'></path>
        </svg>
    )
}

export default IconLetterCase