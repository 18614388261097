import PropTypes from 'prop-types'
import * as API_DONE_WORDLIST from '../../../../shared/http/done-word-list'
import PrivilegesUtil from '../../../utils/PrivilegesUtil'
import Constants from '../../../../const/Constants'
import Privileges from '../../../../const/Privileges'
import AdminDoneWordListItemPanel from './AdminDoneWordListItemPanel'
import { Modal, Tag } from 'antd'
import { GEButton } from '../../../../common/buttons'
import { handleHttpError } from '../../../../common/utils/errors'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { premiumStatusSelector, userSelector } from '../../../../store/global'
import { fetchWorldLists } from '../../../../store/wordLists/extraReducers'
import CopyDoneCardListBtn from './block/CopyDoneCardListBtn'
import DeleteDoneCardListBtn from './block/DeleteDoneCardListBtn'
import { useNavigate } from 'react-router-dom'
import HeartFilledIcon from '../../../elements/svg_icons/HeartFilledIcon'
import HeartIcon from '../../../elements/svg_icons/HeartIcon'
import AddPremiumButton from '../../../elements/buttons/AddPremiumButton'

const DoneWordItem = (props) => {
    const navigate = useNavigate()
    const user = useAppSelector(userSelector)
    const dispatch = useAppDispatch()
    const isPremium = useAppSelector(premiumStatusSelector)
    const updateUserWordLists = () => dispatch(fetchWorldLists())

    const { listName, imageId, userHas, level, premium, author, tags, hasLike, likes } = props.wordList
    let isUserCanEdit = PrivilegesUtil.hasPrivilege(user, Privileges.EDIT_DONE_WORD_LIST)

    const deleteDoneWordList = (wordList) => {
        props.changeDisabledButtonsFlag(true)
        API_DONE_WORDLIST.deleteDoneWordList(props.wordList.id)
            .then(() => {
                wordList.userHas = !wordList.userHas
                Modal.success({
                    content: 'Список успешно удален'
                })
                updateUserWordLists()
            })
            .catch((err) => {
                handleHttpError(err, navigate)
            })
            .finally(() => props.changeDisabledButtonsFlag(false))
    }

    const likedOrDislikedList = () => {
        API_DONE_WORDLIST.likeOrDislike(props.wordList.id).then(resp => {
            props.updateDoneList(resp.data)
        })
    }

    return (
        <div className='done_word_lists_item'>
            <div className='done_word_lists_item_level'>
                <GEButton type='primary' shape='circle'>
                    {level ? level : 'A1'}
                </GEButton>
                {premium && <span className='done_word_lists_item_premium-header'>Премиум</span>}
                <GEButton disabled={!isPremium && premium} type='primary' shape='circle'
                          onClick={() => props.showWords(props.wordList.id)}>
                    {'?'}
                </GEButton>
            </div>

            <div className='done_word_lists_item_name'>
                <span>{listName}</span>
            </div>

            <div className='done_word_lists_item_image'>
                <img src={`${Constants.queryConst}/file/wordlist/image?id=` + imageId} loading='lazy'
                     alt='list of wordlist' />
            </div>

            <div className='done_word_lists_item_tags'>
                {tags && tags.map(tag => (
                    <Tag key={tag} color='magenta'>{tag}</Tag>
                ))}
            </div>

            {user && premium && <div className='done_word_lists_item_li_button'>
                {isPremium ? (
                    <>
                        {userHas &&
                        <DeleteDoneCardListBtn disabledButtons={props.disabledButtons}
                                               deleteDoneWordList={() => deleteDoneWordList(props.wordList)} />
                        }
                        <CopyDoneCardListBtn wordList={props.wordList} updateUserWordLists={updateUserWordLists} />
                    </>
                ) : (
                    userHas ? <DeleteDoneCardListBtn disabledButtons={props.disabledButtons}
                                                     deleteDoneWordList={() => deleteDoneWordList(props.wordList)} /> :
                        <AddPremiumButton />
                )}
            </div>}

            {user && !premium && <div className='done_word_lists_item_li_button'>
                <>
                    {userHas &&
                    <DeleteDoneCardListBtn disabledButtons={props.disabledButtons}
                                           deleteDoneWordList={() => deleteDoneWordList(props.wordList)} />
                    }
                    <CopyDoneCardListBtn wordList={props.wordList} updateUserWordLists={updateUserWordLists} />
                </>
            </div>}

            <div className='done_word_lists_item_like'>
                <div>
                    {hasLike ? <HeartFilledIcon onClick={likedOrDislikedList} /> :
                        <HeartIcon onClick={likedOrDislikedList} />}
                    <span>{likes ? likes : 0}</span>
                </div>

                {author && <div>Author: {author}</div>}
            </div>

            {isUserCanEdit && (
                <AdminDoneWordListItemPanel
                    updateActiveFlag={props.updateActiveFlag}
                    updatePremiumFlag={props.updatePremiumFlag}
                    wordList={props.wordList}
                />
            )}
        </div>
    )
}

export default DoneWordItem

DoneWordItem.propTypes = {
    changeDisabledButtonsFlag: PropTypes.func.isRequired,
    disabledButtons: PropTypes.bool.isRequired,
    wordList: PropTypes.object.isRequired,
    updateActiveFlag: PropTypes.func.isRequired,
    updatePremiumFlag: PropTypes.func.isRequired,
    showWords: PropTypes.func.isRequired
}
