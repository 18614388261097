import { useAppDispatch, useAppSelector } from '../store/hooks'
import { fetchWords } from '../store/words/extraReducers'
import { useEffect, useState } from 'react'
import { fetchCommunities } from '../store/communities/extraReducers'
import { fetchWorldLists, fetchCommunityWorldListsById } from '../store/wordLists/extraReducers'
import {
    communitiesDataSelector,
    communitiesLoadedStatusSelector,
    communitiesLoadingStatusSelector
} from '../store/communities'
import { ID, WordDto, WordList } from '../shared/types/api'
import { wordsDataSelector } from '../store/words'
import {
    wordListsDataSelector,
    wordsListsIsLoadedStatusSelector,
    wordsListsLoadingStatusSelector
} from '../store/wordLists'
import WordUtils from '../components/utils/WordUtils'
import Constants from '../const/Constants'
import { useLocation } from 'react-router-dom'


const useCombineSelector = (startCards: (words: Array<WordDto>) => void) => {

    const dispatch = useAppDispatch()
    const location = useLocation()

    const [selectedWordListId, setSelectedWordListId] = useState<ID | undefined>(undefined)
    const [isCommunity, setIsCommunity] = useState(false)
    const [communityId, setCommunityId] = useState<number | undefined>(undefined)
    const [side, setSide] = useState(Constants.CARD_SIDE_ONE)

    // @ts-ignore
    const communities = useAppSelector(communitiesDataSelector)
    const communitiesIsLoaded = useAppSelector(communitiesLoadedStatusSelector)
    const wordListsIsLoaded = useAppSelector(wordsListsIsLoadedStatusSelector)
    const wordLists = useAppSelector(wordListsDataSelector)
    const reduxWords = useAppSelector(wordsDataSelector)
    const wordListsIsLoading = useAppSelector(wordsListsLoadingStatusSelector)
    const communitiesIsLoading = useAppSelector(communitiesLoadingStatusSelector)

    const getWords = (id: number) => dispatch(fetchWords(id))
    // @ts-ignore
    const getUserWordLists = () => dispatch(fetchWorldLists())
    // @ts-ignore
    const getUserCommunities = () => dispatch(fetchCommunities())
    // @ts-ignore
    const getCommunityWordLists = (cId: number) => dispatch(fetchCommunityWorldListsById(cId))


    /**
     * Изменить wordList
     * @param event.target.value - id выбранного списка
     */
    /**
     * Изменить wordList
     * @param event.target.value - id выбранного списка
     */
    const changeWordList = (event: { target: { value: any } }) => {
        let wlId = event.target.value
        changeWordListById(wlId)
    }

    const getActualWordsInMemory = (wlId: number) => {
        return reduxWords.filter((w: WordDto) => w.listId === parseInt(String(wlId)))
    }

    /**
     * Сменить сообщество
     * @param selectedCommunityId
     */
    const changeCommunity = (selectedCommunityId: number) => {
        setCommunityId(selectedCommunityId)
        if (!wordLists.some((wl: WordList) => wl.communityId === selectedCommunityId)) {
            getCommunityWordLists(selectedCommunityId)
        }
    }

    /**
     * Переключиться на сообщества или на пользовательские слова
     */
    const changeCommunitySwitch = () => {
        let isCom = !isCommunity
        if (!isCommunity) {
            setCommunityId(undefined)
        }
        setIsCommunity(isCom)
    }

    /**
     * Изменить wordList
     * @param wlId - id выбранного списка
     */
    const changeWordListById = (wlId: ID) => {
        let id = Number(wlId)
        setSelectedWordListId(wlId)
        if (!reduxWords.some((w: WordDto) => w.listId === parseInt(String(wlId)))) {
            // @ts-ignore
            getWords(id).then((data) => {
                const words = WordUtils.parseWorlds(data.payload)
                startCards(words)
            })
        } else {
            startCards(getActualWordsInMemory(id))
        }
    }

    /**
     * Возвращает либо списки пользователя либо списки сообщества
     * @returns {*}
     */
    const getActualWordLists = () => {
        if (isCommunity) {
            return wordLists.filter((wl: WordList) => wl.communityId === communityId && !!communityId)
        } else {
            return wordLists.filter((wl: WordList) => !wl.communityId)
        }
    }

    /**
     * Загрузить сообщества пользователя
     */
    const loadUserCommunities = () => {
        if (!communitiesIsLoaded) {
            getUserCommunities()
        }
    }
    /**
     * Загрузить слова пользователя
     */
    const loadUserWordLists = () => {
        if (!wordListsIsLoaded) {
            getUserWordLists()
        }
    }

    const openWlInCommunity = (cId: number, wlId: number) => {
        getCommunityWordLists(cId).then(() => {
            setIsCommunity(true)
            setCommunityId(cId)
            changeWordListById(wlId)
        })
    }

    const openWl = (wlId: number) => {
        changeWordListById(wlId)
    }

    /**
     * Срабатывает перед монтированием
     */
    const beforeMount = () => {
        loadUserWordLists()
        loadUserCommunities()
        preOpenCard()
    }

    const changeSide = (sideValue: { target: { value: string } }) => {
        const sideName = sideValue.target.value
        changeSideValue(sideName)
    }

    const changeSideValue = (sideName: string) => {
        setSide(sideName)
    }

    /**
     * Открытие карточки по ссылке shared-link
     */
    const preOpenCard = () => {
        // @ts-ignore
        const cardList: WordList = location?.state?.cardList
        if (cardList) {
            changeWordListById(cardList.id)
        }
    }


    useEffect(() => {
        beforeMount()
        return () => {
        }
    }, [])


    return {
        wordLists,
        selectedWordListId,
        communityId,
        isCommunity,
        communities,
        wordListsIsLoading,
        communitiesIsLoading,
        side,
        getActualWordsInMemory,
        openWlInCommunity,
        openWl,
        changeSide,
        changeWordListById,
        changeWordList,
        getActualWordLists,
        changeCommunity,
        changeCommunitySwitch,
        changeSideValue
    }
}

export default useCombineSelector