import { Button, Modal, Table } from 'antd'
import { FC } from 'react'
import { WordDto } from '../../shared/types/api'

interface TestWrongAnswersDialogProps {
    wrongAnswers: Array<WordDto>,
    close: () => void
    open: boolean
}

export const TestWrongAnswersDialog: FC<TestWrongAnswersDialogProps> = ({ wrongAnswers, close, open = false }) => {

    const columns = [
        {
            title: 'Сторона 1',
            dataIndex: 'engWord',
            key: 'engWord'
        },
        {
            title: 'Сторона 2',
            dataIndex: 'rusWord',
            key: 'rusWord'
        }
    ]


    return (
        <Modal
            footer={[
                <Button key='submit' onClick={close}>
                    Ок
                </Button>
            ]}
            destroyOnClose={true}
            title='Ошибки в словах'
            open={open} onCancel={close}>
            <Table dataSource={wrongAnswers} columns={columns} />
        </Modal>
    )
}