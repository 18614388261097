import RulesHeader from '../../blocks/RulesHeader'
import NumberBlock from '../../blocks/NumberBlock'
import TaskExample from '../../blocks/TaskExample'
import Example from '../../blocks/Example'
import FactRule from '../../blocks/FactRule'
import RuleTestWrapper from '../../RuleTestWrapper'
import { test } from './test'
import TableThree from '../../blocks/TableThree'

const FuturePerfectContinuous = (props) => {
    return (
        <div>
            <RulesHeader title='Future Perfect Continuous' subtitle='Будущее совершенное длительное время' />

            <NumberBlock numberBlock='1' />
            <TaskExample taskExample='Изучите следующую ситуацию:' />
            <Example
                engExample={['Next month we ', <u>will have been making</u>, ' project for 3 years.']}
                rusExample='В следующем месяце исполнится 3 года, как мы делаем проект.'
            />
            <p>
                {' '}
                Мы используем Future Perfect Continuous когда указываем на действие, которое началось и продолжалось в
                течение некоторого времени до определенного момента в будущем. Это время используется очень редко, а в
                устной речи – практически никогда.
            </p>
            <FactRule factRule='Will have + been + 1-я форма правильного глагола-ing это the future perfect continuous.' />

            <NumberBlock numberBlock='2' />
            <p>
                {' '}
                Для того чтобы поставить глагол в форму времени Future Perfect Continuous, требуется вспомогательный
                глагол <b>to be</b> во времени Future Perfect и причастие настоящего времени (форма V-ing) смыслового
                глагола.
                <b>To be</b> во времени Future Perfect имеет форму <b>will have been</b>. Причастие настоящего времени
                можно получить, прибавив к начальной форме значимого глагола окончание <b>-ing</b>.
            </p>
            <TableThree
                elements={[{ left: 'I/he/they', center: 'will have been', right: '1-я форма правильного глагола+ing' }]}
            />
            <Example
                engExample={[
                    'When Vlad finishes this project, he ',
                    <u> will have been learning</u>,
                    '  English for 5 years.'
                ]}
                rusExample={[
                    'Когда Влад закончит этот проект, он ',
                    <u> будет учить</u>,
                    '  английский язык в течение пяти лет.'
                ]}
            />
            <p>
                {' '}
                В вопросительном предложении вспомогательный глагол <b>will</b> выносится на место перед подлежащим, а
                остальная часть сказуемого располагается после него.
            </p>
            <TableThree
                elements={[{ left: 'Will', center: 'I/he/they+have been', right: '1-я форма правильного глагола+ing' }]}
            />
            <Example
                engExample={[
                    'I hear, you are going to retire next month? For how many years ',
                    <u>will</u>,
                    ' you ',
                    <u>have been working</u>,
                    ' here by that time?'
                ]}
                rusExample={[
                    'Я слышал, в следующем месяце вы выходите на пенсию? Сколько к тому времени вы уже здесь ',
                    <u>будете работать</u>,
                    '?'
                ]}
            />
            <p>
                {' '}
                В отрицательных предложениях за вспомогательным глаголом <b>will</b> следует отрицательная частица{' '}
                <b>not</b>.
            </p>
            <TableThree
                elements={[
                    { left: 'I/he/they', center: 'will not have been ', right: '1-я форма правильного глагола+ing' }
                ]}
            />
            <Example
                engExample={[
                    'By 2015 you ',
                    <u> not have been living</u>,
                    ' in this country long enough to participate in the election.'
                ]}
                rusExample={[
                    'К 2015 году вы ',
                    <u>не будете жить</u>,
                    ' в этой стране достаточно долго, чтобы принять участие в выборах.'
                ]}
            />

            <NumberBlock numberBlock='3' />
            <p> Мы используем Past Perfect Continuous в следующих ситуациях:</p>
            <FactRule factRule='Действие, которое началось и продолжалось в течение некоторого времени до определенного момента в будущем.' />
            <Example
                engExample={['Next March we ', <u>will have been living</u>, ' in Mexico for 2 years.']}
                rusExample={['В марте исполнится два года с тех пор, как мы ', <u>живем</u>, ' в Мексике.']}
            />

            <RuleTestWrapper value={test} rule={props} />
        </div>
    )
}

export default FuturePerfectContinuous
