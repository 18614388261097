const IconQiwi = (props) => {
    const { className = '', onClick = () => null } = props

    return (
        <svg
            onClick={onClick}
            className={'icon-btn ' + className}
            width='32'
            height='32'
            fill='#ff9300'
            viewBox='0 0 50 50'
        >
            <path d='M39.968 33.853c.126.811-.141 1.147-.409 1.147-.292 0-.66-.329-1.093-.977-.427-.64-.584-1.375-.372-1.746.14-.245.433-.352.803-.218.715.259.996 1.31 1.071 1.794m-2.992 1.651c.993.667 1.295 1.46.77 2.02-.293.307-.766.476-1.333.476-.557 0-1.125-.164-1.52-.437-.903-.627-1.168-1.643-.578-2.221.23-.225.604-.342 1.053-.342.545 0 1.116.174 1.608.504' />
            <path d='M48 49.999c-.279 0-.556-.117-.752-.339C47.199 49.603 42.165 44 34 44c-1.685 0-3.023.215-4.441.443-1.623.261-3.464.557-6.059.557C11.093 45 1 34.907 1 22.5S11.093 0 23.5 0 46 10.093 46 22.5c0 3.462-.786 6.808-2.337 9.943a1.014 1.014 0 01-1.014.55 1 1 0 01-.857-.771c-.006-.026-.745-3.021-3.832-5.005C36.098 26.02 34.018 26 33.998 26a1.002 1.002 0 01-.998-.98c0-.52.418-1.02 1-1.02.125 0 2 0 3.886.298.076-.6.114-1.202.114-1.798C38 14.505 31.495 8 23.5 8S9 14.505 9 22.5 15.505 37 23.5 37c.273 0 .555-.01.853-.031C24.006 35.091 24 33.102 24 33c0-.518.395-.95.911-.997a1.01 1.01 0 011.073.819c.002.012.396 1.933 2.454 3.835 1.593 1.474 3.767 2.558 6.459 3.224l.297.081c10.892 2.521 13.609 8.382 13.719 8.63a.998.998 0 01-.374 1.247.983.983 0 01-.539.16z' />
        </svg>
    )
}

export default IconQiwi
