import {FC} from 'react'

interface BurgerMenuIconProps {
    className?: string,
    onClick?: () => void
}

export const AttentionIcon: FC<BurgerMenuIconProps> = ({className = '', onClick}) => {

    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24 44C26.6269 44.0034 29.2286 43.4876 31.6556 42.4823C34.0826 41.477 36.2869 40.002 38.142 38.142C40.002 36.2869 41.477 34.0826 42.4823 31.6556C43.4876 29.2287 44.0034 26.627 44 24C44.0033 21.3731 43.4875 18.7714 42.4822 16.3444C41.4769 13.9175 40.0019 11.7131 38.142 9.85802C36.2869 7.99804 34.0826 6.52302 31.6556 5.5177C29.2286 4.51239 26.6269 3.99661 24 4.00002C21.3731 3.99667 18.7714 4.51247 16.3444 5.51778C13.9175 6.52309 11.7131 7.99809 9.85799 9.85802C7.99806 11.7131 6.52306 13.9175 5.51775 16.3444C4.51244 18.7714 3.99664 21.3731 3.99999 24C3.99658 26.627 4.51236 29.2287 5.51767 31.6556C6.52298 34.0826 7.99801 36.2869 9.85799 38.142C11.7131 40.0019 13.9175 41.4769 16.3444 42.4823C18.7714 43.4876 21.3731 44.0034 24 44Z"
                stroke="#FF0000" stroke-width="4" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M24 37C24.663 37 25.2989 36.7366 25.7678 36.2678C26.2366 35.7989 26.5 35.163 26.5 34.5C26.5 33.837 26.2366 33.2011 25.7678 32.7322C25.2989 32.2634 24.663 32 24 32C23.337 32 22.7011 32.2634 22.2322 32.7322C21.7634 33.2011 21.5 33.837 21.5 34.5C21.5 35.163 21.7634 35.7989 22.2322 36.2678C22.7011 36.7366 23.337 37 24 37Z"
                  fill="#FF0000"/>
            <path d="M24 12V28" stroke="#FF0000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    )
}
