import PropTypes from 'prop-types'

const IconArrowBorderedRight = (props: { onClick?: (() => void) }) => {
    const { onClick = () => null } = props

    return (
        <svg
            onClick={onClick}
            xmlns='http://www.w3.org/2000/svg'
            width='30'
            height='30'
            fill='none'
            stroke='#000'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            className='icon icon-tabler icon-tabler-arrow-big-right pointer-cursor'
            viewBox='0 0 24 24'
        >
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <path
                d='M4 9h8V5.414a1 1 0 011.707-.707l6.586 6.586a1 1 0 010 1.414l-6.586 6.586A1 1 0 0112 18.586V15H4a1 1 0 01-1-1v-4a1 1 0 011-1z'></path>
        </svg>
    )
}
export default IconArrowBorderedRight

IconArrowBorderedRight.propTypes = {
    onClick: PropTypes.func
}
