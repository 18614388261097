import { FC, useState } from 'react'
import { GEButton } from '../../../../../common/buttons'
import BuyingCardDialog from '../../../../modalwindows/BuyingCardDialog'
import { useAppSelector } from '../../../../../store/hooks'
import { userSelector } from '../../../../../store/global'
import { NavigationPages } from '../../../../../common/navigation/navigation-pages'
import { goToPageNotPremium } from '../../../../utils/goToPage'
import { useNavigate } from 'react-router-dom'

interface BuyingCardProps {
    price: number,
    title: string,
    subTitle?: string,
    link: () => {},
    text: string
}

export const BuyingCard: FC<BuyingCardProps> = ({ price, title, subTitle, link, text }) => {

    const [showBuyingCardModal, setShowBuyingCardModal] = useState(false)
    const currentUser = useAppSelector(userSelector)
    const navigate = useNavigate()

    const closeBuyingCardModal = () => {
        setShowBuyingCardModal(false)
    }

    const openBuyingCardModal = () => {
        if (currentUser) {
            link()
        } else {
            goToPageNotPremium(NavigationPages.MOBILE_AUTH, navigate)
        }
    }

    return (
        <div className='premium__buying-card'>
            <div className='premium__buying-card__price'>
                <div className='premium__buying-card__text-price'>{price} ₽</div>
                <div className='premium__buying-card__price-text'>{text}</div>
            </div>
            <div className='premium__buying-card__content'>
                <div className='premium__buying-card__sub-content'>
                    <div className='premium__buying-card__title-area'>
                        <span className='premium__buying-card__title'>{title}</span>
                        <span className='premium__buying-card__sub-title'>{subTitle}</span>
                    </div>

                    <GEButton onClick={openBuyingCardModal} className='premium__buying-card__btn'
                              type='primary'>Купить</GEButton>
                </div>

            </div>
            <BuyingCardDialog isOpen={showBuyingCardModal} close={closeBuyingCardModal} price={price} text={text}
                              title={title} link={link} />
        </div>
    )
}