import MotivationalQuotesUtil from '../utils/MotivationalQuotesUtil'
import { FC } from 'react'
import { Button, Flex, Modal, Tag } from 'antd'
import { ID, WordList } from '../../shared/types/api'
import { goToExercisePageWithReloading } from '../utils/goToPage'
import { NavigationPages } from '../../common/navigation/navigation-pages'
import { useNavigate } from 'react-router-dom'
import useCardRepeatCardLists from '../../hooks/useCardRepeatCardLists'
import Pages from '../../const/Pages'

interface CompletedExerciseDialogProps {
    selectedWordListId: ID | undefined;
    close: () => void;
    open: boolean
}

const CompletedExerciseDialog: FC<CompletedExerciseDialogProps> = ({
                                                                       close,
                                                                       open,
                                                                       selectedWordListId
                                                                   }) => {

    const navigate = useNavigate()

    const { repeatSuggestion } = useCardRepeatCardLists(Pages.CARD, open, selectedWordListId)

    const clearAndClose = () => {
        close()
    }

    const openSuggestionList = (rs: WordList) => {
        clearAndClose()
        goToExercisePageWithReloading(NavigationPages.CARDS, navigate, rs)
    }

    return (
        <Modal
            title={'Молодец! Все карточки в списке пройдены!'}
            onCancel={clearAndClose}
            footer={[
                <Button danger onClick={clearAndClose}>
                    Закрыть
                </Button>
            ]}
            open={open}>
            <div>
                <span>{MotivationalQuotesUtil.generateRandomMotivationQuote()}</span>
            </div>
            {repeatSuggestion.length > 0 &&
            <>
                <div>
                    <span>Рекомендуем повторить:</span>
                </div>
                <Flex gap={5} wrap={'wrap'}>
                    {repeatSuggestion.map((rs) => {
                        return <Tag onClick={() => openSuggestionList(rs)}
                                    className={'pointer-cursor'}>{rs.listName}</Tag>
                    })}
                </Flex>
            </>}
        </Modal>
    )
}

export default CompletedExerciseDialog