import '../../../styles/css/elements/svg_icons/icon-btn.css'

const IconCrown = ({ width = '24', height = '24' }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            fill='none'
            stroke='#ff9300'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            className='icon icon-tabler icon-tabler-crown'
            viewBox='0 0 24 24'
        >
            <title>Премиум</title>
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <path d='M12 6l4 6 5-4-2 10H5L3 8l5 4z'></path>
        </svg>
    )
}

export default IconCrown
