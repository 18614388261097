import { FC } from 'react'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'
import { useAppSelector } from '../../store/hooks'
import { userSelector } from '../../store/global'
import { NavigationPages } from '../navigation/navigation-pages'
import { goToPageNotPremium } from '../../components/utils/goToPage'
import { useNavigate } from 'react-router-dom'

export const NoAuthGEButton: FC<ButtonProps> = (props) => {

    const navigate = useNavigate()

    const currentUser = useAppSelector(userSelector)

    if (currentUser) {
        return (
            <Button  {...props} className={'scale-btn ' + props.className}>
                {props.children}
            </Button>
        )
    } else {
        return (
            <Button onClick={() => goToPageNotPremium(NavigationPages.MOBILE_AUTH, navigate)}
                    className={'scale-btn ' + props.className}>
                Войти / зарегистрироваться
            </Button>)
    }


}