import { useEffect } from 'react'
import Pages from '../../../../const/Pages'
import { userSelector, setUserRoutine } from '../../../../store/global'
import { wordListsDataSelector } from '../../../../store/wordLists'
import '../../../../styles/scss/dictionary/dictionary.scss'
import NewCommerceUtil from '../../../utils/NewCommerceUtil'
import WordLists from './wordlists/WordLists'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { selectCardList } from '../../../helpers/card-list-helper'
import { wordsDataSelector } from '../../../../store/words'
import AddWordListButton from './wordlists/AddWordListButton'
import Constants from '../../../../const/Constants'
import { isPremium } from '../../../../common/utils/user'
import { Flex } from 'antd'
import { fetchWorldLists } from '../../../../store/wordLists/extraReducers'

const DictionaryMyLists = () => {
    const user = useAppSelector(userSelector)
    const wordLists = useAppSelector(wordListsDataSelector)
    const cards = useAppSelector(wordsDataSelector)

    const dispatch = useAppDispatch()
    useEffect(() => {
        updateUserIfNew()
        dispatch(fetchWorldLists())
        return cancelAllWorListChanges()
    }, [])

    const updateUserIfNew = () => {
        const newUser = NewCommerceUtil.showNewCommerceModal(user, Pages.DICTIONARY)
        if (newUser) {
            dispatch(setUserRoutine(newUser))
        }
    }

    const cancelAllWorListChanges = () => {
        selectCardList(dispatch, 'null-list', wordLists, cards)
    }

    const isNotInEditMode = () => {
        return !wordLists.some((wl) => wl.editMode)
    }
    const canAddUser = () => {
        const userWordLists = wordLists.filter((wl) => !wl.communityId)
        return userWordLists.length < Constants.MAX_AMOUNT_WORD_LISTS || isPremium(user)
    }

    return (
        <div className='dictionary' id='dictionary'>
            <Flex justify='flex-start' className='dictionary_btn'>
                {isNotInEditMode() &&
                <AddWordListButton canAddUser={canAddUser()} />}
            </Flex>

            <div className='dictionary_container'>
                <WordLists
                    wordLists={wordLists}
                    user={user}
                    isCommunity={false}
                    canEditCommunity={false}
                />
            </div>
        </div>
    )
}
export default DictionaryMyLists
