import { useEffect, useState } from 'react'
import '../../../../styles/scss/share-list/share-list.scss'
import Loading from '../../../elements/loading/Loading'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { goToExercisePage } from '../../../utils/goToPage'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { fetchWorldLists } from '../../../../store/wordLists/extraReducers'
import { wordListsDataSelector, wordsListsLoadingStatusSelector } from '../../../../store/wordLists'
import { NavigationPages } from '../../../../common/navigation/navigation-pages'
import { WordList } from '../../../../shared/types/api'

const ShareList = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [cardListId, setCardListId] = useState<number | null>(null)

    const wordLists = useAppSelector(wordListsDataSelector)
    const wordListsLoading = useAppSelector(wordsListsLoadingStatusSelector)

    useEffect(() => {
        beforeMount()
        return () => {
        }
    }, [])


    const beforeMount = () => {
        dispatch(fetchWorldLists())
        let urlParams = new URLSearchParams(window.location.search)
        const communityKey = urlParams.get('communityKey')
        const wl = urlParams.get('wl')
        if (communityKey && wl) {

        } else if (wl) {
            setCardListId(Number(wl))
        }
    }

    const cardList = wordLists && wordLists.find((wl: WordList) => wl.id === cardListId)

    return (
        <div id='share-list'>
            <Helmet title='GE: Повторение карточек' />
            {wordListsLoading ? (
                <Loading />
            ) : (
                cardList && (
                    <>
                        <h2>Повторение списка {cardList.listName}</h2>
                        <ul>
                            <li>
                                <div onClick={() => goToExercisePage(NavigationPages.CARDS, navigate, cardList)}
                                     className='share-list_item'>
                                    <img src='../images/mainpage/cards/cards.png' alt='Карточки' />
                                    <h2>Карточки</h2>
                                </div>
                            </li>
                            <li>
                                <div onClick={() => goToExercisePage(NavigationPages.TEST, navigate, cardList)}
                                     className='share-list_item'>
                                    <img src='../images/mainpage/cards/test.png' alt='Тесты' />
                                    <h2>Тесты</h2>
                                </div>
                            </li>
                            <li>
                                <div onClick={() => goToExercisePage(NavigationPages.GUESS_WORD, navigate, cardList)}
                                     className='share-list_item'>
                                    <img src='../images/mainpage/cards/fill-gaps.png' alt='Заполни пропуски' />
                                    <h2>Заполни пропуски</h2>
                                </div>
                            </li>
                            <li>
                                <div onClick={() => goToExercisePage(NavigationPages.FILL_WORDS, navigate, cardList)}
                                     className='share-list_item'>
                                    <img src='../images/mainpage/cards/scrabble.png' alt='Филворд' />
                                    <h2>Филворд</h2>
                                </div>
                            </li>
                        </ul>
                    </>
                )
            )}
        </div>
    )
}

export default ShareList
