import '../../../styles/css/elements/svg_icons/icon-btn.css'

function IconVolumeUp(props) {
    const { className = '', width = '35', height = '35', onClick = () => null, stroke = '#ffffff' } = props

    return (
        <svg
            onClick={onClick}
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            fill='none'
            stroke={stroke}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.7'
            className={'icon icon-tabler icon-tabler-volume ' + className}
            viewBox='0 0 24 24'
        >
            <title>Воспроизвести</title>
            <path stroke='none' d='M0 0h24v24H0z'></path>
            <path
                d='M15 8a5 5 0 010 8M17.7 5a9 9 0 010 14M6 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h2l3.5-4.5A.8.8 0 0111 5v14a.8.8 0 01-1.5.5L6 15'></path>
        </svg>
    )
}

export default IconVolumeUp
