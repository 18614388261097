import '../../../styles/css/special/select_category.css'
import { FC } from 'react'

interface SelectCategoryProps {
    message: string
}

const SelectCategory: FC<SelectCategoryProps> = ({ message }) => {
    return (
        <div className='custom_main_message'>
            <img src='../images/custom/logo.png' alt='Logo' />
            <span />
            <div className='select_category'>{message}</div>
        </div>
    )
}

export default SelectCategory
