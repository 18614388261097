import { useState, useEffect } from 'react'
import { Flex, Modal, Typography } from 'antd'
import * as API_RULE from '../../../shared/http/rule'
import { cardsData, sliderData, tipsData, wordCardsData } from './data'
import Slider from './Slider'
import Tip from './elements/Tip'
import Card from './elements/Card'
import ChartRound from './elements/ChartRound'
import ChartBar from './elements/ChartBar'
import Reviews from './elements/Reviews'
import IconArrowUpCircle from '../../elements/svg_icons/IconArrowUpCircle'
import LoginModal from '../../modalwindows/LoginModal'
import '../../../styles/css/landing_page/landing_page.css'
import * as API_USER from '../../../shared/http/user'
import { fetchLogin } from '../../../store/global/extraReducers'
import { setLoadingRoutine, userSelector } from '../../../store/global'
import { Helmet } from 'react-helmet'
import { NavigationPages } from '../../../common/navigation/navigation-pages'
import { GEButton } from '../../../common/buttons'
import { handleHttpError } from '../../../common/utils/errors'
import IconPlayStore from '../../elements/svg_icons/IconPlayStore'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import Constants from '../../../const/Constants'
import IconAppStore from '../../elements/svg_icons/IconAppStore'
import YandexAd from '../../blocks/banner/YandexAd'
import { useNavigate } from 'react-router-dom'
import { goToPageNotPremium } from '../../utils/goToPage'
import RuleCardLP from '../../elements/cards/RuleCardLP'
import { ErrorEnum } from '../../../common/error/error-enum'
import { SuccessRegistrationModal } from '../../modalwindows/SuccessRegistrationModal'
import { GELink } from '../../../common/link/GELink'

const { Paragraph } = Typography

const LandingPage = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const user = useAppSelector(userSelector)

    const [rules, setRules] = useState([])
    const [isVisibleModal, setIsVisibleModal] = useState(false)
    const [modalType, setModalType] = useState()
    const [isVisibleSuccessModal, setIsVisibleSuccessModal] = useState(false)
    const [registeredUserEmail, setRegisteredUserEmail] = useState('')
    const [isRegistrationLoading, setIsRegistrationLoading] = useState(false)

    const getYear = () => {
        const date = new Date()
        return date.getFullYear()
    }

    const handleModalOpenLogin = () => {
        setModalType('login')
        setIsVisibleModal(true)
    }

    const handleModalOpenRegister = () => {
        setModalType('register')
        setIsVisibleModal(true)
    }

    const handleModalClose = () => setIsVisibleModal(false)

    const handleParallaxTips = (scroll) => {
        const tipsContainer = document.querySelector('#lp .tips')
        const speed = 10
        let y = 50 - scroll / speed
        if (y > 0) {
            y = y > 50 ? 50 : y
        } else {
            y = 0
        }
        if (tipsContainer.style) {
            tipsContainer.style.setProperty('bottom', `${y}%`)
        }
    }

    const handleScrollTopClick = () => {
        const top = Math.max(document.body.scrollTop, document.documentElement.scrollTop)
        const step = 5
        const iterations = Math.ceil(top / step)
        for (let i = 0; i < iterations; i++) {
            setTimeout(() => window.scrollBy(0, -step), i * i * 0.001)
        }
    }

    const handleScroll = () => {
        const scrolledY = window.scrollY
        handleParallaxTips(scrolledY / 1.5)
    }

    const tryLogin = (user) => handleLogin(user).then((e) => {
        if (e?.error?.message) {
            if (e?.error?.message === ErrorEnum.EMAIL_NOT_CONFIRMED) {
                showWarningNotConfirmedPrompt()
            }
            if (e?.error?.message === ErrorEnum.WRONG_LOGIN_OR_PASSWORD) {
                showWarningWrongEmailOrPasswordPrompt()
            }
        }
    })

    const showWarningNotConfirmedPrompt = () => {
        const t = {
            title: 'Email не подтвержден',
            content: 'Мы отправили вам сообщение на почту, для завершения регистрации необходимо подтвердить email.'
        }
        Modal.warning(t)
    }

    const showWarningWrongEmailOrPasswordPrompt = () => {
        const t = {
            title: 'Неверный пароль или логин',
            content: 'Попробуйте ввести логин и пароль снова'
        }
        Modal.warning(t)
    }

    const handleLogin = (user) => dispatch(fetchLogin(user))

    const closeSuccessModal = () => {
        setIsVisibleSuccessModal(false)
        setIsVisibleModal(false)
    }

    const handleRegister = (user) => {
        let urlParams = new URLSearchParams(window.location.search)
        const referenceUserId = urlParams.get('referral-user-id')
        if (referenceUserId) {
            user.referralUserId = parseInt(referenceUserId)
        }
        setIsRegistrationLoading(true)
        API_USER.registration(user)
            .then((resp) => {
                setRegisteredUserEmail(resp.data.email)
                setIsVisibleSuccessModal(true)
            })
            .catch((e) => {
                handleHttpError(e, navigate)
            })
            .finally(() => {
                dispatch(setLoadingRoutine(false))
                setIsRegistrationLoading(false)
            })
    }

    const handleForgotPassword = () => goToPageNotPremium(NavigationPages.RESET_PASSWORD, navigate)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        API_RULE.getRules()
            .then((rules) => setRules(rules))
            .catch(() => setRules([]))
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        if (user) {
            goToPageNotPremium(`${NavigationPages.PROFILE}/${user.id}`, navigate)
        }
    }, [user])


    return (
        <div id={'lp'}>
            <Helmet title='Geekenglish. Твой карманный словарь' />
            <header className={'header header-scrolled'}>
                <div className={'header__logo'}>
                    <img className={'header__img'} src='./images/custom/logo.png' alt='GeekEnglish logo' />
                    <div className={'header-block'}>
                        <h1 className={'header__title'} style={{ color: '#2d50ba' }}>
                            GeekEnglish
                        </h1>
                        <h3 className={'header__text'}>английский по карточкам</h3>
                    </div>
                    <GELink to={NavigationPages.COURSES_LIST} className='header__btn'>
                        Курсы
                    </GELink>
                </div>
                {/*  <div>
                    Курсы
                </div>*/}
                <Flex gap={8}>
                    <GEButton size='large' type={'primary'} onClick={handleModalOpenLogin}>
                        Войти
                    </GEButton>
                </Flex>
            </header>
            <main className={'content'}>
                <section className={'content__slider'}>
                    <Slider data={sliderData} onClick={handleModalOpenRegister} />
                </section>
                <section className={'content__tips'}>
                    <div className={'tips'}>
                        {tipsData.map((tip, key) => (
                            <Tip {...tip} key={key} />
                        ))}
                    </div>
                </section>
                <section className={'content__cards'}>
                    <h2 className={'content__cards-heading'}>Познакомьтесь с нашими сервисами</h2>
                    <div className={'cards'}>
                        {cardsData.map((card, key) => (
                            <Card {...card} key={key} />
                        ))}
                    </div>
                </section>
                <section className={'content__rule-cards'}>
                    <h2 className={'content__rule-cards-heading'}>
                        Правила
                    </h2>
                    <div className={'cards'}>
                        {rules.slice(0, 3).map((rule, key) => (
                            <RuleCardLP
                                key={key}
                                name={rule.name}
                                image={`${Constants.queryConst}/file/rule/image?id=${rule.imageId}`}
                            />
                        ))}
                    </div>
                </section>
                <section className={'content__statistic'}>
                    <h2 className={'content__statistic-heading'}>Статистика</h2>
                    <div className={'statistic-wrapper'}>
                        <ChartBar />
                        <ChartRound />
                    </div>
                </section>
                <section className={'content__word-cards'}>
                    <h2 className={'content__word-cards-heading'}>Готовые подборки слов по темам и по уровню</h2>
                    <div className={'cards'}>
                        {wordCardsData.map((card, key) => (
                            <Card {...card} className={'card-active'} imgType={'background'} key={key} />
                        ))}
                    </div>
                </section>
                <section className={'content__reviews'}>
                    <h2 className={'content__reviews-heading'}>Отзывы пользователей</h2>
                    <div className={'review'}>
                        <Reviews count={2} />
                    </div>
                </section>
                <section className={'content__register'}>
                    <h2 className={'content__word-cards-heading'}>🗓 Повышайте словарный запас каждый день</h2>
                    <div className={'content__register__btn'}>
                        <GEButton type={'primary'} onClick={handleModalOpenRegister}>👉 Нажмите, чтобы начать
                            👈</GEButton>
                    </div>
                </section>
            </main>
            <footer className={'footer'}>
                <ul className={'footer__content'}>
                    <li className={'footer__item'}>
                        <div className={'footer__social-icons'}>
                            <a href={Constants.GOOGLE_PLAY_LINK}>
                                <IconPlayStore />
                            </a>
                        </div>
                    </li>
                    <li className={'footer__item'}>
                        <div className={'footer__social-icons'}>
                            <a href={Constants.APPLE_STORE_LINK}>
                                <IconAppStore />
                            </a>
                        </div>
                    </li>

                    <li className={'footer__item'}>
                        <h2 className={'footer__title'}>Наши контакты</h2>
                        <ul className={'contacts'}>
                            <li className={'contacts__item'}>
                                <p className={'contacts__text'}>Телеграм</p>
                                <a className={'contacts__link'} href={'https://t.me/geekenglishru'}>
                                    https://t.me/geekenglishru
                                </a>
                            </li>
                            <li className={'contacts__item'}>
                                <p className={'contacts__text'}>Предложения</p>
                                <a className={'contacts__link'} href={'mailto:geekenglish@yandex.com'}>
                                    GeekEnglish@yandex.com
                                </a>
                            </li>
                            <li className={'contacts__item'}>
                                <p className={'contacts__text'}>Техподдержка</p>
                                <a className={'contacts__link'} href={'mailto:geekenglishTech@yandex.com'}>
                                    GeekEnglishTech@yandex.com
                                </a>
                            </li>
                            <li className={'contacts__item'}>
                                <p className={'contacts__text'}>Еще наши проекты</p>
                                <a className={'contacts__link'} href={'https://minecraftrating.com'}>
                                    Сервера майнкрафт
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className={'footer__item'}>
                        <p className={'footer__rights'}>GeekEnglish &#174; 2019 &mdash; {getYear()}</p>
                    </li>
                </ul>
            </footer>
            <div className={'up-button-wrap'} onClick={handleScrollTopClick}>
                <IconArrowUpCircle className={'up-button-img'} />
            </div>
            {isVisibleModal && (
                <LoginModal
                    type={modalType}
                    onLogin={tryLogin}
                    onRegister={handleRegister}
                    onForgot={handleForgotPassword}
                    onClose={handleModalClose}
                    isLoading={isRegistrationLoading}
                />
            )}
            <SuccessRegistrationModal isVisibleSuccessModal={isVisibleSuccessModal}
                                      closeSuccessModal={closeSuccessModal}
                                      registeredUserEmail={registeredUserEmail}
                                      containerId={'lp'} />
        </div>
    )
}

export default LandingPage
