import '../../../../styles/css/personal-policy/personal-policy.css'
import { Helmet } from 'react-helmet'

const PersonalPolicy = () => {
    return (
        <div id='personal-data-agreement'>
            <Helmet title='Согласие на обработку персональных данных сервисом Geekenglish' />
            <div className='personal-data-agreement'>
                <h1>Согласие на обработку персональных данных сервисом «GeekEnglish»</h1>
                <p>
                    Настоящим я (далее – Субъект), во исполнение требований Федерального закона от 27.07.2006
                    № 152-ФЗ «О персональных данных», ознакомлен с Политикой конфиденциальности сервиса
                    «GeekEnglish», размещенной в телекоммуникационной сети «Интернет» по адресу:
                    https://geekenglish.ru/personal-policy, и свободно, своей волей и в своем интересе даю согласие
                    Индивидуальному предпринимателю Филатову Александру Вадимовичу (далее – Оператор,
                    ИНН: 622901533825) на обработку моих персональных данных с использованием средств
                    автоматизации, а также без использования средств автоматизации путем сбора, записи,
                    систематизации, накопления, хранения, уточнения (обновления, изменения), извлечения,
                    использования, обезличивания, блокирования, удаления, уничтожения, передачи
                    (предоставления, доступа) моих персональных данных Оператору.
                </p>
                <p>
                    Настоящее согласие на обработку персональных данных является универсальной формой
                    согласия на обработку персональных данных и распространяет свое действие на всех лиц,
                    пользующихся сайтом https://geekenglish.ru/:
                </p><p>
                1. Пользователей, предоставляющих Оператору свои персональные данные (адрес
                электронной почты) в целях предоставления доступа к функционалу сервиса GeekEnglish.
            </p>
                <p>
                    2. Преподавателей, предоставляющих Оператору свои персональные данные (фамилия, имя,
                    отчество; адрес электронной почты; данные документа, удостоверяющего личность; ИНН;
                    реквизиты лицевого счета) в целях предоставления доступа к функционалу сервиса
                    GeekEnglish.
                </p>
                <p>
                    Я настоящим также даю согласие на обработку моих персональных данных для цели
                    получения информации о новостях, мероприятиях, действующих коммерческих
                    предложениях в отношении предоставляемых Оператором услуг, путем направления
                    Оператором информационных и новостных рассылок.
                </p>
                <p>
                    Согласие действует с даты его предоставления в течение срока, необходимого для достижения
                    указанных в настоящем согласии целей обработки персональных данных. Субъект может в
                    любой момент отозвать свое согласие на обработку персональных данных, направив
                    Оператору уведомление на электронный адрес Оператора GeekEnglish@yandex.com с
                    пометкой «Отзыв согласия на обработку персональных данных».
                </p>
                <p>
                    Датой предоставления согласия на обработку персональных данных Пользователя является
                    дата регистрации Пользователя в сервисе «GeekEnglish». Датой предоставления согласия на
                    обработку персональных данных Преподавателя является дата заполнения Преподавателем
                    анкеты преподавателя в разделе «Профиль» сайта https://geekenglish.ru/.
                </p>
                <p>
                    В случае отзыва согласия на обработку персональных данных Оператор прекращает их
                    обработку и уничтожает персональные данные в срок, не превышающий 30 (тридцати) дней с
                    даты поступления отзыва. В случае отказа в предоставлении согласия на обработку
                    персональных данных Оператор будет вынужден отказать Субъекту в совершении действий,
                    предусмотренных целями обработки персональных данных.
                </p>
                <p>
                    Я уведомлен(-а), что при обработке персональных данных Оператор принимает
                    предусмотренные статьей 19 ФЗ «О персональных данных» правовые, организационные и
                    технические меры для защиты персональных данных от неправомерного или случайного
                    доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления,
                    распространения персональных данных, а также от иных неправомерных действий в
                    отношении персональных данных.
                </p>
            </div>
        </div>
    )
}

export default PersonalPolicy
