import '../../../../styles/scss/auth-by-code/auth-by-code.scss'
import { Helmet } from 'react-helmet'
import * as API_USER from '../../../../shared/http/user'
import IconCopy from '../../../elements/svg_icons/IconCopy'
import IconPlayStore from '../../../elements/svg_icons/IconPlayStore'
import { useAppSelector } from '../../../../store/hooks'
import { userSelector } from '../../../../store/global'
import { useEffect, useState } from 'react'
import NotificationsUtils from '../../../utils/NotificationsUtils'
import Constants from '../../../../const/Constants'
import IconAppStore from '../../../elements/svg_icons/IconAppStore'

const AuthByCode = () => {

    const [code, setCode] = useState<string>('')
    const user = useAppSelector(userSelector)

    useEffect(() => {
        API_USER.getAuthCode().then(r => {
            setCode(r.data.value)
        })
    }, [])


    const copyCode = () => {
        navigator.clipboard
            .writeText(code)
            .then(() => {
                NotificationsUtils.openSuccessNotification('Код скопирован!')
            })
            .catch(() => {
                NotificationsUtils.openErrorNotification('Не удалось скопировать код')
            })
    }

    const copyLogin = () => {
        navigator.clipboard
            .writeText(user.login)
            .then(() => {
                NotificationsUtils.openSuccessNotification('Логин скопирован!')
            })
            .catch(() => {
                NotificationsUtils.openErrorNotification('Не удалось скопировать логин')
            })
    }

    return (
        <div id='auth-by-code'>
            <Helmet title='Авторизация по коду' />

            <ul className='auth-by-code__list'>
                <li>
                    <span>Используй код для авторизации в приложении (код доступен 60 секунд)</span>
                </li>
                <li>
                    <a href={Constants.GOOGLE_PLAY_LINK}>
                        <IconPlayStore />
                    </a>

                    <a href={Constants.APPLE_STORE_LINK}>
                        <IconAppStore />
                    </a>

                </li>
                <li>
                    <span className='auth-by-code__title'>Ваш логин:</span>
                    <div>
                        <span className='auth-by-code__value'>{user.login}</span>
                        <IconCopy onClick={copyLogin} />
                    </div>
                </li>
                <li>
                    <span className='auth-by-code__title'>Ваш код:</span>
                    <div>
                        <span className='auth-by-code__value'>{code}</span>
                        <IconCopy onClick={copyCode} />
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default AuthByCode
