import '../../../../styles/css/not_found/not_found.css'
import { PageNotFoundIcon } from '../../../elements/svg_icons/PageNotFounIcon'
import { GEButton } from '../../../../common/buttons'
import { NavigationPages } from '../../../../common/navigation/navigation-pages'
import { useAppSelector } from '../../../../store/hooks'
import { userSelector } from '../../../../store/global'

const ErrorPage = () => {

    const user = useAppSelector(userSelector)
    const openProfile = () => {
        window.location = `${NavigationPages.PROFILE}/${user.id}`
    }

    return (
        <div id='not-found'>
            <div className='central-body'>
                <div className='central-body__icon'>
                    <PageNotFoundIcon />
                </div>
                <div className='central-body__text'>
                <span className='central-body-text__span'>
                Кажется что то пошло не так. Мы уже работаем над этим.
                  </span>
                    <GEButton onClick={openProfile} type={'primary'} className='central-body-text__btn'>Вернуться в
                        профиль</GEButton>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage