import { Button, Input, Modal } from 'antd'
import GoogleAnalyticsUtils from '../utils/GoogleAnalyticsUtils'
import GoogleAnalyticsConsts from '../../const/GoogleAnalyticsConsts'
import { useAppDispatch } from '../../store/hooks'
import WordListUtils from '../utils/WordListUtils'
import { FC, useState } from 'react'
import { createInCommunity, createWordList } from '../../store/wordLists/extraReducers'
import { CommunityDto, ID } from '../../shared/types/api'

interface AddCardListDialogProps {
    isOpen: boolean,
    community?: CommunityDto
    close: () => void
}

const AddCardListDialog: FC<AddCardListDialogProps> = ({ isOpen, close, community }) => {
    const dispatch = useAppDispatch()
    const [name, setName] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [valid, setValid] = useState<boolean>(true)

    const clearAndClose = () => {
        setName('')
        close()
    }

    const addCardList = () => {
        if (name.length === 0) {
            setValid(false)
        } else {
            GoogleAnalyticsUtils.createEvent(GoogleAnalyticsConsts.DICTIONARY_ADD_CARD_LIST)
            setLoading(true)
            if (!!community) {
                const wordList = WordListUtils.createNewCommunityCardList(community.id, name)
                saveWordListInCommunity(community.id, wordList, wordList.id)
            } else {
                const wordList = WordListUtils.createUserCardList(name)
                saveWordList(wordList, wordList.id)
            }
        }
    }

    /**
     * Сохранить список карточек
     * @param data
     * @param id
     */
    const saveWordList = (data: any, id: ID) => {
        dispatch(createWordList({ savedWordList: data, id })).then((r) => {
            setLoading(false)
        }).finally(() => {
            clearAndClose()
        })
    }

    /**
     * Сохранить список слов в сообществе
     * @param communityId
     * @param data
     * @param id
     */
    const saveWordListInCommunity = (communityId: number, data: any, id: ID) => {
        dispatch(createInCommunity({ communityId, data, id })).then((r) => {
            setLoading(false)
        }).finally(() => {
            clearAndClose()
        })
    }

    const onChangeName = (e: any) => {
        const value = e.target.value
        if (value.length !== 0 && !valid) {
            setValid(true)
        }
        setName(value)
    }

    return (
        <Modal title='Создание списка карточек'
               onCancel={clearAndClose}
               footer={[
                   <Button key='submit' onClick={clearAndClose}>
                       Отмена
                   </Button>,
                   <Button
                       disabled={!name}
                       onClick={addCardList}
                       loading={loading}
                       type='primary'
                   >
                       Сохранить
                   </Button>
               ]}
               open={isOpen}>
            <p>Придумайте название для списка карточек, например "Одежда"</p>
            <Input status={!valid ? 'error' : ''} value={name} onChange={onChangeName} size='large'
                   placeholder='Название списка карточек'
                   maxLength={50}
            />
        </Modal>
    )
}

export default AddCardListDialog
