import {
    executePostMethod
} from '../api/rest/restApiUtils'

/**
 * Уведомить админа
 * @method notifyTelegramBot
 */
export function notifyTelegramBot(msg: string) {
    return executePostMethod('/telegram/notify-telegram-bot', { value: msg })
}