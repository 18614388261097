import { useEffect, useState } from 'react'
import { Popconfirm, Table, Tag } from 'antd'
import * as API_PRIVILEGE from '../../../../../shared/http/privilege'
import * as API_USER from '../../../../../shared/http/user'
import { Link } from 'react-router-dom'
import IconClose from '../../../../elements/svg_icons/IconClose'
import { GEButton } from '../../../../../common/buttons'
import { isPremium } from '../../../../../common/utils/user'
import { useAppSelector } from '../../../../../store/hooks'
import { userSelector } from '../../../../../store/global'
import AdminAddPrivilegeModalDialog from '../../../../modalwindows/AdminAddPrivilegeModalDialog'
import Constants from '../../../../../const/Constants'
import DeviceUtils from '../../../../utils/DeviceUtils'
import {GELink} from "../../../../../common/link/GELink";

const Privileges = () => {
    const [users, setUsers] = useState([])
    const [editingUserId, setEditingUserId] = useState('')
    const [showAddPrivilegeDialog, setShowAddPrivilegeDialog] = useState(false)

    const currentUser = useAppSelector(userSelector)

    /**
     * User в статсе редактирования
     * @param user
     * @returns {boolean}
     */
    const isEditing = (user) => user.userId === editingUserId

    /**
     * Перевести в статус edit
     * @param user
     */
    const edit = (user) => {
        setEditingUserId(user.userId)
    }

    /**
     * Сохранить изменения
     */
    const save = () => {
        const updatedUser = users.find((usr) => usr.userId === editingUserId)
        API_PRIVILEGE.updatePrivilegesToUser(updatedUser).then(() => {
            loadPrivileges()
        })
    }

    /**
     * Отменить изменения
     */
    const cancel = () => {
        loadPrivileges()
        setEditingUserId('')
    }

    /**
     * Удалить привилегию у пользователя
     * @param id
     */
    const deletePrivilege = (id) => {
        const newUsers = users.map((usr) => {
            if (usr.userId === editingUserId) {
                usr.privileges = usr.privileges.filter((p) => p.id !== id)
            }
            return usr
        })
        setUsers(newUsers)
    }

    /**
     * Загрузить пользователей с их привилегиями
     */
    const loadPrivileges = () => {
        API_PRIVILEGE.getAllPrivilegesForAllUsers().then((data) => {
            setUsers(data.content)
        })
    }

    /**
     * Добавить привилегию пользователю
     * @param privilege
     */
    const addPrivilege = (privilege) => {
        const newUsers = users.map((usr) => {
            if (usr.userId === editingUserId && !usr.privileges.some((p) => p.id === privilege.id)) {
                usr.privileges.push(privilege)
            }
            return usr
        })
        setUsers(newUsers)
    }

    const addPremium = () => {
        API_USER.switchPremium()
    }

    const goToApp = (appLocation, fallbackLocation) => {

        setTimeout(function() {
            window.location = fallbackLocation
        }, 5)
        window.location = appLocation
    }

    const openApp = () => {
        API_USER.getAuthCode().then(r => {
            const code = r.data.value
            const fallbackUrl = operationSystem === Constants.ANDROID ? Constants.GOOGLE_PLAY_LINK : Constants.APPLE_STORE_LINK
            goToApp(`geekenglish://authbycode?code=${code}&login=${currentUser.login}`, fallbackUrl)
        })
    }


    useEffect(() => {
        loadPrivileges()
    }, [])


    const columns = [
        {
            title: 'Логин пользователя',
            dataIndex: 'login',
            key: 'login',
            render: (text) => <a>{text}</a>
        },
        {
            title: 'Права',
            key: 'privileges',
            dataIndex: 'privileges',
            editable: true,
            render: (_, user) => {
                const editable = isEditing(user)
                return (
                    <>
                        {user.privileges.map((privilege) => {
                            return (
                                <>
                                    <Tag color='geekblue' key={privilege}>
                                        {privilege.name.toUpperCase()}
                                    </Tag>
                                    {editable && (
                                        <IconClose
                                            height={'20'}
                                            width={'20'}
                                            onClick={() => deletePrivilege(privilege.id)}
                                        />
                                    )}
                                </>
                            )
                        })}
                        {editable && <GEButton onClick={() => setShowAddPrivilegeDialog(true)}>Добавить</GEButton>}
                    </>
                )
            }
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (_, user) => {
                const editable = isEditing(user)
                return editable ? (
                    <>
                        <GELink onClick={save} style={{ marginRight: 8 }}>
                            Save
                        </GELink>
                        <Popconfirm title='Точно вертать назад?' onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </>
                ) : (
                    <GELink disabled={editingUserId !== ''} onClick={() => edit(user)}>
                        Edit
                    </GELink>
                )
            }
        }
    ]

    const operationSystem = DeviceUtils.getMobileOperatingSystem()

    return (
        <>
            <Table columns={columns} dataSource={users} />
            <div>Статус премиум : {isPremium(currentUser) ? 'Премиум' : 'Нет премиума'}</div>
            <GEButton onClick={addPremium} type='primary'>Добавить премиум/убрать</GEButton>
            {(operationSystem === Constants.ANDROID || operationSystem === Constants.IPHONE)
            && <GEButton onClick={openApp} type='primary'>Открыть приложение</GEButton>}
            <AdminAddPrivilegeModalDialog
                closeDialog={() => setShowAddPrivilegeDialog(!showAddPrivilegeDialog)}
                addPrivilege={addPrivilege}
                selectedWordListId={1}
                open={showAddPrivilegeDialog}

            />
        </>
    )
}

export default Privileges
