import '../../../styles/scss/banner/banner.scss'
import React, { FC, useEffect, useRef, useState } from 'react'
import { Input, InputRef, Tag, theme } from 'antd'


interface CreateTagElementProps {
    tags: Array<string>,
    setTags: (tags: Array<string>) => void
}


const CreateTagElement: FC<CreateTagElementProps> = ({ tags, setTags }) => {

    const { token } = theme.useToken()
    const [inputVisible, setInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const inputRef = useRef<InputRef | null>(null)

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus()
        }
    }, [inputVisible])

    const handleClose = (removedTag: string) => {
        const newTags = tags.filter((tag) => tag !== removedTag)
        console.log(newTags)
        setTags(newTags)
    }

    const showInput = () => {
        setInputVisible(true)
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
    }

    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue])
        }
        setInputVisible(false)
        setInputValue('')
    }

    const forMap = (tag: string) => {
        const tagElem = (
            <Tag
                color='magenta'
                closable
                onClose={(e) => {
                    e.preventDefault()
                    handleClose(tag)
                }}
            >
                {tag}
            </Tag>
        )
        return (
            <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
        )
    }

    const tagChild = tags.map(forMap)

    const tagPlusStyle: React.CSSProperties = {
        background: token.colorBgContainer,
        borderStyle: 'dashed'
    }

    return (
        <>
            <div style={{ marginBottom: 16 }}>
                {tagChild}
            </div>

            {inputVisible ? (
                <Input
                    ref={inputRef}
                    type='text'
                    size='small'
                    style={{ width: 78 }}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                />
            ) : (

                tags.length < 7 && <Tag onClick={showInput} style={tagPlusStyle}>
                    Добавить тег
                </Tag>
                
            )}
        </>
    )
}

export default CreateTagElement
