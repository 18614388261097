import { createAsyncThunk } from '@reduxjs/toolkit'
import * as API_WORD from '../../shared/http/word'
import { WordDto } from '../../shared/types/api'

export const fetchWords = createAsyncThunk('words/fetchWords', (id: number) => API_WORD.getWords(id))

//@ts-ignore
export const deleteWord = createAsyncThunk('words/deleteWord', ({
                                                                    wordListId,
                                                                    wordId
                                                                }: { wordListId: number, wordId: number }) =>
    //@ts-ignore
    API_WORD.deleteWord(wordListId, { id: wordId }).then(() => ({
        wordId: wordId
    }))
)

//@ts-ignore
export const createNewWord = createAsyncThunk('words/createNewWord', ({
                                                                          wordListId,
                                                                          word
                                                                      }: { wordListId: number, word: WordDto }) =>
    API_WORD.create(word, wordListId).then((res) => ({
        word: res,
        wordId: word.id
    }))
)

//@ts-ignore
export const updateWord = createAsyncThunk('update/updateWord', ({
                                                                     wordListId,
                                                                     word
                                                                 }: { wordListId: number, word: WordDto }) =>
    API_WORD.update(wordListId, word).then((res) => ({
        wordListId: wordListId,
        word: res
    }))
)
