import '../../styles/css/dictionary/modalwindow/modalw.css'
import { FC, useState } from 'react'
import { Button, Input, Modal } from 'antd'
import * as API_COMMUNITY from '../../shared/http/community'
import NotificationsUtils from '../utils/NotificationsUtils'
import CommunityUtils from '../utils/CommunityUtils'
import { CommunityDto, UserDto } from '../../shared/types/api'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { useNavigate } from 'react-router-dom'
import { userSelector } from '../../store/global'
import { openPremiumPage } from '../../common/utils/promocode'
import { communitiesDataSelector, setCommunitiesRoutine } from '../../store/communities'
import { handleHttpError } from '../../common/utils/errors'

interface FindCommunityDialogProps {
    showWindow: boolean,
    cancelWindow: () => void
}

const FindCommunityDialog: FC<FindCommunityDialogProps> = ({ cancelWindow, showWindow }) => {

    const [inviteKey, setInviteKey] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    const dispatch = useAppDispatch()
    const communities = useAppSelector<Array<CommunityDto>>(communitiesDataSelector)

    const user = useAppSelector(userSelector)
    const navigate = useNavigate()

    const handleChange = (event: any) => {
        setInviteKey(event.target.value)
    }

    const clearAndClose = () => {
        setInviteKey('')
        cancelWindow()
    }

    const joinToCommunity = () => {
        if (CommunityUtils.canAddCommunity(user as UserDto)) {
            setLoading(true)
            API_COMMUNITY.joinToCommunity({ inviteKey: inviteKey })
                .then((c) => {
                    const data = c
                    if (!communities.some(uc => uc.id === data.id)) {
                        dispatch(setCommunitiesRoutine([...communities, data]))
                    }
                })
                .catch((err) => {
                    handleHttpError(err, navigate)
                })
                .finally(() => {
                    setLoading(false)
                    clearAndClose()
                })
        } else {
            openPremiumPage(navigate)
        }
    }

    return (
        <Modal destroyOnClose={true} title='Присоединиться к сообществу'
               onCancel={clearAndClose}
               footer={[
                   <Button key='submit' onClick={clearAndClose}>
                       Отмена
                   </Button>,
                   <Button
                       disabled={inviteKey.length === 0}
                       onClick={joinToCommunity}
                       loading={loading}
                       type='primary'
                   >
                       Вступить
                   </Button>
               ]}
               open={showWindow}>
            <Input
                type='text'
                placeholder='Введите ключ сообщества...'
                value={inviteKey}
                onChange={(e) => handleChange(e)}
            />
        </Modal>
    )
}

export default FindCommunityDialog
