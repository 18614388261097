import {
    executeDeleteMethod,
    executeFilePostMethod,
    executeJsonGetMethod,
    executeJsonPostMethod, executeJsonPutMethod,
    executePostMethod,
    executePutMethod
} from '../api/rest/restApiUtils'
import { ID } from '../types/api'

/**
 * Получить списоки готовых слов
 * @method getDoneWordLists
 */
export function getDoneWordLists() {
    return executeJsonGetMethod('/donewordlists').then((resp) => {
        return resp.data.content
    })
}

/**
 * Получить списки готовых слов
 * @method getDoneWordLists
 */
export function getDoneWordListsPage(search: string | undefined, level: string | undefined, tag: string | undefined) {
    const page = 0
    const size = 1000
    //@ts-ignore
    const params = new URLSearchParams({ page: page, size: size, search: search, level: level, tag: tag })
    let keysForDel = [] as any[]
    params.forEach((value, key) => {
        if (value === 'undefined') {
            keysForDel.push(key)
        }
    })
    keysForDel.forEach(key => {
        params.delete(key)
    })
    return executeJsonGetMethod(`/donewordlists/list?` + params).then((resp) => {
        return resp.data.content
    })
}


/**
 * @Deprecated
 * Добавить готовый список
 * @param {number} id
 * @method addDoneWordList
 * @return {Response} Fetch Response
 */
export function addDoneWordList(id: ID) {
    return executeJsonPostMethod('/donewordlists/user/' + id, undefined)
}

/**
 * Удалить готовый список слов
 * @param {number} id
 * @method deleteDoneWordList
 * @return {Response} Fetch Response
 */
export function deleteDoneWordList(id: ID) {
    return executeDeleteMethod('/donewordlists/user/' + id, undefined)
}

/**
 * Удалить готовый список слов
 * @param {number} id
 * @method deleteDoneWordList
 * @return {Response} Fetch Response
 */
export function likeOrDislike(id: ID) {
    return executeJsonPutMethod(`/donewordlists/${id}/likes`, undefined)
}

/**
 * Скопировать готовый список слов
 * @param {number} id
 * @method copyDoneWordList
 * @return {Response} Fetch Response
 */
export function copyDoneWordList(id: ID) {
    return executeJsonPostMethod('/donewordlists/' + id + '/copy', undefined)
}

/**
 * Превратить пользовательский список в готовый
 * @param {number} id
 * @method addDoneWordList
 * @return {Response} Fetch Response
 */
export function transformUserListToDoneList(id: ID) {
    return executePostMethod('/user/wordlists/transform/' + id, undefined)
}


/**
 * Сделать все слова в списке с заглавной буквы
 * @param {number} id
 * @method makeLettersUppercase
 * @return {Response} Fetch Response
 */
export function makeLettersUppercase(id: ID) {
    return executePostMethod('/user/wordlists/uppercase-cards/' + id, undefined)
}

/**
 * Изменить активность у готового списка слов
 * @param {number} id
 * @method changeActiveWordList
 * @return {Response} Fetch Response
 */
export function changeActiveWordList(id: ID) {
    return executePutMethod('/donewordlists/' + id + '/active', undefined)
}

/**
 * Изменить тип готового списка слов (премиум)
 * @param {number} id
 * @method changeActiveWordList
 * @return {Response} Fetch Response
 */
export function changePremiumWordList(id: ID) {
    return executePutMethod('/donewordlists/' + id + '/premium', undefined)
}

/**
 * Смена изображения готового списка слов
 * @method updatePhoto
 * @return {Response} Fetch Response
 */
export function updatePhoto(id: ID, photo: any) {
    let formData = new FormData()
    formData.append('photo', photo)
    return executeFilePostMethod('/donewordlists/' + id + '/photo', formData)
}