import { executeJsonGetMethod, executeJsonPostMethod } from '../api/rest/restApiUtils'

/**
 * Получить диалоги
 * @method getDialogs
 * @return {Response} Fetch Response
 */
export function getDialogs() {
    return executeJsonGetMethod('/user/dialogs')
}

/**
 * Получить сообщения
 * @method getAllMoneyPacks
 * @param {number} id диалога
 * @return {Response} Fetch Response
 */
export function getMessages(id) {
    return executeJsonGetMethod(`/user/dialogs/${id}/messages`)
}

/**
 * Отправить сообщение
 * @method sendMessage
 * @param {Object} msg диалога
 * @return {Response} Fetch Response
 */
export function sendMessage(msg) {
    return executeJsonPostMethod(`/user/dialogs/message`, msg).then((resp) => {
        return Promise.resolve(resp.data)
    })
}
