import PropTypes from 'prop-types'
import Constants from '../../../../../const/Constants'
import { GEButton } from '../../../../../common/buttons'

const AddWordButton = ({ selectedCategory, wordsAmount, addWord }) => {
    const isVisible = selectedCategory !== null && wordsAmount < Constants.MAX_COUNT_WORDS_IN_WORD_LIST
    return (
        <div className={isVisible ? 'add-button-area' : 'ge-invisible'}>
            <GEButton type='primary' onClick={addWord}>
                Добавить карточку
            </GEButton>
        </div>
    )
}

AddWordButton.propTypes = {
    selectedCategory: PropTypes.object,
    wordsAmount: PropTypes.number,
    addWord: PropTypes.func
}
export default AddWordButton
