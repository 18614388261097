import PropTypes from 'prop-types'

const Example = (props) => {
    const engExample = Array.isArray(props.engExample)
        ? props.engExample.reduce((prev, curr) => [prev, ' ', curr])
        : props.engExample
    const rusExample = Array.isArray(props.rusExample)
        ? props.rusExample.reduce((prev, curr) => [prev, ' ', curr])
        : props.rusExample
    return (
        <div className='example'>
            <div> {engExample} </div>
            <div> {rusExample} </div>
        </div>
    )
}

export default Example

Example.propTypes = {
    engExample: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    rusExample: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}
