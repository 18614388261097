import { v4 as uuidv4 } from 'uuid'
import Constants from '../../../../../const/Constants'

export type valueRealValue = { value: string, realValue: string, id: string }
export type letterValue = { letterId: string, value: string }

const GuessWordUtils = {

    /**
     * Функция для генерации массива из букв (некоторые из которых скрыты)
     * @param phrase - фраза или слово
     * @param percent - процент скрытых букв
     */
    generateArray: function(phrase: string, percent: number): Array<{ letters: Array<valueRealValue>, id: string }> {
        let wordCounter = 0
        let chunk
        const innerWidth = window.innerWidth
        if (innerWidth <= 640) {
            chunk = Math.round((innerWidth - 10) / 45)
        } else {
            chunk = Math.round((innerWidth - 400) / 80)
        }
        const fullPhrase = phrase.replace(' ', '')
        const sliceValue = fullPhrase.length - Math.floor((fullPhrase.length * percent) / 100)
        const randomIndexes = Array.from(Array(fullPhrase.length).keys())
            .sort(() => Math.random() - 0.5)
            .slice(0, sliceValue)
        const myRegexp = new RegExp(`^[${Constants.ALL_SYMBOL}]`, 'g')

        const phraseArray = phrase.toUpperCase().split(' ')
        let result = phraseArray.map((word) => {
            let resultWord = word.split('').map((letter, index) => {
                let value = ''
                if (word.length > 1) {
                    value = randomIndexes.includes(wordCounter + index) && !!letter.match(myRegexp) ? '' : letter
                }
                return {
                    value: value,
                    realValue: letter,
                    id: uuidv4()
                }
            })
            wordCounter += word.length
            return resultWord
        })
        for (let index = 0; index < result.length; ++index) {
            const word = result[index]
            if (word && word.length > chunk) {
                result.splice(index, 1)
                let i,
                    j,
                    counter = 0
                for (i = 0, j = word.length; i < j; i += chunk) {
                    result.splice(index + counter, 0, word.slice(i, i + chunk))
                    if (i + chunk < word.length) {
                        //@ts-ignore
                        result.splice(index + counter + 1, 0, [{ value: '-', realValue: '-' }])
                        counter += 2
                    }
                }
            }
        }
        return this.arrayToObjectArray(result)
    },

    arrayToObjectArray: function(phrase: Array<Array<valueRealValue>>): Array<{ letters: Array<valueRealValue>, id: string }> {
        return phrase.map((word) => {
            return { letters: word, id: uuidv4() }
        })
    },

    allAnswersIsTrue: function(userAnswers: Array<letterValue>, skippedLetters: Array<valueRealValue>): boolean {
        for (const sl of skippedLetters) {
            const id = sl.id
            const realValue = sl.realValue
            if (!userAnswers.some(ua => ua.letterId === id && ua.value === realValue)) {
                return false
            }
        }
        return true
    }

}

export default GuessWordUtils
