import { FC } from 'react'

interface MagicIconProps {

}

export const MagicIcon: FC<MagicIconProps> = () => {

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='15'
            fill='none'
            viewBox='0 0 18 17'
        >
            <path
                fill='#1677ff'
                d='M5.625 4.2L3.75 5.25 4.8 3.375 3.75 1.5l1.875 1.05L7.5 1.5 6.45 3.375 7.5 5.25 5.625 4.2zm9 7.35L16.5 10.5l-1.05 1.875 1.05 1.875-1.875-1.05-1.875 1.05 1.05-1.875-1.05-1.875 1.875 1.05zM16.5 1.5l-1.05 1.875L16.5 5.25 14.625 4.2 12.75 5.25l1.05-1.875L12.75 1.5l1.875 1.05L16.5 1.5zm-6.495 8.085l1.83-1.83-1.59-1.59-1.83 1.83 1.59 1.59zm.772-4.118l1.755 1.756c.293.277.293.764 0 1.057L3.78 17.032c-.293.293-.78.293-1.058 0L.967 15.277c-.292-.277-.292-.764 0-1.057L9.72 5.467c.292-.292.78-.292 1.057 0z'
            ></path>
        </svg>
    )
}
