import * as API_DONE_WORDLIST from '../../../../shared/http/done-word-list'
import * as API_WORDLIST from '../../../../shared/http/word-list'
import CheckBox from '../../../elements/switch/CheckBox'
import { Upload } from 'antd'
import PropTypes from 'prop-types'
import { GEButton } from '../../../../common/buttons'

const AdminDoneWordListItemPanel = (props) => {
    const changeActive = () => {
        let wordListCurrent = props.wordList
        wordListCurrent.active = !wordListCurrent.active
        API_DONE_WORDLIST.changeActiveWordList(props.wordList.id).then(() => {
            props.updateActiveFlag(wordListCurrent.id, wordListCurrent.active)
        })
    }

    const changePremium = () => {
        let wordListCurrent = props.wordList
        wordListCurrent.premium = !wordListCurrent.premium
        API_DONE_WORDLIST.changePremiumWordList(props.wordList.id).then(() => {
            props.updatePremiumFlag(wordListCurrent.id, wordListCurrent.premium)
        })
    }

    const uploadImage = (info) => {
        API_DONE_WORDLIST.updatePhoto(info.data, info.file).then(() => {
            info.onSuccess()
        })
    }

    const deleteList = () => {
        API_WORDLIST.deleteWordList(props.wordList.id)
    }

    return (
        <li className='done_word_lists_item_admin_panel margin10top'>
            <CheckBox onChange={changeActive} text={'Active'} value={props.wordList.active}
                      toolTipText={'Сделать список активным'} />
            <CheckBox onChange={changePremium} text={'Premium'} value={props.wordList.premium}
                      toolTipText={'Сделать список премиумным'} />
            <Upload customRequest={uploadImage} data={props.wordList.id} accept='image/png, image/jpeg'>
                <GEButton>Click to Upload</GEButton>
            </Upload>
            <GEButton onClick={deleteList}>Удалить список ADMIN</GEButton>
        </li>
    )
}

AdminDoneWordListItemPanel.propTypes = {
    wordList: PropTypes.object.isRequired,
    updateActiveFlag: PropTypes.func.isRequired,
    updatePremiumFlag: PropTypes.func.isRequired
}

export default AdminDoneWordListItemPanel
