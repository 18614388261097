import PropTypes from 'prop-types'
import { GEButton } from '../../../../../common/buttons'

const SaveAllWordButton = (props) => {
    return (
        <div className='margin10right'>
            <GEButton disabled={props.disabled} type='primary' onClick={props.saveAllWords}>
                Сохранить все
            </GEButton>
        </div>
    )
}

SaveAllWordButton.propTypes = {
    saveAllWords: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}

export default SaveAllWordButton
