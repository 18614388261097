import { FC } from 'react'
import IconArrowBorderedLeft from '../../../../elements/svg_icons/IconArrowBorderedLeft'
import IconArrowBorderedRight from '../../../../elements/svg_icons/IconArrowBorderedRight'

interface CardArrowsProps {
    nextWordCard: () => void
    prevWordCard: () => void
}

const CardArrows: FC<CardArrowsProps> = ({ nextWordCard, prevWordCard }) => {
    return (
        <div className='word-card__arrows'>
            <IconArrowBorderedLeft onClick={prevWordCard} />
            <IconArrowBorderedRight onClick={nextWordCard} />
        </div>
    )
}

export default CardArrows
